import React, { useState, useEffect } from "react";
import {
  Dialog,
  Button,
  Grid,
  Icon,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";

import { createSalesman, updateSalesman } from "../../redux/actions/SalesmanActions"

import Input from "../../components/Input"
import { listManager, listManagerIndicator, updateManagerIndicator } from "../../redux/actions/ManagerActions"


import Swal from 'sweetalert2'

const MemberEditorDialog = (props) => {

  const { handleClose, open, userRedux, uid, managers, indicators } = props

  const [usuario, setUsuario] = useState(uid != null ? uid?.user?.id : "");
  const [options, setOptions] = useState([])

  const [diaryProduction, setDiaryProduction] = useState("")
  const [diaryRevenue, setDiaryRevenue] = useState("")
  const [diaryClientsByDay, setDiaryClientsByDay] = useState("")

  const [message, setMessage] = useState("");

  function TestaCPF(strCPF) {
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF == "00000000000") return false;

    for (var i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (var i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const handleFormSubmit = () => {
    props.dispatch(updateManagerIndicator(userRedux?.token, userRedux?.company, usuario, [{
      indicator: {
        id: 1,
        value: "VOLUME_PRODUCAO_DIARIA",
        name: "Volume Produção Diária"
      },
      permission: diaryProduction
    },
      {
        indicator: {
          id: 2,
          value: "FATURAMENTO_BRUTO_DIA",
          name: "Faturamento Bruto do Dia"
        },
        permission: diaryRevenue
      },
      {
        indicator: {
          id: 3,
          value: "CLIENTES_ATENDIDOS_DIA",
          name: "Clientes atendidos por dia"
        },
        permission: diaryClientsByDay
      }]))
      handleClose()
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  useEffect(() => {
    props.dispatch(listManager(userRedux?.token, userRedux?.company))
  }, [])

  useEffect(() => {
    if (managers != null) {
      console.log("managers", managers)
      var newOptions = []
      managers.map(item => {
        newOptions.push({ label: item?.user?.name, value: item?.user?.id })
      })
      setOptions(newOptions)
    }
  }, [managers])

  useEffect(() => {
    if (usuario != "") {
      props.dispatch(listManagerIndicator(userRedux?.token, userRedux?.company, usuario))
    }
  }, [usuario])

  useEffect(() => {
    if (indicators != null) {
      setDiaryProduction(indicators.find(item => (item?.indicator?.value == "VOLUME_PRODUCAO_DIARIA" && item?.permission == true)) != undefined ? true : false)
      setDiaryRevenue(indicators.find(item => (item?.indicator?.value == "FATURAMENTO_BRUTO_DIA" && item?.permission == true)) != undefined ? true : false)
      setDiaryClientsByDay(indicators.find(item => (item?.indicator?.value == "CLIENTES_ATENDIDOS_DIA" && item?.permission == true)) != undefined ? true : false)
    }
  }, [indicators])

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="p-24" style={{ width: "400px" }}>
        <h4 className="mb-20">Adicionar Indicador</h4>
        <ValidatorForm onSubmit={() => {
          handleFormSubmit()
        }}>
          <Grid className="mb-16" container spacing={10}>
            <Grid item sm={12}>
              <label className="mb-4">Usuario</label>
              <select onChange={(event) => { setUsuario(event.target.value) }} className="w-100 mb-16" style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                <option value=""></option>
                {
                  options.map(item =>
                    <option selected={item?.value == usuario} value={item?.value}>{item?.label}</option>
                  )
                }
              </select>
              {
                usuario != "" && <div style={{ display: "flex", flexDirection: "column" }}>
                  <label className="mb-4" style={{ flex: 1 }}>Indicadores</label>
                  <FormControlLabel
                    className="flex-grow-1"
                    style={{ flex: 1 }}
                    onChange={() => {
                      setDiaryProduction(!diaryProduction)
                    }}
                    control={<Checkbox checked={diaryProduction} />}
                    label={
                      "Volume Produção Diária"
                    }
                  />
                  <FormControlLabel
                    className="flex-grow-1"
                    style={{ flex: 1 }}
                    onChange={() => {
                      setDiaryRevenue(!diaryRevenue)
                    }}
                    control={<Checkbox checked={diaryRevenue} />}
                    label={
                      "Faturamento Bruto do Dia"
                    }
                  />
                  <FormControlLabel
                    className="flex-grow-1"
                    style={{ flex: 1 }}
                    onChange={() => {
                      setDiaryClientsByDay(!diaryClientsByDay)
                    }}
                    control={<Checkbox checked={diaryClientsByDay} />}
                    label={
                      "Clientes Atendidos por Dia"
                    }
                  />

                </div>
              }


              {
                message != "" && <div style={{ backgroundColor: "rgba(255,0,0,.2)", padding: 5, marginTop: 10 }}><Icon style={{ color: "red", marginRight: 5, marginLeft: 5 }} fontSize="small">close</Icon><span style={{ color: "red", fontWeight: "bold" }}>{message}</span></div>
              }
            </Grid>
          </Grid>

          <div className="flex flex-space-between flex-middle">
            <Button variant="contained" color="primary" type="submit">
              Salvar
            </Button>
            <Button onClick={() => {
              handleClose()
            }}>Cancelar</Button>
          </div>
        </ValidatorForm>
      </div>
    </Dialog>
  );
}

const mapStateToProps = state => ({
  userRedux: state.user,
  loading: state.salesman.loading,
  managers: state.manager.managers,
  indicators: state.manager.indicators,
  error: state.salesman.error,
  success: state.salesman.success,
});

export default connect(mapStateToProps)(MemberEditorDialog);