import React, { useState, useEffect } from "react";
import {
  Dialog,
  Button,
  Grid,
  Icon
} from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";

import { createException, updateException } from "../../redux/actions/ExceptionActions"
import IntegrationReactSelect from "./ReactSelect"

import Input from "./../../components/Input"

import Swal from 'sweetalert2'

const MemberEditorDialog = (props) => {

  const { handleClose, open, user, uid } = props

  const [nome, setNome] = useState(uid != null ? uid?.name : "");
  const [latitude, setLatitude] = useState(uid != null ? uid?.latitude : "");
  const [longitude, setLongitude] = useState(uid != null ? uid?.longitude : "");
  const [radius, setRadius] = useState(uid != null ? uid?.radius : "");
  const [type, setType] = useState(uid != null ? uid?.type : "");

  const [message, setMessage] = useState("");

  const handleFormSubmit = () => {

    if (nome == "") {
      setMessage("Preencha o nome do ponto de exceção")
    } else if (latitude == "") {
      setMessage("Preencha a latitude do ponto de exceção")
    } else if (longitude == "") {
      setMessage("Preencha a longitude do ponto de exceção")
    } else if (radius == "") {
      setMessage("Preencha o raio do ponto de exceção")
    } else {
      handleClose()
      Swal.fire({
        icon: 'warning',
        title: uid == null ? "Deseja realmente adicionar este ponto de exceção?" : "Deseja realmente editar este ponto de exceção?",
        showDenyButton: true,
        confirmButtonColor: "#f78726",
        denyButtonColor: "#262d47",
        confirmButtonText: uid == null ? 'Adicionar' : "Editar",
        denyButtonText: uid == null ? `Não adicionar` : "Não Editar",
      }).then((result) => {
        if (result.isConfirmed) {
          if (uid == null) {
            props.dispatch(createException(user?.token, {
              name: nome,
              latitude: parseFloat(latitude),
              longitude: parseFloat(longitude),
              radius: parseInt(radius),
              type
            }, user?.company))
          } else {
            props.dispatch(updateException(user?.token, {
              id: uid?.id,
              name: nome,
              latitude: parseFloat(latitude),
              longitude: parseFloat(longitude),
              radius: parseInt(radius),
              type
            }, user?.company))
          }
        }
      })
    }
  }

  useEffect(() => {
    console.log("uid", uid)
  })

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="p-24" style={{ width: "400px" }}>
        <h4 className="mb-20">Adicionar Ponto De Exceção</h4>
        <ValidatorForm onSubmit={() => {
          handleFormSubmit()
        }}>
          <Grid className="mb-16" container spacing={10}>
            <Grid item sm={12}>
              <label className="mb-4">Nome</label>
              <Input value={nome} style={{ marginTop: -50 }} onChange={(event) => {
                setNome(event.target.value)
              }} />

              <label className="mb-4">Latitude</label>
              <Input value={latitude} onChange={(event) => {
                setLatitude(event.target.value)
              }} />
              <label className="mb-4">Longitude</label>
              <Input value={longitude} onChange={(event) => {
                setLongitude(event.target.value)
              }} />
              <label className="mb-4">Raio (em Metros)</label>
              <Input value={radius} onChange={(event) => {
                setRadius(event.target.value)
              }} />
              <label className="mb-4">Tipo</label>
              <IntegrationReactSelect data={[
                {
                  label: "Descarte",
                  value: "DISCARD",
                },
                {
                  label: "Exceção",
                  value: "EXCEPTION",
                },
                {
                  label: "Lavagem",
                  value: "WASHING",
                }
              ]} 
              selected={type}
              onChange={value => {
                setType(value)
              }} />


              {
                message != "" && <div style={{ backgroundColor: "rgba(255,0,0,.2)", padding: 5, marginTop: 10 }}><Icon style={{ color: "red", marginRight: 5, marginLeft: 5 }} fontSize="small">close</Icon><span style={{ color: "red", fontWeight: "bold" }}>{message}</span></div>
              }
            </Grid>
          </Grid>

          <div className="flex flex-space-between flex-middle">
            <Button variant="contained" color="primary" type="submit">
              Salvar
            </Button>
            <Button onClick={() => {
              handleClose()
            }}>Cancelar</Button>
          </div>
        </ValidatorForm>
      </div>
    </Dialog>
  );
}

const mapStateToProps = state => ({
  user: state.user,
  loading: state.exception.loading,
  error: state.exception.error,
  success: state.exception.success,
});

export default connect(mapStateToProps)(MemberEditorDialog);