import UserTable from "./ExceededDistanceAlert";

const ExceededDistanceAlert = [
  {
    path: "/distancia-excedida",
    exact: true,
    component: UserTable
  }
];

export default ExceededDistanceAlert;
