import Inbox from "./AppInbox";

const inboxRoute = [
  {
    path: "/inbox",
    exact: true,
    component: Inbox
  }
];

export default inboxRoute;
