import history from "history.js";
import jwtAuthService from "../../services/jwtAuthService";
import axios from "axios";


export const LIST_BRANCH_LOADING = "LIST_BRANCH_LOADING";
export const LIST_BRANCH_SUCCESS = "LIST_BRANCH_SUCCESS";
export const LIST_BRANCH_FAILED = "LIST_BRANCH_FAILED";
export const UPDATE_BRANCH_LOADING = "UPDATE_BRANCH_LOADING";
export const UPDATE_BRANCH_SUCCESS = "UPDATE_BRANCH_SUCCESS";
export const UPDATE_BRANCH_FAILED = "UPDATE_BRANCH_FAILED";
export const DELETE_BRANCH_LOADING = "DELETE_BRANCH_LOADING";
export const DELETE_BRANCH_SUCCESS = "DELETE_BRANCH_SUCCESS";
export const DELETE_BRANCH_FAILED = "DELETE_BRANCH_FAILED";
export const CREATE_BRANCH_LOADING = "CREATE_BRANCH_LOADING";
export const CREATE_BRANCH_SUCCESS = "CREATE_BRANCH_SUCCESS";
export const CREATE_BRANCH_FAILED = "CREATE_BRANCH_FAILED";

export const CLEAR = "CLEAR";

export function listBranch(token, company) {
  return dispatch => {
    dispatch({
      type: LIST_BRANCH_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/company/${company}/branch`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_BRANCH_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_BRANCH_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function createBranch(token, data, company) {
  return dispatch => {
    dispatch({
      type: CREATE_BRANCH_LOADING,
    });
    axios({
      method: 'post',
      url: `https://api.stonik.com.br/web/company/${company}/branch`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: CREATE_BRANCH_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: CREATE_BRANCH_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}
export function updateBranch(token, data, id, company) {
  return dispatch => {
    dispatch({
      type: UPDATE_BRANCH_LOADING,
    });
    axios({
      method: 'put',
      url: `https://api.stonik.com.br/web/company/${company}/branch/${id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: UPDATE_BRANCH_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: UPDATE_BRANCH_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function deleteBranch(token, id, company) {
  return dispatch => {
    dispatch({
      type: DELETE_BRANCH_LOADING,
    });
    axios({
      method: 'delete',
      url: `https://api.stonik.com.br/web/company/${company}/branch/${id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: DELETE_BRANCH_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: DELETE_BRANCH_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function clear() {
  return dispatch => {
    dispatch({
      type: CLEAR
    });
  };
}
