import React, { useEffect, useState } from "react";
import {
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Icon,
  TablePagination,
  Button,
  Card
} from "@material-ui/core";
import MemberEditorDialog from "./MemberEditorDialog";
import { Breadcrumb, ConfirmationDialog } from "egret";
import shortid from "shortid";

import { listClient, listNotClient, deleteClient, clear } from "../../redux/actions/ClientActions"
import { listModules, listUserCompany } from "../../redux/actions/ModuleActions"

import { connect } from "react-redux";

import Swal from 'sweetalert2'

const CrudTable = (props) => {

  const {
    userRedux,
    fleets,
    clients,
    modules,
    not_clients,
    user_company,
    loading,
    success,
    error,
  } = props

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [uid, setUid] = useState(null)
  const [clientsList, setClientsList] = useState([])
  const [modulesList, setModulesList] = useState([])
  const [userCompanyList, setUserCompanyList] = useState([])
  const [notClientsList, setNotClientsList] = useState([])
  const [user, setUser] = useState("")
  const [shouldOpenEditorDialog, setShouldOpenEditorDialog] = useState(false)
  const [shouldOpenConfirmationDialog, setShouldOpenConfirmationDialog] = useState(false)

  const setPageFunction = page => {
    setPage(page);
  };

  const setRowsPerPageFunction = event => {
    setRowsPerPage(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPageFunction(newPage);
  };

  const handleDialogCloseFunction = () => {
    setShouldOpenEditorDialog(false)
    setShouldOpenConfirmationDialog(false)
  };

  const handleDeleteUser = user => {
    setShouldOpenConfirmationDialog(true)
    setUid(user?.client?.id)
  };

  const handleConfirmationResponse = () => {
    props.dispatch(deleteClient(userRedux?.token, uid, userRedux?.company))
  };

  const translateProfile = (profile) => {
    switch (profile) {
      case "DRIVER":
        return "MOTORISTA"
      case "MANAGER":
        return "GESTOR"
      case "OPERATOR":
        return "OPERADOR"
      case "BOMBER":
        return "BOMBISTA"
      case "SELLER":
        return "VENDEDOR"
      case "WRITER":
        return "DIGITADOR"
      case "CLIENT":
        return "CLIENT"
      case "SUPER_ADM":
        return "SA"
      default:
        return "CLIENT"
    }
  }

  useEffect(() => {
    // props.dispatch(listClient(userRedux?.token, userRedux?.company))
    props.dispatch(listUserCompany(userRedux?.token, userRedux?.company))
    // props.dispatch(listNotClient(userRedux?.token, userRedux?.company, 3))
    props.dispatch(listModules(userRedux?.token))
    console.log(userRedux?.token)
  }, [])

  useEffect(() => {
    console.log("clients 2", clients)
    clients != null && setClientsList(clients)
  }, [clients])

  useEffect(() => {
    console.log("not_clients 2", not_clients)
    not_clients != null && setNotClientsList(not_clients)
  }, [not_clients])

  useEffect(() => {
    console.log("modules", modules)
    modules != null && setModulesList(modules)
  }, [modules])

  useEffect(() => {
    console.log("user_company", user_company)

    if (user_company != null) {
      var newArray = []
      user_company.map(item => {
        item.type != "BOMBER" && userRedux?.id != item?.id && newArray.push(item)
      })
      setUserCompanyList(newArray)
    }

  }, [user_company])

  useEffect(() => {
    if (success != false) {
      setShouldOpenConfirmationDialog(false)
      Swal.fire({
        icon: 'success',
        title: success,
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(() => {
        props.dispatch(clear())
        window.location.reload()
      }, 3500)
    }
  }, [success])

  useEffect(() => {
    if (error != null) {
      console.log("Error", error)
      setShouldOpenConfirmationDialog(false)

      Swal.fire({
        icon: 'error',
        title: error,
        showConfirmButton: false,
        timer: 3000
      })

      setTimeout(() => {
        props.dispatch(clear())
        window.location.reload()
      }, 3500)
    }
  }, [error])

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: "Minhas Configurações" }]} />
      </div>

      {/* <Button
        className="mb-16"
        variant="contained"
        color="primary"
        onClick={() => {
          setUid(null)
          setShouldOpenEditorDialog(true)
        }}
      >
        Adicionar configuração
      </Button> */}
      <Card className="w-100 overflow-auto" elevation={6}>
        <Table className="crud-table" style={{ whiteSpace: "pre", minWidth: "750px" }}>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Perfil</TableCell>
              <TableCell>CPF</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell align="center">Ação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userCompanyList.length > 0 && userCompanyList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => (
                <TableRow key={shortid.generate()}>
                  <TableCell className="px-0" align="left">
                    {item?.name}
                  </TableCell>
                  <TableCell className="px-0" align="left">
                    {translateProfile(item?.type)}
                  </TableCell>
                  <TableCell className="px-0" align="left">
                    {item?.cpf}
                  </TableCell>
                  <TableCell className="px-0" align="left">
                    {item?.email}
                  </TableCell>
                  <TableCell className="px-0 border-none" align="center">
                    {/* <IconButton onClick={() => this.handleDeleteUser(user)}>
                      <Icon style={{ color: "red" }}>close</Icon>
                    </IconButton>
                    <IconButton onClick={() => this.handleDeleteUser(user)}>
                      <Icon style={{ color: "green" }}>check</Icon>
                    </IconButton> */}
                    <IconButton
                      onClick={() => {
                        setUid(item)
                        setShouldOpenEditorDialog(true)
                      }}
                    >
                      <Icon color="primary">edit</Icon>
                    </IconButton>
                    {/* <IconButton
                    //  onClick={() => handleDeleteUser(item)}
                     >
                      <Icon color="error">delete</Icon>
                    </IconButton> */}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        <TablePagination
          className="px-16"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={userCompanyList?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Linhas por Página"
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={setRowsPerPageFunction}
        />

        {shouldOpenEditorDialog && (
          <MemberEditorDialog
            handleClose={handleDialogCloseFunction}
            clients={clientsList}
            modules={modulesList}
            open={shouldOpenEditorDialog}
            uid={uid}
          />
        )}
        {shouldOpenConfirmationDialog && (
          <ConfirmationDialog
            open={shouldOpenConfirmationDialog}
            onConfirmDialogClose={handleDialogCloseFunction}
            onYesClick={handleConfirmationResponse}
            text="Tem certeza que deseja excluir este registro?"
            title="Excluir registro"
            confirmButtonText="Sim"
            declineButtonText="Não"
          />
        )}
      </Card>
    </div>
  );

}

const mapStateToProps = state => ({
  userRedux: state.user,
  clients: state.client.clients,
  modules: state.module.modules,
  user_company: state.module.user_company,
  not_clients: state.client.not_clients,
  loading: state.module.loading,
  error: state.module.error,
  success: state.module.success
});

export default connect(mapStateToProps)(CrudTable);
