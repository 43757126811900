import React, { useState, useEffect } from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

import { InfoBox } from "react-google-maps/lib/components/addons/InfoBox";
import { Card } from "@material-ui/core";

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDF6_Ns4TY6soa20mgwmJb9u-gUeOuylw4&callback=&v=weekly",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap defaultZoom={8} defaultCenter={props.coords}>
    <Marker
      isMarkerShown={false}
      position={props.coords}
      onClick={props.onMarkerClick}
    >
      <InfoBox options={{ closeBoxURL: ``, enableEventPropagation: true }}>
        <Card className="p-16">
          <p className="white-space-pre m-0">Aqui foi a descarga</p>
        </Card>
      </InfoBox>
    </Marker>
  </GoogleMap>
));

const MarkerMap = props => {
  var timer;

  const {coords} = props

  const [isMarkerShown, setIsMarkerShown] = useState(false)

  useEffect(() => {
    delayedShowMarker()

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [])

  const delayedShowMarker = () => {
    timer = setTimeout(() => {
      setIsMarkerShown(true)
    }, 3000);
  };

  const handleMarkerClick = () => {
    setIsMarkerShown(false)
    delayedShowMarker();
  };

  return (
    <MyMapComponent
      isMarkerShown={isMarkerShown}
      onMarkerClick={handleMarkerClick}
      coords={coords}
    />
  );
}

export default MarkerMap;
