export const authRoles = {
  sa: 'SA',
  gestor: "GESTOR",
  admin: 'ADMIN',
  seller: "VENDEDOR",
  driver: "MOTORISTA",
  client: 'CLIENT',
  operator: "OPERADOR",
  bomber: "BOMBISTA",
  writer: "DIGITADOR",
}