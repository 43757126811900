import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from "@material-ui/core";
import { isThisWeek } from 'date-fns';
// Create styles
const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingTop: 10,
        paddingHorizontal: 10
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    header: {
        width: '15%',
        fontSize: 8,
        textAlign: "center",
        fontWeight: "800"
    },
    subtitle: {
        width: '15%',
        textAlign: "center",
        fontSize: 7,
    }
});

// Create Document Component
export const MyDocument = (props) => {
    const { data } = props
    console.warn("props pdf", props)

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.tableContainer}>
                    <View style={[styles.row, { marginBottom: 10 }]}>
                        <Text style={styles.header}>Data</Text>
                        <Text style={styles.header}>Placa</Text>
                        <Text style={styles.header}>Primeira nota</Text>
                        <Text style={styles.header}>Horario primeira nota</Text>
                        <Text style={styles.header}>Qtd de notas</Text>
                        <Text style={styles.header}>Qtd de entregas</Text>
                        <Text style={styles.header}>Qtd de inconsistências</Text>
                    </View>
                    {
                        data?.map(item => (
                            <View style={styles.row}>
                                <Text style={styles.subtitle}>{item.date}</Text>
                                <Text style={styles.subtitle}>{item.plate}</Text>
                                <Text style={styles.subtitle}>{item.firstInvoice}</Text>
                                <Text style={styles.subtitle}>{item.hourFirstInvoice}</Text>
                                <Text style={styles.subtitle}>{item.qntInvoices}</Text>
                                <Text style={styles.subtitle}>{item.qntDischarges}</Text>
                                <Text style={styles.subtitle}>{item.qntInconsistency}</Text>
                            </View>
                        ))
                    }

                </View>

            </Page>
        </Document>
    )
};

{/* <table style={{ whiteSpace: "pre", minWidth: "750px" }}>
                    <tr>
                        <th style={{ fontSize: 10 }}>Nome</th>
                        <th style={{ fontSize: 10 }}>Cpf/Cnpj</th>
                        <th style={{ fontSize: 10 }}>Endereço</th>
                        <th style={{ fontSize: 10 }}>N° contrato</th>
                        <th style={{ fontSize: 10 }}>N° pedido</th>
                        <th style={{ fontSize: 10 }}>Produto</th>
                        <th style={{ fontSize: 10 }}>Tipo Concreto</th>
                        <th style={{ fontSize: 10 }}>Estado</th>
                        <th style={{ fontSize: 10 }}>Serviço de bombeamento</th>
                        <th style={{ fontSize: 10 }}>Valor total do ticket</th>
                    </tr>
                    <tr>
                        <td className="px-0" align="left" style={{ fontSize: 10 }}>Bruno</td>
                        <td className="px-0" align="left" style={{ fontSize: 10 }}>111.111.111-11</td>
                        <td className="px-0" align="left" style={{ fontSize: 10 }}>Carazinho - Centro</td>
                        <td className="px-0" align="left" style={{ fontSize: 10 }}>123456789</td>
                        <td className="px-0" align="left" style={{ fontSize: 10 }}>123456789</td>
                        <td className="px-0" align="left" style={{ fontSize: 10 }}>argamassa</td>
                        <td className="px-0" align="left" style={{ fontSize: 10 }}>fck 30</td>
                        <td className="px-0" align="left" style={{ fontSize: 10 }}>DF</td>
                        <td className="px-0" align="left" style={{ fontSize: 10 }}>Sim</td>
                        <td className="px-0" align="left" style={{ fontSize: 10 }}>R$ 20,00</td>
                    </tr>
                </table> */}