import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "@material-ui/core";
import TouchRipple from "@material-ui/core/ButtonBase";
import EgretVerticalNavExpansionPanel from "./EgretVerticalNavExpansionPanel";
import { withStyles } from "@material-ui/styles";
import localStorageService from "../../../../src/app/services/localStorageService"
import { authRoles } from "../../../app/auth/authRoles";

const styles = theme => ({
  expandIcon: {
    transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    transform: "rotate(90deg)"
  },
  collapseIcon: {
    transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    transform: "rotate(0deg)"
  }
});

class EgretVerticalNav extends Component {
  state = {
    collapsed: true
  };

  isForShowing = (pagRole) => {
    var auth_user = localStorageService.getItem("auth_user")
    var user_auth_role = auth_user?.role
    if (pagRole.indexOf(user_auth_role) == -1) {
      return false
    } else {
      return true
    }

  }

  renderChildrens = (children) => {
    return children?.map((item, index) => {
      return item.name != "" && (
      <NavLink key={index} to={item.path} style={{backgroundColor: "#23293e"}} className="nav-item">
        <TouchRipple key={item.name} name="child" className="w-100">
          {(() => {
            if (item.icon) {
              return (
                <Icon className="item-icon text-middle" style={{color:"white"}}>{item.icon}</Icon>
              );
            } else {
              return (
                <span className="item-icon icon-text" style={{color:"white"}}>{item.iconText}</span>
              );
            }
          })()}
          <span className="text-middle pl-20 item-text" style={{color:"white"}}>{item.name}</span>
          <div className="mx-auto"></div>
          {item.badge && (
            <div className={`badge bg-${item.badge.color}`}>
              {item.badge.value}
            </div>
          )}
        </TouchRipple>
      </NavLink>
    )})
  }

  renderLevels = data => {

    return data?.map((item, index) => {
      if (item.children) {
        return (
          <EgretVerticalNavExpansionPanel item={item} key={index}>
            {this.renderChildrens(item.children)}
            {/* {this.renderLevels()} */}
          </EgretVerticalNavExpansionPanel>
        );
      } else {
        if (item.auth != undefined) {
          if (this.isForShowing(item.auth)) {
            return item.name != "" && (
              <NavLink key={index} to={item.path} className="nav-item">
                <TouchRipple key={item.name} name="child" className="w-100">
                  {(() => {
                    if (item.icon) {
                      return (
                        <Icon className="item-icon text-middle">{item.icon}</Icon>
                      );
                    } else {
                      return (
                        <span className="item-icon icon-text">{item.iconText}</span>
                      );
                    }
                  })()}
                  <span className="text-middle pl-20 item-text">{item.name}</span>
                  <div className="mx-auto"></div>
                  {item.badge && (
                    <div className={`badge bg-${item.badge.color}`}>
                      {item.badge.value}
                    </div>
                  )}
                </TouchRipple>
              </NavLink>
            );
          } else {
            return;
          }
        } else {
          return (
            <NavLink key={index} to={item.path} className="nav-item">
              <TouchRipple key={item.name} name="child" className="w-100">
                {(() => {
                  if (item.icon) {
                    return (
                      <Icon className="item-icon text-middle">{item.icon}</Icon>
                    );
                  } else {
                    return (
                      <span className="item-icon icon-text">{item.iconText}</span>
                    );
                  }
                })()}
                <span className="text-middle pl-20 item-text">{item.name}</span>
                <div className="mx-auto"></div>
                {item.badge && (
                  <div className={`badge bg-${item.badge.color}`}>
                    {item.badge.value}
                  </div>
                )}
              </TouchRipple>
            </NavLink>
          );
        }
      }
    });
  };

  handleClick = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    return (
      <div className="navigation">
        {this.renderLevels(this.props.navigation)}
      </div>
    );
  }
}

export default withStyles(styles)(EgretVerticalNav);
