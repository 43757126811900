import history from "history.js";
import jwtAuthService from "../../services/jwtAuthService";
import axios from "axios";


export const LIST_EXCEPTION_LOADING = "LIST_EXCEPTION_LOADING";
export const LIST_EXCEPTION_SUCCESS = "LIST_EXCEPTION_SUCCESS";
export const LIST_EXCEPTION_FAILED = "LIST_EXCEPTION_FAILED";
export const UPDATE_EXCEPTION_LOADING = "UPDATE_EXCEPTION_LOADING";
export const UPDATE_EXCEPTION_SUCCESS = "UPDATE_EXCEPTION_SUCCESS";
export const UPDATE_EXCEPTION_FAILED = "UPDATE_EXCEPTION_FAILED";
export const DELETE_EXCEPTION_LOADING = "DELETE_EXCEPTION_LOADING";
export const DELETE_EXCEPTION_SUCCESS = "DELETE_EXCEPTION_SUCCESS";
export const DELETE_EXCEPTION_FAILED = "DELETE_EXCEPTION_FAILED";
export const CREATE_EXCEPTION_LOADING = "CREATE_EXCEPTION_LOADING";
export const CREATE_EXCEPTION_SUCCESS = "CREATE_EXCEPTION_SUCCESS";
export const CREATE_EXCEPTION_FAILED = "CREATE_EXCEPTION_FAILED";

export const CLEAR = "CLEAR";

export function listException(token, company, type = null) {
  return dispatch => {
    dispatch({
      type: LIST_EXCEPTION_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/company/${company}/location/exception${type == null ? "" : "?type=" + type}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_EXCEPTION_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_EXCEPTION_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function createException(token, data, company) {
  return dispatch => {
    dispatch({
      type: CREATE_EXCEPTION_LOADING,
    });
    axios({
      method: 'post',
      url: `https://api.stonik.com.br/web/company/${company}/location/exception`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: CREATE_EXCEPTION_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: CREATE_EXCEPTION_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}
export function updateException(token, data, company) {
  return dispatch => {
    dispatch({
      type: UPDATE_EXCEPTION_LOADING,
    });
    axios({
      method: 'put',
      url: `https://api.stonik.com.br/web/company/${company}/location/exception/${data.id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: UPDATE_EXCEPTION_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: UPDATE_EXCEPTION_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function deleteException(token, id, company) {
  return dispatch => {
    dispatch({
      type: DELETE_EXCEPTION_LOADING,
    });
    axios({
      method: 'delete',
      url: `https://api.stonik.com.br/web/company/${company}/location/exception/${id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: DELETE_EXCEPTION_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: DELETE_EXCEPTION_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function clear() {
  return dispatch => {
    dispatch({
      type: CLEAR
    });
  };
}
