import React, { Component, useState, useEffect } from "react";
import {
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Icon,
  TablePagination,
  Button,
  Card
} from "@material-ui/core";
import { getAllUser, deleteUser } from "./TableService";
import MemberEditorDialog from "./MemberEditorDialog";
import { Breadcrumb, ConfirmationDialog } from "egret";
import shortid from "shortid";
import { connect } from "react-redux";
import { listGoalm3Vehicle, deleteGoalm3Vehicle, clear } from "../../redux/actions/FleetActions"

const CrudTable = (props) => {

  const {goalsVehicles, userRedux} = props

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [unidade, setUnidade] = useState("");
  const [transporte_minimo, setTransporteMinimo] = useState("");
  const [minimo_entrega, setMinimoEntrega] = useState("");
  const [maximo_entrega, setMaximoEntrega] = useState("");
  const [tempo_maximo, setTempoMaximo] = useState("");
  const [userList, setUserList] = useState([]);
  const [shouldOpenEditorDialog, setShouldOpenEditorDialog] = useState(false);
  const [shouldOpenConfirmationDialog, setShouldOpenConfirmationDialog] = useState(false);
  const [uid, setUid] = useState([]);


  const setPageFunction = page => {
    setPage(page)
  };

  const setRowsPerPageFunction = event => {
    setRowsPerPage(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPageFunction(newPage);
  };

  const handleDialogClose = () => {
    setShouldOpenConfirmationDialog(false);
    setShouldOpenEditorDialog(false)
  };

  const handleDeleteUser = item => {
    setShouldOpenConfirmationDialog(true)
    setUid(item)
  };

  const handleConfirmationResponse = () => {
    props.dispatch(deleteGoalm3Vehicle(userRedux?.token, userRedux?.company, uid))
  }

  useEffect(() => {
    props.dispatch(listGoalm3Vehicle(userRedux?.token, userRedux?.company))
  }, [])
  
  useEffect(() => {
    console.log("goalsVehicles", goalsVehicles)
  }, [goalsVehicles])

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: "Configurações Gerais" }]} />
      </div>

      <Button
        className="mb-16"
        variant="contained"
        color="primary"
        onClick={() => setShouldOpenEditorDialog(true)}
      >
        <Icon>add-circle</Icon>
        Adicionar registro
      </Button>
      <Card className="w-100 overflow-auto" elevation={6}>
        <Table className="crud-table" style={{ whiteSpace: "pre", minWidth: "750px" }}>
          <TableHead>
            <TableRow>
              <TableCell>Unidade</TableCell>
              <TableCell>Transporte Mínimo</TableCell>
              <TableCell>Mínimo Para Entrega</TableCell>
              <TableCell>Distância Máxima De Entrega</TableCell>
              <TableCell>Tempo Máximo De Entrega</TableCell>
              <TableCell align="center">Ação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => (
                <TableRow key={shortid.generate()}>
                  <TableCell className="px-0" align="left">
                    {item.company}
                  </TableCell>
                  <TableCell className="px-0" align="left">
                    {item.age}
                  </TableCell>
                  <TableCell className="px-0" align="left">
                    {item.age}
                  </TableCell>
                  <TableCell className="px-0" align="left">
                    {item.age}
                  </TableCell>
                  <TableCell className="px-0" align="left">
                    {item.age}
                  </TableCell>
                  <TableCell className="px-0 border-none" align="center">
                    <IconButton
                      onClick={() => {
                        setUid(item)
                        setShouldOpenEditorDialog(true)
                      }
                      }
                    >
                      <Icon color="primary">edit</Icon>
                    </IconButton>
                    <IconButton onClick={() => handleDeleteUser(item?.id)}>
                      <Icon color="error">delete</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        <TablePagination
          className="px-16"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={userList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Linhas por Página"
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={setRowsPerPageFunction}
        />

        {shouldOpenEditorDialog && (
          <MemberEditorDialog
            handleClose={handleDialogClose}
            open={shouldOpenEditorDialog}
            uid={uid}
          />
        )}
        {shouldOpenConfirmationDialog && (
          <ConfirmationDialog
            open={shouldOpenConfirmationDialog}
            onConfirmDialogClose={handleDialogClose}
            onYesClick={handleConfirmationResponse}
            text="Tem certeza que deseja excluir este registro?"
            title="Excluir registro"
            confirmButtonText="Sim"
            declineButtonText="Não"
          />
        )}
      </Card>
    </div>
  );
}

const mapStateToProps = state => ({
  userRedux: state.user,
  branchs: state.branch.branchs,
  goalsVehicles: state.fleet.goalsVehicles
});

export default connect(mapStateToProps)(CrudTable);
