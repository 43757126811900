import {
  LIST_DISCHARGE_REPORT_LOADING,
  LIST_DISCHARGE_REPORT_SUCCESS,
  LIST_DISCHARGE_REPORT_FAILED,
  GET_EXCEL_DISCHARGE_REPORT_LOADING,
  GET_EXCEL_DISCHARGE_REPORT_SUCCESS,
  GET_EXCEL_DISCHARGE_REPORT_FAILED,
  CLEAR
} from "../actions/DischargeActions";


const initialState = {success: false,
  loading: false,
  error: null,
  discharges: [],
  excel: null
};

const DischargeReducer = function(state = initialState, action) {
  switch (action.type) {
    case LIST_DISCHARGE_REPORT_LOADING: {
      console.log("LIST_DISCHARGE_REPORT_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_DISCHARGE_REPORT_SUCCESS: {
      console.log("LIST_DISCHARGE_REPORT_SUCCESS", action?.payload)
      return {
        ...state,
        discharges: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_DISCHARGE_REPORT_FAILED: {
      console.log("LIST_DISCHARGE_REPORT_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case GET_EXCEL_DISCHARGE_REPORT_LOADING: {
      console.log("GET_EXCEL_DISCHARGE_REPORT_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case GET_EXCEL_DISCHARGE_REPORT_SUCCESS: {
      console.log("GET_EXCEL_DISCHARGE_REPORT_SUCCESS", action?.payload)
      return {
        ...state,
        excel: action?.payload,
        loading: false,
        error: null
      };
    }
    case GET_EXCEL_DISCHARGE_REPORT_FAILED: {
      console.log("GET_EXCEL_DISCHARGE_REPORT_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case CLEAR: {
      return {
        ...state,
        error: null,
        success: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default DischargeReducer;
