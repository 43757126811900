import {
  LIST_FLEET_LOADING,
  LIST_FLEET_SUCCESS,
  LIST_FLEET_FAILED,
  UPDATE_FLEET_LOADING,
  UPDATE_FLEET_SUCCESS,
  UPDATE_FLEET_FAILED,
  DELETE_FLEET_LOADING,
  DELETE_FLEET_SUCCESS,
  DELETE_FLEET_FAILED,
  CREATE_FLEET_LOADING,
  CREATE_FLEET_SUCCESS,
  CREATE_FLEET_FAILED,
  LIST_GOAL_M3_VEHICLE_LOADING,
  LIST_GOAL_M3_VEHICLE_SUCCESS,
  LIST_GOAL_M3_VEHICLE_FAILED,
  UPDATE_GOAL_M3_VEHICLE_LOADING,
  UPDATE_GOAL_M3_VEHICLE_SUCCESS,
  UPDATE_GOAL_M3_VEHICLE_FAILED,
  DELETE_GOAL_M3_VEHICLE_LOADING,
  DELETE_GOAL_M3_VEHICLE_SUCCESS,
  DELETE_GOAL_M3_VEHICLE_FAILED,
  CREATE_GOAL_M3_VEHICLE_LOADING,
  CREATE_GOAL_M3_VEHICLE_SUCCESS,
  CREATE_GOAL_M3_VEHICLE_FAILED,
  CLEAR
} from "../actions/FleetActions";


const initialState = {success: false,
  loading: false,
  error: null,
  fleets: []
};

const fleetReducer = function(state = initialState, action) {
  switch (action.type) {
    case LIST_FLEET_LOADING: {
      console.log("LIST_FLEET_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_FLEET_SUCCESS: {
      console.log("LIST_FLEET_SUCCESS", action?.payload)
      return {
        ...state,
        fleets: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_FLEET_FAILED: {
      console.log("LIST_FLEET_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case DELETE_FLEET_LOADING: {
      console.log("DELETE_FLEET_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_FLEET_SUCCESS: {
      console.log("DELETE_FLEET_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Frota deletada com sucesso!",
        loading: false,
        error: null
      };
    }
    case DELETE_FLEET_FAILED: {
      console.log("DELETE_FLEET_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case UPDATE_FLEET_LOADING: {
      console.log("UPDATE_FLEET_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_FLEET_SUCCESS: {
      console.log("UPDATE_FLEET_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Frota atualizada com sucesso!",
        loading: false,
        error: null
      };
    }
    case UPDATE_FLEET_FAILED: {
      console.log("UPDATE_FLEET_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case CREATE_FLEET_LOADING: {
      console.log("CREATE_FLEET_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case CREATE_FLEET_SUCCESS: {
      console.log("CREATE_FLEET_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Frota adicionada com sucesso!",
        loading: false,
        error: null
      };
    }
    case CREATE_FLEET_FAILED: {
      console.log("CREATE_FLEET_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case LIST_GOAL_M3_VEHICLE_LOADING: {
      console.log("LIST_GOAL_M3_VEHICLE_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_GOAL_M3_VEHICLE_SUCCESS: {
      console.log("LIST_GOAL_M3_VEHICLE_SUCCESS", action?.payload)
      return {
        ...state,
        goalsVehicles: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_GOAL_M3_VEHICLE_FAILED: {
      console.log("LIST_GOAL_M3_VEHICLE_FAILED", action.payload)
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    case CREATE_GOAL_M3_VEHICLE_LOADING: {
      console.log("CREATE_GOAL_M3_VEHICLE_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case CREATE_GOAL_M3_VEHICLE_SUCCESS: {
      console.log("CREATE_GOAL_M3_VEHICLE_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Meta de Betoneira registrada com sucesso!",
        loading: false,
        error: null
      };
    }
    case CREATE_GOAL_M3_VEHICLE_FAILED: {
      console.log("CREATE_GOAL_M3_VEHICLE_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case UPDATE_GOAL_M3_VEHICLE_LOADING: {
      console.log("UPDATE_GOAL_M3_VEHICLE_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_GOAL_M3_VEHICLE_SUCCESS: {
      console.log("UPDATE_GOAL_M3_VEHICLE_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Meta de Betoneira alterada com sucesso!",
        loading: false,
        error: null
      };
    }
    case UPDATE_GOAL_M3_VEHICLE_FAILED: {
      console.log("UPDATE_GOAL_M3_VEHICLE_FAILED", action.payload)
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    case DELETE_GOAL_M3_VEHICLE_LOADING: {
      console.log("DELETE_GOAL_M3_VEHICLE_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_GOAL_M3_VEHICLE_SUCCESS: {
      console.log("DELETE_GOAL_M3_VEHICLE_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Meta de Betoneira deletada com sucesso!",
        loading: false,
        error: null
      };
    }
    case DELETE_GOAL_M3_VEHICLE_FAILED: {
      console.log("DELETE_GOAL_M3_VEHICLE_FAILED", action.payload)
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    case CLEAR: {
      return {
        ...state,
        error: null,
        success: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default fleetReducer;
