import UserTable from "./RevenuesReports";

const RevenuesReports = [
  {
    path: "/faturamento",
    exact: true,
    component: UserTable
  }
];

export default RevenuesReports;
