import history from "history.js";
import jwtAuthService from "../../services/jwtAuthService";
import axios from "axios";


export const LIST_TICKET_LOADING = "LIST_TICKET_LOADING";
export const LIST_TICKET_SUCCESS = "LIST_TICKET_SUCCESS";
export const LIST_TICKET_FAILED = "LIST_TICKET_FAILED";
export const UPDATE_TICKET_LOADING = "UPDATE_TICKET_LOADING";
export const UPDATE_TICKET_SUCCESS = "UPDATE_TICKET_SUCCESS";
export const UPDATE_TICKET_FAILED = "UPDATE_TICKET_FAILED";
export const DELETE_TICKET_LOADING = "DELETE_TICKET_LOADING";
export const DELETE_TICKET_SUCCESS = "DELETE_TICKET_SUCCESS";
export const DELETE_TICKET_FAILED = "DELETE_TICKET_FAILED";
export const CREATE_TICKET_LOADING = "CREATE_TICKET_LOADING";
export const CREATE_TICKET_SUCCESS = "CREATE_TICKET_SUCCESS";
export const CREATE_TICKET_FAILED = "CREATE_TICKET_FAILED";

export const CLEAR = "CLEAR";

export function listTicket(token, company) {
  return dispatch => {
    dispatch({
      type: LIST_TICKET_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/company/${company}/ticket`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_TICKET_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_TICKET_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function createTicket(token, data) {
  return dispatch => {
    dispatch({
      type: CREATE_TICKET_LOADING,
    });
    axios({
      method: 'post',
      url: `https://api.stonik.com.br/web/ticket`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: CREATE_TICKET_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: CREATE_TICKET_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}
export function updateTicket(token, data, company) {
  return dispatch => {
    dispatch({
      type: UPDATE_TICKET_LOADING,
    });
    axios({
      method: 'put',
      url: `https://api.stonik.com.br/web/company/${company}/writer/${data.id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: UPDATE_TICKET_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: UPDATE_TICKET_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function deleteTicket(token, id, company) {
  return dispatch => {
    dispatch({
      type: DELETE_TICKET_LOADING,
    });
    axios({
      method: 'delete',
      url: `https://api.stonik.com.br/web/company/${company}/writer/${id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: DELETE_TICKET_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: DELETE_TICKET_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function clear() {
  return dispatch => {
    dispatch({
      type: CLEAR
    });
  };
}
