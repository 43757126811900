import CrudTable from "./ExceededDistanceByVolumeAlert";

const ExceededDistanceByVolumeAlert = [
  {
    path: "/distancia-excedida-por-volume",
    exact: true,
    component: CrudTable
  }
];

export default ExceededDistanceByVolumeAlert;
