import {
  LIST_APPLICATION_LOADING,
  LIST_APPLICATION_SUCCESS,
  LIST_APPLICATION_FAILED,
  UPDATE_APPLICATION_LOADING,
  UPDATE_APPLICATION_SUCCESS,
  UPDATE_APPLICATION_FAILED,
  DELETE_APPLICATION_LOADING,
  DELETE_APPLICATION_SUCCESS,
  DELETE_APPLICATION_FAILED,
  CREATE_APPLICATION_LOADING,
  CREATE_APPLICATION_SUCCESS,
  CREATE_APPLICATION_FAILED,
  CLEAR
} from "../actions/ApplicationActions";


const initialState = {success: false,
  loading: false,
  error: null,
  applications: []
};

const ApplicationReducer = function(state = initialState, action) {
  switch (action.type) {
    case LIST_APPLICATION_LOADING: {
      console.log("LIST_APPLICATION_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_APPLICATION_SUCCESS: {
      console.log("LIST_APPLICATION_SUCCESS", action?.payload)
      return {
        ...state,
        applications: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_APPLICATION_FAILED: {
      console.log("LIST_APPLICATION_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case DELETE_APPLICATION_LOADING: {
      console.log("DELETE_APPLICATION_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_APPLICATION_SUCCESS: {
      console.log("DELETE_APPLICATION_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Tipo de aplicação deletada com sucesso!",
        loading: false,
        error: null
      };
    }
    case DELETE_APPLICATION_FAILED: {
      console.log("DELETE_APPLICATION_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case UPDATE_APPLICATION_LOADING: {
      console.log("UPDATE_APPLICATION_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_APPLICATION_SUCCESS: {
      console.log("UPDATE_APPLICATION_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Tipo de aplicação atualizada com sucesso!",
        loading: false,
        error: null
      };
    }
    case UPDATE_APPLICATION_FAILED: {
      console.log("UPDATE_APPLICATION_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case CREATE_APPLICATION_LOADING: {
      console.log("CREATE_APPLICATION_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case CREATE_APPLICATION_SUCCESS: {
      console.log("CREATE_APPLICATION_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Tipo de aplicação adicionado com sucesso!",
        loading: false,
        error: null
      };
    }
    case CREATE_APPLICATION_FAILED: {
      console.log("CREATE_APPLICATION_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    
    case CLEAR: {
      return {
        ...state,
        error: null,
        success: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default ApplicationReducer;
