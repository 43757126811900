import React, { useEffect, useState } from "react";
import {
  Card,
  Grid,
  Button
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";

import { resetPassword, sendCode, clear } from "../../redux/actions/LoginActions";

import Swal from 'sweetalert2'

const ForgotPassword = (props) => {

  const { loading, error, success, showMeCode = false, cpfSignIn = ""} = props;

  const [cpf, setCpf] = useState(cpfSignIn)
  const [code, setCode] = useState("")
  const [senha, setSenha] = useState("")
  const [confirmarSenha, setConfirmarSenha] = useState("")
  const [showCode, setShowCode] = useState(showMeCode)

  function TestaCPF(strCPF) {
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF == "00000000000") return false;

    for (var i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (var i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }

  const handleFormSubmit = () => {
    if(TestaCPF(cpf) == true){
      props.dispatch(resetPassword(cpf));
    }else{
      Swal.fire({
        icon: 'error',
        title: "Cpf inválido",
        showConfirmButton: false,
        timer: 3000
      })
    }
  };

  const handleCodeFormSubmit = () => {
    const regex = /\W|_/;
    console.log("code", code.length)
    if(code.length < 6){
      Swal.fire({
        icon: 'error',
        title: "O código contem 6 dígitos",
        showConfirmButton: false,
        timer: 3000
      })
    }else if(senha == "" || confirmarSenha == "" || senha.length < 8 || confirmarSenha.length < 8){
      Swal.fire({
        icon: 'error',
        title: "As senhas devem conter 8 dígitos no mínimo",
        showConfirmButton: false,
        timer: 3000
      })
    }else if(!regex.test(senha)){
      Swal.fire({
        icon: 'error',
        title: "A sua senha deve conter: caracteres especiais, números e letras.",
        showConfirmButton: false,
        timer: 3000
      })
    }else if(senha != confirmarSenha){
      Swal.fire({
        icon: 'error',
        title: "As senhas não conferem",
        showConfirmButton: false,
        timer: 3000
      })
    }else{
      props.dispatch(sendCode(code, senha, confirmarSenha));
    }
  };

  useEffect(() => {
    console.log("props", props)
  }, [props])

  useEffect(() => {
    if (loading == true) {
      console.log("CAIU AQUI")
      Swal.showLoading()
    } else {
      Swal.hideLoading()
    }
  }, [loading])

  useEffect(() => {
    if (success != false) {
      Swal.fire({
        icon: 'success',
        title: success,
        showConfirmButton: false,
        timer: 3000
      })
      if (showCode == true) {
        props.history.push("/login")
      }
      setShowCode(true)
      setTimeout(() => {
        props.dispatch(clear())
      }, 3500)
    }
  }, [success])

  useEffect(() => {
    if (error != null) {
      Swal.fire({
        icon: 'error',
        title: error,
        showConfirmButton: false,
        timer: 3000
      })
      showCode == false && setShowCode(false)

      setTimeout(() => {
        props.dispatch(clear())
      }, 3500)
    }
  }, [error])

  return (
    <div className="signup flex flex-center w-100 h-100vh">
      <div className="p-8">
        <Card className="signup-card position-relative y-center">
          <Grid container>
            <Grid item lg={5} md={5} sm={5} xs={12}>
              <div className="p-32 flex flex-center flex-middle h-100">
                <img src="/assets/images/stonik-laranja.png" alt="" />
              </div>
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              <div className="p-36 h-100 bg-light-gray position-relative">
                <ValidatorForm>

                  {
                    showCode ? <>
                      <TextValidator
                        className="mb-24 w-100"
                        variant="outlined"
                        label="Código"
                        type="code"
                        name="code"
                        onChange={(event) => {
                          setCode(event.target.value)
                        }}
                        value={code}
                        validators={["required"]}
                        errorMessages={[
                          "this field is required",
                        ]}
                      />
                      <TextValidator
                        className="mb-24 w-100"
                        variant="outlined"
                        label="Senha"
                        type="senha"
                        name="senha"
                        onChange={(event) => {
                          setSenha(event.target.value)
                        }}
                        value={senha}
                        validators={["required"]}
                        errorMessages={[
                          "this field is required",
                        ]}
                      />
                      <TextValidator
                        className="mb-24 w-100"
                        variant="outlined"
                        label="Confirmar Senha"
                        type="confirmarSenha"
                        name="confirmarSenha"
                        onChange={(event) => {
                          setConfirmarSenha(event.target.value)
                        }}
                        value={confirmarSenha}
                        validators={["required"]}
                        errorMessages={[
                          "this field is required",
                        ]}
                      />
                    </>
                      :
                      <TextValidator
                        className="mb-24 w-100"
                        variant="outlined"
                        label="Cpf"
                        type="cpf"
                        name="cpf"
                        onChange={(event) => {
                          setCpf(event.target.value)
                        }}
                        value={cpf}
                      />
                  }
                  <div className="flex flex-middle">
                    <Button variant="contained" color="primary" onClick={() => { showCode ? handleCodeFormSubmit() : handleFormSubmit() }}>
                      Avançar
                    </Button>
                    <span className="ml-16 mr-8">ou</span>
                    <Button
                      className="capitalize"
                      onClick={() =>
                        props.history.push("/login")
                      }
                    >
                      Login
                    </Button>
                  </div>
                </ValidatorForm>
              </div>
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
}


const mapStateToProps = state => ({
  loading: state.login.loading,
  error: state.login.error,
  success: state.login.success,
});

export default connect(mapStateToProps)(ForgotPassword)