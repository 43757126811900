
import Color from './Color';
import Spacing from './Spacing';
import Typography from './Typography';
import Display from './Display';

const utilitiesRoutes = [
  {
    path: '/utilities/color',
    component: Color
  },
  {
    path: "/utilities/spacing",
    component: Spacing
  },
  {
    path: "/utilities/typography",
    component: Typography
  },
  {
    path: "/utilities/display",
    component: Display
  },
]

export default utilitiesRoutes;