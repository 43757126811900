import history from "history.js";
import jwtAuthService from "../../services/jwtAuthService";
import axios from "axios";


export const LIST_DIARY_PRODUCTION_LOADING = "LIST_DIARY_PRODUCTION_LOADING";
export const LIST_DIARY_PRODUCTION_SUCCESS = "LIST_DIARY_PRODUCTION_SUCCESS";
export const LIST_DIARY_PRODUCTION_FAILED = "LIST_DIARY_PRODUCTION_FAILED";
export const LIST_DIARY_BILLING_LOADING = "LIST_DIARY_BILLING_LOADING";
export const LIST_DIARY_BILLING_SUCCESS = "LIST_DIARY_BILLING_SUCCESS";
export const LIST_DIARY_BILLING_FAILED = "LIST_DIARY_BILLING_FAILED";
export const LIST_CLIENT_SERVED_LOADING = "LIST_CLIENT_SERVED_LOADING";
export const LIST_CLIENT_SERVED_SUCCESS = "LIST_CLIENT_SERVED_SUCCESS";
export const LIST_CLIENT_SERVED_FAILED = "LIST_CLIENT_SERVED_FAILED";

export const CLEAR = "CLEAR";

export function listDiaryProduction(token, company) {
  return dispatch => {
    dispatch({
      type: LIST_DIARY_PRODUCTION_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/dashboard/${company}/dailyProductionVolume`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_DIARY_PRODUCTION_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_DIARY_PRODUCTION_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function listDiaryBilling(token, company) {
  return dispatch => {
    dispatch({
      type: LIST_DIARY_BILLING_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/dashboard/${company}/dailyBilling`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_DIARY_BILLING_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_DIARY_BILLING_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function listClientsServed(token, company) {
  return dispatch => {
    dispatch({
      type: LIST_CLIENT_SERVED_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/dashboard/${company}/dailyNumberClientsServed`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_CLIENT_SERVED_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_CLIENT_SERVED_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function clear() {
  return dispatch => {
    dispatch({
      type: CLEAR
    });
  };
}
