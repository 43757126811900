import React, { Component, useState, useEffect } from "react";
import {
  Dialog,
  Button,
  Grid,
  FormControlLabel,
  Switch,
  Icon,
  LinearProgress,
  CircularProgress
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { getUserById, updateUser, addNewUser } from "./TableService";
import { generateRandomId } from "utils";
import InputMask from "react-input-mask";
import CustomizedSwitch from "./CustomizedSwitch"
import { connect } from "react-redux";

import { registerContract, updateContract, clear } from "../../redux/actions/ContractActions"
import { listProductTypes, listProducts } from "../../redux/actions/ProductActions"
import { listClient } from "../../redux/actions/ClientActions"

import Swal from 'sweetalert2'

const MemberEditorDialog = (props) => {

  const {
    open,
    handleClose,
    user,
    clients,
    loadingClient,
    errorClient,
    successClient,
    loading,
    error,
    uid,
    success,
    productTypes,
    loadingProductTypes,
    errorProductTypes,
    successProductTypes,
    products,
    loadingProducts,
    errorProducts,
    successProducts
  } = props

  const [data, setData] = useState([])
  const [userSelected, setUser] = useState(uid == null ? "" : uid?.clientCompany?.id)
  const [pessoa, setPessoa] = useState("")
  const [isActive, setIsActive] = useState(false)
  const [cpf, setCpf] = useState("")
  const [cnpj, setCnpj] = useState("")
  const [contrato, setContrato] = useState(uid == null ? "" : uid?.contractNumber)
  const [valor_geral_contrato, setValor_geral_contrato] = useState(uid == null ? "" : uid?.valueTotal)
  const [valor_metro, setValor_metro] = useState("")
  const [saldo, setSaldo] = useState("")
  const [product, setProduct] = useState("")
  const [productType, setProductType] = useState("")
  const [productsSelecteds, setProductsSelecteds] = useState(uid == null ? [] : uid?.prices)
  const [status, setStatus] = useState(uid == null ? false : uid?.status)

  const [titleError, setTitleError] = useState("")

  const [containsErrorInBlock, setContainsErrorInBlock] = useState(false)
  const [titleErrorBlock, setTitleErrorBlock] = useState(false)

  const [modeInspect, setModeInspect] = useState(false)
  const [itemSelected, setItem] = useState(null)

  const handleFormSubmit = () => {

    if (userSelected == "") {
      setTitleError("Selecione o usuário")
    } else if (contrato == "") {
      setTitleError("Preencha o número contrato")
    } else if (valor_geral_contrato == "") {
      setTitleError("Informe o valor geral do contrato")
    } else {
      if (uid == null) {
        props.dispatch(registerContract(
          user?.token,
          {
            clientId: userSelected,
            contractNumber: contrato,
            valueTotal: valor_geral_contrato,
            prices: productsSelecteds,
          }, user?.company
        ))
      } else {
        props.dispatch(updateContract(user?.token, user?.company, uid?.clientCompany?.id, uid?.id, status))
      }

    }
  };

  const handleProductTypes = (value) => {
    props.dispatch(listProducts(user?.token, user?.company, value))
    setProductType(value)
  }

  const setChanged = value => {
    console.log("Value", value)
    setStatus(value)
  }

  useEffect(() => {
    props.dispatch(listClient(user?.token, user?.company))
    props.dispatch(listProductTypes(user?.token, user?.company))
    console.log("uid", uid)
  }, [])

  useEffect(() => {
    console.log("productsSelecteds", productsSelecteds)
  }, [productsSelecteds])
  useEffect(() => {
    console.log("clients", clients)
  }, [clients])

  useEffect(() => {
    if (success != false) {
      handleClose()
      Swal.fire({
        icon: 'success',
        title: success,
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(() => {
        props.dispatch(clear())
        window.location.reload()
      }, 3500)
    }
  }, [success])

  useEffect(() => {
    if (error != null) {
      handleClose()

      Swal.fire({
        icon: 'error',
        title: error,
        showConfirmButton: false,
        timer: 3000
      })

      setTimeout(() => {
        props.dispatch(clear())
      }, 3500)
    }
  }, [error])

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="p-24" style={{ width: "400px" }}>
        <h4 className="mb-20">Adicionar contrato</h4>
        <ValidatorForm onSubmit={() => {
          handleFormSubmit()
        }}>
          <Grid container spacing={10}>
            <Grid item sm={12}>
              {
                uid != null && (
                  <>
                    <label className="mb-4" style={{ marginTop: "10px" }}>Status do contrato</label>
                    <CustomizedSwitch checkedOption={uid?.status} changed={(value) => {
                      setChanged(value)
                    }} />
                  </>
                )
              }

              <label className="mb-4" style={{ marginTop: "10px" }}>Nome do Cliente</label>
              {
                loadingClient != true ?
                  <select onChange={(event) => {
                    setUser(event.target.value)
                  }} className="w-100 mb-16" style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                    <option value=""></option>
                    {clients.length > 0 && clients.map(item => (
                      <option selected={uid != null ? uid?.clientCompany?.id == item?.id ? "selected" : false : false} value={item?.id}>{item?.name}</option>
                    ))
                    }
                  </select>
                  :
                  <LinearProgress />
              }

              <label className="mb-4">Número do Contrato</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="contrato"
                onChange={(event) => {
                  setContrato(event.target.value)
                }}
                type="numeric"
                name="contrato"
                value={contrato}
              />
              <label className="mb-4">Valor Geral do Contrato</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="valor_geral_contrato"
                onChange={(event) => {
                  setValor_geral_contrato(event.target.value)
                }}
                type="number"
                name="valor_geral_contrato"
                value={valor_geral_contrato}
              />
              <label className="mb-4">Valor do Metro Cúbico Negociado</label>
              <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                {
                  modeInspect ?
                    <div onClick={() => {
                      setModeInspect(false)
                      setValor_metro("")
                      setContainsErrorInBlock(false)
                    }} style={{ display: "flex", cursor: "pointer", flexDirection: "row", borderRadius: 10, padding: "5px 10px", marginTop: 5, backgroundColor: "#f78726" }}>
                      <div style={{ flex: 7, color: "white", alignItems: "center", justifyContent: "center" }}><span style={{ textAlign: "center", marginLeft: 10, fontWeight: "bold" }}>{itemSelected?.product?.name}</span></div>
                      <div style={{ flex: 4, color: "white", alignItems: "center", justifyContent: "center" }}><span style={{ textAlign: "center", fontWeight: "bold" }}>R$ {itemSelected.priceM3.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span></div>
                      <div style={{ flex: 1, color: "white", alignItems: "center", justifyContent: "center" }}><Icon style={{ align: "right" }} fontSize="small">close</Icon></div>
                    </div>
                    :
                    productsSelecteds.length > 0 &&
                    productsSelecteds.map(item => (
                      <div style={{ display: "flex", border: "1px solid #f78726", flexDirection: "row", padding: "5px 0px 0px 0px", marginTop: 5 }}>
                        <div style={{ flex: 5, color: "#f78726", alignItems: "center", justifyContent: "center" }}><span style={{ textAlign: "center", marginLeft: 10, fontWeight: "bold" }}>{uid == null ? item.product.text : item.product.name}</span></div>
                        <div style={{ flex: 5, color: "#f78726", alignItems: "center", justifyContent: "center" }}><span style={{ textAlign: "center", fontWeight: "bold" }}>R$ {item.priceM3.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span></div>

                        <div onClick={() => {
                          setModeInspect(true)
                          setValor_metro(item.priceM3)
                          console.log("selected", item)
                          setItem(item)
                          setContainsErrorInBlock(false)
                        }} style={{ flex: 1, cursor: "pointer", alignItems: "center", justifyContent: "center", backgroundColor: "white", height: "100%" }}><Icon style={{ align: "right", color: "#f78726" }} fontSize="small">edit</Icon></div>
                        <div onClick={() => {
                          var newArray = [...productsSelecteds]
                          var exist = newArray.findIndex(itemInner => itemInner.product.id == item.product.id)
                          newArray.splice(exist, 1);

                          setProductsSelecteds(newArray)

                        }} style={{ flex: 1, cursor: "pointer", alignItems: "center", justifyContent: "center", backgroundColor: "white", height: "100%" }}><Icon style={{ align: "right", color: "red" }} fontSize="small">delete_forever</Icon></div>
                      </div>

                    ))
                }
              </div>

              {
                modeInspect ?
                  <div style={{ marginTop: 10, backgroundColor: containsErrorInBlock ? "rgba(255,0,0,.2)" : "#e1e0da", width: "100%", padding: 10 }}>
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <label className="mb-4" style={{ fontWeight: "bold", color: "#f78726" }}>Edição - {itemSelected?.product?.text}</label>
                    </div>
                    <label className="mb-4">Tipo do Produto</label>
                    {
                      loadingProductTypes != true ?
                        <select disabled="disabled" onChange={(event) => { handleProductTypes(event.target.value) }} className="w-100 mb-16" style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                          <option value=""></option>
                          {productTypes.length > 0 && productTypes.map(item => (
                            <option selected={productType == item?.id && "selected"} value={item?.id}>{item?.name}</option>
                          ))
                          }
                        </select>
                        :
                        <LinearProgress />
                    }
                    <label className="mb-4">Produto</label>
                    {
                      loadingProducts != true ?
                        <select disabled="disabled" onChange={(event) => { setProduct({ value: event.target.value, text: event.target[event.target.selectedIndex].text }) }} className="w-100 mb-16" style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                          <option selected={product == "" && "selected"} value=""></option>
                          {products.length > 0 && products.map(item => (
                            <option selected={itemSelected.product.id == item?.id && "selected"} value={item?.id}>{item?.name}</option>
                          ))
                          }
                        </select>
                        :
                        <LinearProgress />
                    }

                    <label className="mb-4">Valor Metro Negociado</label>
                    <InputMask
                      className="w-100 mb-16"
                      style={{ width: "97%", padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                      label="valor_metro"
                      onChange={(event) => {
                        setValor_metro(event.target.value)
                      }}
                      type="number"
                      name="valor_metro"
                      value={valor_metro}
                    />
                    {
                      containsErrorInBlock && <span style={{ color: "red", marginBottom: 10, fontWeight: "bold" }}>{titleErrorBlock}</span>
                    }

                    <Button variant="contained" color="primary" className="w-100" onClick={() => {
                      if (product.value != "" && valor_metro != "") {
                        setContainsErrorInBlock(false)
                        var newArray = [...productsSelecteds]
                        var exist = newArray.findIndex(item => item.product.id == itemSelected.product.id)
                        newArray[exist] = {
                          product: { id: itemSelected.product.id, text: itemSelected.product.text },
                          priceM3: valor_metro
                        }
                        setProduct("")
                        setValor_metro("")

                        setProductsSelecteds(newArray)
                        setModeInspect(false)
                      } else {
                        setContainsErrorInBlock(true)
                        setTitleErrorBlock("Preencha o produto e o valor em m3")
                      }

                    }}>Salvar alterações</Button>

                  </div>

                  :
                  <div style={{ marginTop: 10, backgroundColor: containsErrorInBlock ? "rgba(255,0,0,.2)" : "#e1e0da", width: "100%", padding: 10 }}>
                    <label className="mb-4">Produto</label>
                    {
                      loadingProductTypes != true ?
                        <select onChange={(event) => { handleProductTypes(event.target.value) }} className="w-100 mb-16" style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                          <option value=""></option>
                          {productTypes.length > 0 && productTypes.map(item => (
                            <option value={item?.id}>{item?.name}</option>
                          ))
                          }
                        </select>
                        :
                        <LinearProgress />
                    }
                    <label className="mb-4">Tipo do Produto</label>
                    {
                      loadingProducts != true ?
                        <select onChange={(event) => { setProduct({ value: event.target.value, text: event.target[event.target.selectedIndex].text }) }} className="w-100 mb-16" style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                          <option selected={product == "" && "selected"} value=""></option>
                          {products.length > 0 && products.map(item => (
                            <option value={item?.id}>{item?.name}</option>
                          ))
                          }
                        </select>
                        :
                        <LinearProgress />
                    }

                    <label className="mb-4">Valor Metro Negociado</label>
                    <InputMask
                      className="w-100 mb-16"
                      style={{ width: "97%", padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                      label="valor_metro"
                      onChange={(event) => {
                        setValor_metro(event.target.value)
                      }}
                      type="number"
                      name="valor_metro"
                      value={valor_metro}
                    />
                    {
                      containsErrorInBlock && <span style={{ color: "red", marginBottom: 10, fontWeight: "bold" }}>{titleErrorBlock}</span>
                    }

                    <Button variant="contained" color="primary" className="w-100" onClick={() => {
                      if (product.value != "" && valor_metro != "") {
                        setContainsErrorInBlock(false)
                        var newArray = [...productsSelecteds]
                        var exist = newArray.findIndex(item => item.product.id == product.value)
                        if (exist == -1) {
                          newArray.push({
                            product: { id: product.value, text: product.text },
                            priceM3: valor_metro
                          })

                          setProduct("")
                          setValor_metro("")

                          setProductsSelecteds(newArray)
                        } else {
                          setContainsErrorInBlock(true)
                          setTitleErrorBlock("Já existe uma regra para este produto")
                        }
                      } else {
                        setContainsErrorInBlock(true)
                        setTitleErrorBlock("Preencha o produto e o valor em m3")
                      }

                    }}><Icon>add</Icon>Adicionar</Button>

                  </div>
              }

              {/* <label className="mb-4">Saldo disponível</label>
              <InputMask
                className="w-100 mb-16"
                style={{ width: "97%", padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="saldo"
                onChange={(event) => {
                  setSaldo(event.target.value)
                }}
                type="text"
                name="saldo"
                value={saldo}
              /> */}

            </Grid>
          </Grid>
          {
            titleError != "" && <div style={{ backgroundColor: "rgba(255,0,0,.2)", padding: 5, marginTop: 10 }}><Icon style={{ color: "red", marginRight: 5, marginLeft: 5 }} fontSize="small">close</Icon><span style={{ color: "red", fontWeight: "bold" }}>{titleError}</span></div>
          }

          <div style={{ marginTop: 10, backgroundColor: "rgba(255,193,7, .2)", padding: 10 }}>
            <p style={{ color: "#f78726", fontWeight: "bold" }}>
              * Por gentiliza, revise seus dados! Somente salve-os se todos os dados estiverem corretos.
            </p>
          </div>

          <div className="flex flex-space-between flex-middle" style={{ marginTop: 10 }}>
            <Button variant="contained" color="primary" type="submit">
              {
                loading == true ?
                  <CircularProgress />
                  :
                  "Salvar"
              }

            </Button>
            <Button onClick={() => {
              handleClose()
            }}>Cancelar</Button>
          </div>
        </ValidatorForm>
      </div>
    </Dialog>
  );

}

const mapStateToProps = state => ({
  user: state.user,
  clients: state.client.clients,
  loadingClient: state.client.loading,
  errorClient: state.client.error,
  successClient: state.client.success,
  productTypes: state.product.productTypes,
  loadingProductTypes: state.product.loading,
  errorProductTypes: state.product.error,
  successProductTypes: state.product.success,
  products: state.product.products,
  loadingProducts: state.product.loadingProduct,
  errorProducts: state.product.errorProduct,
  successProducts: state.product.successProduct,
  loading: state.contract.loading,
  error: state.contract.error,
  success: state.contract.success,
});

export default connect(mapStateToProps)(MemberEditorDialog);
