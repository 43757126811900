import {
  LIST_TICKET_LOADING,
  LIST_TICKET_SUCCESS,
  LIST_TICKET_FAILED,
  UPDATE_TICKET_LOADING,
  UPDATE_TICKET_SUCCESS,
  UPDATE_TICKET_FAILED,
  DELETE_TICKET_LOADING,
  DELETE_TICKET_SUCCESS,
  DELETE_TICKET_FAILED,
  CREATE_TICKET_LOADING,
  CREATE_TICKET_SUCCESS,
  CREATE_TICKET_FAILED,
  CLEAR
} from "../actions/TicketActions";


const initialState = {success: false,
  loading: false,
  error: null,
  tickets: []
};

const TicketReducer = function(state = initialState, action) {
  switch (action.type) {
    case LIST_TICKET_LOADING: {
      console.log("LIST_TICKET_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_TICKET_SUCCESS: {
      console.log("LIST_TICKET_SUCCESS", action?.payload)
      return {
        ...state,
        tickets: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_TICKET_FAILED: {
      console.log("LIST_TICKET_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case DELETE_TICKET_LOADING: {
      console.log("DELETE_TICKET_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_TICKET_SUCCESS: {
      console.log("DELETE_TICKET_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Ticket deletado com sucesso!",
        loading: false,
        error: null
      };
    }
    case DELETE_TICKET_FAILED: {
      console.log("DELETE_TICKET_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case UPDATE_TICKET_LOADING: {
      console.log("UPDATE_TICKET_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_TICKET_SUCCESS: {
      console.log("UPDATE_TICKET_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Ticket atualizado com sucesso!",
        loading: false,
        error: null
      };
    }
    case UPDATE_TICKET_FAILED: {
      console.log("UPDATE_TICKET_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case CREATE_TICKET_LOADING: {
      console.log("CREATE_TICKET_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case CREATE_TICKET_SUCCESS: {
      console.log("CREATE_TICKET_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Ticket adicionado com sucesso!",
        loading: false,
        error: null
      };
    }
    case CREATE_TICKET_FAILED: {
      console.log("CREATE_TICKET_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    
    case CLEAR: {
      return {
        ...state,
        error: null,
        success: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default TicketReducer;
