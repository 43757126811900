import {
  LIST_MODULE_LOADING,
  LIST_MODULE_SUCCESS,
  LIST_MODULE_FAILED,
  LIST_USER_COMPANY_LOADING,
  LIST_USER_COMPANY_SUCCESS,
  LIST_USER_COMPANY_FAILED,
  LIST_MODULE_CLIENT_LOADING,
  LIST_MODULE_CLIENT_SUCCESS,
  LIST_MODULE_CLIENT_FAILED,
  UPDATE_MODULE_CLIENT_LOADING,
  UPDATE_MODULE_CLIENT_SUCCESS,
  UPDATE_MODULE_CLIENT_FAILED,
  CLEAR
} from "../actions/ModuleActions";


const initialState = {success: false,
  loading: false,
  error: null,
  modules: [],
  user_company: [],
  modules_client: null,
};

const ModuleReducer = function(state = initialState, action) {
  switch (action.type) {
    case LIST_MODULE_LOADING: {
      console.log("LIST_MODULE_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_MODULE_SUCCESS: {
      console.log("LIST_MODULE_SUCCESS", action?.payload)
      return {
        ...state,
        modules: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_MODULE_FAILED: {
      console.log("LIST_MODULE_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case LIST_USER_COMPANY_LOADING: {
      console.log("LIST_USER_COMPANY_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_USER_COMPANY_SUCCESS: {
      console.log("LIST_USER_COMPANY_SUCCESS", action?.payload)
      return {
        ...state,
        user_company: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_USER_COMPANY_FAILED: {
      console.log("LIST_USER_COMPANY_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case LIST_MODULE_CLIENT_LOADING: {
      console.log("LIST_MODULE_CLIENT_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_MODULE_CLIENT_SUCCESS: {
      console.log("LIST_MODULE_CLIENT_SUCCESS", action?.payload)
      return {
        ...state,
        modules_client: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_MODULE_CLIENT_FAILED: {
      console.log("LIST_MODULE_CLIENT_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case UPDATE_MODULE_CLIENT_LOADING: {
      console.log("UPDATE_MODULE_CLIENT_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_MODULE_CLIENT_SUCCESS: {
      console.log("UPDATE_MODULE_CLIENT_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Módulos vinculados ao usuário!",
        loading: false,
        error: null
      };
    }
    case UPDATE_MODULE_CLIENT_FAILED: {
      console.log("UPDATE_MODULE_CLIENT_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    
    case CLEAR: {
      return {
        ...state,
        error: null,
        success: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default ModuleReducer;
