import React from "react"
import InputMask from 'react-input-mask';

const Input = (props) => {
    return (
        <div className="mb-24 w-100" style={{ display: "flex", flexDirection: "column", height: 40, width: "100%" }}>
            <div style={{ flex: 1 }}>
                <label style={{color: "#f78726", fontWeight: "700" }}>{props.label}</label>
            </div>
            <InputMask type={props.type ? props.type : ""} style={{ flex: 1, padding: 10, borderRadius: 3, borderColor: "lightgray", borderWidth: 1 }} mask={props.mask} maskChar={null} value={props.value} onChange={(event) => { props.onChange(event) }} />
        </div>
    )
}

export default Input