import React, { useState, useEffect } from "react";
import {
  Dialog,
  Button,
  Grid,
  Icon,
  LinearProgress
} from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputMask from "react-input-mask";

import { createTicket } from "../../redux/actions/TicketActions"
import { listProducts, listSpecificProduct } from '../../redux/actions/ProductActions'
import { listCounty } from '../../redux/actions/StateActions'
import { listConfirmedOrders } from '../../redux/actions/OrderActions'
import { listBranch, deleteBranch, clear } from "../../redux/actions/BranchActions"
import moment from "moment"
import Swal from 'sweetalert2'

import { connect } from "react-redux";
moment.locale('pt-br');
const MemberEditorDialog = (props) => {

  const { open, handleClose ,item = null} = props;

  console.log("item", item)

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="p-24" style={{ width: "400px" }}>
        <h4 className="mb-20">Detalhes da Ação</h4>
        <ValidatorForm>
          <Grid className="mb-16" container spacing={10}>
            <Grid item sm={12}>
             
            </Grid>
          </Grid>

          <div className="flex flex-space-between flex-middle">
            <Button variant="contained" color="primary" type="submit">
              Salvar
            </Button>
            <Button onClick={() => {
              handleClose()
            }}>Cancelar</Button>
          </div>
        </ValidatorForm>
      </div>
    </Dialog >
  );
}


const mapStateToProps = state => ({
  userRedux: state.user,
  products: state.product.products,
  productRedux: state.product.product,
  countys: state.state.countys,
  orders: state.orders.orders,
  branchs: state.branch.branchs,
  loading: state.contract.loading,
  error: state.contract.error,
  success: state.contract.success,
});

export default connect(mapStateToProps)(MemberEditorDialog);
