import history from "history.js";
import jwtAuthService from "../../services/jwtAuthService";
import axios from "axios";

export const LIST_DISCHARGE_REPORT_LOADING = "LIST_DISCHARGE_REPORT_LOADING";
export const LIST_DISCHARGE_REPORT_SUCCESS = "LIST_DISCHARGE_REPORT_SUCCESS";
export const LIST_DISCHARGE_REPORT_FAILED = "LIST_DISCHARGE_REPORT_FAILED";
export const GET_EXCEL_DISCHARGE_REPORT_LOADING = "GET_EXCEL_DISCHARGE_REPORT_LOADING";
export const GET_EXCEL_DISCHARGE_REPORT_SUCCESS = "GET_EXCEL_DISCHARGE_REPORT_SUCCESS";
export const GET_EXCEL_DISCHARGE_REPORT_FAILED = "GET_EXCEL_DISCHARGE_REPORT_FAILED";

export const CLEAR = "CLEAR";

export function listDischargeReport(token, company, date, branch = null, productType) {
  return dispatch => {
    dispatch({
      type: LIST_DISCHARGE_REPORT_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/report/discharge?companyId=${company}${branch != null ? '&companyBranchId=' + branch : ''}${productType != null ? '&productTypeId=' + productType : ''}&dateFilter=${date}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_DISCHARGE_REPORT_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_DISCHARGE_REPORT_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function getExcel(token, company, start, end, driverId) {
  return dispatch => {
    dispatch({
      type: GET_EXCEL_DISCHARGE_REPORT_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/report/${company}/discharge/download/excel?start=${start}&end=${end}&driverId=${driverId}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: GET_EXCEL_DISCHARGE_REPORT_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: GET_EXCEL_DISCHARGE_REPORT_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function clear() {
  return dispatch => {
    dispatch({
      type: CLEAR
    });
  };
}
