import UserTable from "./AnalysisOfDistanceAndVolumeReports";

const AnalysisOfDistanceAndVolumeReports = [
  {
    path: "/analise-de-distancia-e-volume",
    exact: true,
    component: UserTable
  }
];

export default AnalysisOfDistanceAndVolumeReports;
