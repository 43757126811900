import {
  UPDATE_STATE_CONFIRM_SELLER_LOADING,
  UPDATE_STATE_CONFIRM_SELLER_SUCCESS,
  UPDATE_STATE_CONFIRM_SELLER_FAILED,
  LIST_CONFIRMED_ORDERS_LOADING,
  LIST_CONFIRMED_ORDERS_SUCCESS,
  LIST_CONFIRMED_ORDERS_FAILED,
  LIST_LAVAGENS_LOADING,
  LIST_LAVAGENS_SUCCESS,
  LIST_LAVAGENS_FAILED,
  LIST_OCORRENCIAS_LOADING,
  LIST_OCORRENCIAS_SUCCESS,
  LIST_OCORRENCIAS_FAILED,
  LIST_INCONSISTENCIAS_LOADING,
  LIST_INCONSISTENCIAS_SUCCESS,
  LIST_INCONSISTENCIAS_FAILED,
  DELETE_ORDER_LOADING,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAILED,
  UPDATE_ORDER_LOADING,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAILED,
  CLEAR
} from "../actions/OrderActions";


const initialState = {success: false,
  loading: false,
  error: null,
  orders: [],
  lavagens: [],
  ocorrencias: [],
  inconsistencias: [],
};

const OrderReducer = function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_STATE_CONFIRM_SELLER_LOADING: {
      console.log("UPDATE_STATE_CONFIRM_SELLER_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_STATE_CONFIRM_SELLER_SUCCESS: {
      console.log("UPDATE_STATE_CONFIRM_SELLER_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Pedido Confirmado com sucesso!",
        loading: false,
        error: null
      };
    }
    case UPDATE_STATE_CONFIRM_SELLER_FAILED: {
      console.log("UPDATE_STATE_CONFIRM_SELLER_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case LIST_OCORRENCIAS_LOADING: {
      console.log("LIST_OCORRENCIAS_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_OCORRENCIAS_SUCCESS: {
      console.log("LIST_OCORRENCIAS_SUCCESS", action?.payload)
      return {
        ...state,
        ocorrencias: action?.payload,
        loading: false
      };
    }
    case LIST_OCORRENCIAS_FAILED: {
      console.log("LIST_OCORRENCIAS_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case LIST_INCONSISTENCIAS_LOADING: {
      console.log("LIST_INCONSISTENCIAS_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_INCONSISTENCIAS_SUCCESS: {
      console.log("LIST_INCONSISTENCIAS_SUCCESS", action?.payload)
      return {
        ...state,
        inconsistencias: action?.payload,
        loading: false
      };
    }
    case LIST_INCONSISTENCIAS_FAILED: {
      console.log("LIST_INCONSISTENCIAS_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case DELETE_ORDER_LOADING: {
      console.log("DELETE_ORDER_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_ORDER_SUCCESS: {
      console.log("DELETE_ORDER_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Pedido deletado com sucesso!",
        loading: false
      };
    }
    case DELETE_ORDER_FAILED: {
      console.log("DELETE_ORDER_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case LIST_LAVAGENS_LOADING: {
      console.log("LIST_LAVAGENS_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_LAVAGENS_SUCCESS: {
      console.log("LIST_LAVAGENS_SUCCESS", action?.payload)
      return {
        ...state,
        lavagens: action?.payload,
        loading: false
      };
    }
    case LIST_LAVAGENS_FAILED: {
      console.log("LIST_LAVAGENS_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case LIST_CONFIRMED_ORDERS_LOADING: {
      console.log("LIST_CONFIRMED_ORDERS_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_CONFIRMED_ORDERS_SUCCESS: {
      console.log("LIST_CONFIRMED_ORDERS_SUCCESS", action?.payload)
      return {
        ...state,
        orders: action?.payload,
        loading: false
      };
    }
    case LIST_CONFIRMED_ORDERS_FAILED: {
      console.log("LIST_CONFIRMED_ORDERS_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case UPDATE_ORDER_LOADING: {
      console.log("UPDATE_ORDER_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_ORDER_SUCCESS: {
      console.log("UPDATE_ORDER_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Pedido alterado com sucesso!",
        loading: false,
        error: null
      };
    }
    case UPDATE_ORDER_FAILED: {
      console.log("UPDATE_ORDER_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    
    case CLEAR: {
      return {
        ...state,
        error: null,
        success: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default OrderReducer;
