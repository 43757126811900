import React, { Component, useState, useEffect } from "react";
import {
  Dialog,
  Button,
  Grid,
  FormControlLabel,
  Switch,
  Icon
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { getUserById, updateUser, addNewUser } from "./TableService";
import { generateRandomId } from "utils";
import InputMask from "react-input-mask";
import { connect } from "react-redux";

import { createBomber, updateBomber } from "../../redux/actions/BomberActions"
import { createApplication, updateApplication } from "../../redux/actions/ApplicationActions"

import Input from "./../../components/Input"


import Swal from 'sweetalert2'

const MemberEditorDialog = (props) => {

  const { handleClose, open, user, uid } = props

  const [name, setName] = useState(uid != null ? uid.name : "");
  const [description, setDescription] = useState(uid != null ? uid.description : "");

  const [message, setMessage] = useState("");

  const handleFormSubmit = () => {

    if (name == "") {
      setMessage("Preencha o nome da aplicação")
    } else if (description == "") {
      setMessage("Preencha a descrição da aplicação")
    } else {
      handleClose()
      Swal.fire({
        icon: 'warning',
        title: uid == null ? "Deseja realmente adicionar este tipo de aplicação?" : "Deseja realmente editar este tipo de aplicação?",
        showDenyButton: true,
        confirmButtonColor: "#f78726",
        denyButtonColor: "#262d47",
        confirmButtonText: uid == null ? 'Adicionar' : "Editar",
        denyButtonText: uid == null ? `Não adicionar` : "Não Editar",
      }).then((result) => {
        if (result.isConfirmed) {
          if (uid == null) {
            props.dispatch(createApplication(user?.token, {
              name,
              description
            }))
          } else {
            props.dispatch(updateApplication(user?.token, {
              name,
              description,
              id: uid.id
            }))
          }
        }
      })
    }
  }

  useEffect(() => {
    console.log("uid", uid)
  })

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="p-24" style={{ width: "400px" }}>
        <h4 className="mb-20">Adicionar Tipo de Aplicação</h4>
        <ValidatorForm onSubmit={() => {
          handleFormSubmit()
        }}>
          <Grid className="mb-16" container spacing={10}>
            <Grid item sm={12}>
              <label className="mb-4">Nome</label>
              <Input value={name} style={{ marginTop: -50 }} onChange={(event) => {
                setName(event.target.value)
              }} />

              <label className="mb-4">Descrição</label>
              <Input value={description} style={{ marginTop: -50 }} onChange={(event) => {
                setDescription(event.target.value)
              }} />
              {
                message != "" && <div style={{ backgroundColor: "rgba(255,0,0,.2)", padding: 5, marginTop: 10 }}><Icon style={{ color: "red", marginRight: 5, marginLeft: 5 }} fontSize="small">close</Icon><span style={{ color: "red", fontWeight: "bold" }}>{message}</span></div>
              }
            </Grid>
          </Grid>

          <div className="flex flex-space-between flex-middle">
            <Button variant="contained" color="primary" type="submit">
              Salvar
            </Button>
            <Button onClick={() => {
              handleClose()
            }}>Cancelar</Button>
          </div>
        </ValidatorForm>
      </div>
    </Dialog>
  );
}

const mapStateToProps = state => ({
  user: state.user,
  loading: state.application.loading,
  error: state.application.error,
  success: state.application.success,
});

export default connect(mapStateToProps)(MemberEditorDialog);