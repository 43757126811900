import {
  CREATE_CONTRACT_LOADING,
  CREATE_CONTRACT_SUCCESS,
  CREATE_CONTRACT_FAILED,
  UPDATE_CONTRACT_LOADING,
  UPDATE_CONTRACT_SUCCESS,
  UPDATE_CONTRACT_FAILED,
  GET_CONTRACT_LOADING,
  GET_CONTRACT_SUCCESS,
  GET_CONTRACT_FAILED,
  CLEAR
} from "../actions/ContractActions";


const initialState = {success: false,
  loading: false,
  error: null,
contracts: []};

const userReducer = function(state = initialState, action) {
  switch (action.type) {
    case CREATE_CONTRACT_LOADING: {
      console.log("CREATE_CONTRACT_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case CREATE_CONTRACT_SUCCESS: {
      console.log("CREATE_CONTRACT_SUCCESS")
      return {
        ...state,
        success: "Contrato cadastrado com sucesso!",
        loading: false,
        error: null
      };
    }
    case CREATE_CONTRACT_FAILED: {
      console.log("CREATE_CONTRACT_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case UPDATE_CONTRACT_LOADING: {
      console.log("UPDATE_CONTRACT_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_CONTRACT_SUCCESS: {
      console.log("UPDATE_CONTRACT_SUCCESS")
      return {
        ...state,
        success: "Contrato atualizado com sucesso!",
        loading: false,
        error: null
      };
    }
    case UPDATE_CONTRACT_FAILED: {
      console.log("UPDATE_CONTRACT_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case GET_CONTRACT_LOADING: {
      console.log("GET_CONTRACT_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case GET_CONTRACT_SUCCESS: {
      console.log("GET_CONTRACT_SUCCESS")
      return {
        ...state,
        contracts: action?.payload,
        loading: false,
        error: null
      };
    }
    case GET_CONTRACT_FAILED: {
      console.log("GET_CONTRACT_FAILED", action.payload)
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    
    case CLEAR: {
      return {
        ...state,
        error: null,
        success: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default userReducer;
