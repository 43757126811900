/* eslint-disable */
import React, { Component, useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Icon,
  TablePagination,
  Button,
  Grid,
  Card
} from "@material-ui/core";
import { getAllUser, deleteUser } from "./TableService";
import MemberEditorDialog from "./MemberEditorDialog";
import { Breadcrumb, ConfirmationDialog } from "egret";
import shortid from "shortid";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptLocale from "date-fns/locale/pt-BR";
import { MyDocument } from "./pdf"
import { format } from "date-fns"
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReactExport from "react-export-excel";
import { connect } from "react-redux";
import Swal from 'sweetalert2'
import IntegrationReactSelect from "./ReactSelect"
import moment from "moment"

import { listConcreteMixerProductionReports } from "../../redux/actions/ReportsActions"
import { listFleet } from "../../redux/actions/FleetActions"

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import './Button.css'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const CrudTable = (props) => {

  const { userRedux, reports, fleets } = props

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [date, setDate] = useState(new Date())
  const [finalData, setFinalData] = useState(new Date())
  const [uid, setUid] = useState([])
  const [dataList, setDataList] = useState([])
  const [dataSet, setDataSet] = useState([])
  const [shouldOpenEditorDialog, setShouldOpenEditorDialog] = useState(false)
  const [shouldOpenConfirmationDialog, setShouldOpenConfirmationDialog] = useState(false)
  const [options, setOptions] = useState([])
  const [selected, setSelected] = useState(null)

  const setPageFunction = page => {
    setPage(page)
  };

  const setRowsPerPageFunction = event => {
    setRowsPerPage(event.target.value)
  };

  const handleChangePage = (event, newPage) => {
    setPageFunction(newPage)
  };

  const handleDialogClose = () => {
    setShouldOpenEditorDialog(false)
    setShouldOpenConfirmationDialog(false)
    updatePageData()
  };

  const handleDeleteUser = user => {
    setShouldOpenConfirmationDialog(true)
  };

  const handleConfirmationResponse = () => {

  };

  const createObjectDataSet = (reports) => {
    var newObj = []
    reports?.map((item, key) => {
      newObj.push({
        Data: item?.date,
        Frota: item?.vehicle?.plate,
        Volume_Medio: item?.mediaVolumeM3,
        Total: item?.totalVolumeM3,
        Volume_Medio_Total: key == 0 ? reports?.mediaVolumeTotal : "",
      })
      return item;
    })
    console.log("newOjt", newObj)
    setDataSet(newObj)
  }

  const filterData = () => {
    if (date == null) {
      Swal.fire({
        icon: 'error',
        title: "Adicione a data inicial",
        showConfirmButton: false,
        timer: 3000
      })
    } else if (finalData == null) {
      Swal.fire({
        icon: 'error',
        title: "Adicione a data final",
        showConfirmButton: false,
        timer: 3000
      })
    } else {
      props.dispatch(listConcreteMixerProductionReports(userRedux?.token, format(date, "dd/MM/yyyy"), format(finalData, "dd/MM/yyyy"), selected, userRedux?.company))
    }
  }

  useEffect(() => {
    updatePageData()
    console.log("finalData", finalData)
    props.dispatch(listConcreteMixerProductionReports(userRedux?.token, format(date, "dd/MM/yyyy"), format(finalData, "dd/MM/yyyy"), selected, userRedux?.company))
    props.dispatch(listFleet(userRedux?.token, userRedux?.company))
  }, [])

  useEffect(() => {
    console.log("reports", reports)
    reports.mediaVolumeTotal = reports?.reduce((previous, current) => previous += current?.totalVolumeM3, 0)
    setDataList(reports)
    createObjectDataSet(reports)
  }, [reports])

  useEffect(() => {
    if (fleets != null) {
      console.log("fleets", fleets)
      var newOptions = []
      fleets.map(item => {
        newOptions.push({ label: item?.plate, value: item?.id })
      })
      setOptions(newOptions)
    }
  }, [fleets])

  const updatePageData = () => {
  };

  const handleDateChange = date => {
    console.log("date", date)
    setDate(date)
  };

  const handleFinalDateChange = finalDate => {
    setFinalData(finalDate)
  };

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: "Relatório Produção Média Por Betoneira" }]} />
      </div>
      <Grid container spacing={5}>
        <Grid item lg={3} md={3} sm={12} xs={12} style={{ marginTop: 6 }}>
          <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="mb-16 w-100"
              margin="none"
              id="mui-pickers-date"
              label="Data Início"
              format="dd/MM/yyyy"
              inputVariant="standard"
              type="text"
              autoOk={true}
              value={date}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12} style={{ marginTop: 6 }}>
          <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="mb-16 w-100"
              margin="none"
              id="mui-pickers-date"
              format="dd/MM/yyyy"
              label="Data Fim"
              inputVariant="standard"
              type="text"
              autoOk={true}
              value={finalData}
              onChange={handleFinalDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <IntegrationReactSelect data={options} setSelected={(value) => { setSelected(value) }} />
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Button color="primary" variant="contained" type="button" onClick={() => { filterData() }} style={{ marginTop: 10 }}>
            <Icon>filter_list</Icon>
            <span className="pl-8 capitalize">Filtrar</span>
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <ReactHTMLTableToExcel
            id="button"
            className="button"
            table="table-to-xls"
            filename="relatório-medio-por-betoneira"
            sheet="Média por Betoneira"
            buttonText="Exportar Excel" />
          <Table id="table-to-xls" className="crud-table" style={{ display: "none" }}>
            <TableHead>
              <TableRow>
                <TableCell style={{ textAlign: "left" }}>Volume total: {dataList?.mediaVolumeTotal}</TableCell>
              </TableRow>
            </TableHead>
            <TableRow>
            </TableRow>
            <TableRow>
            </TableRow>
            <TableHead>
              <TableRow>
                <TableCell>Data</TableCell>
                <TableCell>Frota</TableCell>
                <TableCell>Volume médio</TableCell>
                <TableCell>Volume Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow key={shortid.generate()} style={{ paddingVertical: 5 }}>
                    <TableCell className="px-0" align="left" style={{ fontSize: 10 }}>{item?.date}</TableCell>
                    <TableCell className="px-0" align="left" style={{ fontSize: 10 }}>{item?.vehicle?.plate}</TableCell>
                    <TableCell className="px-0" align="left" style={{ fontSize: 10 }}>{item?.mediaVolumeM3}</TableCell>
                    <TableCell className="px-0" align="left" style={{ fontSize: 10 }}>{item?.totalVolumeM3}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {/* <ExcelFile filename="relatório-medio-por-betoneira" element={<Button style={{ backgroundColor: "#f78726", color: "white", marginBottom: 10, marginRight: 5 }} aria-label="Add">Exportar Excel</Button>}>
            <ExcelSheet data={dataSet} name="Produção por Betoneira">
              <ExcelColumn label="Data Inicial" value="Data_Inicial" />
              <ExcelColumn label="Data Final" value="Data_Final" />
              <ExcelColumn label="Frota" value="Frota" />
              <ExcelColumn label="Volume Medio" value="Volume_Medio" />
              <ExcelColumn label="Volume Total" value="Total" />
              <ExcelColumn label="Volume Total Do Periodo" value="Volume_Medio_Total" />
            </ExcelSheet>
            {/* Volume_Medio_Total */}
          {/* </ExcelFile>  */}
          <PDFDownloadLink document={<MyDocument data={dataSet} />} fileName="relatório-medio-por-betoneira.pdf">
            <Button style={{ backgroundColor: "transparent", border: "1.5px solid #f78726", color: "#f78726" }} aria-label="Add">Exportar Pdf</Button>
          </PDFDownloadLink>
          <span style={{ float: "right", backgroundColor: "white", borderColor: "#f78726", borderWidth: 1, color: "#f78726", padding: "10px" }}>Volume Total: <span style={{ backgroundColor: "#f78726", color: 'white', padding: "8px", borderRadius: "10px", fontWeight: "bold" }}>{dataList?.mediaVolumeTotal}</span></span>
        </Grid>
      </Grid>


      <Card className="w-100 overflow-auto" elevation={6}>
        <h6 style={{ textAlign: "center", marginRight: 10, fontSize: 10, marginTop: 10 }}>Data de emissão: {moment().format("D/MM/Y")}</h6>
        <h6 style={{ textAlign: "center", marginRight: 10, fontSize: 10, }}>Usuário: {userRedux?.displayName}</h6>
        <h4 style={{ textAlign: "center", marginRight: 10 }}>Relatório De Produção Média Por Betoneira</h4>

        <Table className="crud-table" style={{ whiteSpace: "pre", minWidth: "750px" }}>
          <TableHead>
            <TableRow>
              <TableCell>Data</TableCell>
              <TableCell>Frota</TableCell>
              <TableCell>Volume médio</TableCell>
              <TableCell>Volume Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => (
                <TableRow key={shortid.generate()} style={{ paddingVertical: 5 }}>
                  <TableCell className="px-0" align="left" style={{ fontSize: 10 }}>{item?.date}</TableCell>
                  <TableCell className="px-0" align="left" style={{ fontSize: 10 }}>{item?.vehicle?.plate}</TableCell>
                  <TableCell className="px-0" align="left" style={{ fontSize: 10 }}>{item?.mediaVolumeM3}</TableCell>
                  <TableCell className="px-0" align="left" style={{ fontSize: 10 }}>{item?.totalVolumeM3}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        <TablePagination
          className="px-16"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={dataList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Linhas por Página"
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={setRowsPerPageFunction}
        />

        {shouldOpenEditorDialog && (
          <MemberEditorDialog
            handleClose={handleDialogClose}
            open={shouldOpenEditorDialog}
            uid={uid}
          />
        )}
        {shouldOpenConfirmationDialog && (
          <ConfirmationDialog
            open={shouldOpenConfirmationDialog}
            onConfirmDialogClose={handleDialogClose}
            onYesClick={handleConfirmationResponse}
            text="Tem certeza que deseja excluir este registro?"
            title="Excluir registro"
            confirmButtonText="Sim"
            declineButtonText="Não"
          />
        )}
      </Card>
    </div >
  );
}

const mapStateToProps = state => ({
  userRedux: state.user,
  reports: state.reports.concreteMixerProduction,
  fleets: state.fleet.fleets,
  loading: state.reports.loading,
  error: state.reports.error,
  success: state.reports.success,
});

export default connect(mapStateToProps)(CrudTable);