import history from "history.js";
import jwtAuthService from "../../services/jwtAuthService";
import axios from "axios";


export const SET_USER_DATA = "USER_SET_DATA";
export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const REGISTER_USER_LOADING = "REGISTER_USER_LOADING";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED";
export const CHANGE_AVATAR_LOADING = "CHANGE_AVATAR_LOADING";
export const CHANGE_AVATAR_SUCCESS = "CHANGE_AVATAR_SUCCESS";
export const CHANGE_AVATAR_FAILED = "CHANGE_AVATAR_FAILED";
export const UPDATE_USER_LOADING = "UPDATE_USER_LOADING";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";
export const LIST_ALERT_LOADING = "LIST_ALERT_LOADING";
export const LIST_ALERT_SUCCESS = "LIST_ALERT_SUCCESS";
export const LIST_ALERT_FAILED = "LIST_ALERT_FAILED";
export const UPDATE_ALERT_LOADING = "UPDATE_ALERT_LOADING";
export const UPDATE_ALERT_SUCCESS = "UPDATE_ALERT_SUCCESS";
export const UPDATE_ALERT_FAILED = "UPDATE_ALERT_FAILED";
export const UPDATE_PASSWORD_LOADING = "UPDATE_PASSWORD_LOADING";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILED = "UPDATE_PASSWORD_FAILED";
export const CLEAR = "CLEAR";

export function setUserData(user) {
  return dispatch => {
    dispatch({
      type: SET_USER_DATA,
      data: user
    });
  };
}

export function listarAlert(token) {
  return dispatch => {
    dispatch({
      type: LIST_ALERT_LOADING,
    });
    axios({
      method: 'get',
      url: 'https://api.stonik.com.br/web/user/alert',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_ALERT_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_ALERT_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function updateAlert(token, data) {
  return dispatch => {
    dispatch({
      type: UPDATE_ALERT_LOADING,
    });
    axios({
      method: 'put',
      url: 'https://api.stonik.com.br/web/user/alert',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: UPDATE_ALERT_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: UPDATE_ALERT_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function registerUser(user) {
  return dispatch => {
    dispatch({
      type: REGISTER_USER_LOADING,
    });
    axios({
      method: 'post',
      url: 'https://api.stonik.com.br/mobile/user',
      headers: {
        "Content-Type": "application/json"
      },
      data: user
    }).then(res => {
      dispatch({
        type: REGISTER_USER_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: REGISTER_USER_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function changeAvatar(avatar, token) {
  return dispatch => {
    dispatch({
      type: CHANGE_AVATAR_LOADING,
    });
    axios({
      method: 'put',
      url: 'https://api.stonik.com.br/web/user/avatar',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data: {
        file: avatar
      }
    }).then(res => {
      dispatch({
        type: CHANGE_AVATAR_SUCCESS,
        payload: res.data
      });
      
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: CHANGE_AVATAR_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function updateUser(user, token) {
  return dispatch => {
    dispatch({
      type: UPDATE_USER_LOADING,
    });
    axios({
      method: 'put',
      url: 'https://api.stonik.com.br/web/user',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data: user
    }).then(res => {
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: res.data
      });
      
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: UPDATE_USER_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function updatePassword(data, token) {
  return dispatch => {
    dispatch({
      type: UPDATE_PASSWORD_LOADING,
    });
    axios({
      method: 'put',
      url: 'https://api.stonik.com.br/mobile/user/password',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: UPDATE_PASSWORD_SUCCESS,
        payload: res.data
      });
      
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: UPDATE_PASSWORD_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function clear() {
  return dispatch => {
    dispatch({
      type: CLEAR
    });
  };
}

export function logoutUser() {
  return dispatch => {
    jwtAuthService.logout();

    history.push({
      pathname: "/login"
    });

    dispatch({
      type: USER_LOGGED_OUT
    });
  };
}
