import LeftSidebarLayout from "./LeftSidebarCard";
import UserProfile from "./UserProfile";

const pageLayoutRoutes = [
  {
    path: "/page-layouts/Left-sidebar-card",
    component: LeftSidebarLayout
  },
  {
    path: "/perfil",
    component: UserProfile
  }
];

export default pageLayoutRoutes;
