import EditUser from "./EditUser";

const formsRoutes = [
  {
    path: "/editar-usuario",
    component: EditUser
  },
];

export default formsRoutes;
