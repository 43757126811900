import CrudTable from "./MyDischards";

const MyDischards = [
  {
    path: "/minhas-descargas",
    exact: true,
    component: CrudTable
  }
];

export default MyDischards;
