import React, { Component } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Button,
  Icon,
  Grid,
  Card,
} from "@material-ui/core";
import { getAllUser, deleteUser } from "./TableService";
import { Breadcrumb } from "egret";
import shortid from "shortid";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptLocale from "date-fns/locale/pt-BR";
import IntegrationReactSelect from "./ReactSelect";
import { MyDocument } from "../utilities/pdf"
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const dataSet1 = [
  {
    inicial: "02/09/2021",
    final: "02/09/2021",
    filial: 'Matriz',
    faturado: "R$ 3000,00"
  },
  {
    inicial: "02/09/2021",
    final: "02/09/2021",
    filial: 'Matriz',
    faturado: "R$ 3000,00"
  }, {
    inicial: "02/09/2021",
    final: "02/09/2021",
    filial: 'Matriz',
    faturado: "R$ 3000,00"
  },
];

class CrudTable extends Component {
  state = {
    rowsPerPage: 10,
    page: 0,
    date: new Date(),
    finalDate: new Date(),
    userList: [],
    shouldOpenEditorDialog: false,
    shouldOpenConfirmationDialog: false
  };

  setPage = page => {
    this.setState({ page });
  };

  setRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleChangePage = (event, newPage) => {
    this.setPage(newPage);
  };

  handleDialogClose = () => {
    this.setState({
      shouldOpenEditorDialog: false,
      shouldOpenConfirmationDialog: false
    });
    this.updatePageData();
  };

  handleDeleteUser = user => {
    this.setState({
      user,
      shouldOpenConfirmationDialog: true
    });
  };

  handleConfirmationResponse = () => {
    deleteUser(this.state.user).then(() => {
      this.handleDialogClose();
    });
  };

  componentDidMount() {
    this.updatePageData();
  }

  updatePageData = () => {
    getAllUser().then(({ data }) => this.setState({ userList: [...data] }));
  };

  handleDateChange = date => {
    console.log("date", date)
    this.setState({ date });
  };

  handleFinalDateChange = finalDate => {
    this.setState({ finalDate });
  };

  render() {
    let {
      rowsPerPage,
      page,
      userList,
      date,
      finalDate,
      shouldOpenConfirmationDialog,
      shouldOpenEditorDialog
    } = this.state;
    return (
      <div className="m-sm-30">
        <div className="mb-sm-30">
          <Breadcrumb routeSegments={[{ name: "Meus descartes" }]} />
        </div>
        <Grid container spacing={5}>
          <Grid item lg={3} md={3} sm={12} xs={12} style={{ marginTop: 6 }}>
            <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
              <KeyboardDatePicker
                className="mb-16 w-100"
                margin="none"
                id="mui-pickers-date"
                label="Data Início"
                format="dd/MM/yyyy"
                inputVariant="standard"
                type="text"
                autoOk={true}
                value={date}
                onChange={this.handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12} style={{ marginTop: 6 }}>
            <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
              <KeyboardDatePicker
                className="mb-16 w-100"
                margin="none"
                id="mui-pickers-date"
                format="dd/MM/yyyy"
                label="Data Fim"
                inputVariant="standard"
                type="text"
                autoOk={true}
                value={finalDate}
                onChange={this.handleFinalDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <IntegrationReactSelect />
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <Button color="primary" variant="contained" type="submit" style={{ marginTop: 10 }}>
              <Icon>filter_list</Icon>
              <span className="pl-8 capitalize">Filtrar relatório</span>
            </Button>
          </Grid>
        </Grid>
        {
          this.state.userList.length > 0 ?
            <>
              <ExcelFile filename="relatorio-faturamento" element={<Button style={{ backgroundColor: "#f78726", color: "white", marginBottom: 10, marginRight: 5 }} aria-label="Add">Exportar Excel</Button>}>
                <ExcelSheet data={dataSet1} name="Employees">
                  <ExcelColumn label="Data Inicial" value="inicial" />
                  <ExcelColumn label="Data Final" value="final" />
                  <ExcelColumn label="Filial" value="filial" />
                  <ExcelColumn label="Valor Faturado" value="faturado" />
                </ExcelSheet>
              </ExcelFile>

              <PDFDownloadLink document={<MyDocument />} fileName="relatório-faturamento.pdf">
                <Button style={{ backgroundColor: "transparent", border: "1.5px solid #f78726", color: "#f78726", marginBottom: 10 }} aria-label="Add">Exportar Pdf</Button>
              </PDFDownloadLink>
              <Card className="w-100 overflow-auto" elevation={6}>
                <h6 style={{ textAlign: "center", marginRight: 10, fontSize: 10, marginTop: 10 }}>Data de emissão: 02/09/2021</h6>
                <h6 style={{ textAlign: "center", marginRight: 10, fontSize: 10, }}>Usuário: Bruno Victor</h6>
                <h4 style={{ textAlign: "center", marginRight: 10 }}>Meus descartes</h4>

                <Table className="crud-table" style={{ whiteSpace: "pre", minWidth: "750px" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Data Inicial</TableCell>
                      <TableCell>Data Final</TableCell>
                      <TableCell>Filial</TableCell>
                      <TableCell>Valor Faturado</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userList
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((user, index) => (
                        <TableRow key={shortid.generate()} style={{ paddingVertical: 5 }}>
                          <TableCell className="px-0" align="left">01/10/2021</TableCell>
                          <TableCell className="px-0" align="left">01/10/2021</TableCell>
                          <TableCell className="px-0" align="left">Matriz</TableCell>
                          <TableCell className="px-0" align="left">R$ 3000,00</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>

                <TablePagination
                  className="px-16"
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={userList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "Anterior"
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Próxima"
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.setRowsPerPage}
                />
              </Card>
            </>
            :
            <Card className="w-100 overflow-auto" elevation={6} style={{ padding: 50 }}>
              <h4 align="center">Sem resultados</h4>
              <h6 align="center">(Selecione os parâmetros)</h6>
            </Card>
        }

      </div >
    );
  }
}

export default CrudTable;
