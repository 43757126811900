import UserTable from "./CustomReports";

const CustomReports = [
  {
    path: "/relatorio-personalizado",
    exact: true,
    component: UserTable
  }
];

export default CustomReports;
