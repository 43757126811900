/* eslint-disable */
import React, { Component, useEffect, useRef, useState } from "react";
import {
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Icon,
  TablePagination,
  Button,
  Card
} from "@material-ui/core";
import { getAllUser, deleteUser } from "./TableService";
import MemberEditorDialog from "./MemberEditorDialog";
import { Breadcrumb, ConfirmationDialog } from "egret";
import shortid from "shortid";
import * as ReactDOM from "react-dom";
import CalendarHeader from "./CalendarHeader";
import EventEditorDialog from "./EventEditorDialog";
import ptBR from 'date-fns/locale/pt-BR'

import { clear, listOperator, deleteOperator } from "../../redux/actions/OperatorActions"

import { connect } from "react-redux";

import Swal from 'sweetalert2'
import { Calendar, Views, momentLocalizer } from "react-big-calendar";

import moment from "moment"
import 'moment/locale/pt-br';
import { listConfirmedOrders } from '../../redux/actions/OrderActions'

const localizer = momentLocalizer(moment);

let viewList = Object.keys(Views).map(key => Views[key]);

const date = new Date();

const CrudTable = (props) => {

  const {
    userRedux,
    operators,
    loading,
    success,
    error,
    orders
  } = props

  const [events, setEvents] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [uid, setUid] = useState(null)
  const [operatorList, setOperatorList] = useState([])
  const [user, setUser] = useState("")
  const [newEvent, setNewEvent] = useState(null)
  const [shouldShowEventDialog, setShouldShowEventDialog] = useState(false)

  var headerComponentRef = useRef();

  const setPageFunction = page => {
    setPage(page);
  };

  const setRowsPerPageFunction = event => {
    setRowsPerPage(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPageFunction(newPage);
  };

  const handleDialogCloseFunction = () => {
    setShouldShowEventDialog(false)
  };

  const handleConfirmationResponse = () => {
    if (userRedux?.role != "SA") {
      props.dispatch(deleteOperator(userRedux?.token, uid, userRedux?.company))
    }
  };

  const openExistingEventDialog = event => {
    setNewEvent(event)
    setShouldShowEventDialog(true)
  };

  useEffect(() => {
    props.dispatch(listConfirmedOrders(userRedux?.token, userRedux?.company))
  }, [])

  useEffect(() => {
    console.log("operators", operators)
    operators != null && setOperatorList(operators)
  }, [operators])

  function subtractMonths(date) {
    date.setMonth(date.getMonth() - 1);
    date.setMonth(date.getMonth() + 1);

    return date;
  }

  const setRegisters = (orders) => {
    var newObj = []
    orders?.map(item => {
      var date = new Date(item?.dateDelivery?.split(" ")[0]?.split("/")[2], item?.dateDelivery?.split(" ")[0]?.split("/")[1] - 1, item?.dateDelivery?.split(" ")[0]?.split("/")[0], item?.dateDelivery?.split(" ")[1]?.split(":")[0], item?.dateDelivery?.split(" ")[1]?.split(":")[1], 0, 0);
      newObj.push({
        id: item?.order,
        title: item?.order + " - " + item?.client?.name,
        start: date,
        end: date,
        // start: moment(item?.dateDelivery?.split(" ")[0]?.split("/")[2] + "-" + item?.dateDelivery?.split(" ")[0]?.split("/")[1] + "-" + item?.dateDelivery?.split(" ")[0]?.split("/")[0] + "T" + item?.dateDelivery?.split(" ")[1]).locale("en")?.format('LLLL'),
        meterage: item?.meterage,
        contrato: item?.contract?.id,
        pumpServiceRequired: item?.pumpServiceRequired,
        application: item?.application?.id,
        client: item?.client?.name,
        deliveryLocation: item?.deliveryLocation,
        product: item?.product,
        status: item?.currentStatus?.name,
        telefone: item?.client?.contact,
        email: item?.client?.email,
        totalValue: item?.valueTotal,
        contrato: item?.contract?.id
      })
    })
    setEvents(newObj)
  }

  useEffect(() => {
    console.log("orders", orders)
    if (orders != null) {
      setRegisters(orders)
    }
  }, [orders])

  useEffect(() => {
    console.log("events", events)
  }, [events])

  useEffect(() => {
    if (success != false) {
      Swal.fire({
        icon: 'success',
        title: success,
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(() => {
        props.dispatch(clear())
        window.location.reload()
      }, 3500)
    }
  }, [success])

  useEffect(() => {
    if (error != null) {
      console.log("Error", error)

      Swal.fire({
        icon: 'error',
        title: error,
        showConfirmButton: false,
        timer: 3000
      })

      setTimeout(() => {
        props.dispatch(clear())
        window.location.reload()
      }, 3500)
    }
  }, [error])

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: "Gestão de agendamento" }]} />
      </div>

      {/* <Button
        className="mb-16"
        variant="contained"
        color="primary"
        onClick={() => {
          setUid(null)
        }}
      >
        Adicionar registro
      </Button> */}
      <div className="h-700 flex-column">
        <div ref={headerComponentRef} />
        <Calendar
          selectable
          localizer={localizer}
          events={events || null}
          defaultView={Views.MONTH}
          defaultDate={new Date()}
          startAccessor="start"
          endAccessor="end"
          views={viewList}
          step={60}
          components={{
            toolbar: props => {
              return headerComponentRef.current ? (
                ReactDOM.createPortal(
                  <CalendarHeader {...props} />,
                  headerComponentRef.current
                )
              ) : (
                <div>Header component not found</div>
              );
            }
          }}
          // onNavigate={this.handleNavigate}
          onSelectEvent={event => {
            openExistingEventDialog(event);
          }}
        />
      </div>
      {shouldShowEventDialog && (
        <EventEditorDialog
          handleClose={handleDialogCloseFunction}
          open={shouldShowEventDialog}
          event={newEvent}
        />
      )}
    </div>
  );

}

const mapStateToProps = state => ({
  userRedux: state.user,
  operators: state.operator.operators,
  loading: state.operator.loading,
  orders: state.orders.orders,
  error: state.operator.error,
  success: state.operator.success,
});

export default connect(mapStateToProps)(CrudTable);
