import React, { useState, useEffect } from "react";
import {
  Dialog,
  Button,
  Grid,
  Icon
} from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";

import { createManager, updateManager } from "../../redux/actions/ManagerActions"

import Input from "./../../components/Input"
import { verifyPassword } from "../utilities/utils"

import Swal from 'sweetalert2'

const MemberEditorDialog = (props) => {

  const { handleClose, open, user, uid } = props

  const [company, setCompany] = useState(uid != null ? uid.user.company : "");
  const [nome, setNome] = useState(uid != null ? uid.user.name : "");
  const [cpf, setCpf] = useState(uid != null ? uid.user.cpf : "");
  const [email, setEmail] = useState(uid != null ? uid.user.email : "");
  const [password, setPassword] = useState("");
  const [contact, setContact] = useState(uid != null ? uid.user.contact : "");

  const [message, setMessage] = useState("");

  function TestaCPF(strCPF) {
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF == "00000000000") return false;

    for (var i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (var i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const handleFormSubmit = () => {
    var contactReplace = contact.replace(/[^0-9]/g, "")
    if (nome == "") {
      setMessage("Preencha o nome do gestor")
    } else if (cpf == "") {
      setMessage("Preencha o CPF do gestor")
    } else if (TestaCPF(cpf.replace(/[^0-9]/g, "")) == false) {
      setMessage("CPF inválido")
    } else if (email == "") {
      setMessage("Preencha o email do gestor")
    } else if (validateEmail(email) == false) {
      setMessage("Email inválido")
    } else if (uid == null && password == "") {
      setMessage("Preencha a senha do gestor")
    } else if (uid == null && password.length < 8) {
      setMessage("A senha deve conter 8 caracteres no mínimo")
    } else if (verifyPassword(password) == false) {
      setMessage("A sua senha deve conter: caracteres especiais, números e letras.")
    } else if (contact == "") {
      setMessage("Preencha o contato do gestor")
    } else if (contactReplace.length < 11) {
      setMessage("Contato inválido")
    } else {
      handleClose()
      Swal.fire({
        icon: 'warning',
        title: uid == null ? "Deseja realmente adicionar este gestor?" : "Deseja realmente editar este gestor?",
        showDenyButton: true,
        confirmButtonColor: "#f78726",
        denyButtonColor: "#262d47",
        confirmButtonText: uid == null ? 'Adicionar' : "Editar",
        denyButtonText: uid == null ? `Não adicionar` : "Não Editar",
      }).then((result) => {
        if (result.isConfirmed) {
          if (uid == null) {
            props.dispatch(createManager(user?.token, {
              name: nome,
              cpf: cpf.replace(/[^0-9]/g, ""),
              email,
              password,
              contact
            }, user?.company))
          } else {
            props.dispatch(updateManager(user?.token, {
              name: nome,
              cpf: cpf.replace(/[^0-9]/g, ""),
              email,
              contact,
              new_password: password,
              confirm_new_password: password,
              id: uid?.user?.id
            }, user?.company))
          }
        }
      })
    }
  }

  useEffect(() => {
    console.log("uid", uid)
  })

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="p-24" style={{ width: "400px" }}>
        <h4 className="mb-20">Adicionar Gestor</h4>
        <ValidatorForm onSubmit={() => {
          handleFormSubmit()
        }}>
          <Grid className="mb-16" container spacing={10}>
            <Grid item sm={12}>
              <label className="mb-4">Nome</label>
              <Input value={nome} style={{ marginTop: -50 }} onChange={(event) => {
                setNome(event.target.value)
              }} />
              {
                uid == null && (
                  <>
                    <label className="mb-4">CPF</label>
                    <Input mask="999.999.999-99" value={cpf} onChange={(event) => {
                      setCpf(event.target.value)
                    }} />
                  </>
                )
              }

              <label className="mb-4">E-mail</label>
              <Input value={email} onChange={(event) => {
                setEmail(event.target.value)
              }} />

              <label className="mb-4">Senha</label>
              <Input value={password} onChange={(event) => {
                setPassword(event.target.value)
              }} />

              <label className="mb-4">Contato</label>
              <Input mask="(99) 9 9999-9999" value={contact} onChange={(event) => {
                setContact(event.target.value)
              }} />
              {
                message != "" && <div style={{ backgroundColor: "rgba(255,0,0,.2)", padding: 5, marginTop: 10 }}><Icon style={{ color: "red", marginRight: 5, marginLeft: 5 }} fontSize="small">close</Icon><span style={{ color: "red", fontWeight: "bold" }}>{message}</span></div>
              }
            </Grid>
          </Grid>

          <div className="flex flex-space-between flex-middle">
            <Button variant="contained" color="primary" type="submit">
              Salvar
            </Button>
            <Button onClick={() => {
              handleClose()
            }}>Cancelar</Button>
          </div>
        </ValidatorForm>
      </div>
    </Dialog>
  );
}

const mapStateToProps = state => ({
  user: state.user,
  loading: state.driver.loading,
  error: state.driver.error,
  success: state.driver.success,
});

export default connect(mapStateToProps)(MemberEditorDialog);