import UserTable from "./DiscardReports";

const DiscardReports = [
  {
    path: "/relatorio-descarte",
    exact: true,
    component: UserTable
  }
];

export default DiscardReports;
