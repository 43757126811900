import {
  LIST_BOMBER_LOADING,
  LIST_BOMBER_SUCCESS,
  LIST_BOMBER_FAILED,
  UPDATE_BOMBER_LOADING,
  UPDATE_BOMBER_SUCCESS,
  UPDATE_BOMBER_FAILED,
  DELETE_BOMBER_LOADING,
  DELETE_BOMBER_SUCCESS,
  DELETE_BOMBER_FAILED,
  CREATE_BOMBER_LOADING,
  CREATE_BOMBER_SUCCESS,
  CREATE_BOMBER_FAILED,
  CLEAR
} from "../actions/BomberActions";


const initialState = {success: false,
  loading: false,
  error: null,
  bombers: []
};

const BomberReducer = function(state = initialState, action) {
  switch (action.type) {
    case LIST_BOMBER_LOADING: {
      console.log("LIST_BOMBER_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_BOMBER_SUCCESS: {
      console.log("LIST_BOMBER_SUCCESS", action?.payload)
      return {
        ...state,
        bombers: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_BOMBER_FAILED: {
      console.log("LIST_BOMBER_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case DELETE_BOMBER_LOADING: {
      console.log("DELETE_BOMBER_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_BOMBER_SUCCESS: {
      console.log("DELETE_BOMBER_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Bombista deletado com sucesso!",
        loading: false,
        error: null
      };
    }
    case DELETE_BOMBER_FAILED: {
      console.log("DELETE_BOMBER_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case UPDATE_BOMBER_LOADING: {
      console.log("UPDATE_BOMBER_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_BOMBER_SUCCESS: {
      console.log("UPDATE_BOMBER_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Bombista atualizado com sucesso!",
        loading: false,
        error: null
      };
    }
    case UPDATE_BOMBER_FAILED: {
      console.log("UPDATE_BOMBER_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case CREATE_BOMBER_LOADING: {
      console.log("CREATE_BOMBER_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case CREATE_BOMBER_SUCCESS: {
      console.log("CREATE_BOMBER_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Bombista adicionado com sucesso!",
        loading: false,
        error: null
      };
    }
    case CREATE_BOMBER_FAILED: {
      console.log("CREATE_BOMBER_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    
    case CLEAR: {
      return {
        ...state,
        error: null,
        success: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default BomberReducer;
