import UserTable from "./DiscardAnalysisReports";

const DiscardAnalysisReports = [
  {
    path: "/relatorio-analise-descarte",
    exact: true,
    component: UserTable
  }
];

export default DiscardAnalysisReports;
