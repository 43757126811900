import React, { Component, useState, useEffect } from "react";
import {
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Icon,
  TablePagination,
  Button,
  Card
} from "@material-ui/core";
import { getAllUser, deleteUser } from "./TableService";
import MemberEditorDialog from "./MemberEditorDialog";
import { Breadcrumb, ConfirmationDialog } from "egret";
import shortid from "shortid";
import ReactSelect from "./ReactSelect"
import Swal from 'sweetalert2'

const CrudTable = props => {

  const {
    userRedux,
    salesmans,
    loading,
    success,
    error
  } = props

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [uid, setUid] = useState(null)
  const [salesmanList, setSalesmanList] = useState([])
  const [userList, setUserList] = useState([])
  const [shouldOpenEditorDialog, setShouldOpenEditorDialog] = useState(false)
  const [shouldOpenConfirmationDialog, setShouldOpenConfirmationDialog] = useState(false)

  const setPageFunction = page => {
    setPage(page);
  };

  const setRowsPerPageFunction = event => {
    setRowsPerPage(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPageFunction(newPage);
  };

  const handleDialogCloseFunction = () => {
    setShouldOpenEditorDialog(false)
    setShouldOpenConfirmationDialog(false)
  };

  const handleDeleteUser = user => {
    setShouldOpenConfirmationDialog(true)
    setUid(user.user.id)
  };

  const handleConfirmationResponse = () => {
  };

  const inactiveUser = () => {
    Swal.fire({
      icon: 'success',
      title: "Usuário bloqueado com sucesso!",
      showConfirmButton: false,
      timer: 3000
    })
  }

  const activeUser = () => {
    Swal.fire({
      icon: 'success',
      title: "Usuário ativado com sucesso!",
      showConfirmButton: false,
      timer: 3000
    })
  }

  useEffect(() => {
    getAllUser().then(({ data }) => setUserList([...data]));
  }, [])


  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: "Adicionar novo usuário" }]} />
      </div>

      <Button
        className="mb-16"
        variant="contained"
        color="primary"
        onClick={() => setShouldOpenEditorDialog(true)}
      >
        Adicionar novo usuário
      </Button>
      <Card className="w-100 overflow-auto" elevation={6}>
        <Table className="crud-table" style={{ whiteSpace: "pre", minWidth: "750px" }}>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Cpf</TableCell>
              <TableCell>Email</TableCell>
              <TableCell align="center">Ação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user, index) => (
                <TableRow key={shortid.generate()}>
                  <TableCell className="px-0" align="left">
                    {user.name}
                  </TableCell>
                  <TableCell className="px-0" align="left">
                    {user.cpf}
                  </TableCell>
                  <TableCell className="px-0" align="left">{user.email}</TableCell>
                  <TableCell className="px-0 border-none" align="center">
                    <IconButton
                      onClick={() =>{
                        setUid(user)
                        setShouldOpenEditorDialog(true)
                      }}
                    >
                      <Icon color="primary">edit</Icon>
                    </IconButton>
                    <IconButton onClick={() => handleDeleteUser(user)}>
                      <Icon color="error">delete</Icon>
                    </IconButton>
                    <IconButton onClick={() => activeUser()}>
                      <Icon style={{ color: "green" }}>check</Icon>
                    </IconButton>
                    <IconButton onClick={() => inactiveUser()}>
                      <Icon style={{ color: "red" }}>close</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        <TablePagination
          className="px-16"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={userList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Linhas por Página"
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={setRowsPerPage}
        />

        {shouldOpenEditorDialog && (
          <MemberEditorDialog
            handleClose={handleDialogCloseFunction}
            open={shouldOpenEditorDialog}
            uid={uid}
          />
        )}
        {shouldOpenConfirmationDialog && (
          <ConfirmationDialog
            open={shouldOpenConfirmationDialog}
            onConfirmDialogClose={handleDialogCloseFunction}
            onYesClick={handleConfirmationResponse}
            text="Tem certeza que deseja excluir este registro?"
            title="Excluir registro"
            confirmButtonText="Sim"
            declineButtonText="Não"
          />
        )}
      </Card>
    </div>
  );
}

export default CrudTable;
