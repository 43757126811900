import React, { useState, useEffect } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
  Button,
  Icon,
  Grid,
} from "@material-ui/core";
import "date-fns";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import Swal from 'sweetalert2'

import {updatePassword, clear} from "../../redux/actions/UserActions"
import {verifyPassword} from "../utilities/utils"

const SimpleForm = (props) => {

  const {user, loading, success, error} = props

  const [senhaAtual, setSenhaAtual] = useState("")
  const [senha, setSenha] = useState("")
  const [repita_senha, setRepita_senha] = useState("")


  useEffect(() => {
  }, [])

  useEffect(() => {
    if (loading == true) {
      console.log("CAIU AQUI")
      Swal.showLoading()
    } else {
      Swal.hideLoading()
    }
  }, [loading])

  useEffect(() => {
    if (success != false) {
      Swal.fire({
        icon: 'success',
        title: success,
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(() => {
        props.dispatch(clear())
        window.location.reload()
      }, 3500)
    }
  }, [success])

  useEffect(() => {
    if (error != null) {
      Swal.fire({
        icon: 'error',
        title: error,
        showConfirmButton: false,
        timer: 3000
      })

      setTimeout(() => {
        props.dispatch(clear())
      }, 3500)
    }
  }, [error])

  const handleSubmit = event => {
    if (senhaAtual == "") {
      Swal.fire({
        icon: 'error',
        title: "Sua senha atual está incorreta",
        showConfirmButton: false,
        timer: 3000
      })
    }else if(senha == "") {
      Swal.fire({
        icon: 'error',
        title: "Preencha a senha",
        showConfirmButton: false,
        timer: 3000
      })
    }else if(senha.length < 8){
      Swal.fire({
        icon: 'error',
        title: "A sua senha deve ter no mínimo 8 caracteres",
        showConfirmButton: false,
        timer: 3000
      })
    }else if(verifyPassword(senha) == false){
      Swal.fire({
        icon: 'error',
        title: "A sua senha deve conter: caracteres especiais, números e letras.",
        showConfirmButton: false,
        timer: 3000
      })
    }else if(senha != repita_senha){
      Swal.fire({
        icon: 'error',
        title: "As senhas devem ser idênticas",
        showConfirmButton: false,
        timer: 3000
      })
    }else{
      props.dispatch(updatePassword({
        current_password: senhaAtual,
        new_password: senha,
        confirm_new_password: repita_senha
      }, user?.token))
    }
  };

  return (
    <div>
      <ValidatorForm
        onSubmit={() => 
          handleSubmit()
        }
        onError={errors => null}
      >
        <Grid container spacing={6}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <label className="mb-4">Senha Atual</label>
            <InputMask
              className="w-100 mb-16"
              style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
              label="Senha atual"
              onChange={(event) => {setSenhaAtual(event.target.value)}}
              type="text"
              name="senhaAtual"
              value={senhaAtual}
            />
            <label className="mb-4">Nova Senha</label>
            <InputMask
              className="w-100 mb-16"
              style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
              label="Nova Senha"
              onChange={(event) => {setSenha(event.target.value)}}
              type="text"
              name="senha"
              value={senha}
            />
            <label className="mb-4">Repita a Nova Senha</label>
            <InputMask
              className="w-100 mb-16"
              style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
              label="Repita a nova senha"
              onChange={(event) => {setRepita_senha(event.target.value)}}
              type="text"
              name="repita_senha"
              value={repita_senha}
            />
            <Button color="primary" variant="contained" style={{ marginTop: 10 }} type="submit">
              <Icon>send</Icon>
              <span className="pl-8 capitalize">Alterar senha</span>
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    </div>
  );
}

const mapStateToProps = state => ({
  user: state.user,
  loading: state.user.loading,
  error: state.user.error,
  success: state.user.success,
});

export default connect(mapStateToProps)(SimpleForm);
