import React, { Component, useState, useEffect } from "react";
import {
  Dialog,
  Button,
  Grid,
  FormControlLabel,
  Switch,
  Icon
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { getUserById, updateUser, addNewUser } from "./TableService";
import { generateRandomId } from "utils";
import InputMask from "react-input-mask";
import {
  TimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import Select from "./ReactSelect"
import { listProducts, createGoalm3Product, updateGoalm3Product, clear } from "../../redux/actions/ProductActions"
import { connect } from "react-redux";

const MemberEditorDialog = (props) => {
  let { userRedux, products, handleClose, open, uid } = props;


  const [valor, setValor] = useState(uid != null ? uid?.volumeM3 : "")
  const [days, setDays] = useState([])
  const [options, setOptions] = useState([])
  const [product, setProduct] = useState(uid != null ? uid?.product?.id : null)
  const [message, setMessage] = useState("")


  console.log("uid", uid)

  const changeDay = (day) => {
    var daysArray = [...days]
    var exist = daysArray.indexOf(day)
    if (exist == -1) {
      daysArray.push(day)
      console.log("days", daysArray)
      setDays(daysArray)
    } else {
      daysArray.splice(exist, 1);
      console.log("days", daysArray)
      setDays(daysArray)
    }
  }

  const handleFormSubmit = () => {
    if (product == "") {
      setMessage("Preencha o Produto!")
    } else if (valor == "") {
      setMessage("Preencha o valor do Produto!")
    } else {
      const obj = {
        product: {
          id: product
        },
        volumeM3: valor
      }
      if(uid != null){
        props.dispatch(updateGoalm3Product(userRedux?.token, obj, userRedux?.company))
      }else{
        props.dispatch(createGoalm3Product(userRedux?.token, obj, userRedux?.company))
      }
      handleClose()
    }
  };

  useEffect(() => {
    props.dispatch(listProducts(userRedux?.token, userRedux?.company))
  }, [])

  useEffect(() => {
    if (products != null) {
      console.log("products", products)
      var newOptions = []
      products.map(item => {
        newOptions.push({ label: item?.name, value: item?.id })
      })
      setOptions(newOptions)
    }
  }, [products])

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="p-24" style={{ width: "400px" }}>
        <h4 className="mb-20">Valor Médio De Metro Cúbico Para Venda</h4>
        <ValidatorForm onSubmit={handleFormSubmit}>
          <Grid className="mb-16" container spacing={10}>
            <Grid item sm={12}>
              <Select data={options} disabled={uid == null ? false : true} selected={product} onChange={(value) => { setProduct(value) }} />
              <label className="mb-4">Volume em M3</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="valor"
                onChange={(event) => {
                  setValor(event.target.value)
                }}
                type="number"
                name="valor"
                value={valor}
              />
            </Grid>
          </Grid>
          {
            message != "" && <div style={{ backgroundColor: "rgba(255,0,0,.2)", padding: 5, marginTop: 10 }}><Icon style={{ color: "red", marginRight: 5, marginLeft: 5 }} fontSize="small">close</Icon><span style={{ color: "red", fontWeight: "bold" }}>{message}</span></div>
          }
          <div className="flex flex-space-between flex-middle">
            <Button variant="contained" color="primary" type="submit">
              Salvar
            </Button>
            <Button onClick={() => props.handleClose()}>Cancelar</Button>
          </div>
        </ValidatorForm>
      </div>
    </Dialog >
  );
}

const mapStateToProps = state => ({
  userRedux: state.user,
  products: state.product.products,
});

export default connect(mapStateToProps)(MemberEditorDialog);
