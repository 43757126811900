import {
  LIST_MANAGER_INDICATOR_LOADING,
  LIST_MANAGER_INDICATOR_SUCCESS,
  LIST_MANAGER_INDICATOR_FAILED,
  UPDATE_MANAGER_INDICATOR_LOADING,
  UPDATE_MANAGER_INDICATOR_SUCCESS,
  UPDATE_MANAGER_INDICATOR_FAILED,
  LIST_MANAGER_LOADING,
  LIST_MANAGER_SUCCESS,
  LIST_MANAGER_FAILED,
  UPDATE_MANAGER_LOADING,
  UPDATE_MANAGER_SUCCESS,
  UPDATE_MANAGER_FAILED,
  DELETE_MANAGER_LOADING,
  DELETE_MANAGER_SUCCESS,
  DELETE_MANAGER_FAILED,
  CREATE_MANAGER_LOADING,
  CREATE_MANAGER_SUCCESS,
  CREATE_MANAGER_FAILED,
  CLEAR
} from "../actions/ManagerActions";


const initialState = {success: false,
  loading: false,
  error: null,
  managers: [],
  indicators: [],
};

const ManagerReducer = function(state = initialState, action) {
  switch (action.type) {
    case LIST_MANAGER_INDICATOR_LOADING: {
      console.log("LIST_MANAGER_INDICATOR_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_MANAGER_INDICATOR_SUCCESS: {
      console.log("LIST_MANAGER_INDICATOR_SUCCESS", action?.payload)
      return {
        ...state,
        indicators: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_MANAGER_INDICATOR_FAILED: {
      console.log("LIST_MANAGER_INDICATOR_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case UPDATE_MANAGER_INDICATOR_LOADING: {
      console.log("UPDATE_MANAGER_INDICATOR_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_MANAGER_INDICATOR_SUCCESS: {
      console.log("UPDATE_MANAGER_INDICATOR_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Indicadores vinculados com sucesso!",
        loading: false,
        error: null
      };
    }
    case UPDATE_MANAGER_INDICATOR_FAILED: {
      console.log("UPDATE_MANAGER_INDICATOR_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case LIST_MANAGER_LOADING: {
      console.log("LIST_MANAGER_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_MANAGER_SUCCESS: {
      console.log("LIST_MANAGER_SUCCESS", action?.payload)
      return {
        ...state,
        managers: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_MANAGER_FAILED: {
      console.log("LIST_MANAGER_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case DELETE_MANAGER_LOADING: {
      console.log("DELETE_MANAGER_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_MANAGER_SUCCESS: {
      console.log("DELETE_MANAGER_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Gestor deletado com sucesso!",
        loading: false,
        error: null
      };
    }
    case DELETE_MANAGER_FAILED: {
      console.log("DELETE_MANAGER_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case UPDATE_MANAGER_LOADING: {
      console.log("UPDATE_MANAGER_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_MANAGER_SUCCESS: {
      console.log("UPDATE_MANAGER_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Gestor atualizado com sucesso!",
        loading: false,
        error: null
      };
    }
    case UPDATE_MANAGER_FAILED: {
      console.log("UPDATE_MANAGER_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case CREATE_MANAGER_LOADING: {
      console.log("CREATE_MANAGER_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case CREATE_MANAGER_SUCCESS: {
      console.log("CREATE_MANAGER_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Gestor adicionado com sucesso!",
        loading: false,
        error: null
      };
    }
    case CREATE_MANAGER_FAILED: {
      console.log("CREATE_MANAGER_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    
    case CLEAR: {
      return {
        ...state,
        error: null,
        success: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default ManagerReducer;
