import EgretCalendar from "./EgretCalendar";

const calendarRoutes = [
  {
    path: "/calendar",
    exact: true,
    component: EgretCalendar
  }
];

export default calendarRoutes;
