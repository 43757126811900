import React, { useState, useEffect } from "react";
import {
  Dialog,
  Button,
  Grid,
  Icon,
  LinearProgress
} from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputMask from "react-input-mask";

import { createTicket } from "../../redux/actions/TicketActions"
import { listProducts, listSpecificProduct } from '../../redux/actions/ProductActions'
import { listCounty } from '../../redux/actions/StateActions'
import { listConfirmedOrders } from '../../redux/actions/OrderActions'
import { listBranch, deleteBranch, clear } from "../../redux/actions/BranchActions"
import moment from "moment"
import Swal from 'sweetalert2'

import { connect } from "react-redux";
moment.locale('pt-br');
const MemberEditorDialog = (props) => {

  const { open, handleClose, userRedux, branchs, contracts, productType, products, productRedux, states, countys, drivers, bombers, fleets, applications, uid = null, historicHandle, historic = null, orders } = props;

  const [productsList, setProductsList] = useState([])
  const [productsListLoading, setProductsListLoading] = useState(false)
  const [countyList, setCountyList] = useState([])
  const [countyListLoading, setCountysListLoading] = useState(false)
  const [county, setCounty] = useState([])
  const [nome, setNome] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [razao, setRazao] = useState("")
  const [pessoa, setPessoa] = useState("")
  const [cpf, setCpf] = useState("")
  const [cnpj, setCnpj] = useState("")
  const [contract, setContract] = useState("")
  const [endereco, setEndereco] = useState("")
  const [pedido, setPedido] = useState("")
  const [application, setApplication] = useState("")
  const [total_volume, setTotal_volume] = useState("")
  const [fleet, setFleet] = useState("")
  const [driver, setDriver] = useState("")
  const [agendamento, setAgendamento] = useState("")
  const [productTypeState, setProductType] = useState("")
  const [product, setProduct] = useState("")
  const [productId, setProductId] = useState("")
  const [state, setState] = useState("")
  const [entrega, setEntrega] = useState("")
  const [frota, setFrota] = useState("")
  const [haveBomber, setHaveBomber] = useState(false)
  const [bomber, setBomber] = useState("")
  const [nota, setNota] = useState("")
  const [unitario, setUnitario] = useState("")
  const [metro_cubico, setMetro_cubico] = useState("")
  const [valor_bombeamento, setValor_bombeamento] = useState(0)
  const [valor_total, setValor_total] = useState("")
  const [prazo_pagamento, setPrazo_pagamento] = useState("")
  const [unity, setUnity] = useState("")

  const [message, setMessage] = useState("");

  function TestaCPF(strCPF) {
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF == "00000000000") return false;

    for (var i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (var i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }

  const handleProduct = (id) => {
    setProduct(id)
    if (id != "") {
      setProductsListLoading(true)
      props.dispatch(listProducts(userRedux?.token, userRedux?.company, id))
    }
  }

  const handleCounty = (state) => {
    setState(state)
    if (state != "") {
      setCountysListLoading(true)
      console.log("state", state)
      props.dispatch(listCounty(userRedux?.token, state))
    }
  }

  const handleFormSubmit = () => {

    // if (nome == "") {
    //   setMessage("Preencha o nome do cliente")
    // } else if (pessoa == "") {
    //   setMessage("Preencha o tipo de pessoa")
    // } else if (pessoa == "Fisica" && cpf == "") {
    //   setMessage("Preencha o cpf do cliente")
    // } else if (pessoa == "Juridica" && cnpj == "") {
    //   setMessage("Preencha o cnpj da empresa")
    // } else if (endereco == "") {
    //   setMessage("Preencha o endereço da pessoa")
    // } 
    if (pedido == "") {
      setMessage("Preencha o número do pedido")
    } else if (entrega == "") {
      setMessage("Preencha a data de entrega")
    } else if (application == "") {
      setMessage("Preencha o tipo de aplicação")
    } else if (haveBomber == true && bomber == "") {
      setMessage("Preencha o bombista")
    } else if (haveBomber == true && valor_bombeamento == "") {
      setMessage("Preencha o valor do bombeamento")
    } else if (driver == "") {
      setMessage("Preencha o motorista")
    } else if (frota == "") {
      setMessage("Preencha a frota")
    } else if (unity == "") {
      setMessage("Preencha a unidade")
    } else if (unitario == "") {
      setMessage("Preencha o valor unitário")
    } else if (total_volume == "") {
      setMessage("Preencha o volume total em m3")
    } else if (prazo_pagamento == "") {
      setMessage("Preencha o prazo de pagamento")
    } else if (valor_total == "") {
      setMessage("Preencha o valor total do  ticket")
    } else if (agendamento == "") {
      setMessage("Preencha a data de agendamento")
    } else {
      handleClose()
      setMessage("")
      const obj = {
        order: pedido?.order,
        companyId: userRedux?.company,
        volumeM3Product: parseFloat(total_volume),
        valueTotalProduct: parseFloat(unitario),
        valueTotalPumping: valor_bombeamento > 0 ? parseFloat(valor_bombeamento) : parseFloat(0.0),
        valueTotalTicket: parseFloat(valor_total),
        driverId: parseInt(driver),
        vehicleId: parseInt(frota),
        bomberId: parseInt(bomber),
        applicationId: parseInt(application),
        paymentTerm: prazo_pagamento,
        dateScheduling: agendamento,
        dateDelivery: entrega
      }
      Swal.fire({
        icon: 'warning',
        title: "Deseja realmente adicionar este ticket?",
        showDenyButton: true,
        confirmButtonColor: "#f78726",
        denyButtonColor: "#262d47",
        confirmButtonText: 'Adicionar',
        denyButtonText: 'Não adicionar',
      }).then((result) => {
        if (result.isConfirmed) {
          props.dispatch(createTicket(userRedux?.token, obj))
        }
        historicHandle(obj)

        
      })

    }



    // Swal.fire({
    //   icon: 'success',
    //   title: "Dado registrado com sucesso!",
    //   showConfirmButton: false,
    //   timer: 3000
    // })
  };

  const setValuesToState = pedido => {
    var date = new Date(pedido?.dateDelivery)
    setNome(pedido?.client?.name)
    setState(pedido?.deliveryLocation?.city?.state?.unit)
    setCpf(pedido?.client?.cpf)
    setCounty(pedido?.deliveryLocation?.city?.name)
    setEndereco(`${pedido?.deliveryLocation?.street} ${pedido?.deliveryLocation?.number} ${pedido?.deliveryLocation?.neighborhood}`)
    setProduct(pedido?.product?.name)
    setProductId(pedido?.product?.id)
    setTotal_volume(pedido?.meterage)
    setHaveBomber(pedido?.pumpServiceRequired)
    setAgendamento(moment(pedido?.createdDate).format("DD/MM/YYYY"))
    setEntrega(pedido?.dateDelivery)
    setApplication(pedido?.application?.name)
    setProductType(pedido?.product?.type?.name)
    console.log("entrega", date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + " " + date.getHours() + ":" + date.getUTCMinutes())
  }

  useEffect(() => {
    if (total_volume != null && total_volume != "" && unitario != null && unitario != "") {
      setValor_total((parseInt(total_volume) * parseFloat(unitario)) + (parseFloat(valor_bombeamento) || 0))
    }
  }, [total_volume, unitario, valor_bombeamento])

  useEffect(() => {
    console.log("uid", uid)
  })

  useEffect(() => {
    props.dispatch(listConfirmedOrders(userRedux?.token, userRedux?.company))
    props.dispatch(listBranch(userRedux?.token, userRedux?.company))
  }, [])

  useEffect(() => {
    console.log("orders", orders)
  }, [orders])

  useEffect(() => {
    if (pedido != null && pedido != "") {
      setValuesToState(pedido)
    }
  }, [pedido])



  useEffect(() => {
    console.log("products", products)
    if (products != null) {
      setProductsListLoading(false)
      setProductsList(products)
    }
  }, [products])

  useEffect(() => {
    console.log("historic", historic)
  }, [historic])

  useEffect(() => {
    console.log("states", states)
  }, [states])

  useEffect(() => {
    console.log("countys", countys)
    if (countys != null) {
      setCountysListLoading(false)
      setCountyList(countys)
    }
  }, [countys])

  useEffect(() => {
    if (product != null && product != "") {
      props.dispatch(listSpecificProduct(userRedux?.token, userRedux?.company, productId))
    }
  }, [product])

  useEffect(() => {
    setUnitario(productRedux?.priceM3)
  }, [productRedux])

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="p-24" style={{ width: "400px" }}>
        <h4 className="mb-20">Adicionar Ticket</h4>
        <ValidatorForm onSubmit={() => {

          handleFormSubmit()
        }}>
          <Grid className="mb-16" container spacing={10}>
            <Grid item sm={12}>
              <p style={{ marginTop: 0, fontSize: 12, fontWeight: "bold" }}>Dados do Cliente</p>
              <label className="mb-4">Nome</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="nome"
                type="text"
                disabled="disabled"
                onChange={
                  (event) => { setNome(event.target.value) }}
                name="nome"
                value={nome}
              />

              <label className="mb-4">CPF</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                mask="999.999.999-99"
                label="cpf"
                disabled="disabled"
                // onChange={
                //   (event) => { setCpf(event.target.value) }}
                type="text"
                name="cpf"
                value={cpf}
              />

              <label className="mb-4">Estado</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="Estado"
                disabled="disabled"
                type="text"
                name="state"
                value={state}
              />
              {/* <select className="w-100 mb-16" onChange={event => { handleCounty(event.target.value) }} style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                <option value=""></option>
                {
                  states.length > 0 && states.map(item => (
                    <option value={item?.unit}>{item?.description}</option>
                  ))
                }
              </select> */}
              <label className="mb-4">Cidade</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="Cidade"
                disabled="disabled"
                type="text"
                name="county"
                value={county}
              />
              {/* {
                countyListLoading ? (
                  <LinearProgress />
                )
                  :
                  <>
                    <select disabled={state == ""} onChange={event => setCounty(event.target.value)} className="w-100 mb-16" style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                      <option value=""></option>
                      {
                        countyList.length > 0 && countyList.map(item => (
                          <option value={item?.id}>{item?.name}</option>
                        ))
                      }
                    </select>
                  </>
              } */}
              <label className="mb-4">Endereço</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="endereço"
                disabled="disabled"
                // onChange={
                //   (event) => { setEndereco(event.target.value) }}
                type="text"
                name="endereco"
                value={endereco}
              />
              {/* <label className="mb-4">Número do Contrato</label>
              <select className="w-100 mb-16" onChange={event => { setContract(event.target.value) }} style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                <option value=""></option>
                {
                  contracts.map(item => (
                    <option value={item?.id}>{item?.contractNumber}</option>
                  ))
                }
              </select> */}
              <p style={{ marginTop: 10, fontSize: 12, fontWeight: "bold" }}>Dados da Nota Fiscal</p>
              <label className="mb-4">Número do Pedido</label>
              <select onChange={(event) => {
                setPedido(JSON.parse(event.target.value))
              }} className="w-100 mb-16" style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                <option value=""></option>
                {orders.length > 0 && orders.map(item => {
                  return (
                    <option value={JSON.stringify({ ...item })}>{item?.order} - {item?.client?.name}</option>
                  )
                })
                }
              </select>
              {/* <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="pedido"
                onChange={
                  (event) => { setPedido(event.target.value) }}
                type="text"
                name="pedido"
                value={pedido}
              /> */}
              <label className="mb-4">Data de Solicitação</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                mask="99/99/9999"
                label="agendamento"
                disabled="disabled"
                onChange={
                  (event) => { setAgendamento(event.target.value) }}
                type="text"
                name="agendamento"
                value={agendamento}
              />
              <label className="mb-4">Data Entrega</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                mask="99/99/9999"
                label="entrega"
                onChange={
                  (event) => { setEntrega(event.target.value) }}
                type="text"
                name="entrega"
                value={entrega}
              />
              {/* <label className="mb-4">Número da Nota</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="nota"
                onChange={
                  (event) => { setNota(event.target.value) }}
                type="text"
                name="nota"
                value={nota}
              /> */}
              <label className="mb-4">Produto</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="productTypeState"
                disabled="disabled"
                onChange={
                  (event) => { setProductType(event.target.value) }}
                type="text"
                name="productTypeState"
                value={productTypeState}
              />
              <label className="mb-4">Tipo Produto</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="product"
                disabled="disabled"
                onChange={
                  (event) => { setProduct(event.target.value) }}
                type="text"
                name="product"
                value={product}
              />
              <label className="mb-4">Tipo de Aplicação</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="application"
                disabled="disabled"
                onChange={
                  (event) => { setApplication(event.target.value) }}
                type="text"
                name="application"
                value={application}
              />
              {/* <select className="w-100 mb-16" onChange={event => { setApplication(event.target.value) }} style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                <option value=""></option>
                {
                  applications.length > 0 && applications.map(item => (
                    <option value={item?.id}>{item?.name}</option>
                  ))
                }
              </select> */}
              {/* <select className="w-100 mb-16" onChange={(event) => { handleProduct(event.target.value) }} style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                <option value=""></option>
                {
                  productType.length > 0 && productType.map(item => (
                    <option value={item?.id}>{item?.name}</option>
                  ))
                }
              </select> */}
              {/* <label className="mb-4">Tipo Concreto</label>
              {
                productsListLoading ? (
                  <LinearProgress />
                )
                  :
                  <>
                    <select disabled={product == ""} onChange={event => { setProductType(event.target.value) }} className="w-100 mb-16" style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                      <option value=""></option>

                      {
                        productsList.length > 0 && productsList.map(item => (
                          <option value={item?.id}>{item?.name}</option>
                        ))
                      }

                    </select>
                  </>
              } */}
              <label className="mb-4">Serviço de Bombeamento</label>
              <select disabled="disabled" className="w-100 mb-16" onChange={(event) => {
                console.log(event.target.value)
                setHaveBomber(event.target.value)
              }} style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                <option selected={!haveBomber} value={"false"}>Não</option>
                <option selected={haveBomber} value={"true"}>Sim</option>
              </select>
              {
                haveBomber == true && (
                  <>
                    <label className="mb-4">Bombista</label>
                    <select className="w-100 mb-16" onChange={event => { setBomber(event.target.value) }} style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                      <option value=""></option>
                      {
                        bombers.length > 0 && bombers.map(item => (
                          <option value={item?.user?.id}>{item?.user?.name}</option>
                        ))
                      }
                    </select>
                    <label className="mb-4">Valor Bombeamento</label>
                    <InputMask
                      className="w-100 mb-16"
                      style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                      label="valor_bombeamento"
                      onChange={
                        (event) => { setValor_bombeamento(event.target.value) }}
                      type="numeric"
                      name="valor_bombeamento"
                      value={valor_bombeamento}
                    />
                  </>
                )
              }
              <label className="mb-4">Motorista</label>
              <select className="w-100 mb-16" onChange={event => { setDriver(event.target.value) }} style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                <option value=""></option>
                {
                  drivers.length > 0 && drivers.map(item => (
                    <option value={item?.user?.id}>{item?.user?.name}</option>
                  ))
                }
              </select>
              <label className="mb-4">Frota</label>
              <select className="w-100 mb-16" onChange={event => { setFrota(event.target.value) }} style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                <option value=""></option>
                {
                  fleets.length > 0 && fleets.map(item => (
                    <option value={item?.id}>{item?.plate}</option>
                  ))
                }
              </select>
              <label className="mb-4">Unidade</label>
              <select className="w-100 mb-16" onChange={(event) => { setUnity(event.target.value) }} style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                <option value=""></option>
                {
                  branchs?.map(item =>
                    <option value={item?.id}>{item?.name}</option>

                  )
                }
              </select>
              <p style={{ marginTop: 0, fontSize: 12, fontWeight: "bold" }}>Dados do Pagamento</p>
              <label className="mb-4">Valor Unitário Produto</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="unitario"
                onChange={
                  (event) => { setUnitario(event.target.value) }}
                type="numeric"
                name="unitario"
                value={unitario}
              />
              <label className="mb-4">Volume em Metro Cúbico</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="Total_volume"
                disabled="disabled"
                onChange={
                  (event) => { setTotal_volume(event.target.value) }}
                type="number"
                name="Total_volume"
                value={total_volume}
              />
              <label className="mb-4">Prazo Pagamento</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="prazo_pagamento"
                onChange={
                  (event) => { setPrazo_pagamento(event.target.value) }}
                mask="99/99/9999"
                type="numeric"
                name="prazo_pagamento"
                value={prazo_pagamento}
              />
              <label className="mb-4">Valor Total do Ticket</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="valor_total"
                disabled="disabled"
                onChange={
                  (event) => { setValor_total(event.target.value) }}
                type="numeric"
                name="valor_total"
                value={`R$ ${valor_total}`}
              />

              {
                message != "" && <div style={{ backgroundColor: "rgba(255,0,0,.2)", padding: 5, marginTop: 10 }}><Icon style={{ color: "red", marginRight: 5, marginLeft: 5 }} fontSize="small">close</Icon><span style={{ color: "red", fontWeight: "bold" }}>{message}</span></div>
              }
            </Grid>
          </Grid>

          <div className="flex flex-space-between flex-middle">
            <Button variant="contained" color="primary" type="submit">
              Salvar
            </Button>
            <Button onClick={() => {
              handleClose()
            }}>Cancelar</Button>
          </div>
        </ValidatorForm>
      </div>
    </Dialog >
  );
}


const mapStateToProps = state => ({
  userRedux: state.user,
  products: state.product.products,
  productRedux: state.product.product,
  countys: state.state.countys,
  orders: state.orders.orders,
  branchs: state.branch.branchs,
  loading: state.contract.loading,
  error: state.contract.error,
  success: state.contract.success,
});

export default connect(mapStateToProps)(MemberEditorDialog);
