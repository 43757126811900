import React, { useEffect, useState } from "react";
import {
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Icon,
  TablePagination,
  Button,
  Card
} from "@material-ui/core";
import MemberEditorDialog from "./MemberEditorDialog";
import { Breadcrumb, ConfirmationDialog } from "egret";
import shortid from "shortid";

import { listProductTypes, deleteProductType, clear } from "../../redux/actions/ProductActions"

import { connect } from "react-redux";

import Swal from 'sweetalert2'

const CrudTable = (props) => {

  const { handleDialogClose, user, productTypes, loadingProductTypes, errorProductTypes, successProductTypes } = props

  const [rowsPerPage, setRowsPerPage] = useState(0)
  const [page, setPage] = useState(0)
  const [uid, setUid] = useState(null)
  const [item, setItem] = useState("")
  const [shouldOpenEditorDialog, setShouldOpenEditorDialog] = useState(false)
  const [shouldOpenConfirmationDialog, setShouldOpenConfirmationDialog] = useState(false)

  const setPageFunction = page => {
    setPage(page);
  };

  const setRowsPerPageFunction = event => {
    setRowsPerPage(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPageFunction(newPage);
  };

  const handleDialogCloseFunction = () => {
    setShouldOpenEditorDialog(false)
    setShouldOpenConfirmationDialog(false)
  };

  const handleDeleteUser = item => {
    setShouldOpenConfirmationDialog(true)
    setItem(item)
  };

  const handleConfirmationResponse = () => {
    props.dispatch(deleteProductType(user?.token, item, user?.company))
    handleDialogCloseFunction();
  };

  useEffect(() => {
    props.dispatch(listProductTypes(user?.token, user?.company))
  }, [])

  useEffect(() => {
    if (successProductTypes != false) {
      setShouldOpenConfirmationDialog(false)
      Swal.fire({
        icon: 'success',
        title: successProductTypes,
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(() => {
        props.dispatch(clear())
        props.dispatch(listProductTypes(user?.token, user?.company))
      }, 3500)
    }
  }, [successProductTypes])

  useEffect(() => {
    if (errorProductTypes != null) {
      setShouldOpenConfirmationDialog(false)

      Swal.fire({
        icon: 'error',
        title: errorProductTypes,
        showConfirmButton: false,
        timer: 3000
      })

      setTimeout(() => {
        props.dispatch(clear())
      }, 3500)
    }
  }, [errorProductTypes])


  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: "Adicionar Produto" }]} />
      </div>

      <Button
        className="mb-16"
        variant="contained"
        color="primary"
        onClick={() => {
          setShouldOpenEditorDialog(true)
          setUid(null)
        }}
      >
        <Icon>add-circle</Icon>
        Adicionar registro
      </Button>
      <Card className="w-100 overflow-auto" elevation={6}>
        <Table className="crud-table" style={{ whiteSpace: "pre", minWidth: "750px" }}>
          <TableHead>
            <TableRow>
              <TableCell align="left">Produto</TableCell>
              {/* <TableCell align="left">Descrição</TableCell> */}
              <TableCell align="center">Ação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productTypes.length > 0 &&
              productTypes
                .map((item, index) => (
                  <TableRow key={shortid.generate()}>
                    <TableCell className="px-0" align="left">
                      {item.name}
                    </TableCell>
                    {/* <TableCell className="px-0" align="left">
                      {item.description}
                    </TableCell> */}
                    <TableCell className="px-0 border-none" align="center">
                      <IconButton
                        onClick={() => {
                          setUid(item)
                          setShouldOpenEditorDialog(true)
                        }}
                      >
                        <Icon color="primary">edit</Icon>
                      </IconButton>
                      <IconButton onClick={() => handleDeleteUser(item?.id)}>
                        <Icon color="error">delete</Icon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>

        <TablePagination
          className="px-16"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={productTypes.length}
          rowsPerPage={rowsPerPage}
          page={page}
                labelRowsPerPage="Linhas por Página"
                backIconButtonProps={{
            "aria-label": "Página anterior"
          }}
          nextIconButtonProps={{
            "aria-label": "Próxima página"
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={setRowsPerPageFunction}
        />

        {shouldOpenEditorDialog && (
          <MemberEditorDialog
            handleClose={handleDialogCloseFunction}
            open={shouldOpenEditorDialog}
            uid={uid}
          />
        )}
        {shouldOpenConfirmationDialog && (
          <ConfirmationDialog
            open={shouldOpenConfirmationDialog}
            onConfirmDialogClose={handleDialogCloseFunction}
            onYesClick={handleConfirmationResponse}
            text="Tem certeza que deseja excluir este registro?"
            title="Excluir registro"
            confirmButtonText="Sim"
            declineButtonText="Não"
          />
        )}
      </Card>
    </div>
  );

}

const mapStateToProps = state => ({
  user: state.user,
  productTypes: state.product.productTypes,
  loadingProductTypes: state.product.loading,
  errorProductTypes: state.product.error,
  successProductTypes: state.product.success,
});

export default connect(mapStateToProps)(CrudTable);
