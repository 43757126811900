import AppDragAndDrop from "./AppDragAndDrop";

const dragAndDropRoute = [
  {
    path: "/others/drag-and-drop",
    component: AppDragAndDrop
  }
];

export default dragAndDropRoute;
