import {
  LIST_DISCARD_REPORT_LOADING,
  LIST_DISCARD_REPORT_SUCCESS,
  LIST_DISCARD_REPORT_FAILED,
  LIST_DISCARD_ANALISYS_REPORT_LOADING,
  LIST_DISCARD_ANALISYS_REPORT_SUCCESS,
  LIST_DISCARD_ANALISYS_REPORT_FAILED,
  LIST_DISCARD_RADIUS_REPORT_LOADING,
  LIST_DISCARD_RADIUS_REPORT_SUCCESS,
  LIST_DISCARD_RADIUS_REPORT_FAILED,
  LIST_DISCARD_LOADING,
  LIST_DISCARD_SUCCESS,
  LIST_DISCARD_FAILED,
  UPDATE_DISCARD_LOADING,
  UPDATE_DISCARD_SUCCESS,
  UPDATE_DISCARD_FAILED,
  DELETE_DISCARD_LOADING,
  DELETE_DISCARD_SUCCESS,
  DELETE_DISCARD_FAILED,
  CREATE_DISCARD_LOADING,
  CREATE_DISCARD_SUCCESS,
  CREATE_DISCARD_FAILED,
  CLEAR
} from "../actions/DiscardActions";


const initialState = {success: false,
  loading: false,
  error: null,
  discards: [],
  analisysDiscard: [],
  discardPerRadius: []
};

const DiscardReducer = function(state = initialState, action) {
  switch (action.type) {
    case LIST_DISCARD_RADIUS_REPORT_LOADING: {
      console.log("LIST_DISCARD_RADIUS_REPORT_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_DISCARD_RADIUS_REPORT_SUCCESS: {
      console.log("LIST_DISCARD_RADIUS_REPORT_SUCCESS", action?.payload)
      return {
        ...state,
        discardPerRadius: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_DISCARD_RADIUS_REPORT_FAILED: {
      console.log("LIST_DISCARD_RADIUS_REPORT_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case LIST_DISCARD_ANALISYS_REPORT_LOADING: {
      console.log("LIST_DISCARD_ANALISYS_REPORT_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_DISCARD_ANALISYS_REPORT_SUCCESS: {
      console.log("LIST_DISCARD_ANALISYS_REPORT_SUCCESS", action?.payload)
      return {
        ...state,
        analisysDiscard: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_DISCARD_ANALISYS_REPORT_FAILED: {
      console.log("LIST_DISCARD_ANALISYS_REPORT_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case LIST_DISCARD_REPORT_LOADING: {
      console.log("LIST_DISCARD_REPORT_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_DISCARD_REPORT_SUCCESS: {
      console.log("LIST_DISCARD_REPORT_SUCCESS", action?.payload)
      return {
        ...state,
        discards: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_DISCARD_REPORT_FAILED: {
      console.log("LIST_DISCARD_REPORT_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case LIST_DISCARD_LOADING: {
      console.log("LIST_DISCARD_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_DISCARD_SUCCESS: {
      console.log("LIST_DISCARD_SUCCESS", action?.payload)
      return {
        ...state,
        discards: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_DISCARD_FAILED: {
      console.log("LIST_DISCARD_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case DELETE_DISCARD_LOADING: {
      console.log("DELETE_DISCARD_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_DISCARD_SUCCESS: {
      console.log("DELETE_DISCARD_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Local de descarte deletado com sucesso!",
        loading: false,
        error: null
      };
    }
    case DELETE_DISCARD_FAILED: {
      console.log("DELETE_DISCARD_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case UPDATE_DISCARD_LOADING: {
      console.log("UPDATE_DISCARD_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_DISCARD_SUCCESS: {
      console.log("UPDATE_DISCARD_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Local de descarte atualizado com sucesso!",
        loading: false,
        error: null
      };
    }
    case UPDATE_DISCARD_FAILED: {
      console.log("UPDATE_DISCARD_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case CREATE_DISCARD_LOADING: {
      console.log("CREATE_DISCARD_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case CREATE_DISCARD_SUCCESS: {
      console.log("CREATE_DISCARD_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Local de descarte adicionado com sucesso!",
        loading: false,
        error: null
      };
    }
    case CREATE_DISCARD_FAILED: {
      console.log("CREATE_DISCARD_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    
    case CLEAR: {
      return {
        ...state,
        error: null,
        success: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default DiscardReducer;
