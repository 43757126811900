import React, { useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from "@material-ui/core";
import { isThisWeek } from 'date-fns';
// Create styles
const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingTop: 10,
        paddingHorizontal: 10
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    header: {
        width: '20%',
        fontSize: 7,
        textAlign: "center",
        fontWeight: "800"
    },
    headerTotal: {
        width: '50%',
        fontSize: 12,
        textAlign: "center",
        fontWeight: "800"
    },
    subtitle: {
        width: '20%',
        textAlign: "center",
        fontSize: 6,
    }
});

// Create Document Component
export const MyDocument = (props) => {
    const { data } = props

    useEffect(() => {
        console.log('data', data)
    }, [data])

    return (
        < Document >
            <Page size="A4" style={styles.page}>
                <View style={styles.tableContainer}>
                    <View style={[styles.row, { marginBottom: 10 }]}>
                        <Text style={styles.header}>Data</Text>
                        <Text style={styles.header}>Nota</Text>
                        <Text style={styles.header}>Motorista</Text>
                        <Text style={styles.header}>Placa</Text>
                        <Text style={styles.header}>Status</Text>
                    </View>
                    {
                        data?.map(item => {
                            console.log("item", item)
                            return (
                                <View style={[styles.row, { marginTop: 8 }]}>
                                    <Text style={styles.subtitle}>{item?.Data}</Text>
                                    <Text style={styles.subtitle}>{item?.Nota}</Text>
                                    <Text style={styles.subtitle}>{item?.Motorista}</Text>
                                    <Text style={styles.subtitle}>{item?.Placa}</Text>
                                    <Text style={styles.subtitle}>{item?.Status}</Text>
                                </View>)
                        })
                    }
                </View>

            </Page>
        </Document >
    )
};
