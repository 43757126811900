import {
  LIST_BRANCH_LOADING,
  LIST_BRANCH_SUCCESS,
  LIST_BRANCH_FAILED,
  UPDATE_BRANCH_LOADING,
  UPDATE_BRANCH_SUCCESS,
  UPDATE_BRANCH_FAILED,
  DELETE_BRANCH_LOADING,
  DELETE_BRANCH_SUCCESS,
  DELETE_BRANCH_FAILED,
  CREATE_BRANCH_LOADING,
  CREATE_BRANCH_SUCCESS,
  CREATE_BRANCH_FAILED,
  CLEAR
} from "../actions/BranchActions";


const initialState = {success: false,
  loading: false,
  error: null,
  branchs: []
};

const BranchReducer = function(state = initialState, action) {
  switch (action.type) {
    case LIST_BRANCH_LOADING: {
      console.log("LIST_BRANCH_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_BRANCH_SUCCESS: {
      console.log("LIST_BRANCH_SUCCESS", action?.payload)
      return {
        ...state,
        branchs: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_BRANCH_FAILED: {
      console.log("LIST_BRANCH_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case DELETE_BRANCH_LOADING: {
      console.log("DELETE_BRANCH_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_BRANCH_SUCCESS: {
      console.log("DELETE_BRANCH_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Filial deletada com sucesso!",
        loading: false,
        error: null
      };
    }
    case DELETE_BRANCH_FAILED: {
      console.log("DELETE_BRANCH_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case UPDATE_BRANCH_LOADING: {
      console.log("UPDATE_BRANCH_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_BRANCH_SUCCESS: {
      console.log("UPDATE_BRANCH_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Filial atualizada com sucesso!",
        loading: false,
        error: null
      };
    }
    case UPDATE_BRANCH_FAILED: {
      console.log("UPDATE_BRANCH_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case CREATE_BRANCH_LOADING: {
      console.log("CREATE_BRANCH_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case CREATE_BRANCH_SUCCESS: {
      console.log("CREATE_BRANCH_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Filial adicionada com sucesso!",
        loading: false,
        error: null
      };
    }
    case CREATE_BRANCH_FAILED: {
      console.log("CREATE_BRANCH_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    
    case CLEAR: {
      return {
        ...state,
        error: null,
        success: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default BranchReducer;
