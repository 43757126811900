import React, { Component } from "react";
import {
  Dialog,
  Button,
  Grid,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { getUserById, updateUser, addNewUser } from "./TableService";
import { generateRandomId } from "utils";
import InputMask from "react-input-mask";
import {
  TimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib


class MemberEditorDialog extends Component {
  state = {
    name: "",
    cpf: "",
    email: "",
    phone: "",
    capacity: "",
    days: [],
    comeco: new Date(),
    fim: new Date(),
    isActive: false
  };

  changeDay = (day) => {
    var daysArray = [...this.state.days]
    var exist = daysArray.indexOf(day)
    if (exist == -1) {
      daysArray.push(day)
      console.log("days", daysArray)
      this.setState({ days: daysArray })
    } else {
      daysArray.splice(exist, 1);
      console.log("days", daysArray)
      this.setState({ days: daysArray })
    }
  }

  handleChange = (event, source) => {
    event.persist();
    if (source === "switch") {
      this.setState({ isActive: event.target.checked });
      return;
    }

    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleFormSubmit = () => {
    let { id } = this.state;
    if (id) {
      updateUser({
        ...this.state
      }).then(() => {
        this.props.handleClose();
      });
    } else {
      addNewUser({
        id: generateRandomId(),
        ...this.state
      }).then(() => {
        this.props.handleClose();
      });
    }
  };

  componentWillMount() {
    getUserById(this.props.uid).then(data => this.setState({ ...data.data }));
  }

  render() {
    let {
      name,
      cpf,
      email,
      capacity,
      phone,
      comeco,
      fim,
      isActive
    } = this.state;
    let { open, handleClose } = this.props;

    return (
      <Dialog onClose={handleClose} open={open}>
        <div className="p-24" style={{ width: "400px" }}>
          <h4 className="mb-20">Cadastrar Empresa/Filial</h4>
          <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
            <Grid className="mb-16" container spacing={10}>
              <Grid item sm={12}>
                <label className="mb-4">Capacidade de produção diária</label>
                <InputMask
                  className="w-100 mb-16"
                  style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                  label="Capacity"
                  onChange={this.handleChange}
                  type="numeric"
                  name="capacity"
                  value={capacity}
                />
                <label className="mb-4">Selecione os dias de trabalho</label>
                <div className="w-100" style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", marginBottom: 10 }}>
                  <span onClick={() => { this.changeDay(1) }} style={{ flex: 1, backgroundColor: this.state.days.indexOf(1) == -1 ? "transparent" : "#f78726", border: "1px solid #f78726", borderRadius: 10, cursor: "pointer", fontWeight: "bold", color: this.state.days.indexOf(1) == -1 ? "#f78726" : "white", padding: 5, width: 25, textAlign: "center" }}>
                    SEG
                  </span>
                  <span onClick={() => { this.changeDay(2) }} style={{ flex: 1, backgroundColor: this.state.days.indexOf(2) == -1 ? "transparent" : "#f78726", border: "1px solid #f78726", borderRadius: 10, cursor: "pointer", fontWeight: "bold", color: this.state.days.indexOf(2) == -1 ? "#f78726" : "white", padding: 5, width: 25, textAlign: "center" }}>
                    TER
                  </span>
                  <span onClick={() => { this.changeDay(3) }} style={{ flex: 1, backgroundColor: this.state.days.indexOf(3) == -1 ? "transparent" : "#f78726", border: "1px solid #f78726", borderRadius: 10, cursor: "pointer", fontWeight: "bold", color: this.state.days.indexOf(3) == -1 ? "#f78726" : "white", padding: 5, width: 25, textAlign: "center" }}>
                    QUA
                  </span>
                  <span onClick={() => { this.changeDay(4) }} style={{ flex: 1, backgroundColor: this.state.days.indexOf(4) == -1 ? "transparent" : "#f78726", border: "1px solid #f78726", borderRadius: 10, cursor: "pointer", fontWeight: "bold", color: this.state.days.indexOf(4) == -1 ? "#f78726" : "white", padding: 5, width: 25, textAlign: "center" }}>
                    QUI
                  </span>
                  <span onClick={() => { this.changeDay(5) }} style={{ flex: 1, backgroundColor: this.state.days.indexOf(5) == -1 ? "transparent" : "#f78726", border: "1px solid #f78726", borderRadius: 10, cursor: "pointer", fontWeight: "bold", color: this.state.days.indexOf(5) == -1 ? "#f78726" : "white", padding: 5, width: 25, textAlign: "center" }}>
                    SEX
                  </span>
                  <span onClick={() => { this.changeDay(6) }} style={{ flex: 1, backgroundColor: this.state.days.indexOf(6) == -1 ? "transparent" : "#f78726", border: "1px solid #f78726", borderRadius: 10, cursor: "pointer", fontWeight: "bold", color: this.state.days.indexOf(6) == -1 ? "#f78726" : "white", padding: 5, width: 25, textAlign: "center" }}>
                    SAB
                  </span>
                  <span onClick={() => { this.changeDay(7) }} style={{ flex: 1, backgroundColor: this.state.days.indexOf(7) == -1 ? "transparent" : "#f78726", border: "1px solid #f78726", borderRadius: 10, cursor: "pointer", fontWeight: "bold", color: this.state.days.indexOf(7) == -1 ? "#f78726" : "white", padding: 5, width: 25, textAlign: "center" }}>
                    DOM
                  </span>
                </div>
                <label className="mt-4">Selecione o horário de atendimento</label>
                <div className="w-100" style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", marginBottom: 10 }}>
                  <label className="mb-4 w-100" style={{fontSize: 10}}>De:</label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker value={comeco} onChange={hour => { this.setState({ ...this.state, comeco: hour }) }} />
                  </MuiPickersUtilsProvider>
                 

                </div>
                <div className="w-100" style={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                <label className="mb-4 w-100" style={{fontSize: 10}}>Até:</label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker value={fim} onChange={hour => { this.setState({ ...this.state, fim: hour }) }} />
                  </MuiPickersUtilsProvider>
                </div>


              </Grid>
            </Grid>

            <div className="flex flex-space-between flex-middle">
              <Button variant="contained" color="primary" type="submit">
                Salvar
              </Button>
              <Button onClick={() => this.props.handleClose()}>Cancelar</Button>
            </div>
          </ValidatorForm>
        </div>
      </Dialog>
    );
  }
}

export default MemberEditorDialog;
