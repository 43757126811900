import history from "history.js";
import jwtAuthService from "../../services/jwtAuthService";
import axios from "axios";


export const LIST_CLIENT_LOADING = "LIST_CLIENT_LOADING";
export const LIST_CLIENT_SUCCESS = "LIST_CLIENT_SUCCESS";
export const LIST_CLIENT_FAILED = "LIST_CLIENT_FAILED";
export const LIST_NOT_CLIENT_LOADING = "LIST_NOT_CLIENT_LOADING";
export const LIST_NOT_CLIENT_SUCCESS = "LIST_NOT_CLIENT_SUCCESS";
export const LIST_NOT_CLIENT_FAILED = "LIST_NOT_CLIENT_FAILED";
export const UPDATE_CLIENT_LOADING = "UPDATE_CLIENT_LOADING";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_FAILED = "UPDATE_CLIENT_FAILED";
export const DELETE_CLIENT_LOADING = "DELETE_CLIENT_LOADING";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_FAILED = "DELETE_CLIENT_FAILED";
export const CREATE_CLIENT_LOADING = "CREATE_CLIENT_LOADING";
export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
export const CREATE_CLIENT_FAILED = "CREATE_CLIENT_FAILED";
export const ADD_USER_TO_CLIENT_LOADING = "ADD_USER_TO_CLIENT_LOADING";
export const ADD_USER_TO_CLIENT_SUCCESS = "ADD_USER_TO_CLIENT_SUCCESS";
export const ADD_USER_TO_CLIENT_FAILED = "ADD_USER_TO_CLIENT_FAILED";
export const LIST_CLIENT_UNBOUND_LOADING = "LIST_CLIENT_UNBOUND_LOADING";
export const LIST_CLIENT_UNBOUND_SUCCESS = "LIST_CLIENT_UNBOUND_SUCCESS";
export const LIST_CLIENT_UNBOUND_FAILED = "LIST_CLIENT_UNBOUND_FAILED";
export const LIST_CLIENT_LINKED_LOADING = "LIST_CLIENT_LINKED_LOADING";
export const LIST_CLIENT_LINKED_SUCCESS = "LIST_CLIENT_LINKED_SUCCESS";
export const LIST_CLIENT_LINKED_FAILED = "LIST_CLIENT_LINKED_FAILED";

export const LIST_GESTAO_INDICADORES_LOADING = "LIST_GESTAO_INDICADORES_LOADING";
export const LIST_GESTAO_INDICADORES_SUCCESS = "LIST_GESTAO_INDICADORES_SUCCESS";
export const LIST_GESTAO_INDICADORES_FAILED = "LIST_GESTAO_INDICADORES_FAILED";
export const CREATE_GESTAO_INDICADORES_LOADING = "CREATE_GESTAO_INDICADORES_LOADING";
export const CREATE_GESTAO_INDICADORES_SUCCESS = "CREATE_GESTAO_INDICADORES_SUCCESS";
export const CREATE_GESTAO_INDICADORES_FAILED = "CREATE_GESTAO_INDICADORES_FAILED";
export const UPDATE_GESTAO_INDICADORES_LOADING = "UPDATE_GESTAO_INDICADORES_LOADING";
export const UPDATE_GESTAO_INDICADORES_SUCCESS = "UPDATE_GESTAO_INDICADORES_SUCCESS";
export const UPDATE_GESTAO_INDICADORES_FAILED = "UPDATE_GESTAO_INDICADORES_FAILED";
export const DELETE_GESTAO_INDICADORES_LOADING = "DELETE_GESTAO_INDICADORES_LOADING";
export const DELETE_GESTAO_INDICADORES_SUCCESS = "DELETE_GESTAO_INDICADORES_SUCCESS";
export const DELETE_GESTAO_INDICADORES_FAILED = "DELETE_GESTAO_INDICADORES_FAILED";

export const CLEAR_UNBOUND = "CLEAR_UNBOUND";
export const CLEAR = "CLEAR";

export function listClient(token, company) {
  return dispatch => {
    dispatch({
      type: LIST_CLIENT_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/company/${company}/client`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_CLIENT_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_CLIENT_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function listNotClient(token, company, client) {
  return dispatch => {
    dispatch({
      type: LIST_NOT_CLIENT_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/company/${company}/client/${client}/users-not-client`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_NOT_CLIENT_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_NOT_CLIENT_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function listUnboundClient(token, company, client, cpf) {
  return dispatch => {
    dispatch({
      type: LIST_CLIENT_UNBOUND_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/company/${company}/client/${client}/users-not-client?cpf=${cpf}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_CLIENT_UNBOUND_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_CLIENT_UNBOUND_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function listLinkedClient(token, company) {
  return dispatch => {
    dispatch({
      type: LIST_CLIENT_LINKED_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/company/${company}/client/users-client`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_CLIENT_LINKED_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_CLIENT_LINKED_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function addUserToClient(token, data, company, client) {
  return dispatch => {
    dispatch({
      type: ADD_USER_TO_CLIENT_LOADING,
    });
    axios({
      method: 'post',
      url: `https://api.stonik.com.br/web/company/${company}/client/${client}/add-user`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: ADD_USER_TO_CLIENT_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: ADD_USER_TO_CLIENT_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}
export function createClient(token, data, company) {
  return dispatch => {
    dispatch({
      type: CREATE_CLIENT_LOADING,
    });
    axios({
      method: 'post',
      url: `https://api.stonik.com.br/web/company/${company}/client`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: CREATE_CLIENT_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: CREATE_CLIENT_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}
export function updateClient(token, data, company) {
  return dispatch => {
    dispatch({
      type: UPDATE_CLIENT_LOADING,
    });
    axios({
      method: 'put',
      url: `https://api.stonik.com.br/web/company/${company}/client/${data.id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: UPDATE_CLIENT_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: UPDATE_CLIENT_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function deleteClient(token, id, company) {
  return dispatch => {
    dispatch({
      type: DELETE_CLIENT_LOADING,
    });
    axios({
      method: 'delete',
      url: `https://api.stonik.com.br/web/company/${company}/client/${id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: DELETE_CLIENT_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: DELETE_CLIENT_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

// export function listGestaoIndicadores(token, company) {
//   return dispatch => {
//     dispatch({
//       type: LIST_GESTAO_INDICADORES_LOADING,
//     });
//     axios({
//       method: 'get',
//       url: `https://api.stonik.com.br/app/indicator`,
//       headers: {
//         "Content-Type": "application/json",
//         "Authorization": `Bearer ${token}`
//       }
//     }).then(res => {
//       dispatch({
//         type: LIST_GESTAO_INDICADORES_SUCCESS,
//         payload: res.data
//       });
//       console.log("res", res)
//     }).catch(eer => {
//       console.log("eer", eer?.response?.data?.message)
//       dispatch({
//         type: LIST_GESTAO_INDICADORES_FAILED,
//         payload: eer?.response?.data?.message
//       });
//     });
//   };
// }

// export function createClient(token, data, company) {
//   return dispatch => {
//     dispatch({
//       type: CREATE_CLIENT_LOADING,
//     });
//     axios({
//       method: 'post',
//       url: `https://api.stonik.com.br/web/company/${company}/client`,
//       headers: {
//         "Content-Type": "application/json",
//         "Authorization": `Bearer ${token}`
//       },
//       data
//     }).then(res => {
//       dispatch({
//         type: CREATE_CLIENT_SUCCESS,
//         payload: res.data
//       });
//       console.log("res", res)
//     }).catch(eer => {
//       console.log("eer", eer?.response?.data?.message)
//       dispatch({
//         type: CREATE_CLIENT_FAILED,
//         payload: eer?.response?.data?.message
//       });
//     });
//   };
// }
// export function updateClient(token, data, company) {
//   return dispatch => {
//     dispatch({
//       type: UPDATE_CLIENT_LOADING,
//     });
//     axios({
//       method: 'put',
//       url: `https://api.stonik.com.br/web/company/${company}/client/${data.id}`,
//       headers: {
//         "Content-Type": "application/json",
//         "Authorization": `Bearer ${token}`
//       },
//       data
//     }).then(res => {
//       dispatch({
//         type: UPDATE_CLIENT_SUCCESS,
//         payload: res.data
//       });
//       console.log("res", res)
//     }).catch(eer => {
//       console.log("eer", eer?.response?.data?.message)
//       dispatch({
//         type: UPDATE_CLIENT_FAILED,
//         payload: eer?.response?.data?.message
//       });
//     });
//   };
// }

// export function deleteClient(token, id, company) {
//   return dispatch => {
//     dispatch({
//       type: DELETE_CLIENT_LOADING,
//     });
//     axios({
//       method: 'delete',
//       url: `https://api.stonik.com.br/web/company/${company}/client/${id}`,
//       headers: {
//         "Content-Type": "application/json",
//         "Authorization": `Bearer ${token}`
//       }
//     }).then(res => {
//       dispatch({
//         type: DELETE_CLIENT_SUCCESS,
//         payload: res.data
//       });
//       console.log("res", res)
//     }).catch(eer => {
//       console.log("eer", eer?.response?.data?.message)
//       dispatch({
//         type: DELETE_CLIENT_FAILED,
//         payload: eer?.response?.data?.message
//       });
//     });
//   };
// }

export function clearClientsUnbound() {
  return dispatch => {
    dispatch({
      type: CLEAR_UNBOUND
    });
  };
}

export function clear() {
  return dispatch => {
    dispatch({
      type: CLEAR
    });
  };
}
