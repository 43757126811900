/* eslint-disable */
import React, { Component, useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Icon,
  TablePagination,
  Button,
  Grid,
  Card
} from "@material-ui/core";
import { getAllUser, deleteUser } from "./TableService";
import MemberEditorDialog from "./MemberEditorDialog";
import { Breadcrumb, ConfirmationDialog } from "egret";
import shortid from "shortid";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptLocale from "date-fns/locale/pt-BR";
import { MyDocument } from "./pdf"
import { format } from "date-fns"
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReactExport from "react-export-excel";
import { connect } from "react-redux";
import Swal from 'sweetalert2'
import moment from "moment"

import { listGeneralOperationReports } from "../../redux/actions/ReportsActions"

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const CrudTable = (props) => {

  const { userRedux, reports } = props

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [date, setDate] = useState(new Date())
  const [finalData, setFinalData] = useState(new Date())
  const [uid, setUid] = useState([])
  const [dataList, setDataList] = useState([])
  const [dataSet, setDataSet] = useState([])
  const [shouldOpenEditorDialog, setShouldOpenEditorDialog] = useState(false)
  const [shouldOpenConfirmationDialog, setShouldOpenConfirmationDialog] = useState(false)

  const setPageFunction = page => {
    setPage(page)
  };

  const setRowsPerPageFunction = event => {
    setRowsPerPage(event.target.value)
  };

  const handleChangePage = (event, newPage) => {
    setPageFunction(newPage)
  };

  const handleDialogClose = () => {
    setShouldOpenEditorDialog(false)
    setShouldOpenConfirmationDialog(false)
    updatePageData()
  };

  const handleDeleteUser = user => {
    setShouldOpenConfirmationDialog(true)
  };

  const handleConfirmationResponse = () => {

  };

  const createObjectDataSet = (reports) => {
    var newObj = []
    reports?.map(item => {
      newObj.push({
        Nome: item?.client?.name,
        Tp_Pessoa: item?.client?.type,
        Cpf_cnpj: item?.client?.document,
        Produto: item?.product?.type?.name,
        Data_agendamento: item?.dateScheduling,
        Data_entrega: item?.dateDelivery,
        N_pedido: item?.order,
        N_contrato: item?.contractNumber,
        Numero_frota: item?.vehicle?.fleet,
        Numero_nota: item?.ticketNumber,
        tipo_concreto: item?.product?.name,
        valor_unitario: item?.product?.priceM3,
        volume_m3: item?.volumeM3Product,
        endereco: item?.deliveryLocation?.street,
        estado: item?.deliveryLocation?.city?.state?.unit,
        cidade: item?.deliveryLocation?.city?.name,
        aplicacao: item?.application?.name,
        motorista: item?.driver?.name,
        bombeamento: item?.pumpServiceRequired == true ? "Sim" : "Não",
        bombista: item?.bomber?.name,
        valor_bombeamento: item?.valueTotalPumping,
        prazo_pagamento: item?.paymentTerm,
        unidade: item?.company?.name,
        vl_total: item?.valueTotalTicket,
      })
      return item;
    })
    console.log("newOjt", newObj)
    setDataSet(newObj)
  }

  const filterData = () => {
    if (date == null) {
      Swal.fire({
        icon: 'error',
        title: "Adicione a data inicial",
        showConfirmButton: false,
        timer: 3000
      })
    } else if (finalData == null) {
      Swal.fire({
        icon: 'error',
        title: "Adicione a data final",
        showConfirmButton: false,
        timer: 3000
      })
    } else {
      props.dispatch(listGeneralOperationReports(userRedux?.token, format(date, "dd/MM/yyyy"), format(finalData, "dd/MM/yyyy"), userRedux?.company))
    }
  }

  useEffect(() => {
    updatePageData()
    console.log("finalData", finalData)
    props.dispatch(listGeneralOperationReports(userRedux?.token, format(date, "dd/MM/yyyy"), format(finalData, "dd/MM/yyyy"), userRedux?.company))
  }, [])

  useEffect(() => {
    console.log("reports", reports)
    setDataList(reports)
    createObjectDataSet(reports)
  }, [reports])

  const updatePageData = () => {
  };

  const handleDateChange = date => {
    console.log("date", date)
    setDate(date)
  };

  const handleFinalDateChange = finalDate => {
    setFinalData(finalDate)
  };

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: "Operação Geral" }]} />
      </div>
      <Grid container spacing={5}>
        <Grid item lg={5} md={5} sm={12} xs={12}>
          <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="mb-16 w-100"
              margin="none"
              id="mui-pickers-date"
              label="Data Início"
              format="dd/MM/yyyy"
              inputVariant="standard"
              type="text"
              autoOk={true}
              value={date}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item lg={5} md={5} sm={12} xs={12}>
          <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="mb-16 w-100"
              margin="none"
              id="mui-pickers-date"
              format="dd/MM/yyyy"
              label="Data Fim"
              inputVariant="standard"
              type="text"
              autoOk={true}
              value={finalData}
              onChange={handleFinalDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item lg={2} md={2} sm={12} xs={12}>
          <Button color="primary" variant="contained" type="button" onClick={() => { filterData() }} style={{ marginTop: 10 }}>
            <Icon>filter_list</Icon>
            <span className="pl-8 capitalize">Filtrar</span>
          </Button>
        </Grid>
      </Grid>
      {
        dataList?.length > 0 && <Grid container spacing={5}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ExcelFile filename="relatorio-operacao-geral" element={<Button style={{ backgroundColor: "#f78726", color: "white", marginBottom: 10, marginRight: 5 }} aria-label="Add">Exportar Excel</Button>}>
              <ExcelSheet data={dataSet} name="Employees">
                <ExcelColumn label="Nome" value="Nome" />
                <ExcelColumn label="Tipo pessoa" value="Tp_Pessoa" />
                <ExcelColumn label="Cpf/Cnpj" value="Cpf_cnpj" />
                <ExcelColumn label="Produto" value="Produto" />
                <ExcelColumn label="Data agendamento" value="Data_agendamento" />
                <ExcelColumn label="Data entrega" value="Data_entrega" />
                <ExcelColumn label="Numero pedido" value="N_pedido" />
                <ExcelColumn label="Numero contrato" value="N_contrato" />
                <ExcelColumn label="Numero frota" value="Numero_frota" />
                <ExcelColumn label="Numero nota" value="Numero_nota" />
                <ExcelColumn label="Tipo concreto" value="tipo_concreto" />
                <ExcelColumn label="Valor unitario" value="valor_unitario" />
                <ExcelColumn label="Volume em m3" value="volume_m3" />
                <ExcelColumn label="Endereço" value="endereco" />
                <ExcelColumn label="Estado" value="estado" />
                <ExcelColumn label="Cidade" value="cidade" />
                <ExcelColumn label="Aplicacao" value="aplicacao" />
                <ExcelColumn label="Motorista" value="motorista" />
                <ExcelColumn label="Bombeamento" value="bombeamento" />
                <ExcelColumn label="Bombista" value="bombista" />
                <ExcelColumn label="Valor bombeamento" value="valor_bombeamento" />
                <ExcelColumn label="Prazo pagamento" value="prazo_pagamento" />
                <ExcelColumn label="Unidade" value="unidade" />
                <ExcelColumn label="Valor Total" value="vl_total" />
              </ExcelSheet>
            </ExcelFile>
            <PDFDownloadLink document={<MyDocument data={dataSet} />} fileName="relatório-operacao-geral.pdf">
              <Button style={{ backgroundColor: "transparent", border: "1.5px solid #f78726", color: "#f78726", marginBottom: 10 }} aria-label="Add">Exportar Pdf</Button>
            </PDFDownloadLink>
          </Grid>
        </Grid>
      }



      <Card className="overflow-auto" style={{width: dataList?.length > 0 ? "150%" : "100%"}} elevation={6}>
        <h6 style={{ textAlign: "center", marginRight: 10, fontSize: 10, marginTop: 10 }}>Data de emissão: {moment().format("D/MM/Y")}</h6>
        <h6 style={{ textAlign: "center", marginRight: 10, fontSize: 10, }}>Usuário: {userRedux?.displayName}</h6>
        <h4 style={{ textAlign: "center", marginRight: 10 }}>Relatório de Operação Geral</h4>
        {
          dataList?.length > 0 ?
            <Table className="crud-table" style={{ whiteSpace: "pre", minWidth: "750px" }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontSize: 9 }}>Nome</TableCell>
                  <TableCell style={{ fontSize: 9 }}>Tp. pessoa</TableCell>
                  <TableCell style={{ fontSize: 9 }}>CPF/CNPJ</TableCell>
                  <TableCell style={{ fontSize: 9 }}>Produto</TableCell>
                  <TableCell style={{ fontSize: 9 }}>Dt agendamento</TableCell>
                  <TableCell style={{ fontSize: 9 }}>Dt entrega</TableCell>
                  <TableCell style={{ fontSize: 9 }}>Nº pedido</TableCell>
                  <TableCell style={{ fontSize: 9 }}>Nº contrato</TableCell>
                  <TableCell style={{ fontSize: 9 }}>Nº frota</TableCell>
                  <TableCell style={{ fontSize: 9 }}>Nº nota</TableCell>
                  <TableCell style={{ fontSize: 9 }}>Tipo Concreto</TableCell>
                  <TableCell style={{ fontSize: 9 }}>Vl. unitario</TableCell>
                  <TableCell style={{ fontSize: 9 }}>Volume em M3</TableCell>
                  <TableCell style={{ fontSize: 9 }}>Endereço</TableCell>
                  <TableCell style={{ fontSize: 9 }}>Estado</TableCell>
                  <TableCell style={{ fontSize: 9 }}>Cidade</TableCell>
                  <TableCell style={{ fontSize: 9 }}>Aplicação</TableCell>
                  <TableCell style={{ fontSize: 9 }}>Motorista</TableCell>
                  <TableCell style={{ fontSize: 9 }}>Bombeamento</TableCell>
                  <TableCell style={{ fontSize: 9 }}>Bombista</TableCell>
                  <TableCell style={{ fontSize: 9 }}>Vr Bomb.</TableCell>
                  <TableCell style={{ fontSize: 9 }}>Prazo pag.</TableCell>
                  <TableCell style={{ fontSize: 9 }}>Unidade</TableCell>
                  <TableCell style={{ fontSize: 9 }}>Vl. Total</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {dataList
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((item, index) => (
                    <TableRow key={shortid.generate()} style={{ paddingVertical: 5 }}>
                      <TableCell className="px-0" align="left" style={{ fontSize: 9 }}>{item?.client?.name}</TableCell>
                      <TableCell className="px-0" align="left" style={{ fontSize: 9 }}>{item?.client?.type}</TableCell>
                      <TableCell className="px-0" align="left" style={{ fontSize: 9 }}>{item?.client?.document}</TableCell>
                      <TableCell className="px-0" align="left" style={{ fontSize: 9 }}>{item?.product?.type?.name}</TableCell>
                      <TableCell className="px-0" align="left" style={{ fontSize: 9 }}>{item?.dateScheduling}</TableCell>
                      <TableCell className="px-0" align="left" style={{ fontSize: 9 }}>{item?.dateDelivery}</TableCell>
                      <TableCell className="px-0" align="left" style={{ fontSize: 9 }}>{item?.order}</TableCell>
                      <TableCell className="px-0" align="left" style={{ fontSize: 9 }}>{item?.contractNumber}</TableCell>
                      <TableCell className="px-0" align="left" style={{ fontSize: 9 }}>{item?.vehicle?.fleet}</TableCell>
                      <TableCell className="px-0" align="left" style={{ fontSize: 9 }}>{item?.ticketNumber}</TableCell>
                      <TableCell className="px-0" align="left" style={{ fontSize: 9 }}>{item?.product?.name}</TableCell>
                      <TableCell className="px-0" align="left" style={{ fontSize: 9 }}>{item?.product?.priceM3}</TableCell>
                      <TableCell className="px-0" align="left" style={{ fontSize: 9 }}>{item?.volumeM3Product}</TableCell>
                      <TableCell className="px-0" align="left" style={{ fontSize: 9 }}>{item?.deliveryLocation?.street}</TableCell>
                      <TableCell className="px-0" align="left" style={{ fontSize: 9 }}>{item?.deliveryLocation?.city?.state?.unit}</TableCell>
                      <TableCell className="px-0" align="left" style={{ fontSize: 9 }}>{item?.deliveryLocation?.city?.name}</TableCell>
                      <TableCell className="px-0" align="left" style={{ fontSize: 9 }}>{item?.application?.name}</TableCell>
                      <TableCell className="px-0" align="left" style={{ fontSize: 9 }}>{item?.driver?.name}</TableCell>
                      <TableCell className="px-0" align="left" style={{ fontSize: 9 }}>{item?.pumpServiceRequired == true ? "Sim" : "Não"}</TableCell>
                      <TableCell className="px-0" align="left" style={{ fontSize: 9 }}>{item?.bomber?.name}</TableCell>
                      <TableCell className="px-0" align="left" style={{ fontSize: 9 }}>{item?.valueTotalPumping}</TableCell>
                      <TableCell className="px-0" align="left" style={{ fontSize: 9 }}>{item?.paymentTerm}</TableCell>
                      <TableCell className="px-0" align="left" style={{ fontSize: 9 }}>{item?.company?.name}</TableCell>
                      <TableCell className="px-0" align="left" style={{ fontSize: 9 }}>{item?.valueTotalTicket}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            :
            <h5 style={{ textAlign: "center", marginTop: 20 }}>Sem dados para esta filtragem.</h5>
        }
        <TablePagination
          className="px-16"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={dataList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Linhas por Página"
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={setRowsPerPageFunction}
        />

        {shouldOpenEditorDialog && (
          <MemberEditorDialog
            handleClose={handleDialogClose}
            open={shouldOpenEditorDialog}
            uid={uid}
          />
        )}
        {shouldOpenConfirmationDialog && (
          <ConfirmationDialog
            open={shouldOpenConfirmationDialog}
            onConfirmDialogClose={handleDialogClose}
            onYesClick={handleConfirmationResponse}
            text="Tem certeza que deseja excluir este registro?"
            title="Excluir registro"
            confirmButtonText="Sim"
            declineButtonText="Não"
          />
        )}
      </Card>
    </div>
  );
}

const mapStateToProps = state => ({
  userRedux: state.user,
  reports: state.reports.generalOperation,
  loading: state.reports.loading,
  error: state.reports.error,
  success: state.reports.success,
});

export default connect(mapStateToProps)(CrudTable);