import React, { Component, useState, useEffect } from "react";
import {
  Dialog,
  Button,
  Grid,
  FormControlLabel,
  Switch,
  Icon
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { getUserById, updateUser, addNewUser } from "./TableService";
import { generateRandomId } from "utils";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import Input from "./../../components/Input"

import { createClient, updateClient } from "../../redux/actions/ClientActions"

import Swal from 'sweetalert2'

const MemberEditorDialog = (props) => {

  const { handleClose, open, uid, not_clients, user } = props

  const [nome, setNome] = useState(uid == null ? "" : uid?.name);
  const [email, setEmail] = useState(uid == null ? "" : uid?.email);
  const [password, setPassword] = useState("");
  const [contact, setContact] = useState(uid == null ? "" : uid?.contact1);
  const [razaoSocial, setRazaoSocial] = useState(uid == null ? "" : uid?.name);

  const [pessoa, setPessoa] = useState(uid == null ? "" : uid?.type)
  const [cpf, setCpf] = useState(uid == null ? "" : uid?.document)
  const [cnpj, setCnpj] = useState(uid == null ? "" : uid?.document)

  const [message, setMessage] = useState("");

  const handleFormSubmit = () => {
    if (pessoa == "") {
      setMessage("Selecione o tipo de usuário")
    } else {
      if (pessoa == "Fisica") {
        if (nome == "") {
          setMessage("Preencha o nome do cliente")
        } else if (cpf == "") {
          setMessage("Preencha o cpf do cliente")
        } else if (email == "") {
          setMessage("Preencha o email do cliente")
        } else if (contact == "") {
          setMessage("Preencha o telefone do cliente")
        } else {
          handleClose()
          Swal.fire({
            icon: 'warning',
            title: "Deseja realmente adicionar este Cliente?",
            showDenyButton: true,
            confirmButtonColor: "#f78726",
            denyButtonColor: "#262d47",
            confirmButtonText: 'Adicionar',
            denyButtonText: `Não adicionar`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              if (uid == null) {
                props.dispatch(createClient(user?.token, { type: "PF", document: cpf, name: nome, email, contact1: contact }, user?.company))
              } else {
                props.dispatch(updateClient(user?.token, { id: uid?.id, type: "PF", document: cpf, name: razaoSocial, email, contact1: contact }, user?.company))
              }
            }
          })
        }
      } else {
        if (razaoSocial == "") {
          setMessage("Preencha a razão social do cliente")
        } else if (cnpj == "") {
          setMessage("Preencha o cnpj do cliente")
        } else if (email == "") {
          setMessage("Preencha o email do cliente")
        } else if (contact == "") {
          setMessage("Preencha o telefone do cliente")
        } else {
          handleClose()
          Swal.fire({
            icon: 'warning',
            title: "Deseja realmente adicionar este Cliente?",
            showDenyButton: true,
            confirmButtonColor: "#f78726",
            denyButtonColor: "#262d47",
            confirmButtonText: 'Adicionar',
            denyButtonText: `Não adicionar`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              if (uid == null) {
                props.dispatch(createClient(user?.token, { type: "PJ", document: cnpj, name: nome, email, contact1: contact }, user?.company))
              } else {
                props.dispatch(updateClient(user?.token, { id: uid?.id, type: "PJ", document: cnpj, name: razaoSocial, email, contact1: contact }, user?.company))
              }
            }
          })
        }
      }
    }


  }

  useEffect(() => {
    console.log("uid", uid)
  }, [uid])

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="p-24" style={{ width: "400px" }}>
        <h4 className="mb-20">Adicionar Cliente</h4>
        <ValidatorForm onSubmit={() => {
          handleFormSubmit()
        }}>
          <Grid className="mb-16" container spacing={10}>
            <Grid item sm={12}>
              {
                uid == null ?
                  <>
                    <label className="mb-4">Tipo de pessoa</label>
                    <select onChange={(event) => {
                      setPessoa(event.target.value)
                      setCpf("")
                      setCnpj("")
                      setEmail("")
                      setContact("")
                    }} className="w-100 mb-16" style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                      <option value=""></option>
                      <option value="Fisica">Pessoa Física</option>
                      <option value="Juridica">Pessoa Jurídica</option>
                    </select>
                    {
                      pessoa == "Fisica" ?
                        <>
                          <label className="mb-4">Nome</label>
                          <InputMask
                            className="w-100 mb-16"
                            style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                            label="nome"
                            onChange={
                              (event) => { setNome(event.target.value) }}
                            type="text"
                            name="nome"
                            value={nome}
                          />
                          <label className="mb-4">CPF</label>
                          <InputMask
                            className="w-100 mb-16"
                            style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                            mask="999.999.999-99"
                            label="cpf"
                            onChange={
                              (event) => { setCpf(event.target.value) }}
                            type="text"
                            name="cpf"
                            value={cpf}
                          />
                          <label className="mb-4">Email</label>
                          <InputMask
                            className="w-100 mb-16"
                            style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                            label="email"
                            onChange={
                              (event) => { setEmail(event.target.value) }}
                            type="text"
                            name="email"
                            value={email}
                          />
                          <label className="mb-4">Contato</label>
                          <InputMask
                            className="w-100 mb-16"
                            style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                            mask="(99) 99999-9999"
                            label="contato"
                            onChange={
                              (event) => { setContact(event.target.value) }}
                            type="text"
                            name="contato"
                            value={contact}
                          />
                        </>
                        : pessoa == "Juridica" ?
                          <>
                            <label className="mb-4">Razão social</label>
                            <InputMask
                              className="w-100 mb-16"
                              style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                              label="razaoSocial"
                              onChange={
                                (event) => { setRazaoSocial(event.target.value) }}
                              type="text"
                              name="razaoSocial"
                              value={razaoSocial}
                            />
                            <label className="mb-4">Cnpj</label>
                            <InputMask
                              className="w-100 mb-16"
                              style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                              mask="99.999.999/9999-99"
                              label="Cnpj"
                              onChange={
                                (event) => { setCnpj(event.target.value) }}
                              type="text"
                              name="cnpj"
                              value={cnpj}
                            />
                            <label className="mb-4">Email</label>
                            <InputMask
                              className="w-100 mb-16"
                              style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                              label="email"
                              onChange={
                                (event) => { setEmail(event.target.value) }}
                              type="text"
                              name="email"
                              value={email}
                            />
                            <label className="mb-4">Contato</label>
                            <InputMask
                              className="w-100 mb-16"
                              style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                              mask="(99) 99999-9999"
                              label="contato"
                              onChange={
                                (event) => { setContact(event.target.value) }}
                              type="text"
                              name="contato"
                              value={contact}
                            />

                          </>
                          : null
                    }
                  </>
                  :
                  <>
                    {
                      uid?.type == "PF" ?
                        <>
                          <label className="mb-4">Nome</label>
                          <InputMask
                            className="w-100 mb-16"
                            style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                            label="nome"
                            onChange={
                              (event) => { setNome(event.target.value) }}
                            type="text"
                            name="nome"
                            value={nome}
                          />
                          <label className="mb-4">CPF</label>
                          <InputMask
                            className="w-100 mb-16"
                            style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                            mask="999.999.999-99"
                            label="cpf"
                            onChange={
                              (event) => { setCpf(event.target.value) }}
                            disabled="disabled"
                            type="text"
                            name="cpf"
                            value={cpf}
                          />
                        </>
                        :
                        <>
                          <label className="mb-4">Razão social</label>
                          <InputMask
                            className="w-100 mb-16"
                            style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                            label="razaoSocial"
                            onChange={
                              (event) => { setRazaoSocial(event.target.value) }}
                            type="text"
                            name="razaoSocial"
                            value={razaoSocial}
                          />
                          <label className="mb-4">Cnpj</label>
                          <InputMask
                            className="w-100 mb-16"
                            style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                            mask="99.999.999/9999-99"
                            label="Cnpj"
                            onChange={
                              (event) => { setCnpj(event.target.value) }}
                            disabled="disabled"
                            type="text"
                            name="cnpj"
                            value={cnpj}
                          />
                        </>
                    }
                    <label className="mb-4">Email</label>
                    <InputMask
                      className="w-100 mb-16"
                      style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                      label="email"
                      onChange={
                        (event) => { setEmail(event.target.value) }}
                      type="text"
                      name="email"
                      value={email}
                    />
                    <label className="mb-4">Contato</label>
                    <InputMask
                      className="w-100 mb-16"
                      style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                      mask="(99) 99999-9999"
                      label="contato"
                      onChange={
                        (event) => { setContact(event.target.value) }}
                      type="text"
                      name="contato"
                      value={contact}
                    />
                  </>
              }





              {
                message != "" && <div style={{ backgroundColor: "rgba(255,0,0,.2)", padding: 5, marginTop: 10 }}><Icon style={{ color: "red", marginRight: 5, marginLeft: 5 }} fontSize="small">close</Icon><span style={{ color: "red", fontWeight: "bold" }}>{message}</span></div>
              }
            </Grid>
          </Grid>

          <div className="flex flex-space-between flex-middle">
            <Button variant="contained" color="primary" type="submit">
              Salvar
            </Button>
            <Button onClick={() => {
              handleClose()
            }}>Cancelar</Button>
          </div>
        </ValidatorForm>
      </div>
    </Dialog>
  );
}

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(MemberEditorDialog);