import AppChat from "./AppChat";

const chatRoutes = [
  {
    path: "/chat",
    component: AppChat
  }
];

export default chatRoutes;
