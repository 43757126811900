import UserTable from "./DiscardRadiusReports";

const DiscardRadiusReports = [
  {
    path: "/descarte-por-raio",
    exact: true,
    component: UserTable
  }
];

export default DiscardRadiusReports;
