import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_LOADING,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  SEND_CODE_LOADING,
  SEND_CODE_SUCCESS,
  SEND_CODE_FAILED,
  CLEAR
} from "../actions/LoginActions";

const initialState = {
  success: false,
  loading: false,
  error: null
};

const LoginReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_LOADING: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false,
        error: null
      };
    }
    case RESET_PASSWORD_LOADING: {
      console.log("RESET_PASSWORD_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case RESET_PASSWORD_SUCCESS: {
      console.log("RESET_PASSWORD_SUCCESS")
      return {
        ...state,
        success: "Foi enviado um email com o código para você",
        loading: false,
        error: null
      };
    }
    case RESET_PASSWORD_FAILED: {
      console.log("RESET_PASSWORD_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case SEND_CODE_LOADING: {
      console.log("SEND_CODE_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case SEND_CODE_SUCCESS: {
      console.log("SEND_CODE_SUCCESS")
      return {
        ...state,
        success: "Senha alterada com sucesso!",
        loading: false,
        error: null
      };
    }
    case SEND_CODE_FAILED: {
      console.log("SEND_CODE_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case LOGIN_ERROR: {
      return {
        success: false,
        loading: false,
        error: action?.payload?.response?.data?.message
      };
    }
    case CLEAR: {
      return {
        ...state,
        error: null,
        success: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default LoginReducer;
