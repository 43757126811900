import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import UserReducer from "./UserReducer";
import LayoutReducer from "./LayoutReducer";
import ScrumBoardReducer from "./ScrumBoardReducer";
import NotificationReducer from "./NotificationReducer";
import EcommerceReducer from "./EcommerceReducer";
import ContractReducer from "./ContractReducer";
import ClientReducer from "./ClientReducer";
import ProductReducer from "./ProductReducer";
import FleetReducer from "./FleetReducer";
import DriverReducer from "./DriverReducer";
import ManagerReducer from "./ManagerReducer";
import ModuleReducer from "./ModuleReducer";
import OperatorReducer from "./OperatorReducer";
import BomberReducer from "./BomberReducer";
import SalesmanReducer from "./SalesmanReducer";
import TypistReducer from "./TypistReducer";
import TicketReducer from "./TicketReducer";
import StateReducer from "./StateReducer";
import BranchReducer from "./BranchReducer";
import ApplicationReducer from "./ApplicationReducer";
import DiscardReducer from "./DiscardReducer";
import DischargeReducer from "./DischargeReducer";
import ExceptionReducer from "./ExceptionReducer";
import DashboardReducer from "./DashboardReducer";
import CompanyReducer from "./CompanyReducer";
import ReportsReducer from "./ReportsReducer";
import OrderReducer from "./OrderReducer";

const RootReducer = combineReducers({
  login: LoginReducer,
  user: UserReducer,
  layout: LayoutReducer,
  scrumboard: ScrumBoardReducer,
  notification: NotificationReducer,
  ecommerce: EcommerceReducer,
  contract: ContractReducer,
  client: ClientReducer,
  product: ProductReducer,
  fleet: FleetReducer,
  driver: DriverReducer,
  manager: ManagerReducer,
  module: ModuleReducer,
  operator: OperatorReducer,
  bomber: BomberReducer,
  salesman: SalesmanReducer,
  typist: TypistReducer,
  ticket: TicketReducer,
  state: StateReducer,
  branch: BranchReducer,
  application: ApplicationReducer,
  discard: DiscardReducer,
  discharge: DischargeReducer,
  exception: ExceptionReducer,
  dashboard: DashboardReducer,
  company: CompanyReducer,
  reports: ReportsReducer,
  orders: OrderReducer
});

export default RootReducer;
