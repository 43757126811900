import UserTable from "./RequestTracking";

const WashingControl = [
  {
    path: "/controle-lavagem",
    exact: true,
    component: UserTable
  }
];

export default WashingControl;
