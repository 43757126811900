import BasicForm from "./BasicForm";
import EditorForm from "./EditorForm";
import WizardForm from "./WizardForm";
import UploadForm  from "./UploadForm";

const formsRoutes = [
  {
    path: "/forms/basic",
    component: BasicForm
  },
  {
    path: "/forms/editor",
    component: EditorForm
  },
  {
    path: "/forms/upload",
    component: UploadForm
  },
  {
    path: "/forms/wizard",
    component: WizardForm
  }
];

export default formsRoutes;
