/* eslint-disable */
import React, { Component, Fragment, useState, useEffect } from "react";
import {
  Grid,
  Card,
  Icon,
  IconButton,
  Button,
  Checkbox,
  Fab,
  Avatar,
  Hidden
} from "@material-ui/core";
import { Link } from "react-router-dom";

import { Breadcrumb, SimpleCard, EgretProgressBar } from "egret";
import DashboardWelcomeCard from "../cards/DashboardWelcomeCard";
import AreaChart from "../charts/echarts/AreaChart";
import SimpleLineChart from "./SimpleLineChart";
import { connect } from "react-redux";

import SimpleBarChart from "../charts/recharts/SimpleBarChart";

import { format } from "date-fns";
import ModifiedAreaChart from "./ModifiedAreaChart";
import { withStyles } from "@material-ui/styles";

import DoughnutChart from "../charts/echarts/Doughnut";
import { listManager, listManagerIndicator, updateManagerIndicator } from "../../redux/actions/ManagerActions"

import moment from "moment"

import { listDiaryProduction, listDiaryBilling, listClientsServed } from "../../redux/actions/DashboardActions"

function formatarMoeda(valor) {

  valor = valor + '';
  valor = parseInt(valor.replace(/[\D]+/g, ''));
  valor = valor + '';
  valor = valor.replace(/([0-9]{2})$/g, ",$1");

  if (valor.length > 6) {
    valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
  }

  return `R$ ${valor}`;
}

const Dashboard1 = props => {

  const [recenBuyerList, setRecenBuyerList] = useState([
    {
      imgUrl: "/assets/images/face-1.jpg",
      name: "john doe",
      date: "18 january, 2019"
    },
    {
      imgUrl: "/assets/images/face-2.jpg",
      name: "kessy bryan",
      date: "10 january, 2019"
    },
    {
      imgUrl: "/assets/images/face-3.jpg",
      name: "james cassegne",
      date: "8 january, 2019"
    },
    {
      imgUrl: "/assets/images/face-4.jpg",
      name: "lucy brown",
      date: "1 january, 2019"
    }
  ])

  const [diaryProduction, setDiaryProduction] = useState([])
  const [diaryBilling, setDiaryBilling] = useState([{
    value: 0,
    name: "Ontem \n" + formatarMoeda(0)
  }])
  const [clientsServed, setClientsServed] = useState([{
    value: 0,
    name: "0"
  }])

  let { dispatch, theme, diary_productions, diary_billing, clients_serveds, history, userRedux, indicators } = props;

  useEffect(() => {
    dispatch(listDiaryProduction(userRedux.token, userRedux.company));
    dispatch(listDiaryBilling(userRedux.token, userRedux.company));
    dispatch(listClientsServed(userRedux.token, userRedux.company));
    props.dispatch(listManagerIndicator(userRedux?.token, userRedux?.company, userRedux?.userId))

  }, [])

  useEffect(() => {
    console.log("userRedux", userRedux?.role)
    if (userRedux?.role == "VENDEDOR") {
      history.push("lista_pedidos")
    }
  }, [userRedux])

  useEffect(() => {
    if (diary_productions != null) {
      // indicators?.find(item => (item?.indicator?.value == "VOLUME_PRODUCAO_DIARIA" && item?.permission == true)) != undefined && setDiaryProduction(diary_productions)
      setDiaryProduction(diary_productions)
    }
  }, [diary_productions])

  useEffect(() => {
    if (diary_billing != null) {
      var newArray = []
      console.log("diary_billing", diary_billing)

      console.log("newArray 1", newArray)
      var find = indicators?.find(item => (item?.indicator?.value == "FATURAMENTO_BRUTO_DIA" && item?.permission == true))
      console.log("find", find)
      setDiaryBilling([{
        value: diary_billing?.valueTotal,
        name: "Ontem \n" + formatarMoeda(diary_billing?.valueTotal)
      }])
      // :
      // setDiaryBilling([{
      //   value: diary_billing?.valueTotal,
      //   name: "Sem permissão \n" + formatarMoeda(diary_billing?.valueTotal)
      // }])
    }
  }, [diary_billing])

  useEffect(() => {
    if (clients_serveds != null) {
      var newArray = []
      if (clients_serveds?.length > 0) {
        let slice = clients_serveds?.data?.slice(0, 1);
        indicators?.find(item => (item?.indicator?.value == "CLIENTES_ATENDIDOS_DIA" && item?.permission == true)) != undefined ?
          newArray.push({
            value: slice[0].qntClients,
            name: slice[0].qntClients
          })
          :
          newArray.push({
            value: 0,
            name: 0
          })
        console.log("newArray 2", newArray)
        setClientsServed(newArray)
      }
      // var values = []
      // var names = []
      // var item = clients_serveds.data[0]
      // values.push(
      //   0,
      // )
      // names.push(
      //   "",
      // )
      // values.push(
      //   item.qntClients,
      // )
      // names.push(
      //   `Ontem`,
      // )
      // values.push(
      //   0,
      // )
      // names.push(
      //   "",
      // )
      // console.log("clientserverd", {
      //   values,
      //   names
      // })

      // setClientsServed({
      //   values,
      //   names
      // })
    }
  }, [clients_serveds])

  return (
    <>

      <div className="analytics m-sm-30">
        <div className="mb-sm-30">
          <Breadcrumb
            routeSegments={[
              { name: "Painel", path: "/painel" },
            ]}
          />
        </div>
        <Grid container spacing={3}>

          <Grid sm={12} md={12} lg={12}>
            <div className="pt-8">
              <SimpleCard title="Gráfico de volume de produção diária">
                <SimpleLineChart data={diaryProduction} />
                {/* <SimpleBarChart /> */}
              </SimpleCard>


            </div>




          </Grid>


          <Grid sm={12} md={12} lg={12}>
            <div className="pt-8">
              <SimpleCard title={`Faturamento Bruto do dia: ${moment().subtract("1", "days").format("DD/MM/YYYY")}`}>
                <DoughnutChart
                  data={diaryBilling}
                  height="300px"
                  color={[
                    "#f78726",
                    "lightgray"
                  ]}
                />
              </SimpleCard>
            </div>
          </Grid>

          <Grid sm={12} md={12} lg={12}>
            <div className="pt-8">
              <SimpleCard title={`Gráfico de clientes atendidos do dia: ${moment().subtract("1", "days").format("DD/MM/YYYY")}`}>
                <DoughnutChart
                  data={clientsServed}
                  height="300px"
                  color={[
                    "#f78726",
                    "lightgray"
                  ]}
                />
              </SimpleCard>
            </div>
          </Grid>
        </Grid>
      </div>
    </>

  );
}

const mapStateToProps = state => ({
  userRedux: state.user,
  diary_productions: state.dashboard.diary_productions,
  diary_billing: state.dashboard.diary_billing,
  clients_serveds: state.dashboard.clients_serveds,
  loading: state.dashboard.loading,
  error: state.dashboard.error,
  success: state.dashboard.success,
  indicators: state.manager.indicators,

});

export default connect(mapStateToProps)(withStyles({}, { withTheme: true })(Dashboard1));
