import history from "history.js";
import jwtAuthService from "../../services/jwtAuthService";
import axios from "axios";


export const CREATE_CONTRACT_LOADING = "CREATE_CONTRACT_LOADING";
export const CREATE_CONTRACT_SUCCESS = "CREATE_CONTRACT_SUCCESS";
export const CREATE_CONTRACT_FAILED = "CREATE_CONTRACT_FAILED";
export const UPDATE_CONTRACT_LOADING = "UPDATE_CONTRACT_LOADING";
export const UPDATE_CONTRACT_SUCCESS = "UPDATE_CONTRACT_SUCCESS";
export const UPDATE_CONTRACT_FAILED = "UPDATE_CONTRACT_FAILED";
export const GET_CONTRACT_LOADING = "GET_CONTRACT_LOADING";
export const GET_CONTRACT_SUCCESS = "GET_CONTRACT_SUCCESS";
export const GET_CONTRACT_FAILED = "GET_CONTRACT_FAILED";

export const CLEAR = "CLEAR";

export function registerContract(token, data, company) {
  return dispatch => {
    dispatch({
      type: CREATE_CONTRACT_LOADING,
    });
    axios({
      method: 'post',
      url: `https://api.stonik.com.br/web/company/${company}/client/${data.clientId}/contract`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data: data
    }).then(res => {
      dispatch({
        type: CREATE_CONTRACT_SUCCESS,
        payload: res.data
      });
      console.log("res CREATE_CONTRACT_LOADING", res)
    }).catch(eer => {
      console.log("eer CREATE_CONTRACT_LOADING", eer?.response?.data?.message)
      dispatch({
        type: CREATE_CONTRACT_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function updateContract(token, company, client, contract, status) {
  return dispatch => {
    dispatch({
      type: UPDATE_CONTRACT_LOADING,
    });
    axios({
      method: 'put',
      url: `https://api.stonik.com.br/web/company/${company}/client/${client}/contract/${contract}/status/${status}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: UPDATE_CONTRACT_SUCCESS,
        payload: res.data
      });
      console.log("res UPDATE_CONTRACT_LOADING", res)
    }).catch(eer => {
      console.log("eer UPDATE_CONTRACT_LOADING", eer?.response?.data?.message)
      dispatch({
        type: UPDATE_CONTRACT_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function listContracts(token, id, company) {
  return dispatch => {
    dispatch({
      type: GET_CONTRACT_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/company/${company}/contract`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: GET_CONTRACT_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: GET_CONTRACT_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function clear() {
  return dispatch => {
    dispatch({
      type: CLEAR
    });
  };
}
