import MyConfigs from "./MyGeneralConfigs";

const formsRoutes = [
  {
    path: "/configuracoes-gerais",
    component: MyConfigs
  },
];

export default formsRoutes;
