import React, { useState, useEffect } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
  Button,
  Icon,
  Grid,
  Avatar
} from "@material-ui/core";
import "date-fns";
import InputMask from "react-input-mask";
import { connect } from "react-redux";

import Swal from 'sweetalert2'

import { changeAvatar, updateUser, clear } from "../../redux/actions/UserActions";

const SimpleForm = (props) => {

  const { user, loading, error, success } = props

  const [name, setName] = useState("")
  const [cpf, setCpf] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")

  const [photo, setPhoto] = useState("")

  function TestaCPF(strCPF) {
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF == "00000000000") return false;

    for (var i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (var i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const changeHandler = (event) => {
    getBase64(event.target.files[0]).then(
      data => {
        setPhoto(data.substring(22))
      }
    );
  };

  const handleSubmission = () => {
    props.dispatch(changeAvatar(photo, user?.token))
  };

  const handleSubmit = event => {
    var phoneReplace = phone.replace(/[^0-9]/g, "")
    if (name == "") {
      Swal.fire({
        icon: 'error',
        title: "Nome inválido",
        showConfirmButton: false,
        timer: 3000
      })
    } else if (cpf == "") {
      Swal.fire({
        icon: 'error',
        title: "Cpf inválido",
        showConfirmButton: false,
        timer: 3000
      })
    } else if (phone == "" || phoneReplace.length < 11) {
      Swal.fire({
        icon: 'error',
        title: "Telefone inválido",
        showConfirmButton: false,
        timer: 3000
      })
    } else if (email == "" || validateEmail(email) == false) {
      Swal.fire({
        icon: 'error',
        title: "Email inválido",
        showConfirmButton: false,
        timer: 3000
      })
    } else {
      props.dispatch(updateUser({
        name,
        cpf,
        email,
        contact: phone
      }, user?.token))
    }
  };

  useEffect(() => {
    console.log("user", user)
    setName(user?.displayName)
    setCpf(user?.cpf)
    setPhone(user?.contact)
    setEmail(user?.email)
  }, [user])

  useEffect(() => {
    console.log("props", props)
  }, [props])

  useEffect(() => {
    if (loading == true) {
      console.log("CAIU AQUI")
      Swal.showLoading()
    } else {
      Swal.hideLoading()
    }
  }, [loading])

  useEffect(() => {
    if (success != false) {
      Swal.fire({
        icon: 'success',
        title: success,
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(() => {
        props.dispatch(clear())
        window.location.reload()
        setPhoto("")
      }, 3500)
    }
  }, [success])

  useEffect(() => {
    if (error != null) {
      Swal.fire({
        icon: 'error',
        title: error,
        showConfirmButton: false,
        timer: 3000
      })

      setTimeout(() => {
        props.dispatch(clear())
      }, 3500)
    }
  }, [error])

  return (
    <div>
      <ValidatorForm
      >
        <Grid container spacing={6}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <label className="mb-4">Nome</label>
            <InputMask
              className="w-100 mb-16"
              style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
              label="name"
              type="text"
              onChange={(event) => {
                setName(event.target.value)
              }}
              name="name"
              value={name}
            />
            <label className="mb-4">CPF</label>
            <InputMask
              className="w-100 mb-16"
              style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
              mask="999.999.999-99"
              label="Cpf"
              type="text"
              disabled
              onChange={(event) => {
                setCpf(event.target.value)
              }}
              name="cpf"
              value={cpf}
            />
            <label className="mb-4">Telefone</label>
            <InputMask
              className="w-100 mb-16"
              mask="(99) 9 9999-9999"
              style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
              label="telefone"
              type="text"
              onChange={(event) => {
                setPhone(event.target.value)
              }}
              name="phone"
              value={phone}
            />
            <label className="mb-4">E-mail</label>
            <InputMask
              className="w-100 mb-16"
              style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
              label="email"
              type="text"
              onChange={(event) => {
                setEmail(event.target.value)
              }}
              name="email"
              value={email}
            />
            <Button color="primary" variant="contained" style={{ marginTop: 10 }} onClick={() => { handleSubmit() }}>
              <Icon>send</Icon>
              <span className="pl-8 capitalize">Alterar dados</span>
            </Button>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12} align="center">
            <Avatar
              className="avatar mb-20"
              style={{ width: 100, height: 100 }}
              src={user?.photoURL}
            />
            <div>
              <input type="file" name="file" onChange={changeHandler} />
            </div>
            <Button variant="contained" color="primary" onClick={() => { handleSubmission() }} style={{ marginTop: 10 }}>
              <span className="pl-8 capitalize">Alterar Foto</span>
            </Button>
          </Grid>

        </Grid>

      </ValidatorForm>
    </div>
  );
}

const mapStateToProps = state => ({
  loading: state.user.loading,
  error: state.user.error,
  success: state.user.success,
  user: state.user,
});

export default connect(mapStateToProps)(SimpleForm);
