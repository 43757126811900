import {
  LIST_TYPIST_LOADING,
  LIST_TYPIST_SUCCESS,
  LIST_TYPIST_FAILED,
  UPDATE_TYPIST_LOADING,
  UPDATE_TYPIST_SUCCESS,
  UPDATE_TYPIST_FAILED,
  DELETE_TYPIST_LOADING,
  DELETE_TYPIST_SUCCESS,
  DELETE_TYPIST_FAILED,
  CREATE_TYPIST_LOADING,
  CREATE_TYPIST_SUCCESS,
  CREATE_TYPIST_FAILED,
  CLEAR
} from "../actions/TypistActions";


const initialState = {success: false,
  loading: false,
  error: null,
  typists: []
};

const TypistReducer = function(state = initialState, action) {
  switch (action.type) {
    case LIST_TYPIST_LOADING: {
      console.log("LIST_TYPIST_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_TYPIST_SUCCESS: {
      console.log("LIST_TYPIST_SUCCESS", action?.payload)
      return {
        ...state,
        typists: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_TYPIST_FAILED: {
      console.log("LIST_TYPIST_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case DELETE_TYPIST_LOADING: {
      console.log("DELETE_TYPIST_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_TYPIST_SUCCESS: {
      console.log("DELETE_TYPIST_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Digitador deletado com sucesso!",
        loading: false,
        error: null
      };
    }
    case DELETE_TYPIST_FAILED: {
      console.log("DELETE_TYPIST_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case UPDATE_TYPIST_LOADING: {
      console.log("UPDATE_TYPIST_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_TYPIST_SUCCESS: {
      console.log("UPDATE_TYPIST_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Digitador atualizado com sucesso!",
        loading: false,
        error: null
      };
    }
    case UPDATE_TYPIST_FAILED: {
      console.log("UPDATE_TYPIST_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case CREATE_TYPIST_LOADING: {
      console.log("CREATE_TYPIST_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case CREATE_TYPIST_SUCCESS: {
      console.log("CREATE_TYPIST_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Digitador adicionado com sucesso!",
        loading: false,
        error: null
      };
    }
    case CREATE_TYPIST_FAILED: {
      console.log("CREATE_TYPIST_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    
    case CLEAR: {
      return {
        ...state,
        error: null,
        success: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default TypistReducer;
