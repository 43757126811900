import UserTable from "./GeneralOperationReports";

const GeneralOperationReports = [
  {
    path: "/operacao-geral",
    exact: true,
    component: UserTable
  }
];

export default GeneralOperationReports;
