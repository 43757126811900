import MyConfigs from "./MyConfigs";

const MyAverageSaleValueConfigsRoutes = [
  {
    path: "/valor-medio-metro-cubico-venda",
    component: MyConfigs
  },
];

export default MyAverageSaleValueConfigsRoutes;
