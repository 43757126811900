import UserTable from "./ConcreteMixerProductionReports";

const ConcreteMixerProductionReports = [
  {
    path: "/producao-media-betoneira",
    exact: true,
    component: UserTable
  }
];

export default ConcreteMixerProductionReports;
