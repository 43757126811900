import React, { Component, useState, useEffect } from "react";
import {
  Dialog,
  Button,
  Grid,
  Icon,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { getUserById, updateUser, addNewUser } from "./TableService";
import { generateRandomId } from "utils";
import InputMask from "react-input-mask";
import {
  TimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import Select from "./ReactSelect"
import { listBranch } from "../../redux/actions/BranchActions"
import { connect } from "react-redux";
import { createGoalm3Vehicle, updateGoalm3Vehicle, clear } from "../../redux/actions/FleetActions"

const MemberEditorDialog = props => {

  const { open, handleClose, userRedux, uid, branchs } = props

  const [diario, setDiario] = useState(uid != null ? uid?.volumeM3Daily : "")
  const [semanal, setSemanal] = useState(uid != null ? uid?.volumeM3Weekly : "")
  const [mensal, setMensal] = useState(uid != null ? uid?.volumeM3Montly : "")
  const [isActive, setIsActive] = useState("")
  const [options, setOptions] = useState([])
  const [branch, setBranch] = useState(uid != null ? uid?.branch?.id : "")
  const [message, setMessage] = useState("")

  console.log("uid", uid)

  const handleChange = (event, source) => {
    event.persist();
    if (source === "switch") {
      setIsActive(event.target.checked)
      return;
    }
  };

  const handleFormSubmit = () => {
    if (branch == "") {
      setMessage("Selecione a Filial!")
    } else if (diario == "") {
      setMessage("Preencha o valor diário!")
    } else if (semanal == "") {
      setMessage("Preencha o valor semanal!")
    } else if (mensal == "") {
      setMessage("Preencha valor mensal!")
    } else {
      const obj = {
        branch: {
          id: branch
        },
        volumeM3Daily: diario,
        volumeM3Weekly: semanal,
        volumeM3Montly: mensal
      }
      if (uid != null) {
        props.dispatch(updateGoalm3Vehicle(userRedux?.token, obj, userRedux?.company))
      } else {
        props.dispatch(createGoalm3Vehicle(userRedux?.token, obj, userRedux?.company))
      }
      handleClose()
    }

  };

  useEffect(() => {
    props.dispatch(listBranch(userRedux?.token, userRedux?.company))

  }, [])

  useEffect(() => {
    if (branchs != null) {
      console.log("branchs", branchs)
      var newOptions = []
      branchs.map(item => {
        newOptions.push({ label: item?.name, value: item?.id })
      })
      setOptions(newOptions)
    }
  }, [branchs])

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="p-24" style={{ width: "400px" }}>
        <h4 className="mb-20">Produção Média Esperada Por Betoneira</h4>
        <ValidatorForm onSubmit={handleFormSubmit}>
          <Grid className="mb-16" container spacing={10}>
            <Grid item sm={12}>
              <Select data={options} selected={branch} disabled={uid == null ? false : true} onChange={(value) => { setBranch(value) }} />
              <label className="mb-4">Diário</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="diario"
                onChange={(event) => {
                  setDiario(event.target.value)
                }}
                type="number"
                name="diario"
                value={diario}
              />
              <label className="mb-4">Semanal</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="semanal"
                onChange={(event) => {
                  setSemanal(event.target.value)
                }}
                type="number"
                name="semanal"
                value={semanal}
              />
              <label className="mb-4">Mensal</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="mensal"
                onChange={(event) => {
                  setMensal(event.target.value)
                }}
                type="number"
                name="mensal"
                value={mensal}
              />

            </Grid>
          </Grid>
          {
            message != "" && <div style={{ backgroundColor: "rgba(255,0,0,.2)", padding: 5, marginBottom: 10 }}><Icon style={{ color: "red", marginRight: 5, marginLeft: 5 }} fontSize="small">close</Icon><span style={{ color: "red", fontWeight: "bold" }}>{message}</span></div>
          }
          <div className="flex flex-space-between flex-middle">
            <Button variant="contained" color="primary" type="submit">
              Salvar
            </Button>
            <Button onClick={() => handleClose()}>Cancelar</Button>
          </div>
        </ValidatorForm>
      </div>
    </Dialog >
  );
}

const mapStateToProps = state => ({
  userRedux: state.user,
  branchs: state.branch.branchs,
});

export default connect(mapStateToProps)(MemberEditorDialog);