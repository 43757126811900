import {
  LIST_DIARY_PRODUCTION_LOADING,
  LIST_DIARY_PRODUCTION_SUCCESS,
  LIST_DIARY_PRODUCTION_FAILED,
  LIST_DIARY_BILLING_LOADING,
  LIST_DIARY_BILLING_SUCCESS,
  LIST_DIARY_BILLING_FAILED,
  LIST_CLIENT_SERVED_LOADING,
  LIST_CLIENT_SERVED_SUCCESS,
  LIST_CLIENT_SERVED_FAILED,
  CLEAR
} from "../actions/DashboardActions";


const initialState = {success: false,
  loading: false,
  error: null,
  diary_productions: null,
  diary_billing: null,
  clients_serveds: null
};

const DashboardReducer = function(state = initialState, action) {
  switch (action.type) {
    case LIST_DIARY_PRODUCTION_LOADING: {
      console.log("LIST_DIARY_PRODUCTION_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_DIARY_PRODUCTION_SUCCESS: {
      console.log("LIST_DIARY_PRODUCTION_SUCCESS", action?.payload)
      return {
        ...state,
        diary_productions: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_DIARY_PRODUCTION_FAILED: {
      console.log("LIST_DIARY_PRODUCTION_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case LIST_DIARY_BILLING_LOADING: {
      console.log("LIST_DIARY_BILLING_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_DIARY_BILLING_SUCCESS: {
      console.log("LIST_DIARY_BILLING_SUCCESS", action?.payload)
      return {
        ...state,
        diary_billing: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_DIARY_BILLING_FAILED: {
      console.log("LIST_DIARY_BILLING_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case LIST_CLIENT_SERVED_LOADING: {
      console.log("LIST_CLIENT_SERVED_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_CLIENT_SERVED_SUCCESS: {
      console.log("LIST_CLIENT_SERVED_SUCCESS", action?.payload)
      return {
        ...state,
        clients_serveds: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_CLIENT_SERVED_FAILED: {
      console.log("LIST_CLIENT_SERVED_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    
    case CLEAR: {
      return {
        ...state,
        error: null,
        success: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default DashboardReducer;
