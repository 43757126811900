import CrudTable from "./CrudTable";

const crudRoute = [
  {
    path: "/crud-table",
    exact: true,
    component: CrudTable
  }
];

export default crudRoute;
