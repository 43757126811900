import UserTable from "./UnloadingReports";

const UnloadingReports = [
  {
    path: "/descarregamento",
    exact: true,
    component: UserTable
  }
];

export default UnloadingReports;
