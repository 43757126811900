import React from "react";
import Toolbar from "react-big-calendar/lib/Toolbar";
import { navigate } from "react-big-calendar/lib/utils/constants";
import { Tooltip, IconButton, Icon } from "@material-ui/core";

class CalendarHeader extends Toolbar {
  state = {};

  render() {
    const { label } = this.props;

    return (
      <div className="calendar-header flex py-4 flex-space-around bg-primary">
        <div className="flex flex-center">
          <Tooltip title="Anterior">
            <IconButton onClick={this.navigate.bind(null, navigate.PREVIOUS)}>
              <Icon className="text-white">chevron_left</Icon>
            </IconButton>
          </Tooltip>

          <Tooltip title="Hoje">
            <IconButton onClick={this.navigate.bind(null, navigate.TODAY)}>
              <Icon className="text-white">today</Icon>
            </IconButton>
          </Tooltip>

          <Tooltip title="Próximo">
            <IconButton onClick={this.navigate.bind(null, navigate.NEXT)}>
              <Icon className="text-white">chevron_right</Icon>
            </IconButton>
          </Tooltip>
        </div>

        <div className="flex flex-middle">
          <h6 className="m-0 text-white">{label}</h6>
        </div>

      </div>
    );
  }
}

export default CalendarHeader;
