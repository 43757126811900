import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { setUserData } from "../redux/actions/UserActions";
import jwtAuthService from "../services/jwtAuthService";
import localStorageService from "../services/localStorageService";
// import firebaseAuthService from "../services/firebase/firebaseAuthService";

class Auth extends Component {
  state = {};
  
  constructor(props) {
    super(props);
    this.props.setUserData(localStorageService.getItem("auth_user"));
    this.checkJwtAuth();
  }

  checkJwtAuth = () => {
    this.props.setUserData(localStorageService.getItem("auth_user"));
  };

  render() {
    const { children } = this.props;
    return <Fragment>{children}</Fragment>;
  }
}

const mapStateToProps = state => ({
  setUserData: PropTypes.func.isRequired,
  login: state.login
});

export default connect(
  mapStateToProps,
  { setUserData }
)(Auth);
