import {
  LIST_DRIVER_LOADING,
  LIST_DRIVER_SUCCESS,
  LIST_DRIVER_FAILED,
  UPDATE_DRIVER_LOADING,
  UPDATE_DRIVER_SUCCESS,
  UPDATE_DRIVER_FAILED,
  DELETE_DRIVER_LOADING,
  DELETE_DRIVER_SUCCESS,
  DELETE_DRIVER_FAILED,
  CREATE_DRIVER_LOADING,
  CREATE_DRIVER_SUCCESS,
  CREATE_DRIVER_FAILED,
  CLEAR
} from "../actions/DriverActions";


const initialState = {success: false,
  loading: false,
  error: null,
  drivers: []
};

const DriverReducer = function(state = initialState, action) {
  switch (action.type) {
    case LIST_DRIVER_LOADING: {
      console.log("LIST_DRIVER_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_DRIVER_SUCCESS: {
      console.log("LIST_DRIVER_SUCCESS", action?.payload)
      return {
        ...state,
        drivers: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_DRIVER_FAILED: {
      console.log("LIST_DRIVER_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case DELETE_DRIVER_LOADING: {
      console.log("DELETE_DRIVER_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_DRIVER_SUCCESS: {
      console.log("DELETE_DRIVER_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Motorista deletado com sucesso!",
        loading: false,
        error: null
      };
    }
    case DELETE_DRIVER_FAILED: {
      console.log("DELETE_DRIVER_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case UPDATE_DRIVER_LOADING: {
      console.log("UPDATE_DRIVER_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_DRIVER_SUCCESS: {
      console.log("UPDATE_DRIVER_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Motorista atualizado com sucesso!",
        loading: false,
        error: null
      };
    }
    case UPDATE_DRIVER_FAILED: {
      console.log("UPDATE_DRIVER_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case CREATE_DRIVER_LOADING: {
      console.log("CREATE_DRIVER_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case CREATE_DRIVER_SUCCESS: {
      console.log("CREATE_DRIVER_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Motorista adicionado com sucesso!",
        loading: false,
        error: null
      };
    }
    case CREATE_DRIVER_FAILED: {
      console.log("CREATE_DRIVER_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    
    case CLEAR: {
      return {
        ...state,
        error: null,
        success: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default DriverReducer;
