import {
  LIST_EXCEPTION_LOADING,
  LIST_EXCEPTION_SUCCESS,
  LIST_EXCEPTION_FAILED,
  UPDATE_EXCEPTION_LOADING,
  UPDATE_EXCEPTION_SUCCESS,
  UPDATE_EXCEPTION_FAILED,
  DELETE_EXCEPTION_LOADING,
  DELETE_EXCEPTION_SUCCESS,
  DELETE_EXCEPTION_FAILED,
  CREATE_EXCEPTION_LOADING,
  CREATE_EXCEPTION_SUCCESS,
  CREATE_EXCEPTION_FAILED,
  CLEAR
} from "../actions/ExceptionActions";


const initialState = {success: false,
  loading: false,
  error: null,
  exceptions: []
};

const ExceptionReducer = function(state = initialState, action) {
  switch (action.type) {
    case LIST_EXCEPTION_LOADING: {
      console.log("LIST_EXCEPTION_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_EXCEPTION_SUCCESS: {
      console.log("LIST_EXCEPTION_SUCCESS", action?.payload)
      return {
        ...state,
        exceptions: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_EXCEPTION_FAILED: {
      console.log("LIST_EXCEPTION_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case DELETE_EXCEPTION_LOADING: {
      console.log("DELETE_EXCEPTION_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_EXCEPTION_SUCCESS: {
      console.log("DELETE_EXCEPTION_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Exceção deletada com sucesso!",
        loading: false,
        error: null
      };
    }
    case DELETE_EXCEPTION_FAILED: {
      console.log("DELETE_EXCEPTION_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case UPDATE_EXCEPTION_LOADING: {
      console.log("UPDATE_EXCEPTION_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_EXCEPTION_SUCCESS: {
      console.log("UPDATE_EXCEPTION_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Exceção atualizada com sucesso!",
        loading: false,
        error: null
      };
    }
    case UPDATE_EXCEPTION_FAILED: {
      console.log("UPDATE_EXCEPTION_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case CREATE_EXCEPTION_LOADING: {
      console.log("CREATE_EXCEPTION_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case CREATE_EXCEPTION_SUCCESS: {
      console.log("CREATE_EXCEPTION_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Exceção adicionada com sucesso!",
        loading: false,
        error: null
      };
    }
    case CREATE_EXCEPTION_FAILED: {
      console.log("CREATE_EXCEPTION_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    
    case CLEAR: {
      return {
        ...state,
        error: null,
        success: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default ExceptionReducer;
