import React, { useState, useEffect } from "react";
import {
  Dialog,
  Button,
  Grid,
  Icon,
  CircularProgress
} from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputMask from "react-input-mask";

import { connect } from "react-redux";

import { listProductTypes, registerProductType, updateProductType, clear } from "../../redux/actions/ProductActions"

import Swal from 'sweetalert2'

const MemberEditorDialog = (props) => {

  const {
    open,
    handleClose,
    user,
    uid,
    clients,
    loadingClient,
    errorClient,
    successClient,
    loading,
    error,
    success,
    productTypes,
    loadingProductTypes,
    errorProductTypes,
    successProductTypes,
    products,
    loadingProducts,
    errorProducts,
    successProducts
  } = props

  const [nomeProduto, setNomeProduto] = useState("")
  const [productType, setProductType] = useState("")

  const [titleError, setTitleError] = useState("")

  const handleFormSubmit = () => {
    if (nomeProduto == "") {
      setTitleError("Preencha o nome do produto")
    } else {
      if (uid != null) {
        console.log("Edit", {
          id: uid?.id,
          name: nomeProduto,
          description: "",
          status: true
        })
        props.dispatch(updateProductType(user?.token, {
          id: uid?.id,
          name: nomeProduto,
          description: "",
          status: true
        }, user?.company))
      } else {
        props.dispatch(registerProductType(user?.token, {
          name: nomeProduto,
          description: "",
          status: true
        }, user?.company))
      }

    }
  };

  useEffect(() => {
    if (uid != null) {
      setNomeProduto(uid.name)
    }

    console.log('uid', uid)
  }, [])

  useEffect(() => {
    if (success != false) {
      handleClose()
      Swal.fire({
        icon: 'success',
        title: success,
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(() => {
        props.dispatch(clear())
        props.dispatch(listProductTypes(user?.token, user?.company))
      }, 3500)
    }
  }, [success])

  useEffect(() => {
    if (error != null) {
      handleClose()

      Swal.fire({
        icon: 'error',
        title: error,
        showConfirmButton: false,
        timer: 3000
      })

      setTimeout(() => {
        props.dispatch(clear())
      }, 3500)
    }
  }, [error])

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="p-24" style={{ width: "400px" }}>
        <h4 className="mb-20">Adicionar Produto</h4>
        <ValidatorForm onSubmit={() => {
          handleFormSubmit()
        }}>
          <Grid container spacing={10}>

            <Grid item sm={12}>


              <label className="mb-4">Nome do produto</label>
              <InputMask
                className="w-100 mb-16"
                style={{ width: "97%", padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="Nome Produto"
                onChange={(event) => {
                  setNomeProduto(event.target.value)
                }}
                type="text"
                name="nomeProduto"
                value={nomeProduto}
              />
            </Grid>
          </Grid>
          {
            titleError != "" && <div style={{ backgroundColor: "rgba(255,0,0,.2)", padding: 5, marginTop: 10 }}><Icon style={{ color: "red", marginRight: 5, marginLeft: 5 }} fontSize="small">close</Icon><span style={{ color: "red", fontWeight: "bold" }}>{titleError}</span></div>
          }
          <div className="flex flex-space-between flex-middle" style={{ marginTop: 10 }}>
            <Button variant="contained" color="primary" type="submit">
              {
                loading == true ?
                  <CircularProgress />
                  :
                  "Salvar"
              }
            </Button>
            <Button onClick={() => {
              handleClose()
            }}>Cancelar</Button>
          </div>
        </ValidatorForm>
      </div>
    </Dialog>
  );

}

const mapStateToProps = state => ({
  user: state.user,
  products: state.product.products,
  loading: state.product.loading,
  error: state.product.error,
  success: state.product.success,
  loadingProducts: state.product.loadingProduct,
  errorProducts: state.product.errorProduct,
  successProducts: state.product.successProduct,
});

export default connect(mapStateToProps)(MemberEditorDialog);
