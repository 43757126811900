/* eslint-disable */
import React, { Component, useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Icon,
  TablePagination,
  Button,
  Grid,
  Card
} from "@material-ui/core";
import { getAllUser, deleteUser } from "./TableService";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Breadcrumb, ConfirmationDialog } from "egret";
import shortid from "shortid";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptLocale from "date-fns/locale/pt-BR";
import { MyDocument } from "./pdf"
import { format } from "date-fns"
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReactExport from "react-export-excel";
import { connect } from "react-redux";
import Swal from 'sweetalert2'
import IntegrationReactSelect from "./ReactSelect";
import moment from "moment"

import { listBranch } from "../../redux/actions/BranchActions"
import { listDiscardReport } from "../../redux/actions/DiscardActions"
import { listFleet } from "../../redux/actions/FleetActions"

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const CrudTable = (props) => {

  const { userRedux, reports, branchs, discards } = props

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [date, setDate] = useState(new Date())
  const [finalData, setFinalData] = useState(new Date())
  const [volume, setVolume] = useState("")
  const [distancia, setDistancia] = useState("")
  const [uid, setUid] = useState([])
  const [branchSelected, setBranchSelected] = useState(null)
  const [dataList, setDataList] = useState([])
  const [dataSet, setDataSet] = useState([{
    data: "10/03/2022",
    nota: "123456",
    motorista: "Francisco de Oliveira Graciano",
    placa: "123456"
  }])
  const [shouldOpenEditorDialog, setShouldOpenEditorDialog] = useState(false)
  const [shouldOpenConfirmationDialog, setShouldOpenConfirmationDialog] = useState(false)
  const [options, setOptions] = useState([])
  const [selected, setSelected] = useState(null)

  const setPageFunction = page => {
    setPage(page)
  };

  const setRowsPerPageFunction = event => {
    setRowsPerPage(event.target.value)
  };

  const handleChangePage = (event, newPage) => {
    setPageFunction(newPage)
  };

  const handleDialogClose = () => {
    setShouldOpenEditorDialog(false)
    setShouldOpenConfirmationDialog(false)
    updatePageData()
  };

  const handleDeleteUser = user => {
    setShouldOpenConfirmationDialog(true)
  };

  const handleConfirmationResponse = () => {

  };

  const createObjectDataSet = (reports) => {
    var newObj = []
    reports?.map(item => {
      newObj.push({
        Data: item?.date,
        Nota: item?.ticketNumber || "Sem Nota Fiscal",
        Motorista: item?.driver?.name,
        Placa: item?.vehicle?.plate,
        Status: item?.order?.statusCurrent || "Sem Status",
      })
      return item;
    })
    console.log("newOjt", newObj)
    setDataSet(newObj)
  }

  const filterData = () => {
    if (date == null) {
      Swal.fire({
        icon: 'error',
        title: "Adicione a data inicial",
        showConfirmButton: false,
        timer: 3000
      })
    } else if (finalData == null) {
      Swal.fire({
        icon: 'error',
        title: "Adicione a data final",
        showConfirmButton: false,
        timer: 3000
      })
    } else if (branchSelected == null) {
      Swal.fire({
        icon: 'error',
        title: "Selecione a unidade",
        showConfirmButton: false,
        timer: 3000
      })
    } else {
      props.dispatch(listDiscardReport(userRedux?.token, userRedux?.company, format(date, "dd/MM/yyyy"), branchSelected))
    }
  }

  useEffect(() => {
    updatePageData()
    console.log("date", date)
    props.dispatch(listBranch(userRedux?.token, userRedux?.company))
  }, [])

  useEffect(() => {
    console.log("discards", discards)
    if(discards != null){
      createObjectDataSet(discards)
    }
    // createObjectDataSet(reports)
  }, [discards])

  useEffect(() => {
    if (branchs != null) {
      console.log("branchs", branchs)
      var newOptions = []
      branchs.map(item => {
        newOptions.push({ label: item?.nameFantasy, value: item?.id })
      })
      setOptions(newOptions)
    }
  }, [branchs])

  const updatePageData = () => {
  };

  const handleDateChange = date => {
    console.log("date", date)
    setDate(date)
  };

  const handleFinalDateChange = finalDate => {
    setFinalData(finalDate)
  };

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: "Relatório de descarte" }]} />
      </div>
      <Grid container spacing={5}>
        <Grid item lg={3} md={3} sm={12} xs={12} style={{ marginTop: 6 }}>
          <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="mb-16 w-100"
              margin="none"
              id="mui-pickers-date"
              label="Data Início"
              format="dd/MM/yyyy"
              inputVariant="standard"
              type="text"
              autoOk={true}
              value={date}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12} style={{ marginTop: 6 }}>
          <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="mb-16 w-100"
              margin="none"
              id="mui-pickers-date"
              label="Data Final"
              format="dd/MM/yyyy"
              inputVariant="standard"
              type="text"
              autoOk={true}
              value={finalData}
              onChange={handleFinalDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <IntegrationReactSelect data={options} onChange={(value) => { setBranchSelected(value) }} />
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Button color="primary" variant="contained" type="button" onClick={() => { filterData() }} style={{ marginTop: 10 }}>
            <Icon>filter_list</Icon>
            <span className="pl-8 capitalize">Filtrar</span>
          </Button>
        </Grid>
      </Grid>
      {
        discards?.length > 0 && <Grid container spacing={5}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <ExcelFile filename="relatório-descarte" element={<Button style={{ backgroundColor: "#f78726", color: "white", marginBottom: 10, marginRight: 5 }} aria-label="Add">Exportar Excel</Button>}>
            <ExcelSheet data={dataSet} name="Descarte">
              <ExcelColumn label="Data" value="Data" />
              <ExcelColumn label="Número da NF" value="Nota" />
              <ExcelColumn label="Motorista" value="Motorista" />
              <ExcelColumn label="Placa" value="Placa" />
              {/* <ExcelColumn label="Placa" value="Placa" />> */}
              <ExcelColumn label="Status" value="Status" />
            </ExcelSheet>
          </ExcelFile>
          <PDFDownloadLink document={<MyDocument data={dataSet} />} fileName="relatório-descarte.pdf">
            <Button style={{ backgroundColor: "transparent", border: "1.5px solid #f78726", color: "#f78726", marginBottom: 10 }} aria-label="Add">Exportar Pdf</Button>
          </PDFDownloadLink>
        </Grid>
      </Grid>
      }
      


      <Card className="w-100 overflow-auto" elevation={6}>
        <h6 style={{ textAlign: "center", marginRight: 10, fontSize: 10, marginTop: 10 }}>Data de emissão: {moment().format("D/MM/Y")}</h6>
        <h6 style={{ textAlign: "center", marginRight: 10, fontSize: 10, }}>Usuário: {userRedux?.displayName}</h6>
        <h4 style={{ textAlign: "center", marginRight: 10 }}>Relatório De Descarte</h4>
        {
          discards?.length == 0 ?
            <h5 style={{ textAlign: "center", marginTop: 20 }}>Sem dados para esta filtragem.</h5>
            :
            <Table className="crud-table" style={{ whiteSpace: "pre", minWidth: "750px" }}>
              <TableHead>
                <TableRow>
                  <TableCell>Data</TableCell>
                  <TableCell>Número da NF</TableCell>
                  <TableCell>Motorista</TableCell>
                  <TableCell>Placa</TableCell>
                  <TableCell>Imagem</TableCell>
                  <TableCell>Lat/long</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {discards
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((item, index) => (

                    <TableRow key={shortid.generate()} style={{ paddingVertical: 5 }}>
                      <TableCell className="px-0" align="left">{item?.date}</TableCell>
                      <TableCell className="px-0" align="left">{item?.ticketNumber}</TableCell>
                      <TableCell className="px-0" align="left">{item?.driver?.name}</TableCell>
                      <TableCell className="px-0" align="left">{item?.vehicle?.plate}</TableCell>
                      <TableCell className="px-0" align="left">
                        <a target="_blank" href={item?.file}>
                          <Icon
                            style={{ color: "#f78726", cursor: "pointer" }}
                            fontSize="small"
                          >visibility</Icon>
                        </a>
                      </TableCell>
                      <TableCell className="px-0" align="left">
                        <a target="_blank" href={`https://www.google.com.br/maps/@${item?.latitude},${item?.longitude},13.08z`}>
                          <Icon
                            style={{ color: "#f78726", cursor: "pointer" }}
                            fontSize="small"
                          >room</Icon>
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
        }



        <TablePagination
          className="px-16"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={dataList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Linhas por Página"
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={setRowsPerPageFunction}
        />
        {shouldOpenConfirmationDialog && (
          <ConfirmationDialog
            open={shouldOpenConfirmationDialog}
            onConfirmDialogClose={handleDialogClose}
            onYesClick={handleConfirmationResponse}
            text="Tem certeza que deseja excluir este registro?"
            title="Excluir registro"
            confirmButtonText="Sim"
            declineButtonText="Não"
          />
        )}
      </Card>
    </div>
  );
}

const mapStateToProps = state => ({
  userRedux: state.user,
  reports: state.discard.discards,
  fleets: state.fleet.fleets,
  branchs: state.branch.branchs,
  discards: state.discard.discards,
  loading: state.reports.loading,
  error: state.reports.error,
  success: state.reports.success,
});

export default connect(mapStateToProps)(CrudTable);