import React, { Component, useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  Icon,
  IconButton,
  TableRow,
  TableCell,
  TablePagination,
  Button,
  Grid,
  Card,
  Collapse,
  Box,
  Typography,
  CircularProgress
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { getAllUser, deleteUser } from "./TableService";
import { Breadcrumb } from "egret";
import shortid from "shortid";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment"
import ptLocale from "date-fns/locale/pt-BR";
import { MyDocument } from "./pdf"
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReactExport from "react-export-excel";
import { connect } from "react-redux";
import IntegrationReactSelect from "./ReactSelect"
import IntegrationReactSelectProduct from "./ReactSelectProduct"
import { listDischargeReport } from "../../redux/actions/DischargeActions"
import { listProductTypes } from "../../redux/actions/ProductActions"
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { listBranch } from "../../redux/actions/BranchActions"

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const dataSet1 = [
  {
    data: "02/09/2021",
    placa: "000BBCCC",
    primeira_nota: '01/10/2021',
    horario_nota: '15:35',
    quantidade_notas: "2",
    quantidade_entregas: "1",
    quantidade_incon: "1",
  },
  {
    data: "02/09/2021",
    placa: "000BBCCC",
    primeira_nota: '01/10/2021',
    horario_nota: '15:35',
    quantidade_notas: "2",
    quantidade_entregas: "1",
    quantidade_incon: "1",
  },
  {
    data: "02/09/2021",
    placa: "000BBCCC",
    primeira_nota: '01/10/2021',
    horario_nota: '15:35',
    quantidade_notas: "2",
    quantidade_entregas: "1",
    quantidade_incon: "1",
  },

];

const CrudTable = props => {

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogLats, setOpenDialogLats] = useState(null);
  const [date, setDate] = useState(new Date())
  const [distancia, setDistancia] = useState(0)
  const [uid, setUid] = useState(0)
  const [volume, setVolume] = useState("")
  const [finalDate, setFinalDate] = useState(new Date())
  const [discharges, setDischarges] = useState([])
  const [loading, setLoading] = useState(false)
  const [userList, setUserList] = useState([])
  const [
    , setShouldOpenEditorDialog] = useState(false)
  const [shouldOpenConfirmationDialog, setShouldOpenConfirmationDialog] = useState(false)
  const [options, setOptions] = useState([])
  const [excelAndPdfOptions, setExcelAndPdfOptions] = useState([])

  const { userRedux, discharge, productTypes, branchs } = props

  const setPageFunction = page => {
    setPage(page)
  };

  const setRowsPerPageFunction = event => {
    setRowsPerPage(event.target.value)
  };

  const handleChangePage = (event, newPage) => {
    setPageFunction(newPage);
  };

  const handleDialogClose = () => {
    setShouldOpenEditorDialog(false)
    setShouldOpenConfirmationDialog(false)
    updatePageData();
  };

  const handleDeleteUser = user => {
    setShouldOpenConfirmationDialog(true)
  };

  const handleConfirmationResponse = () => {

  };

  const gerarRelatorio = () => {
    setLoading(true)
    if (date != "") {
      props.dispatch(listDischargeReport(userRedux?.token, userRedux?.company, moment(date).format("D/M/Y")))
    }
  }

  const handleClickOpen = (coords) => {
    setOpenDialog(true)
    setOpenDialogLats(coords)
  }

  const handleClose = () => {
    setOpenDialog(false)
    setOpenDialogLats(null)
  }

  useEffect(() => {
    props.dispatch(listBranch(userRedux?.token, userRedux?.company))

  }, [])

  useEffect(() => {
    if (branchs != null) {
      console.log("branchs", branchs)
      var newOptions = []
      branchs.map(item => {
        newOptions.push({ label: item?.nameFantasy, value: item?.id })
      })
      setOptions(newOptions)
    }
  }, [branchs])

  useEffect(() => {
    if (discharge != null) {
      setDischarges(discharge)
      var newExcelOptions = []
      discharge.map(item => {
        newExcelOptions.push({
          date: item?.date,
          plate: item?.vehicle?.plate,
          firstInvoice: item?.firstInvoice,
          hourFirstInvoice: item?.hourFirstInvoice,
          qntInvoices: item?.qntInvoices,
          qntDischarges: item?.qntDischarges,
          qntInconsistency: item?.qntInconsistency,
        })
      })
      setExcelAndPdfOptions(newExcelOptions)
      setLoading(false)
      console.log("newExcelOptions", newExcelOptions)
      console.log("discharge", discharge)
    }
  }, [discharge])

  const updatePageData = () => {
    getAllUser().then(({ data }) => setUserList([...data]));
  };

  const handleDateChange = date => {
    console.log("date", date)
    setDate(date)
  };

  const handleFinalDateChange = finalDate => {
    setFinalDate(finalDate)
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: "Minhas descargas" }]} />
      </div>
      <Grid container spacing={5}>
        <Grid item lg={5} md={5} sm={12} xs={12} style={{ marginTop: 6 }}>
          <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="mb-16 w-100"
              margin="none"
              id="mui-pickers-date"
              label="Data"
              format="dd/MM/yyyy"
              inputVariant="standard"
              type="text"
              autoOk={true}
              value={date}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item lg={5} md={5} sm={12} xs={12}>
          <IntegrationReactSelectProduct data={options} />
        </Grid>
        <Grid item lg={2} md={2} sm={12} xs={12}>
          <Button color="primary" variant="contained" onClick={() => { gerarRelatorio() }} style={{ marginTop: 10 }}>
            <Icon>filter_list</Icon>
            <span className="pl-8 capitalize">Filtrar</span>
          </Button>
        </Grid>
      </Grid>
      {
        discharges[0]?.discharges?.length > 0 ?
          <>
            <ExcelFile filename="relatorio-descarregamento" element={<Button style={{ backgroundColor: "#f78726", color: "white", marginBottom: 10, marginRight: 5 }} aria-label="Add">Exportar Excel</Button>}>
              <ExcelSheet data={excelAndPdfOptions} name="Employees">
                <ExcelColumn label="Data" value="date" />
                <ExcelColumn label="Placa" value="plate" />
                <ExcelColumn label="Primeira Nota" value="firstInvoice" />
                <ExcelColumn label="Horário da primeira nota" value="hourFirstInvoice" />
                <ExcelColumn label="Quantidade de notas" value="qntInvoices" />
                <ExcelColumn label="Quantidade de entregas" value="qntDischarges" />
                <ExcelColumn label="Quantidade de inconsistências" value="qntInconsistency" />
              </ExcelSheet>
            </ExcelFile>

            <PDFDownloadLink document={<MyDocument data={excelAndPdfOptions} />} fileName="relatório-descarregamento.pdf">
              <Button style={{ backgroundColor: "transparent", border: "1.5px solid #f78726", color: "#f78726", marginBottom: 10 }} aria-label="Add">Exportar Pdf</Button>
            </PDFDownloadLink>
            <Card className="w-100 overflow-auto" elevation={6}>
              <h6 style={{ textAlign: "center", marginRight: 10, fontSize: 10, marginTop: 10 }}>Data de emissão: {moment().format("D/M/Y")}</h6>
              <h6 style={{ textAlign: "center", marginRight: 10, fontSize: 10, }}>Usuário: {userRedux?.displayName}</h6>
              <h4 style={{ textAlign: "center", marginRight: 10 }}>Minhas descargas</h4>

              <Table className="crud-table" style={{ whiteSpace: "pre", minWidth: "750px" }}>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Data</TableCell>
                    <TableCell>Placa</TableCell>
                    <TableCell>Primeira nota</TableCell>
                    <TableCell>Horário primeira nota</TableCell>
                    <TableCell>Qtd. de notas</TableCell>
                    <TableCell>Qtd. de entregas</TableCell>
                    <TableCell>Qtd. de inconsistências</TableCell>
                    {/* <TableCell>Ação</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {discharges[0]?.discharges
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <>
                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={1} style={{ paddingVertical: 5 }}>
                          <TableCell>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => setOpen(!open)}
                            >
                              <Icon>{open ? "expand_less" : "expand_more"}</Icon>
                            </IconButton>
                          </TableCell>
                          <TableCell className="px-0" align="left">{discharges[0]?.date}</TableCell>
                          <TableCell className="px-0" align="left">{"AAABBB"}</TableCell>
                          <TableCell className="px-0" align="left">{discharges[0]?.firstInvoice}</TableCell>
                          <TableCell className="px-0" align="left">{discharges[0]?.hourFirstInvoice}</TableCell>
                          <TableCell className="px-0" align="left">{discharges[0]?.qntInvoices}</TableCell>
                          <TableCell className="px-0" align="left">{discharges[0]?.qntDischarges}</TableCell>
                          <TableCell className="px-0" align="left">{discharges[0]?.qntInconsistency}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                              <Box sx={{ margin: 1 }}>
                                <Grid container spacing={5}>
                                  <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Typography variant="h6" gutterBottom align="center" component="div" style={{ fontSize: 14, backgroundColor: "#cad1d5" }}>
                                      Notas
                                    </Typography>
                                    <Table size="small">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell style={{ fontSize: 12 }}>Nota</TableCell>
                                          <TableCell style={{ fontSize: 12 }}>Data/hora</TableCell>
                                          <TableCell style={{ fontSize: 12 }} align="right">Obra</TableCell>
                                          <TableCell style={{ fontSize: 12 }} align="right">Motorista</TableCell>
                                          <TableCell style={{ fontSize: 12 }} align="right">Conteúdo</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {
                                          discharges[0]?.tickets?.map((item_nota, key_nota) => {

                                            return (
                                              <TableRow key={key_nota}>
                                                <TableCell style={{ fontSize: 10 }}>
                                                  {item_nota?.ticketNumber}
                                                </TableCell>
                                                <TableCell style={{ fontSize: 10 }}>{item_nota?.createdTicket}</TableCell>
                                                <TableCell style={{ fontSize: 10 }} align="right">{item_nota?.order?.deliveryLocation?.street}</TableCell>
                                                <TableCell style={{ fontSize: 10 }} align="right">{item_nota?.driver?.name}</TableCell>
                                                <TableCell style={{ fontSize: 10 }} align="right">{item_nota?.order?.product?.name}</TableCell>
                                              </TableRow>
                                            )

                                          })
                                        }

                                      </TableBody>
                                    </Table>
                                  </Grid>
                                  <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Typography variant="h6" gutterBottom align="center" component="div" style={{ fontSize: 14, backgroundColor: "#cad1d5" }}>
                                      Descargas
                                    </Typography>
                                    <Table size="small">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell style={{ fontSize: 12 }}>Data evento</TableCell>
                                          <TableCell style={{ fontSize: 12 }} align="right">Endereço</TableCell>
                                          <TableCell style={{ fontSize: 12 }} align="right">Ação</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {
                                          discharges[0]?.discharges?.map((item_descarga, key_descarga) => {

                                            return (
                                              <TableRow key={key_descarga}>
                                                <TableCell style={{ fontSize: 10 }}>{moment(item_descarga?.dateTimeAction).format("D/M/Y")}</TableCell>
                                                <TableCell style={{ fontSize: 10 }} align="right">{item_descarga?.address}</TableCell>
                                                <TableCell style={{ fontSize: 10 }} align="right">
                                                  <IconButton
                                                    onClick={() => handleClickOpen({ lat: item_descarga?.latitude, lng: item_descarga?.longitude })}
                                                  >
                                                    <Icon style={{ fontSize: 15 }} color="primary">public</Icon>
                                                  </IconButton>
                                                </TableCell>
                                              </TableRow>
                                            )

                                          })
                                        }
                                      </TableBody>
                                    </Table>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}


                </TableBody>
              </Table>

              <TablePagination
                className="px-16"
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={discharge[0]?.discharges?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage="Linhas por Página"
                backIconButtonProps={{
                  "aria-label": "Anterior"
                }}
                nextIconButtonProps={{
                  "aria-label": "Próxima"
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={setRowsPerPageFunction}
              />
            </Card>
          </>
          :
          <Card className="w-100 overflow-auto" elevation={6} style={{ padding: 50 }}>
            {
              loading ?
                <CircularProgress />
                :
                <>
                  <h4 align="center">Sem resultados</h4>
                  <h6 align="center">(Selecione os parâmetros)</h6>
                </>
            }
          </Card>
      }

    </div >
  );
}

const mapStateToProps = state => ({
  userRedux: state.user,
  branchs: state.branch.branchs,
  discharge: state.discharge.discharges,
  productTypes: state.product.productTypes
});

export default connect(mapStateToProps)(CrudTable);
