import history from "history.js";
import jwtAuthService from "../../services/jwtAuthService";
import axios from "axios";


export const LIST_OPERATOR_LOADING = "LIST_OPERATOR_LOADING";
export const LIST_OPERATOR_SUCCESS = "LIST_OPERATOR_SUCCESS";
export const LIST_OPERATOR_FAILED = "LIST_OPERATOR_FAILED";
export const UPDATE_OPERATOR_LOADING = "UPDATE_OPERATOR_LOADING";
export const UPDATE_OPERATOR_SUCCESS = "UPDATE_OPERATOR_SUCCESS";
export const UPDATE_OPERATOR_FAILED = "UPDATE_OPERATOR_FAILED";
export const DELETE_OPERATOR_LOADING = "DELETE_OPERATOR_LOADING";
export const DELETE_OPERATOR_SUCCESS = "DELETE_OPERATOR_SUCCESS";
export const DELETE_OPERATOR_FAILED = "DELETE_OPERATOR_FAILED";
export const CREATE_OPERATOR_LOADING = "CREATE_OPERATOR_LOADING";
export const CREATE_OPERATOR_SUCCESS = "CREATE_OPERATOR_SUCCESS";
export const CREATE_OPERATOR_FAILED = "CREATE_OPERATOR_FAILED";

export const CLEAR = "CLEAR";

export function listOperator(token, company) {
  return dispatch => {
    dispatch({
      type: LIST_OPERATOR_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/company/${company}/operator`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_OPERATOR_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_OPERATOR_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function createOperator(token, data, company) {
  return dispatch => {
    dispatch({
      type: CREATE_OPERATOR_LOADING,
    });
    axios({
      method: 'post',
      url: `https://api.stonik.com.br/web/company/${company}/operator`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: CREATE_OPERATOR_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: CREATE_OPERATOR_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}
export function updateOperator(token, data, company) {
  return dispatch => {
    dispatch({
      type: UPDATE_OPERATOR_LOADING,
    });
    axios({
      method: 'put',
      url: `https://api.stonik.com.br/web/company/${company}/operator/${data.id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: UPDATE_OPERATOR_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: UPDATE_OPERATOR_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function deleteOperator(token, id, company) {
  return dispatch => {
    dispatch({
      type: DELETE_OPERATOR_LOADING,
    });
    axios({
      method: 'delete',
      url: `https://api.stonik.com.br/web/company/${company}/operator/${id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: DELETE_OPERATOR_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: DELETE_OPERATOR_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function clear() {
  return dispatch => {
    dispatch({
      type: CLEAR
    });
  };
}
