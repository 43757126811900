import UserTable from "./ProductionByProductReports";

const ProductionByProductReports = [
  {
    path: "/producao-por-produto",
    exact: true,
    component: UserTable
  }
];

export default ProductionByProductReports;
