import React, { Component, useState, useEffect } from "react";
import { Dialog, IconButton, Button, Icon, Grid } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { addNewEvent, updateEvent, deleteEvent } from "./CalendarService";
import ptBR from "date-fns/locale/pt-BR"; // the locale you want
import { listProducts } from "../../redux/actions/ProductActions"
import { connect } from "react-redux";
import { listApplication } from '../../redux/actions/ApplicationActions'
import { listState, listCounty } from '../../redux/actions/StateActions'
import axios from "axios"
import { listContracts } from '../../redux/actions/ContractActions'
import { updateOrder, clear } from '../../redux/actions/OrderActions'
import Swal from 'sweetalert2'
import InputMask from "react-input-mask";
import moment from "moment"
import {Link} from "react-router-dom"
const EventEditorDialog = (props) => {

  const { open, handleClose, event, userRedux, products, contracts, loading, applications, states, countys, error, success } = props;


  const [title, setTitle] = useState(event?.title);
  const [dateDelivery, setDateDelivery] = useState(new Date(event?.start).toLocaleDateString()?.split("/")[2] + "-" + new Date(event?.start).toLocaleDateString()?.split("/")[1] + "-" + new Date(event?.start).toLocaleDateString()?.split("/")[0] + "T" + new Date(event?.start).toLocaleTimeString());
  const [dateDeliveryEdit, setDateDeliveryEdit] = useState("");
  const [client, setClient] = useState(event?.client);
  const [meterage, setMeterage] = useState(event?.meterage);
  const [contract, setContract] = useState(event?.contract);
  const [pumpServiceRequired, setPumpServiceRequired] = useState(event?.pumpServiceRequired);
  const [application, setApplication] = useState(event?.application);
  const [product, setProduct] = useState(event?.product?.id);
  const [cep, setCep] = useState(event?.deliveryLocation?.cep);
  const [rua, setRua] = useState(event?.deliveryLocation?.street);
  const [number, setNumber] = useState(event?.deliveryLocation?.number);
  const [complemento, setComplemento] = useState(event?.deliveryLocation?.complement);
  const [bairro, setBairro] = useState(event?.deliveryLocation?.neighborhood);
  const [estado, setEstado] = useState(event?.deliveryLocation?.city?.state?.id);
  const [estadoUf, setEstadoUf] = useState(event?.deliveryLocation?.city?.state?.unit);
  const [cidade, setCidade] = useState(event?.deliveryLocation?.city?.id);
  const [telefone, setTelefone] = useState(event?.telefone);
  const [email, setEmail] = useState(event?.email);
  const [contrato, setContrato] = useState(event?.contrato);
  const [totalValue, setTotalValue] = useState(event?.totalValue);
  const [message, setMessage] = useState("")

  const buscarCep = (text) => {

    var textReplaced = text.replace("-", "")

    axios({
      method: 'get',
      url: `https://viacep.com.br/ws/${textReplaced}/json/`,
      headers: {
        "Content-Type": "application/json"
      }
    }).then(res => {
      var data = res.data
      setRua(`${data.logradouro} ${data.bairro}`)
      setEstado(data.id)
      setEstadoUf(data.uf)
      setCidade(data.ibge)
      var find = states.find(res => res.unit == data.uf)
      // setSelectedEstadoItem(find)
      console.log("data", data)
    }).catch(eer => {
      console.log("err", eer)
    })
  }

  useEffect(() => {
    console.log("cep", cep)
    if (cep.length == 8) {
      buscarCep(cep);
    }
  }, [cep])

  useEffect(() => {
    console.log("contrato", contrato)
    if (contrato != null) {
      // props.dispatch(listContracts(userRedux?.token, userRedux?.userId, userRedux?.company))

    }
  }, [contrato])

  useEffect(() => {
    console.log("dateDelivery", dateDelivery)
  }, [dateDelivery])

  useEffect(() => {
    console.log("success", success)
    if (success != false) {
      handleClose()
      Swal.fire({
        icon: 'success',
        title: success,
        text: "Aguardando emissão do ticket",
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(() => {
        props.dispatch(clear())
        goTo("/gestao-de-agendamento")
      }, 3500)
    }
  }, [success])

  const goTo = (link) => {
    return (
      <Link to={link} />
    )
  }

  useEffect(() => {
    console.log("error", error)
    if (error != null) {
      Swal.fire({
        icon: 'error',
        title: error,
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(() => {
        props.dispatch(clear())
      }, 3500)
    }
  }, [error])

  const handleFormSubmit = () => {
    if (product == "") {
      setMessage("Selecione o produto!")
    } else if (meterage == "") {
      setMessage("Preencha a metragem!")
    } else if (cep == "") {
      setMessage("Preencha o cep do cliente!")
    } else if (cep?.length < 8 || cep?.length > 8) {
      setMessage("O CEP deve conter somente 8 dígitos!")
    } else if (bairro == "") {
      setMessage("Preencha o bairro do cliente!")
    } else if (totalValue == "") {
      setMessage("Preencha o valor total do produto!")
    } else if (application == "") {
      setMessage("Preencha o tipo de aplicação do produto!")
    } else if (number == "") {
      setMessage("Preencha o número do endereço do cliente!")
    } else {
      props.dispatch(updateOrder(userRedux?.token, userRedux?.company, event?.id, {
        product: {
          id: product
        },
        application: {
          id: application
        },
        valueTotal: totalValue,
        dateDelivery: dateDeliveryEdit == "" ? moment(dateDelivery).format("DD/MM/YYYY HH:mm") : moment(dateDeliveryEdit).format("DD/MM/YYYY HH:mm"),
        meterage: meterage,
        pumpServiceRequired: pumpServiceRequired,
        deliveryLocation: {
          street: rua,
          number,
          complement: complemento,
          neighborhood: bairro,
          city: {
            id: cidade
          },
          cep: cep
        },
        contract
      }))
    }

  };

  const handleDateChange = (date) => {
    setDateDelivery(date)
  };

  useEffect(() => {
    props.dispatch(listProducts(userRedux?.token, userRedux?.company, null))
    props.dispatch(listApplication(userRedux?.token))
    props.dispatch(listState(userRedux?.token))
  }, [])

  useEffect(() => {
    console.log("estadoUf", estadoUf)
    if (estadoUf != null) {
      props.dispatch(listCounty(userRedux?.token, estadoUf));
    }
  }, [estadoUf])

  useEffect(() => {
    if (countys != null) {
      console.log("countys", countys)
      console.log("cidade", cidade)
    }
  }, [countys])


  return (
    <Dialog onClose={handleClose} open={open} maxWidth="xs" fullWidth={true}>
      <div className="flex flex-space-between flex-middle pl-16 pr-8 py-8 bg-primary">
        <h4 className="m-0 text-white">Alterar Pedido</h4>
        <IconButton onClick={handleClose}>
          <Icon className="text-white">clear</Icon>
        </IconButton>
      </div>

      <div className="p-16">
        <ValidatorForm onSubmit={handleFormSubmit}>
          <TextValidator
            className="mb-24 w-100"
            label="Pedido"
            disabled="disabled"
            type="text"
            name="title"
            value={title}
          />
          <TextValidator
            className="mb-24 w-100"
            label="Telefone"
            onChange={(event) => setTelefone(event.target.value)}
            type="text"
            name="telefone"
            disabled="disabled"
            value={telefone}
            validators={["required"]}
            errorMessages={["Essa coluna é obrigatória"]}
          />
          <TextValidator
            className="mb-24 w-100"
            label="E-mail"
            onChange={(event) => setEmail(event.target.value)}
            type="text"
            name="email"
            disabled="disabled"
            value={email}
            validators={["required"]}
            errorMessages={["Essa coluna é obrigatória"]}
          />
          {
            dateDelivery != null &&
            <>
              <label className="mb-4">Data de Entrega</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="dateDelivery"
                onChange={
                  (event) => { setDateDeliveryEdit(event.target.value) }}
                type="datetime-local"
                name="dateDelivery"
                value={dateDeliveryEdit == "" ? dateDelivery : dateDeliveryEdit}
              />
            </>
          }
          {/* <TextValidator
            className="mb-24 w-100"
            label="Nome"
            onChange={(event) => setClient(event.target.value)}
            type="text"
            name="client"
            value={client}
            disabled={"disabled"}
            validators={["required"]}
            errorMessages={["Essa coluna é obrigatória"]}
          /> */}
          <TextValidator
            className="mb-24 w-100"
            label="Metragem"
            onChange={(event) => setMeterage(event.target.value)}
            type="text"
            name="meterage"
            value={meterage}
            validators={["required"]}
            errorMessages={["Essa coluna é obrigatória"]}
          />
          <label style={{ fontSize: 11, color: "gray" }}>Serviço de Bombeamento</label>
          <select className="mb-24 w-100" style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0 }} onChange={(event) => { setPumpServiceRequired(event.target.value) }}>
            <option selected={pumpServiceRequired} value={true}>Sim</option>
            <option selected={!pumpServiceRequired} value={false}>Não</option>
          </select>
          <label style={{ fontSize: 11, color: "gray" }}>Tipo de Produto</label>
          <select className="mb-24 w-100" style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0 }} onChange={(event) => { setProduct(event.target.value) }}>
            <option value=""></option>
            {
              products?.map(item =>
                <option selected={product == item?.id} value={item?.id}>{item?.name}</option>
              )
            }
          </select>
          <label style={{ fontSize: 11, color: "gray" }}>Tipo de Aplicação</label>
          <select className="mb-24 w-100" style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0 }} onChange={(event) => { setApplication(event.target.value) }}>
            <option value=""></option>
            {
              applications?.map(item =>
                <option selected={application == item?.id} value={item?.id}>{item?.name}</option>
              )
            }
          </select>
          <TextValidator
            className="mb-24 w-100"
            label="Valor Total"
            onChange={(event) => setTotalValue(event.target.value)}
            type="text"
            name="totalValue"
            value={totalValue}
            validators={["required"]}
            errorMessages={["Essa coluna é obrigatória"]}
          />
          <TextValidator
            className="mb-24 w-100"
            label="CEP"
            onChange={(event) => setCep(event.target.value)}
            type="text"
            name="cep"
            value={cep}
            validators={["required"]}
            errorMessages={["Essa coluna é obrigatória"]}
          />
          <TextValidator
            className="mb-24 w-100"
            label="Rua"
            onChange={(event) => setRua(event.target.value)}
            type="text"
            name="rua"
            value={rua}
            validators={["required"]}
            errorMessages={["Essa coluna é obrigatória"]}
          />
          <label className="mb-4">Bairro</label>
          <InputMask
            className="w-100 mb-16"
            style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
            label="bairro"
            onChange={
              (event) => { setBairro(event.target.value) }}
            type="text"
            name="bairro"
            value={bairro}
          />
          <TextValidator
            className="mb-24 w-100"
            label="Número"
            onChange={(event) => setNumber(event.target.value)}
            type="text"
            name="number"
            value={number}
            validators={["required"]}
            errorMessages={["Essa coluna é obrigatória"]}
          />
          <TextValidator
            className="mb-24 w-100"
            label="Complemento"
            onChange={(event) => setComplemento(event.target.value)}
            type="text"
            name="complemento"
            value={complemento}
          />
          <label style={{ fontSize: 11, color: "gray" }}>Estado</label>
          <select disabled={true} className="mb-24 w-100" style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0 }} onChange={(event) => {
            setEstadoUf(event.target.value)
          }}>
            <option value=""></option>
            {
              states?.map(item =>
                <option selected={estadoUf == item?.unit} value={item?.unit}>{item?.description}</option>
              )
            }
          </select>

          <label style={{ fontSize: 11, color: "gray" }}>Cidade</label>
          <select disabled={true} className="mb-24 w-100" style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0 }} onChange={(event) => { setCidade(event.target.value) }}>
            <option value=""></option>
            {
              countys?.map(item =>
                <option selected={cidade == item?.id} value={item?.id}>{item?.name}</option>
              )
            }
          </select>
          {/* <label style={{ fontSize: 11, color: "gray" }}>Contrato</label> */}
          {/* {
            contrato != null ? <select className="mb-24 w-100" style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0 }} onChange={(event) => { setContrato(event.target.value) }}>
              <option value={true}>Sim</option>
              <option value={false}>Não</option>
            </select>
              :
             <p>Não se Aplica</p>
          } */}

          <div className="py-8" />
          {/* <div className="py-8" />
            

            <TextValidator
              className="mb-36 w-100"
              label="Note"
              onChange={this.handleChange}
              type="text"
              name="note"
              value={note}
              rowsMax={2}
              multiline={true}
              validators={["required"]}
              errorMessages={["this field is required"]}
            /> */}

          {
            message != "" && <div style={{ backgroundColor: "rgba(255,0,0,.2)", padding: 5, marginBottom: 10 }}><Icon style={{ color: "red", marginRight: 5, marginLeft: 5 }} fontSize="small">close</Icon><span style={{ color: "red", fontWeight: "bold" }}>{message}</span></div>
          }
          <div className="flex flex-space-between flex-middle">
            <Button variant="contained" color="primary" type="submit">
              {
                loading ? "Carregando..." : "Editar"
              }
            </Button>
            {/* <Button onClick={this.handleDeleteEvent}>
                <Icon className="mr-8 text-middle">delete</Icon>
                Delete
              </Button> */}
          </div>
        </ValidatorForm>
      </div>
    </Dialog>
  );
}

const mapStateToProps = state => ({
  userRedux: state.user,
  products: state.product.products,
  contracts: state.contract.contracts,
  success: state.orders.success,
  loading: state.orders.loading,
  error: state.orders.error,
  countys: state.state.countys,
  applications: state.application.applications,
  states: state.state.states,
});

export default connect(mapStateToProps)(EventEditorDialog);
