/* eslint-disable */
import React, { Component, useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Button,
  Grid,
  Icon,
  IconButton,
  Card,
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { getAllUser, deleteUser } from "./TableService";
import { Breadcrumb } from "egret";
import shortid from "shortid";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment"
import ptLocale from "date-fns/locale/pt-BR";
import { MyDocument } from "./pdf"
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReactExport from "react-export-excel";
import { connect } from "react-redux";
import IntegrationReactSelect from "./ReactSelect"
import IntegrationReactSelectUser from "./ReactSelectUser"
import { listCompanys } from "../../redux/actions/CompanyActions"
import { listUserCompany } from "../../redux/actions/ModuleActions"
import { listAuditoriaReports } from "../../redux/actions/ReportsActions"
import Swal from 'sweetalert2'
import MemberEditorDialog from "./MemberEditorDialog";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const dataSet1 = [
  {
    inicial: "02/09/2021",
    final: '02/09/2021',
    usuario: "Bruno",
    acao: "Salvar",
  },
  {
    inicial: "02/09/2021",
    final: '02/09/2021',
    usuario: "Bruno",
    acao: "Salvar",
  },
  {
    inicial: "02/09/2021",
    final: '02/09/2021',
    usuario: "Bruno",
    acao: "Salvar",
  },
];

const CrudTable = props => {

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [date, setDate] = useState(new Date())
  const [companiesState, setCompanies] = useState([])
  const [userCompany, setUserCompany] = useState([])
  const [companySelected, setCompanySelected] = useState(null)
  const [distancia, setDistancia] = useState(0)
  const [volume, setVolume] = useState("")
  const [finalDate, setFinalDate] = useState(new Date())
  const [userList, setUserList] = useState([])
  const [user, setUser] = useState(null)
  const [uid, setUid] = useState(null)
  const [dataSet, setDataSet] = useState([])
  const [shouldOpenEditorDialog, setShouldOpenEditorDialog] = useState(false)

  const [shouldOpenVisualizationDialog, setShouldOpenVisualizationDialog] = useState(false)
  const [shouldOpenConfirmationDialog, setShouldOpenConfirmationDialog] = useState(false)

  const { userRedux, companies, user_company, auditoria } = props

  const setPageFunction = page => {
    setPage(page)
  };

  const setRowsPerPageFunction = event => {
    setRowsPerPage(event.target.value)
  };

  const handleChangePage = (event, newPage) => {
    setPageFunction(newPage);
  };

  const handleDialogClose = () => {
    setShouldOpenEditorDialog(false)
    setShouldOpenConfirmationDialog(false)
    updatePageData();
  };

  const handleDeleteUser = user => {
    setShouldOpenConfirmationDialog(true)
  };

  const handleConfirmationResponse = () => {

  };

  useEffect(() => {
    props.dispatch(listCompanys(userRedux?.token))
  }, [])

  useEffect(() => {
    if (companies != null) {
      var newArray = []
      companies.map(item => {
        newArray.push({ label: item?.name, value: item?.id })
      })
      setCompanies(newArray)
    }
  }, [companies])
  useEffect(() => {
    if (user_company != null) {
      console.log("user_company", user_company)
      var newArray = []
      user_company.map(item => {
        newArray.push({ label: item?.name, value: item?.id })
      })
      setUserCompany(newArray)
    }
  }, [user_company])

  const updatePageData = () => {
    getAllUser().then(({ data }) => setUserList([...data]));
  };

  const handleDateChange = date => {
    console.log("date", date)
    setDate(date)
  };

  const handleFinalDateChange = finalDate => {
    setFinalDate(finalDate)
  };

  const handleValue = (value) => {
    setCompanySelected(value)
    props.dispatch(listUserCompany(userRedux?.token, value?.value))
  }

  const handleDialogCloseFunction = () => {
    setShouldOpenEditorDialog(false)
    setShouldOpenConfirmationDialog(false)
  };

  const createObjectDataSet = (auditoria) => {
    var newObj = []
    auditoria?.map(item => {
      newObj.push({
        data: moment(item?.dateTime).format("MM/DD/YYYY HH:mm"),
        usuario: user?.label,
        acao: item?.action
      })
      return item;
    })
    console.log("newOjt", newObj)
    setDataSet(newObj)
  }

  useEffect(() => {
    console.log('auditoria', auditoria)
    createObjectDataSet(auditoria)
  }, [auditoria])

  const handleSubmit = () => {
    if (companySelected == null) {
      Swal.fire({
        icon: 'error',
        title: "Selecione a filial",
        showConfirmButton: false,
        timer: 3000
      })
    } else if (user == null) {
      Swal.fire({
        icon: 'error',
        title: "Selecione o usuário",
        showConfirmButton: false,
        timer: 3000
      })
    } else if (date == "") {
      Swal.fire({
        icon: 'error',
        title: "Selecione a data inicial",
        showConfirmButton: false,
        timer: 3000
      })
    } else if (finalDate == "") {
      Swal.fire({
        icon: 'error',
        title: "Selecione a data final",
        showConfirmButton: false,
        timer: 3000
      })
    } else {
      console.log(user, companySelected)
      props.dispatch(listAuditoriaReports(userRedux?.token, user?.value, companySelected?.value, moment(date).format("DD/MM/YYYY"), moment(finalDate).format("DD/MM/YYYY")))
    }

  }

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: "Controle de auditoria" }]} />
      </div>
      <Grid container spacing={5}>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <IntegrationReactSelect company={companiesState} handleValue={(value) => { handleValue(value) }} />
        </Grid>
        {
          companySelected != null && <Grid item lg={3} md={3} sm={12} xs={12}>
            <IntegrationReactSelectUser userCompany={userCompany} handleValue={(value) => { setUser(value) }} />
          </Grid>
        }

        <Grid item lg={2} md={2} sm={12} xs={12} style={{ marginTop: 6 }}>
          <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="mb-16 w-100"
              margin="none"
              id="mui-pickers-date"
              label="Data Início"
              format="dd/MM/yyyy"
              inputVariant="standard"
              type="text"
              autoOk={true}
              value={date}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item lg={2} md={2} sm={12} xs={12} style={{ marginTop: 6 }}>
          <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="mb-16 w-100"
              margin="none"
              id="mui-pickers-date"
              format="dd/MM/yyyy"
              label="Data Fim"
              inputVariant="standard"
              type="text"
              autoOk={true}
              value={finalDate}
              onChange={handleFinalDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item lg={2} md={2} sm={12} xs={12}>
          <Button color="primary" variant="contained" type="button" onClick={() => { handleSubmit() }} style={{ marginTop: 10 }}>
            <Icon>filter_list</Icon>
            <span className="pl-8 capitalize">Filtrar</span>
          </Button>
        </Grid>
      </Grid>
      {
        auditoria?.length > 0 ?
          <>
            <ExcelFile filename="relatorio-controle-auditoria" element={<Button style={{ backgroundColor: "#f78726", color: "white", marginBottom: 10, marginRight: 5 }} aria-label="Add">Exportar Excel</Button>}>
              <ExcelSheet data={dataSet} name="Employees">
                <ExcelColumn label="Data" value="data" />
                <ExcelColumn label="Usuário" value="usuario" />
                <ExcelColumn label="Ação" value="acao" />
              </ExcelSheet>
            </ExcelFile>

            <PDFDownloadLink document={<MyDocument data={dataSet}/>} fileName="relatório-controle-auditoria.pdf">
              <Button style={{ backgroundColor: "transparent", border: "1.5px solid #f78726", color: "#f78726", marginBottom: 10 }} aria-label="Add">Exportar Pdf</Button>
            </PDFDownloadLink>
            <Card className="w-100 overflow-auto" elevation={6}>
              <h6 style={{ textAlign: "center", marginRight: 10, fontSize: 10, marginTop: 10 }}>Data de emissão: {moment().format("D/M/Y")}</h6>
              <h6 style={{ textAlign: "center", marginRight: 10, fontSize: 10, }}>Usuário: {userRedux?.displayName}</h6>
              <h4 style={{ textAlign: "center", marginRight: 10 }}>Controle de auditoria</h4>

              <Table className="crud-table" style={{ whiteSpace: "pre", minWidth: "750px" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Data</TableCell>
                    <TableCell>Usuario</TableCell>
                    <TableCell>Ação</TableCell>
                    <TableCell>Detalhes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {auditoria
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <TableRow key={shortid.generate()} style={{ paddingVertical: 5 }}>
                        <TableCell className="px-0" align="left">{moment(item?.dateTime).format("MM/DD/YYYY HH:mm")}</TableCell>
                        <TableCell className="px-0" align="left">{user?.label}</TableCell>
                        <TableCell className="px-0" align="left">{item?.action}</TableCell>
                        {/* <TableCell className="px-0 border-none" align="center">
                          <IconButton
                            onClick={() => {
                              setUid(item)
                              setShouldOpenEditorDialog(true)
                            }}
                          >
                            <Icon color="primary">visibility</Icon>
                          </IconButton> */}
                          {/* <IconButton onClick={() => handleDeleteUser(item)}>
                      <Icon color="error">remove_red_eye</Icon>
                    </IconButton> */}
                        {/* </TableCell> */}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>

              <TablePagination
                className="px-16"
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={userList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage="Linhas por Página"
                backIconButtonProps={{
                  "aria-label": "Anterior"
                }}
                nextIconButtonProps={{
                  "aria-label": "Próxima"
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={setRowsPerPageFunction}
              />
             {shouldOpenEditorDialog && (
          <MemberEditorDialog
            handleClose={handleDialogCloseFunction}
            open={shouldOpenEditorDialog}
            item={uid}
          />
        )}
            </Card>
          </>
          :
          <Card className="w-100 overflow-auto" elevation={6} style={{ padding: 50 }}>
            <h4 align="center">Sem resultados</h4>
            <h6 align="center">(Selecione os parâmetros)</h6>
          </Card>
      }

    </div >
  );
}

const mapStateToProps = state => ({
  userRedux: state.user,
  companies: state.company.companies,
  user_company: state.module.user_company,
  auditoria: state.reports.auditoria,
});

export default connect(mapStateToProps)(CrudTable);
