import React, { Component, useState, useEffect } from "react";
import {
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Icon,
  TablePagination,
  Button,
  Card
} from "@material-ui/core";
import { getAllUser, deleteUser } from "./TableService";
import MemberEditorDialog from "./MemberEditorDialog";
import { Breadcrumb, ConfirmationDialog } from "egret";
import shortid from "shortid";
import { listRaioFilial, clear } from "../../redux/actions/CompanyActions"
import { connect } from "react-redux";
import Swal from 'sweetalert2'

const CrudTable = props => {

  const {
    userRedux,
    salesmans,
    loading,
    success,
    goalsVehicles,
    raioFiliais,
    error
  } = props

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [uid, setUid] = useState(null)
  const [salesmanList, setSalesmanList] = useState([])
  const [userList, setUserList] = useState([])
  const [shouldOpenEditorDialog, setShouldOpenEditorDialog] = useState(false)
  const [shouldOpenConfirmationDialog, setShouldOpenConfirmationDialog] = useState(false)

  const setPageFunction = page => {
    setPage(page);
  };

  const setRowsPerPageFunction = event => {
    setRowsPerPage(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPageFunction(newPage);
  };

  const handleDialogCloseFunction = () => {
    setShouldOpenEditorDialog(false)
    setShouldOpenConfirmationDialog(false)
  };

  const handleDeleteUser = item => {
    setShouldOpenConfirmationDialog(true)
    setUid(item)
  };

  useEffect(() => {
    props.dispatch(listRaioFilial(userRedux?.token, userRedux?.company))
  }, [])

  useEffect(() => {
    console.log("raioFiliais", raioFiliais)
  }, [raioFiliais])

  useEffect(() => {
    if (success != false) {
      setShouldOpenConfirmationDialog(false)
      Swal.fire({
        icon: 'success',
        title: success,
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(() => {
        props.dispatch(clear())
        window.location.reload()
      }, 3500)
    }
  }, [success])

  useEffect(() => {
    if (error != null) {
      console.log("Error", error)
      setShouldOpenConfirmationDialog(false)

      Swal.fire({
        icon: 'error',
        title: error,
        showConfirmButton: false,
        timer: 3000
      })

      setTimeout(() => {
        props.dispatch(clear())
        setShouldOpenEditorDialog(true)
        // window.location.reload()
      }, 3500)
    }
  }, [error])

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: "Km Do Raio Da Unidade" }]} />
      </div>

      {/* <Button
        className="mb-16"
        variant="contained"
        color="primary"
        onClick={() => setShouldOpenEditorDialog(true)}
      >
        <Icon>add-circle</Icon>
        Adicionar registro
      </Button> */}
      <Card className="w-100 overflow-auto" elevation={6}>
        <Table className="crud-table" style={{ whiteSpace: "pre", minWidth: "750px" }}>
          <TableHead>
            <TableRow>
              <TableCell>Unidade</TableCell>
              <TableCell>Raio</TableCell>
              <TableCell align="center">Ação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {raioFiliais
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((item, index) => (
                <TableRow key={shortid.generate()}>
                  <TableCell className="px-0" align="left">
                    {item?.branch?.name}
                  </TableCell>
                  <TableCell className="px-0" align="left">
                    {item?.raio}
                  </TableCell>
                  <TableCell className="px-0 border-none" align="center">
                    <IconButton
                      onClick={() => {
                        setUid(item)
                        setShouldOpenEditorDialog(true)
                      }}
                    >
                      <Icon color="primary">edit</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        <TablePagination
          className="px-16"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={userList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Linhas por Página"
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={setRowsPerPage}
        />

        {shouldOpenEditorDialog && (
          <MemberEditorDialog
            handleClose={handleDialogCloseFunction}
            open={shouldOpenEditorDialog}
            uid={uid}
          />
        )}
      </Card>
    </div>
  );
}

const mapStateToProps = state => ({
  userRedux: state.user,
  branchs: state.branch.branchs,
  goalsVehicles: state.fleet.goalsVehicles,
  raioFiliais: state.company.raioFiliais,
  success: state.company.success,
  error: state.company.error
});

export default connect(mapStateToProps)(CrudTable);
