import CrudTable from "./MyWashes";

const MyWashes = [
  {
    path: "/minhas-lavagens",
    exact: true,
    component: CrudTable
  }
];

export default MyWashes;
