import UserTable from "./InconsistenciesReports";

const InconsistenciesReports = [
  {
    path: "/inconsistencias",
    exact: true,
    component: UserTable
  }
];

export default InconsistenciesReports;
