import React, { Component, useState, useEffect } from "react";
import {
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Icon,
  TablePagination,
  Button,
  Card
} from "@material-ui/core";
import { getAllUser, deleteUser } from "./TableService";
import MemberEditorDialog from "./MemberEditorDialog";
import { Breadcrumb, ConfirmationDialog } from "egret";
import shortid from "shortid";
import { listCompanys, deleteCompany, clear } from "../../redux/actions/CompanyActions"
import set from "date-fns/esm/fp/set/index";
import { connect } from "react-redux";
import Swal from 'sweetalert2'

const CrudTable = props => {
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [date, setDate] = useState(new Date())
  const [distancia, setDistancia] = useState(0)
  const [volume, setVolume] = useState("")
  const [finalDate, setFinalDate] = useState(new Date())
  const [userList, setUserList] = useState([])
  const [shouldOpenEditorDialog
    , setShouldOpenEditorDialog] = useState(false)
  const [shouldOpenConfirmationDialog, setShouldOpenConfirmationDialog] = useState(false)
  const [uid, setUid] = useState(null)
  const [companiesState, setCompanies] = useState([])

  const { userRedux, companies, success, error } = props

  const setPageFunction = page => {
    setPage(page)
  };

  const setRowsPerPageFunction = event => {
    setRowsPerPage(event.target.value)
  };

  const handleChangePage = (event, newPage) => {
    setPageFunction(newPage);
  };

  const handleDialogClose = () => {
    setShouldOpenEditorDialog(false)
    setShouldOpenConfirmationDialog(false)
    updatePageData();
  };

  const handleDeleteUser = item => {
    setShouldOpenConfirmationDialog(true)
    setUid(item.id)
  };

  const handleConfirmationResponse = () => {
    props.dispatch(deleteCompany(userRedux?.token, uid))
    handleDialogClose();
  };

  useEffect(() => {
    props.dispatch(listCompanys(userRedux?.token))
  }, [])

  useEffect(() => {
    console.log("companies", companies)
    if (companies != null) {
      setCompanies(companies)
    }
  }, [companies])

  useEffect(() => {
    if (success != false) {
      setShouldOpenConfirmationDialog(false)
      Swal.fire({
        icon: 'success',
        title: success,
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(() => {
        props.dispatch(clear())
        props.dispatch(listCompanys(userRedux?.token))
      }, 3500)
    }
  }, [success])

  useEffect(() => {
    if (error != null) {
      setShouldOpenConfirmationDialog(false)

      Swal.fire({
        icon: 'error',
        title: error,
        showConfirmButton: false,
        timer: 3000
      })

      setTimeout(() => {
        props.dispatch(clear())
      }, 3500)
    }
  }, [error])

  const updatePageData = () => {
    getAllUser().then(({ data }) => setUserList([...data]));
  };

  const handleDateChange = date => {
    console.log("date", date)
    setDate(date)
  };

  const handleFinalDateChange = finalDate => {
    setFinalDate(finalDate)
  };

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: "Cadastrar Empresas" }]} />
      </div>

      <Button
        className="mb-16"
        variant="contained"
        color="primary"
        onClick={() => {
          setUid(null)
          setShouldOpenEditorDialog(true)
        }}
      >
        <Icon>add-circle</Icon>
        Adicionar registro
      </Button>
      <Card className="w-100 overflow-auto" elevation={6}>
        <Table className="crud-table" style={{ whiteSpace: "pre", minWidth: "750px" }}>
          <TableHead>
            <TableRow>
              <TableCell>Razão social</TableCell>
              <TableCell>Cnpj</TableCell>
              <TableCell>Telefone</TableCell>
              <TableCell align="center">Ação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companiesState
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => (
                <TableRow key={shortid.generate()}>
                  <TableCell className="px-0" align="left">
                    {item?.nameCorporate}
                  </TableCell>
                  <TableCell className="px-0" align="left">
                    {item?.document}
                  </TableCell>
                  <TableCell className="px-0" align="left">{item?.contact1}</TableCell>
                  <TableCell className="px-0 border-none" align="center">
                    <IconButton
                      onClick={() => {
                        setUid(item)
                        setShouldOpenEditorDialog(true)
                      }}
                    >
                      <Icon color="primary">edit</Icon>
                    </IconButton>
                    <IconButton onClick={() => handleDeleteUser(item)}>
                      <Icon color="error">delete</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        <TablePagination
          className="px-16"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={companiesState.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Linhas por Página"
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={setRowsPerPage}
        />

        {shouldOpenEditorDialog && (
          <MemberEditorDialog
            handleClose={handleDialogClose}
            open={shouldOpenEditorDialog}
            uid={uid}
          />
        )}
        {shouldOpenConfirmationDialog && (
          <ConfirmationDialog
            open={shouldOpenConfirmationDialog}
            onConfirmDialogClose={handleDialogClose}
            onYesClick={() => {handleConfirmationResponse()}}
            text="Tem certeza que deseja excluir este registro?"
            title="Excluir registro"
            confirmButtonText="Sim"
            declineButtonText="Não"
          />
        )}
      </Card>
    </div>
  );
}

const mapStateToProps = state => ({
  userRedux: state.user,
  companies: state.company.companies,
  success: state.company.success,
  error: state.company.error,
});

export default connect(mapStateToProps)(CrudTable);
