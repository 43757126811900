import {
  GET_PRODUCT_TYPE_LOADING,
  GET_PRODUCT_TYPE_SUCCESS,
  GET_PRODUCT_TYPE_FAILED,
  REGISTER_PRODUCT_TYPE_LOADING,
  REGISTER_PRODUCT_TYPE_SUCCESS,
  REGISTER_PRODUCT_TYPE_FAILED,
  DELETE_PRODUCT_TYPE_LOADING,
  DELETE_PRODUCT_TYPE_SUCCESS,
  DELETE_PRODUCT_TYPE_FAILED,
  UPDATE_PRODUCT_TYPE_LOADING,
  UPDATE_PRODUCT_TYPE_SUCCESS,
  UPDATE_PRODUCT_TYPE_FAILED,
  GET_PRODUCT_LOADING,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAILED,
  GET_SPECIFIC_PRODUCT_LOADING,
  GET_SPECIFIC_PRODUCT_SUCCESS,
  GET_SPECIFIC_PRODUCT_FAILED,
  DELETE_PRODUCT_LOADING,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILED,
  CREATE_PRODUCT_LOADING,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILED,
  UPDATE_PRODUCT_LOADING,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILED,
  DELETE_GOAL_M3_PRODUCT_LOADING,
  DELETE_GOAL_M3_PRODUCT_SUCCESS,
  DELETE_GOAL_M3_PRODUCT_FAILED,
  CREATE_GOAL_M3_PRODUCT_LOADING,
  CREATE_GOAL_M3_PRODUCT_SUCCESS,
  CREATE_GOAL_M3_PRODUCT_FAILED,
  UPDATE_GOAL_M3_PRODUCT_LOADING,
  UPDATE_GOAL_M3_PRODUCT_SUCCESS,
  UPDATE_GOAL_M3_PRODUCT_FAILED,
  LIST_GOAL_M3_PRODUCT_LOADING,
  LIST_GOAL_M3_PRODUCT_SUCCESS,
  LIST_GOAL_M3_PRODUCT_FAILED,
  CLEAR
} from "../actions/ProductActions";


const initialState = {
  success: false,
  loading: false,
  error: null,
  successProduct: false,
  loadingProduct: false,
  errorProduct: null,
  productTypes: [],
  goalsProducts: [],
  products: [],
  product: [],
};

const userReducer = function(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_TYPE_LOADING: {
      console.log("GET_PRODUCT_TYPE_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case GET_PRODUCT_TYPE_SUCCESS: {
      console.log("GET_PRODUCT_TYPE_SUCCESS", action?.payload)
      return {
        ...state,
        productTypes: action?.payload,
        loading: false,
        error: null
      };
    }
    case GET_PRODUCT_TYPE_FAILED: {
      console.log("GET_PRODUCT_TYPE_FAILED", action.payload)
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    case UPDATE_PRODUCT_TYPE_LOADING: {
      console.log("UPDATE_PRODUCT_TYPE_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_PRODUCT_TYPE_SUCCESS: {
      console.log("UPDATE_PRODUCT_TYPE_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Tipo de produto alterado com sucesso!",
        loading: false,
        error: null
      };
    }
    case UPDATE_PRODUCT_TYPE_FAILED: {
      console.log("UPDATE_PRODUCT_TYPE_FAILED", action.payload)
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    case DELETE_PRODUCT_TYPE_LOADING: {
      console.log("DELETE_PRODUCT_TYPE_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_PRODUCT_TYPE_SUCCESS: {
      console.log("DELETE_PRODUCT_TYPE_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Tipo de produto deletado com sucesso!",
        loading: false,
        error: null
      };
    }
    case DELETE_PRODUCT_TYPE_FAILED: {
      console.log("DELETE_PRODUCT_TYPE_FAILED", action.payload)
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    case REGISTER_PRODUCT_TYPE_LOADING: {
      console.log("REGISTER_PRODUCT_TYPE_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case REGISTER_PRODUCT_TYPE_SUCCESS: {
      console.log("REGISTER_PRODUCT_TYPE_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Tipo de produto registrado com sucesso!",
        loading: false,
        error: null
      };
    }
    case REGISTER_PRODUCT_TYPE_FAILED: {
      console.log("REGISTER_PRODUCT_TYPE_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case GET_SPECIFIC_PRODUCT_LOADING: {
      console.log("GET_SPECIFIC_PRODUCT_LOADING")
      return {
        ...state,
        loadingProduct: true,
      };
    }
    case GET_SPECIFIC_PRODUCT_SUCCESS: {
      console.log("GET_SPECIFIC_PRODUCT_SUCCESS", action?.payload)
      return {
        ...state,
        product: action?.payload,
        loadingProduct: false,
        errorProduct: null
      };
    }
    case GET_SPECIFIC_PRODUCT_FAILED: {
      console.log("GET_SPECIFIC_PRODUCT_FAILED", action.payload)
      return {
        ...state,
        successProduct: false,
        loadingProduct: false,
        errorProduct: action.payload
      };
    }
    case GET_PRODUCT_LOADING: {
      console.log("GET_PRODUCT_LOADING")
      return {
        ...state,
        loadingProduct: true,
      };
    }
    case GET_PRODUCT_SUCCESS: {
      console.log("GET_PRODUCT_SUCCESS", action?.payload)
      return {
        ...state,
        products: action?.payload,
        loadingProduct: false,
        errorProduct: null
      };
    }
    case GET_PRODUCT_FAILED: {
      console.log("GET_PRODUCT_FAILED", action.payload)
      return {
        ...state,
        successProduct: false,
        loadingProduct: false,
        errorProduct: action.payload
      };
    }
    case DELETE_PRODUCT_LOADING: {
      console.log("DELETE_PRODUCT_LOADING")
      return {
        ...state,
        loadingProduct: true,
      };
    }
    case DELETE_PRODUCT_SUCCESS: {
      console.log("DELETE_PRODUCT_SUCCESS", action?.payload)
      return {
        ...state,
        successProduct: "Produto deletado com sucesso!",
        loadingProduct: false,
        errorProduct: null
      };
    }
    case DELETE_PRODUCT_FAILED: {
      console.log("DELETE_PRODUCT_FAILED", action.payload)
      return {
        ...state,
        successProduct: false,
        loadingProduct: false,
        errorProduct: action.payload
      };
    }
    case CREATE_PRODUCT_LOADING: {
      console.log("CREATE_PRODUCT_LOADING")
      return {
        ...state,
        loadingProduct: true,
      };
    }
    case CREATE_PRODUCT_SUCCESS: {
      console.log("CREATE_PRODUCT_SUCCESS", action?.payload)
      return {
        ...state,
        successProduct: "Produto registrado com sucesso!",
        loadingProduct: false,
        errorProduct: null
      };
    }
    case CREATE_PRODUCT_FAILED: {
      console.log("CREATE_PRODUCT_FAILED", action.payload)
      return {
        ...state,
        successProduct: false,
        loadingProduct: false,
        errorProduct: action.payload
      };
    }
    case UPDATE_PRODUCT_LOADING: {
      console.log("UPDATE_PRODUCT_LOADING")
      return {
        ...state,
        loadingProduct: true,
      };
    }
    case UPDATE_PRODUCT_SUCCESS: {
      console.log("UPDATE_PRODUCT_SUCCESS", action?.payload)
      return {
        ...state,
        successProduct: "Produto alterado com sucesso!",
        loadingProduct: false,
        errorProduct: null
      };
    }
    case UPDATE_PRODUCT_FAILED: {
      console.log("UPDATE_PRODUCT_FAILED", action.payload)
      return {
        ...state,
        loadingProduct: false,
        errorProduct: action.payload
      };
    }
    case LIST_GOAL_M3_PRODUCT_LOADING: {
      console.log("LIST_GOAL_M3_PRODUCT_LOADING")
      return {
        ...state,
        loadingProduct: true,
      };
    }
    case LIST_GOAL_M3_PRODUCT_SUCCESS: {
      console.log("LIST_GOAL_M3_PRODUCT_SUCCESS", action?.payload)
      return {
        ...state,
        goalsProducts: action?.payload,
        loadingProduct: false,
        errorProduct: null
      };
    }
    case LIST_GOAL_M3_PRODUCT_FAILED: {
      console.log("LIST_GOAL_M3_PRODUCT_FAILED", action.payload)
      return {
        ...state,
        loadingProduct: false,
        errorProduct: action.payload
      };
    }
    case CREATE_GOAL_M3_PRODUCT_LOADING: {
      console.log("CREATE_GOAL_M3_PRODUCT_LOADING")
      return {
        ...state,
        loadingProduct: true,
      };
    }
    case CREATE_GOAL_M3_PRODUCT_SUCCESS: {
      console.log("CREATE_GOAL_M3_PRODUCT_SUCCESS", action?.payload)
      return {
        ...state,
        successProduct: "Meta de Produto registrada com sucesso!",
        loadingProduct: false,
        errorProduct: null
      };
    }
    case CREATE_GOAL_M3_PRODUCT_FAILED: {
      console.log("CREATE_GOAL_M3_PRODUCT_FAILED", action.payload)
      return {
        ...state,
        successProduct: false,
        loadingProduct: false,
        errorProduct: action.payload
      };
    }
    case UPDATE_GOAL_M3_PRODUCT_LOADING: {
      console.log("UPDATE_GOAL_M3_PRODUCT_LOADING")
      return {
        ...state,
        loadingProduct: true,
      };
    }
    case UPDATE_GOAL_M3_PRODUCT_SUCCESS: {
      console.log("UPDATE_GOAL_M3_PRODUCT_SUCCESS", action?.payload)
      return {
        ...state,
        successProduct: "Meta de Produto alterada com sucesso!",
        loadingProduct: false,
        errorProduct: null
      };
    }
    case UPDATE_GOAL_M3_PRODUCT_FAILED: {
      console.log("UPDATE_GOAL_M3_PRODUCT_FAILED", action.payload)
      return {
        ...state,
        loadingProduct: false,
        errorProduct: action.payload
      };
    }
    case DELETE_GOAL_M3_PRODUCT_LOADING: {
      console.log("DELETE_GOAL_M3_PRODUCT_LOADING")
      return {
        ...state,
        loadingProduct: true,
      };
    }
    case DELETE_GOAL_M3_PRODUCT_SUCCESS: {
      console.log("DELETE_GOAL_M3_PRODUCT_SUCCESS", action?.payload)
      return {
        ...state,
        successProduct: "Meta de Produto deletada com sucesso!",
        loadingProduct: false,
        errorProduct: null
      };
    }
    case DELETE_GOAL_M3_PRODUCT_FAILED: {
      console.log("DELETE_GOAL_M3_PRODUCT_FAILED", action.payload)
      return {
        ...state,
        loadingProduct: false,
        errorProduct: action.payload
      };
    }
    case CLEAR: {
      return {
        ...state,
        error: null,
        success: false,
        loading: false,
        errorProduct: null,
        successProduct: false,
        loadingProduct: false
      };
    }

    default: {
      return state;
    }
  }
};

export default userReducer;
