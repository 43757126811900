import UserTable from "./DiaryProductionReports";

const DiaryProductionReports = [
  {
    path: "/producao-diaria",
    exact: true,
    component: UserTable
  }
];

export default DiaryProductionReports;
