import React, { useState, useEffect } from "react";
import {
  Dialog,
  Button,
  Grid,
  Icon,
  LinearProgress
} from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputMask from "react-input-mask";
import axios from "axios"

import { createTicket } from "../../redux/actions/TicketActions"
import { listProducts } from '../../redux/actions/ProductActions'
import { listCounty } from '../../redux/actions/StateActions'

import Swal from 'sweetalert2'

import { connect } from "react-redux";

const MemberEditorDialog = (props) => {

  const { handleClose, open, products, userRedux, states } = props

  const [step, setStep] = useState(1)
  const [order, setOrder] = useState("")
  const [meterage, setMeterage] = useState("")
  const [totalValue, setTotalValue] = useState("")
  const [city, setCity] = useState("")
  const [dateHour, setDateHour] = useState("")
  const [user, setUser] = useState("")
  const [status, setStatus] = useState("")
  const [isHabilited, setIsHabilited] = useState("")
  const [contact, setContact] = useState("")
  const [cep, setCep] = useState("")
  const [endereco, setEndereco] = useState("")
  const [rua, setRua] = useState("")
  const [numero, setNumero] = useState("")
  const [state, setState] = useState("")
  const [selectedEstadoItem, setSelectedEstadoItem] = useState("")
  const [cityId, setCityId] = useState("")
  const [complemento, setComplemento] = useState("")
  const [options, setOptions] = useState([])
  const [optionsState, setOptionsState] = useState([])
  const [requireBomber, setRequireBomber] = useState("")

  const handleFormSubmit = () => {
    return false;
  }

  const buscarCep = (text) => {

    var textReplaced = text.replace("-", "")

    axios({
      method: 'get',
      url: `https://viacep.com.br/ws/${textReplaced}/json/`,
      headers: {
        "Content-Type": "application/json"
      }
    }).then(res => {
      var data = res.data
      setRua(`${data.logradouro} ${data.bairro}`)
      setState(data.uf)
      setCity(data.localidade)
      setCityId(data.ibge)
      var find = states.find(res => res.unit == data.uf)
      setSelectedEstadoItem(find)
      console.log("data", data)
    }).catch(eer => {
      console.log("err", eer)
    })
  }

  useEffect(() => {
    props.dispatch(listProducts(userRedux?.token, userRedux?.company))
  }, [])

  useEffect(() => {
    if (products != null) {
      console.log("products", products)
      var newOptions = []
      products.map(item => {
        newOptions.push({ label: item?.name, value: item?.id })
      })
      setOptions(newOptions)
    }
  }, [products])

  useEffect(() => {
    console.log("cep", cep)
    if (cep.length == 8) {
      buscarCep(cep);
    }
  }, [cep])

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="p-24" style={{ width: "400px" }}>
        <h4 className="mb-20">Adicionar Pedido</h4>
        <ValidatorForm onSubmit={() => {
          handleFormSubmit()
        }}>
          <Grid className="mb-16" container spacing={10} style={{ justifyContent: "center" }}>
            <Grid item sm={12}>
              <label className="mb-4">Produto</label>
              <select className="w-100 mb-16" onChange={event => { setRequireBomber(event.target.value) }} style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                <option value=""></option>
                {
                  options.map(item =>
                    <option value={item?.value}>{item?.label}</option>
                  )
                }

              </select>
              <label className="mb-4">Metragem</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="meterage"
                onChange={
                  (event) => { setMeterage(event.target.value) }}
                type="text"
                name="meterage"
                value={meterage}
              />

              <label className="mb-4">Bombeamento</label>
              <select className="w-100 mb-16" onChange={event => { setRequireBomber(event.target.value) }} style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                <option value="Sim">Sim</option>
                <option value="Nao">Nao</option>
              </select>

              <label className="mb-4">CEP</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="cep"
                onChange={
                  (event) => { setCep(event.target.value) }}
                type="text"
                name="cep"
                value={cep}
              />

              <label className="mb-4">Estado</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="state"
                disabled="disabled"
                onChange={(event) => {
                  setState(event.target.value)
                }}
                type="text"
                name="state"
                value={state}
              />

              <label className="mb-4">Cidade</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="City"
                disabled="disabled"
                onChange={(event) => {
                  setCity(event.target.value)
                }}
                type="text"
                name="City"
                value={city}
              />

              <label className="mb-4">Endereço</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="endereco"
                onChange={
                  (event) => { setEndereco(event.target.value) }}
                type="text"
                name="endereco"
                value={endereco}
              />

              <label className="mb-4">Rua</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="rua"
                onChange={
                  (event) => { setRua(event.target.value) }}
                type="text"
                name="rua"
                value={rua}
              />

              <label className="mb-4">Número</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="numero"
                onChange={
                  (event) => { setNumero(event.target.value) }}
                type="text"
                name="numero"
                value={numero}
              />
              <label className="mb-4">Complemento</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="complemento"
                onChange={
                  (event) => { setComplemento(event.target.value) }}
                type="text"
                name="complemento"
                value={complemento}
              />
            </Grid>
          </Grid>
          <div className="flex flex-space-between flex-middle">
            <Button variant="contained" color="primary" type="submit">
              Salvar
            </Button>
          </div>
        </ValidatorForm>
      </div>
    </Dialog>
  );
}


const mapStateToProps = state => ({
  userRedux: state.user,
  products: state.product.products,
  countys: state.state.countys,
  states: state.state.states,
  loading: state.contract.loading,
  error: state.contract.error,
  success: state.contract.success,
});

export default connect(mapStateToProps)(MemberEditorDialog);
