import React, { Component, useState, useEffect } from "react";
import {
  Card,
  Grid,
  Button,
  ClickAwayListener,
  TextField,
  InputAdornment,
  TablePagination,
  IconButton,
  Icon
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Breadcrumb, ConfirmationDialog } from "egret";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getAllBoard,
  addNewBoard
} from "../../redux/actions/ScrumBoardActions";
import {
  listOrderWaitingForSellerCompany
} from "../../redux/actions/CompanyActions";
import MemberEditorDialog from "./MemberEditorDialog";
import { updateStateConfirmSeller, deleteOrder, clear } from '../../redux/actions/OrderActions'
import Swal from 'sweetalert2'
import moment from "moment"
const AppScrumBoard = props => {

  const { userRedux, orders_company, success, error, history } = props

  const [isDelete, setDelete] = useState(false)
  const [shouldOpenDialog, setShouldOpenDialog] = useState(false)
  const [textFieldValue, setTextFieldValue] = useState("")
  const [shouldOpenEditorDialog, setShouldOpenEditorDialog] = useState(false)

  useEffect(() => {
    props.dispatch(listOrderWaitingForSellerCompany(userRedux?.token, userRedux?.company))
    // props.getAllBoard()
  }, [])


  const openEditorDialog = value => {
    setShouldOpenDialog(value)
  };

  const handleChange = event => {
    if (event.key === "Enter" && !event.shiftKey) {
      handleAddNewBoard()
    } else setTextFieldValue(event.target.value);
  };

  const handleDialogCloseFunction = () => {
    setShouldOpenEditorDialog(false)
    // setShouldOpenConfirmationDialog(false)
  };

  const handleAddNewBoard = () => {
    let title = textFieldValue.trim();
    if (title !== "") {
      props.addNewBoard(title);
      setTextFieldValue("")
    }
  };

  const handleOrder = (order) => {
    Swal.fire({
      icon: 'warning',
      title: 'Tem certeza que deseja deletar este pedido?',
      text: isDelete ? "" : "Aguardando emissão do ticket",
      showDenyButton: true,
      confirmButtonColor: "#f78726",
      denyButtonColor: "#262d47",
      confirmButtonText: "Deletar",
      denyButtonText: "Não Deletar",
    }).then(result => {
      if (result?.isConfirmed) {
        props.dispatch(deleteOrder(userRedux?.token, userRedux?.company, order))
      }
    })

  }

  useEffect(() => {
    console.log("success", success)
    if (success != false) {
      Swal.fire({
        icon: 'success',
        title: success,
        text: isDelete ? "" : "Aguardando emissão do ticket",
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(() => {
        props.dispatch(clear())
        goTo('/lista_pedidos')
        if (isDelete) {
          props.dispatch(listOrderWaitingForSellerCompany(userRedux?.token, userRedux?.company))

        }
      }, 3500)
    }
  }, [success])

  const goTo = (link) => {
    return (
      <Link to={link} />
    )
  }

  useEffect(() => {
    console.log("error", error)
    if (error != null) {
      Swal.fire({
        icon: 'error',
        title: error,
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(() => {
        props.dispatch(clear())
      }, 3500)
    }
  }, [error])

  const handleConfirmOrder = (order) => {
    // if(true){ // SE O LIMITE ESTIVER EXCEDIDO
    //   Swal.fire({
    //     icon: 'error',
    //     title: "O limite diário de metros cúbicos está excedido!",
    //     showConfirmButton: false,
    //     timer: 3000
    //   })
    // }else{
    props.dispatch(updateStateConfirmSeller(userRedux?.token, userRedux?.company, order))
    // }
  };

  return (
    <div className="scrum-board m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: "Fila de pedidos" }]} />
      </div>
      {/* <Button
        className="mb-16"
        variant="contained"
        color="primary"
        onClick={() => {
          setShouldOpenEditorDialog(true)
        }}
      >
        <Icon>add-circle</Icon>
        Adicionar registro
      </Button> */}
      <Grid container spacing={2}>
        {
          userRedux?.role != "VENDEDOR" && <span style={{ textAlign: "center", color: "red", width: "100%", fontWeight: "bold", marginBottom: 15 }}>Por favor, logue com um perfil de vendedor para confirmar a compra.</span>
        }
        {orders_company?.length > 0 && orders_company?.map(item => (
          <Grid key={item?.order} item lg={3} md={3} sm={6} xs={12}>
            <Link>
              <Card className="p-24 cursor-pointer" elevation={10}>
                <h6 className="white-space-pre-line capitalize m-0 font-weight-500">
                  {item?.order}
                </h6>
                <h6 style={{ marginTop: "5px" }} className="white-space-pre-line capitalize font-weight-500">
                  {item?.client?.name}
                </h6>
                <h6 className="white-space-pre-line capitalize font-weight-500">
                  {moment(item.createdDate)?.add("3", "hours")?.format("DD/MM/YYYY HH:mm")}
                </h6>
                <div style={{ marginTop: 10, backgroundColor: item.currentStatus?.name == "descarga_finalizada" ? "#1e9a79" : "#f78726", borderRadius: 10, padding: 5, color: "white" }}>
                  <h6 className="white-space-pre-line capitalize m-0 font-weight-500" style={{ color: "white", textAlign: "center" }}>
                    {item.currentStatus?.name}
                  </h6>
                </div>
                <Grid container style={{ marginTop: 5 }}>
                  <Grid key={item?.order} item lg={6} md={6} sm={6} xs={6}>
                    <Button className="w-100" onClick={() => {
                      history.push({ pathname: `/lista_pedidos/${item?.order}?order=${item?.order}&meterage=${item?.meterage}&valueTotal=${item?.valueTotal}&cidade=${encodeURI(item?.deliveryLocation?.city?.name)}&createdDate=${encodeURI(item?.createdDate)}&usuario=${item?.client?.name}&status=${item?.status[0].name}&isHabilited=${item?.client?.isUserClient}&contact=${encodeURI(item?.client?.contact)}&productTypeId=${item?.product?.id}&bomber=${item?.pumpServiceRequired}&cep=${item?.deliveryLocation?.cep}&endereco=${encodeURI(item?.deliveryLocation?.street + " " + item?.deliveryLocation?.number + " " + item?.deliveryLocation?.neighborhood)}&rua=${encodeURI(item?.deliveryLocation?.street)}&numero=${item?.deliveryLocation?.number}&complemento=${encodeURI(item?.deliveryLocation?.complement)}&productName=${encodeURI(item?.product?.type?.name)}&applicationName=${encodeURI(item?.application?.name)}&productTypeName=${encodeURI(item?.product?.name)}&productId=${item?.product?.type?.id}&applicationId=${item?.application?.id}&dateDelivery=${encodeURI(item?.dateDelivery)}&bairro=${encodeURI(item?.deliveryLocation?.neighborhood)}&contract=${item?.contract?.id || null}&initialStep=2` });
                    }}><Icon color="primary">edit</Icon></Button>
                  </Grid>
                  <Grid key={item?.order} item lg={6} md={6} sm={6} xs={6}>
                    <Button onClick={() => {
                      history.push({ pathname: `/lista_pedidos/${item?.order}?order=${item?.order}&meterage=${item?.meterage}&valueTotal=${item?.valueTotal}&cidade=${encodeURI(item?.deliveryLocation?.city?.name)}&createdDate=${encodeURI(item?.createdDate)}&usuario=${item?.client?.name}&status=${item?.status[0].name}&isHabilited=${item?.client?.isUserClient}&contact=${encodeURI(item?.client?.contact)}&productTypeId=${item?.product?.id}&bomber=${item?.pumpServiceRequired}&cep=${item?.deliveryLocation?.cep}&endereco=${encodeURI(item?.deliveryLocation?.street + " " + item?.deliveryLocation?.number + " " + item?.deliveryLocation?.neighborhood)}&rua=${encodeURI(item?.deliveryLocation?.street)}&numero=${item?.deliveryLocation?.number}&complemento=${encodeURI(item?.deliveryLocation?.complement)}&productName=${encodeURI(item?.product?.type?.name)}&applicationName=${encodeURI(item?.application?.name)}&productTypeName=${encodeURI(item?.product?.name)}&productId=${item?.product?.type?.id}&applicationId=${item?.application?.id}&dateDelivery=${encodeURI(item?.dateDelivery)}&bairro=${encodeURI(item?.deliveryLocation?.neighborhood)}&contract=${item?.contract?.id || null}&initialStep=1` });
                    }} className="w-100"><Icon color="primary">visibility</Icon></Button>

                  </Grid>

                </Grid>
                <Grid container style={{ marginTop: 5 }}>
                  <Grid key={item?.order} item lg={6} md={6} sm={6} xs={6}>
                    <Button
                      onClick={() => { handleConfirmOrder(item?.order) }} className="w-100"><Icon color="primary">check</Icon></Button>
                  </Grid>
                  <Grid key={item?.order} item lg={6} md={6} sm={6} xs={6}>
                    <Button className="w-100" onClick={() => {
                      setDelete(true)
                      handleOrder(item?.order)
                    }}><Icon color="primary">delete</Icon></Button>
                  </Grid>
                </Grid>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
      {shouldOpenEditorDialog && (
        <MemberEditorDialog
          handleClose={handleDialogCloseFunction}
          open={shouldOpenEditorDialog}
        />
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  getAllBoard: PropTypes.func.isRequired,
  addNewBoard: PropTypes.func.isRequired,
  boardList: state.scrumboard.boardList,
  userRedux: state.user,
  loading: state.orders.loading,
  success: state.orders.success,
  error: state.orders.error,
  orders_company: state.company.orders_company
});

export default connect(
  mapStateToProps
)(AppScrumBoard);
