import history from "history.js";
import jwtAuthService from "../../services/jwtAuthService";
import axios from "axios";


export const LIST_STATE_LOADING = "LIST_STATE_LOADING";
export const LIST_STATE_SUCCESS = "LIST_STATE_SUCCESS";
export const LIST_STATE_FAILED = "LIST_STATE_FAILED";
export const LIST_COUNTY_LOADING = "LIST_COUNTY_LOADING";
export const LIST_COUNTY_SUCCESS = "LIST_COUNTY_SUCCESS";
export const LIST_COUNTY_FAILED = "LIST_COUNTY_FAILED";

export const CLEAR = "CLEAR";

export function listState(token) {
  return dispatch => {
    dispatch({
      type: LIST_STATE_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/mobile/base/state`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_STATE_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_STATE_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function listCounty(token, unit) {
  return dispatch => {
    dispatch({
      type: LIST_COUNTY_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/mobile/base/state/${unit}/city`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_COUNTY_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_COUNTY_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function clear() {
  return dispatch => {
    dispatch({
      type: CLEAR
    });
  };
}
