import React, { Component, useState, useEffect } from "react";
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Button
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { connect } from "react-redux";

import { registerUser, clear } from "../../redux/actions/UserActions";


import Swal from 'sweetalert2'

import Input from "./../../components/Input"

const SignUp = (props) => {

  const { loading, error, success } = props;

  const [name, setName] = useState("")
  const [cpf, setCpf] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [agreement, setAgreement] = useState(false)
  const [phone, setPhone] = useState("")

  function TestaCPF(strCPF) {
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF == "00000000000") return false;

    for (var i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (var i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const handleFormSubmit = () => {

    var phoneReplace = phone.replace(/[^0-9]/g, "")

    if (name == "") {
      Swal.fire({
        icon: 'error',
        title: "Nome inválido",
        showConfirmButton: false,
        timer: 3000
      })
    } else if (cpf == "" || TestaCPF(cpf.replace(/[^0-9]/g, "")) == false) {
      Swal.fire({
        icon: 'error',
        title: "Cpf inválido",
        showConfirmButton: false,
        timer: 3000
      })
    } else if (email == "" || validateEmail(email) == false) {
      Swal.fire({
        icon: 'error',
        title: "Email inválido",
        showConfirmButton: false,
        timer: 3000
      })
    } else if (phone == "" || phoneReplace.length < 11) {
      Swal.fire({
        icon: 'error',
        title: "Telefone inválido",
        showConfirmButton: false,
        timer: 3000
      })
    } else if (password == "" || confirmPassword == "") {
      Swal.fire({
        icon: 'error',
        title: "Preencha as senhas",
        showConfirmButton: false,
        timer: 3000
      })
    } else if (password != confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: "As senhas não correspondem",
        showConfirmButton: false,
        timer: 3000
      })
    } else if (agreement == false) {
      Swal.fire({
        icon: 'error',
        title: "Aceite os termos de uso para concluir o cadastro",
        showConfirmButton: false,
        timer: 3000
      })
    } else {
      props.dispatch(registerUser({
        name,
        cpf: cpf.replace(/[^0-9]/g, ''),
        email,
        contact: phone,
        password: password,
        type: "CLIENT"
      }))
    }
  };

  useEffect(() => {
    if (loading == true) {
      Swal.showLoading()
    } else {
      Swal.hideLoading()
    }
  }, [loading])

  useEffect(() => {
    if (success != false) {
      Swal.fire({
        icon: 'success',
        title: success,
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(() => {
        props.dispatch(clear())
        props.history.push("/login")
      }, 3500)
    }
  }, [success])

  useEffect(() => {
    if (error != null) {
      Swal.fire({
        icon: 'error',
        title: error,
        showConfirmButton: false,
        timer: 3000
      })

      setTimeout(() => {
        props.dispatch(clear())
      }, 3500)
    }
  }, [error])

  return (
    <div className="signup flex flex-center w-100 h-100vh">
      <div className="p-8">
        <Card className="signup-card position-relative y-center">
          <Grid container>
            <Grid item lg={5} md={5} sm={5} xs={12}>
              <div className="p-32 flex flex-center bg-light-gray flex-middle h-100">
                <img
                  src="/assets/images/stonik-laranja.png"
                  alt=""
                />
              </div>
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              <div className="p-36 h-100">
                <ValidatorForm>
                  <Input label="Nome" mask="" value={name} onChange={(event) => {
                    setName(event.target.value)
                  }} />
                  <Input label="CPF" mask="999.999.999-99" value={cpf} onChange={(event) => {
                    setCpf(event.target.value)
                  }} />
                  <Input label="Email" mask="" value={email} onChange={(event) => {
                    setEmail(event.target.value)
                  }} />
                  <Input label="Telefone" mask="(99) 9 9999-9999" value={phone} onChange={(event) => {
                    setPhone(event.target.value)
                  }} />
                  <Input label="Senha" mask="" value={password} onChange={(event) => {
                    setPassword(event.target.value)
                  }} />
                  <Input label="Confirmar Senha" mask="" value={confirmPassword} onChange={(event) => {
                    setConfirmPassword(event.target.value)
                  }} />
                  <FormControlLabel
                    className="mb-16"
                    name="agreement"
                    control={<Checkbox onChange={() => { setAgreement(!agreement) }} checked={agreement} />}
                    label="Eu aceito os termos de uso e política de privacidade"
                  />
                  <div className="flex flex-middle">
                    <Button
                      className="capitalize"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleFormSubmit()
                      }}
                    >
                      Cadastrar
                    </Button>
                    <span className="ml-16 mr-8">ou</span>
                    <Button
                      className="capitalize"
                      onClick={() =>
                        props.history.push("/login")
                      }
                    >
                      Login
                    </Button>
                  </div>
                </ValidatorForm>
              </div>
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  loading: state.user.loading,
  error: state.user.error,
  success: state.user.success,
});

export default connect(mapStateToProps)(SignUp);
