import UserTable from "./CalculationSalesPerFckReports";

const CalculationSalesPerFckReports = [
  {
    path: "/calculo-vendas-por-fck",
    exact: true,
    component: UserTable
  }
];

export default CalculationSalesPerFckReports;
