import React from "react";
import {
  Dialog,
  Grid,
  Button
} from "@material-ui/core";

import { connect } from "react-redux";

const MemberEditorDialog = (props) => {

  const { open, handleClose, uid } = props;

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="p-24" style={{ width: "400px" }}>
        <h5 className="mb-20">Visualizar Ticket - {uid?.order?.order}</h5>
        <Button style={{position: "absolute", right: 10, top: 10}} onClick={() => {
          handleClose()
        }}>X</Button>
        <Grid className="mb-16" container spacing={10}>
          <Grid item sm={12} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <label style={{ flex: 1 }}>Pedido</label>
            <label style={{ fontWeight: "bold", flex: 1, marginBottom: 10 }}>{uid?.order?.order || "Sem ordem"}</label>

            <label style={{ flex: 1 }}>Volume em metro cúbico</label>
            <label style={{ fontWeight: "bold", flex: 1, marginBottom: 10 }}>{uid?.volumeM3Product || "Sem metragem"}</label>

            <label style={{ flex: 1 }}>Valor unitário produto</label>
            <label style={{ fontWeight: "bold", flex: 1, marginBottom: 10 }}>{uid?.valueTotalProduct || "Sem valor"}</label>

            <label style={{ flex: 1 }}>Bombista</label>
            <label style={{ fontWeight: "bold", flex: 1, marginBottom: 10 }}>{uid?.bomber?.user?.name || "Sem bombista"}</label>

            <label style={{ flex: 1 }}>Valor total ticket</label>
            <label style={{ fontWeight: "bold", flex: 1, marginBottom: 10 }}>{uid?.valueTotalTicket || "Sem valor total"}</label>

            <label style={{ flex: 1 }}>Motorista</label>
            <label style={{ fontWeight: "bold", flex: 1, marginBottom: 10 }}>{uid?.driver?.user?.name || "Sem motorista"}</label>

            <label style={{ flex: 1 }}>Veiculo</label>
            <label style={{ fontWeight: "bold", flex: 1, marginBottom: 10 }}>{uid?.vehicle?.fleet || "Sem veículo"}</label>

            <label style={{ flex: 1 }}>Tipo de aplicação</label>
            <label style={{ fontWeight: "bold", flex: 1, marginBottom: 10 }}>{uid?.application?.name || "Sem tipo de aplicação"}</label>

            <label style={{ flex: 1 }}>Prazo pagamento</label>
            <label style={{ fontWeight: "bold", flex: 1, marginBottom: 10 }}>{uid?.paymentTerm || "Sem prazo de pagamento"}</label>

          </Grid>
        </Grid>

      </div>
    </Dialog>
  );
}


const mapStateToProps = state => ({
  userRedux: state.user,
  products: state.product.products,
  countys: state.state.countys,
  loading: state.contract.loading,
  error: state.contract.error,
  success: state.contract.success,
});

export default connect(mapStateToProps)(MemberEditorDialog);
