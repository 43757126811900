import React, { Component } from "react";
import {
  Dialog,
  Button,
  Grid,
  Avatar,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { getUserById, updateUser, addNewUser } from "./TableService";
import { generateRandomId } from "utils";
import InputMask from "react-input-mask";
import IntegrationReactSelect from "./ReactSelect";
import IntegrationReactSelectCompany from "./ReactSelectCompany"

import Swal from 'sweetalert2'

class MemberEditorDialog extends Component {
  state = {
    name: "",
    email: "",
    phone: "",
    cpf: "",
    balance: "",
    age: "",
    company: "",
    address: "",
    isActive: false
  };

  handleChange = (event, source) => {
    event.persist();
    if (source === "switch") {
      this.setState({ isActive: event.target.checked });
      return;
    }

    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleFormSubmit = () => {
    Swal.fire({
      icon: 'success',
      title: "Usuário adicionado com sucesso!",
      showConfirmButton: false,
      timer: 3000
    })
    this.props.handleClose();
  };

  componentWillMount() {
    getUserById(this.props.uid).then(data => this.setState({ ...data.data }));
  }

  render() {
    let {
      name,
      email,
      phone,
      balance,
      age,
      cpf,
      company,
      address,
      isActive
    } = this.state;
    let { open, handleClose } = this.props;

    return (
      <Dialog onClose={handleClose} open={open}>
        <div className="p-24" style={{ width: "400px" }}>
          <h4 className="mb-20">Adicionar novo usuário</h4>
          <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
            <Grid className="mb-16" container spacing={10}>
              <Grid item sm={12}>
                <label className="mb-4">Nome</label>
                <InputMask
                  className="w-100 mb-16"
                  style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                  label="Nome"
                  onChange={this.handleChange}
                  type="text"
                  name="name"
                  value={name}
                />
                <label className="mb-4">Cpf</label>
                <InputMask
                  className="w-100 mb-16"
                  style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                  mask="999.999.999-99"
                  label="Cpf"
                  onChange={this.handleChange}
                  type="text"
                  name="cpf"
                  value={cpf}
                />
                <label className="mb-4">Email</label>
                <InputMask
                  className="w-100 mb-16"
                  style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                  label="Email"
                  onChange={this.handleChange}
                  type="text"
                  name="email"
                  value={email}
                />

                <label className="mb-4">Telefone</label>
                <InputMask
                  className="w-100 mb-16"
                  style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                  mask="(99) 9 9999-9999"
                  label="Telefone"
                  onChange={this.handleChange}
                  type="numeric"
                  name="phone"
                  value={phone}
                />
                <div style={{ marginTop: 10 }}>
                  <IntegrationReactSelectCompany/>
                </div>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                <IntegrationReactSelect/>
                </div>
                <label className="mb-4">Foto</label>
                <Avatar
                  className="avatar mb-20"
                  style={{ width: 100, height: 100 }}
                  src="/assets/images/face-7.jpg"
                />
                <input className="w-100 mb-16" type="file" accept="application/png" style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }} />
              </Grid>
            </Grid>

            <div className="flex flex-space-between flex-middle">
              <Button variant="contained" color="primary" type="submit">
                Salvar
              </Button>
              <Button onClick={() => this.props.handleClose()}>Cancelar</Button>
            </div>
          </ValidatorForm>
        </div>
      </Dialog>
    );
  }
}

export default MemberEditorDialog;
