import {
  LIST_STATE_LOADING,
  LIST_STATE_SUCCESS,
  LIST_STATE_FAILED,
  LIST_COUNTY_LOADING,
  LIST_COUNTY_SUCCESS,
  LIST_COUNTY_FAILED,
  CLEAR
} from "../actions/StateActions";


const initialState = {success: false,
  loading: false,
  error: null,
  states: [],
  countys: [],
};

const StateReducer = function(state = initialState, action) {
  switch (action.type) {
    case LIST_STATE_LOADING: {
      console.log("LIST_STATE_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_STATE_SUCCESS: {
      console.log("LIST_STATE_SUCCESS", action?.payload)
      return {
        ...state,
        states: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_STATE_FAILED: {
      console.log("LIST_STATE_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case LIST_COUNTY_LOADING: {
      console.log("LIST_COUNTY_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_COUNTY_SUCCESS: {
      console.log("LIST_COUNTY_SUCCESS", action?.payload)
      return {
        ...state,
        countys: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_COUNTY_FAILED: {
      console.log("LIST_COUNTY_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case CLEAR: {
      return {
        ...state,
        error: null,
        success: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default StateReducer;
