import {
  LIST_GENERAL_OPERATION_REPORTS_LOADING,
  LIST_GENERAL_OPERATION_REPORTS_SUCCESS,
  LIST_GENERAL_OPERATION_REPORTS_FAILED,
  LIST_GENERAL_REPORTS_LOADING,
  LIST_GENERAL_REPORTS_SUCCESS,
  LIST_GENERAL_REPORTS_FAILED,
  LIST_REVENUES_REPORTS_LOADING,
  LIST_REVENUES_REPORTS_SUCCESS,
  LIST_REVENUES_REPORTS_FAILED,
  LIST_DIARY_PRODUCTION_REPORTS_LOADING,
  LIST_DIARY_PRODUCTION_REPORTS_SUCCESS,
  LIST_DIARY_PRODUCTION_REPORTS_FAILED,
  LIST_CONCRETE_MIXER_PRODUCTION_REPORTS_LOADING,
  LIST_CONCRETE_MIXER_PRODUCTION_REPORTS_SUCCESS,
  LIST_CONCRETE_MIXER_PRODUCTION_REPORTS_FAILED,
  LIST_PRODUCTION_BY_PRODUCT_REPORTS_LOADING,
  LIST_PRODUCTION_BY_PRODUCT_REPORTS_SUCCESS,
  LIST_PRODUCTION_BY_PRODUCT_REPORTS_FAILED,
  LIST_DISTANCE_AND_VOLUME_REPORTS_LOADING,
  LIST_DISTANCE_AND_VOLUME_REPORTS_SUCCESS,
  LIST_DISTANCE_AND_VOLUME_REPORTS_FAILED,
  LIST_AUDITORIA_REPORTS_LOADING,
  LIST_AUDITORIA_REPORTS_SUCCESS,
  LIST_AUDITORIA_REPORTS_FAILED,
  LIST_FCK_REPORTS_LOADING,
  LIST_FCK_REPORTS_SUCCESS,
  LIST_FCK_REPORTS_FAILED,
  CLEAR
} from "../actions/ReportsActions";


const initialState = {
  success: false,
  loading: false,
  error: null,
  generalOperation: [],
  general: [],
  auditoria: [],
  fck: [],
  revenues: [],
  diaryProduction: [],
  concreteMixerProduction: [],
  productionByProduct: [],
  distanceAndVolume: [],
};

const reportsReducer = function(state = initialState, action) {
  switch (action.type) {
    case LIST_GENERAL_OPERATION_REPORTS_LOADING: {
      console.log("LIST_GENERAL_OPERATION_REPORTS_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_GENERAL_OPERATION_REPORTS_SUCCESS: {
      console.log("LIST_GENERAL_OPERATION_REPORTS_SUCCESS", action?.payload)
      return {
        ...state,
        generalOperation: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_GENERAL_OPERATION_REPORTS_FAILED: {
      console.log("LIST_GENERAL_OPERATION_REPORTS_FAILED", action.payload)
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    case LIST_GENERAL_REPORTS_LOADING: {
      console.log("LIST_GENERAL_REPORTS_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_GENERAL_REPORTS_SUCCESS: {
      console.log("LIST_GENERAL_REPORTS_SUCCESS", action?.payload)
      return {
        ...state,
        general: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_GENERAL_REPORTS_FAILED: {
      console.log("LIST_GENERAL_REPORTS_FAILED", action.payload)
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    case LIST_REVENUES_REPORTS_LOADING: {
      console.log("LIST_REVENUES_REPORTS_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_REVENUES_REPORTS_SUCCESS: {
      console.log("LIST_REVENUES_REPORTS_SUCCESS", action?.payload)
      return {
        ...state,
        revenues: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_REVENUES_REPORTS_FAILED: {
      console.log("LIST_REVENUES_REPORTS_FAILED", action.payload)
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    case LIST_DIARY_PRODUCTION_REPORTS_LOADING: {
      console.log("LIST_DIARY_PRODUCTION_REPORTS_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_DIARY_PRODUCTION_REPORTS_SUCCESS: {
      console.log("LIST_DIARY_PRODUCTION_REPORTS_SUCCESS", action?.payload)
      return {
        ...state,
        diaryProduction: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_DIARY_PRODUCTION_REPORTS_FAILED: {
      console.log("LIST_DIARY_PRODUCTION_REPORTS_FAILED", action.payload)
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    case LIST_CONCRETE_MIXER_PRODUCTION_REPORTS_LOADING: {
      console.log("LIST_CONCRETE_MIXER_PRODUCTION_REPORTS_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_CONCRETE_MIXER_PRODUCTION_REPORTS_SUCCESS: {
      console.log("LIST_CONCRETE_MIXER_PRODUCTION_REPORTS_SUCCESS", action?.payload)
      return {
        ...state,
        concreteMixerProduction: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_CONCRETE_MIXER_PRODUCTION_REPORTS_FAILED: {
      console.log("LIST_CONCRETE_MIXER_PRODUCTION_REPORTS_FAILED", action.payload)
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    case LIST_PRODUCTION_BY_PRODUCT_REPORTS_LOADING: {
      console.log("LIST_PRODUCTION_BY_PRODUCT_REPORTS_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_PRODUCTION_BY_PRODUCT_REPORTS_SUCCESS: {
      console.log("LIST_PRODUCTION_BY_PRODUCT_REPORTS_SUCCESS", action?.payload)
      return {
        ...state,
        productionByProduct: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_PRODUCTION_BY_PRODUCT_REPORTS_FAILED: {
      console.log("LIST_PRODUCTION_BY_PRODUCT_REPORTS_FAILED", action.payload)
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    case LIST_DISTANCE_AND_VOLUME_REPORTS_LOADING: {
      console.log("LIST_DISTANCE_AND_VOLUME_REPORTS_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_DISTANCE_AND_VOLUME_REPORTS_SUCCESS: {
      console.log("LIST_DISTANCE_AND_VOLUME_REPORTS_SUCCESS", action?.payload)
      return {
        ...state,
        distanceAndVolume: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_DISTANCE_AND_VOLUME_REPORTS_FAILED: {
      console.log("LIST_DISTANCE_AND_VOLUME_REPORTS_FAILED", action.payload)
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    case LIST_AUDITORIA_REPORTS_LOADING: {
      console.log("LIST_AUDITORIA_REPORTS_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_AUDITORIA_REPORTS_SUCCESS: {
      console.log("LIST_AUDITORIA_REPORTS_SUCCESS", action?.payload)
      return {
        ...state,
        auditoria: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_AUDITORIA_REPORTS_FAILED: {
      console.log("LIST_AUDITORIA_REPORTS_FAILED", action.payload)
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    case LIST_FCK_REPORTS_LOADING: {
      console.log("LIST_FCK_REPORTS_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_FCK_REPORTS_SUCCESS: {
      console.log("LIST_FCK_REPORTS_SUCCESS", action?.payload)
      return {
        ...state,
        fck: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_FCK_REPORTS_FAILED: {
      console.log("LIST_FCK_REPORTS_FAILED", action.payload)
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    case CLEAR: {
      return {
        ...state,
        error: null,
        success: false,
        loading: false,
        generalOperation: [],
        fck: [],
        general: [],
        auditoria: [],
        revenues: [],
        diaryProduction: [],
        concreteMixerProduction: [],
        productionByProduct: [],
        distanceAndVolume: [],
      };
    }

    default: {
      return state;
    }
  }
};

export default reportsReducer;
