import UserTable from "./RequestTracking";

const RequestTrackingReports = [
  {
    path: "/rastreio-pedido",
    exact: true,
    component: UserTable
  }
];

export default RequestTrackingReports;
