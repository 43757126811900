import React, { useState, useEffect } from "react";
import {
  Dialog,
  Button,
  Grid,
  Icon,
  CircularProgress
} from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputMask from "react-input-mask";

import { connect } from "react-redux";
import IntegrationReactSelect from "./ReactSelect"

import { listProductTypes, registerProductType, updateProductType, createProduct, listProducts, updateProduct, clear } from "../../redux/actions/ProductActions"
import { listCompanys } from "../../redux/actions/CompanyActions"

import Swal from 'sweetalert2'

const MemberEditorDialog = (props) => {

  const {
    open,
    handleClose,
    user,
    uid,
    products,
    clients,
    loadingClient,
    errorClient,
    successClient,
    loading,
    error,
    success,
    productTypes,
    companies,
    loadingProductTypes,
    errorProductTypes,
    successProductTypes,
    loadingProducts,
    errorProducts,
    successProducts
  } = props

  const [nomeProduto, setNomeProduto] = useState("")
  const [distanciaMaxima, setDistanciaMaxima] = useState("")
  const [volumeMinimo, setVolumeMinimo] = useState("")
  const [tempoMaximo, setTempoMaximo] = useState("")
  const [description, setDescription] = useState("")
  const [price, setPrice] = useState(0)
  const [type, setType] = useState("")
  const [data, setData] = useState([])
  const [company, setCompany] = useState([])

  const [titleError, setTitleError] = useState("")

  const handleFormSubmit = () => {
    if (nomeProduto == "") {
      setTitleError("Preencha o nome do produto")
    } else if (type == "") {
      setTitleError("Selecione o tipo do produto")
    } else if (price == 0 || price == "") {
      setTitleError("Preencha o preço do produto por m3")
    } else if (distanciaMaxima == "") {
      setTitleError("Selecione a distância máxima")
    } else if (volumeMinimo == "") {
      setTitleError("Selecione o volume mínimo")
    } else if (tempoMaximo == "") {
      setTitleError("Selecione o tempo máximo")
    } else {
      if (uid != null) {
        console.log("Edit", {
          id: uid?.id,
          name: nomeProduto,
          description: "",
          status: true
        })
        props.dispatch(updateProduct(user?.token, user?.company, {
          id: uid?.id,
          name: nomeProduto,
          description: description,
          priceM3: price,
          delivery: {
            volumeM3Min: volumeMinimo,
            distanceKmMax: distanciaMaxima,
            timeMinutesMax: tempoMaximo
          },
          type: { id: type?.value }
        }))
      } else {
        console.log("CAIU NO CREATE")
        props.dispatch(createProduct(user?.token, user?.company, {
          name: nomeProduto,
          description: description,
          priceM3: price,
          delivery: {
            volumeM3Min: volumeMinimo,
            distanceKmMax: distanciaMaxima,
            timeMinutesMax: tempoMaximo
          },
          type: { id: type?.value }
        }))
      }

    }
  };

  useEffect(() => {
    if (uid != null) {
      setNomeProduto(uid?.name)
      setType({ value: uid?.type?.id, label: uid?.type?.name })
      setDescription(uid?.description)
      setPrice(uid?.priceM3)
      setDistanciaMaxima(uid?.delivery?.distanceKmMax)
      setVolumeMinimo(uid?.delivery?.volumeM3Min)
      setTempoMaximo(uid?.delivery?.timeMinutesMax)
    }
    props.dispatch(listCompanys(user?.token))
    props.dispatch(listProductTypes(user?.token, user?.company))
    console.log('uid', uid)
  }, [])

  useEffect(() => {
    console.log('type', type)
  }, [type])

  useEffect(() => {
    console.log('companies', companies)
    if (companies != null) {
      var result = companies.filter(item => item.id == user?.company)
      console.log('result', result[0]?.name)
      setCompany(result)
    }
  }, [companies])

  useEffect(() => {
    if (productTypes != null) {
      const newArray = data
      productTypes.map(item => {
        newArray.push({
          label: item?.name,
          value: item?.id
        })
      })
      setData(newArray)
    }
  }, [productTypes])

  useEffect(() => {
    if (success != false) {
      handleClose()
      Swal.fire({
        icon: 'success',
        title: success,
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(() => {
        props.dispatch(clear())
        props.dispatch(listProductTypes(user?.token, user?.company))
      }, 3500)
    }
  }, [success])

  useEffect(() => {
    if (error != null) {
      handleClose()

      Swal.fire({
        icon: 'error',
        title: error,
        showConfirmButton: false,
        timer: 3000
      })

      setTimeout(() => {
        props.dispatch(clear())
      }, 3500)
    }
  }, [error])

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="p-24" style={{ width: "400px", height: "800px" }}>
        <h4 className="mb-20">Adicionar Tipo de Produto</h4>
        <ValidatorForm onSubmit={() => {
          handleFormSubmit()
        }}>
          <Grid container spacing={10}>

            <Grid item sm={12}>
              <label className="mb-16 w-100">Nome da concreteira</label>
              <InputMask
                className="w-100 mb-16"
                style={{ width: "97%", padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="Nome concreteira"
                type="text"
                disabled="disabled"
                name="NomeConcreteira"
                value={company[0]?.name}
              />
              <label className="mb-4">Tipo do Produto</label>
              <InputMask
                className="w-100 mb-16"
                style={{ width: "97%", padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="Nome Produto"
                onChange={(event) => {
                  setNomeProduto(event.target.value)
                }}
                type="text"
                name="nomeProduto"
                value={nomeProduto}
              />
              <label className="mb-4">Produto</label>
              <IntegrationReactSelect data={data} onChangeFunction={(item) => { setType(item) }} selected={uid != null ? type : null} />

              <label className="mb-4">Valor médio do M3</label>
              <InputMask
                className="w-100 mb-16"
                style={{ width: "97%", padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="Preço"
                onChange={(event) => {
                  setPrice(event.target.value)
                }}
                type="number"
                name="price"
                value={price}
              />
              <label className="mb-4">Distância máxima para transporte (em KM)</label>
              <InputMask
                className="w-100 mb-16"
                style={{ width: "97%", padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="Distância máxima"
                onChange={(event) => {
                  setDistanciaMaxima(event.target.value)
                }}
                type="number"
                name="distanciaMaxima"
                value={distanciaMaxima}
              />
              <label className="mb-4">Volume mínimo para transporte (em M3)</label>
              <InputMask
                className="w-100 mb-16"
                style={{ width: "97%", padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="Volume minimo"
                onChange={(event) => {
                  setVolumeMinimo(event.target.value)
                }}
                type="number"
                name="volumeMinimo"
                value={volumeMinimo}
              />
              <label className="mb-4">Tempo máximo de entrega (em Minutos)</label>
              <InputMask
                className="w-100 mb-16"
                style={{ width: "97%", padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="Tempo Maximo"
                onChange={(event) => {
                  setTempoMaximo(event.target.value)
                }}
                type="number"
                name="tempoMaximo"
                value={tempoMaximo}
              />
              <label className="mb-4 py-8">Descrição</label>
              <InputMask
                className="w-100 mb-16"
                style={{ width: "97%", padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="Descrição"
                onChange={(event) => {
                  setDescription(event.target.value)
                }}
                type="text"
                name="description"
                value={description}
              />
            </Grid>
          </Grid>
          {
            titleError != "" && <div style={{ backgroundColor: "rgba(255,0,0,.2)", padding: 5, marginTop: 10 }}><Icon style={{ color: "red", marginRight: 5, marginLeft: 5 }} fontSize="small">close</Icon><span style={{ color: "red", fontWeight: "bold" }}>{titleError}</span></div>
          }
          <div className="flex flex-space-between flex-middle" style={{ marginTop: 10 }}>
            <Button variant="contained" color="primary" type="submit">
              {
                loading == true ?
                  <CircularProgress />
                  :
                  "Salvar"
              }
            </Button>
            <Button onClick={() => {
              handleClose()
            }}>Cancelar</Button>
          </div>
        </ValidatorForm>
      </div>
    </Dialog>
  );

}

const mapStateToProps = state => ({
  user: state.user,
  productTypes: state.product.productTypes,
  loading: state.product.loadingProduct,
  error: state.product.errorProduct,
  success: state.product.successProduct,
  companies: state.company.companies,
  products: state.product.products,
  loadingProducts: state.product.loadingProduct,
  errorProducts: state.product.errorProduct,
  successProducts: state.product.successProduct,
});

export default connect(mapStateToProps)(MemberEditorDialog);
