import CrudTable from "./TopDeliveryPerMinute";

const TopDeliveryPerMinute = [
  {
    path: "/entrega-superior-por-minuto",
    exact: true,
    component: CrudTable
  }
];

export default TopDeliveryPerMinute;
