import React, { Component, useState, useEffect } from "react";
import {
  Dialog,
  Button,
  Grid,
  Icon,
} from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { getUserById, updateUser, addNewUser } from "./TableService";
import { generateRandomId } from "utils";
import InputMask from "react-input-mask";
import Select from "./ReactSelect"
import Swal from 'sweetalert2'
import { listBranch } from "../../redux/actions/BranchActions"
import { createGoalm3Vehicle, updateGoalm3Vehicle, clear } from "../../redux/actions/FleetActions"

import { connect } from "react-redux";

const MemberEditorDialog = props => {

  const { open, handleClose, branchs, userRedux, uid } = props;

  const [tempo_maximo_entrega, setTempo_maximo_entrega] = useState("")
  const [transporte_minimo, setTransporte_minimo] = useState("")
  const [maximo_entrega, setMaximo_entrega] = useState("")
  const [minimo_entrega, setMinimo_entrega] = useState("")
  const [days, setDays] = useState([])
  const [isActive, setIsActive] = useState(false)
  const [branch, setBranch] = useState(null)
  const [options, setOptions] = useState([])
  const [message, setMessage] = useState("")

  const handleChange = (event, source) => {
    event.persist();
    if (source === "switch") {
      setIsActive(event.target.checked)
      return;
    }
  };

  const handleFormSubmit = () => {
    if (branch == "") {
      setMessage("Selecione a Filial!")
    } else if (transporte_minimo == "") {
      setMessage("Preencha o transporte mínimo!")
    } else if (minimo_entrega == "") {
      setMessage("Preencha o mínimo para entrega!")
    } else if (maximo_entrega == "") {
      setMessage("Preencha a distância máxima de entrega!")
    } else if (tempo_maximo_entrega == "") {
      setMessage("Preencha o tempo máximo de entrega!")
    } else {
      const obj = {
        branch: {
          id: 4
      },
      volumeM3Daily: 100,
      volumeM3Weekly: 500,
      volumeM3Montly: 2000
      }
      if (uid != null) {
        props.dispatch(updateGoalm3Vehicle(userRedux?.token, obj, userRedux?.company))
      } else {
        props.dispatch(createGoalm3Vehicle(userRedux?.token, obj, userRedux?.company))
      }
      handleClose()
    }

  };

  useEffect(() => {
    props.dispatch(listBranch(userRedux?.token, userRedux?.company))

  }, [])

  useEffect(() => {
    if (branchs != null) {
      console.log("branchs", branchs)
      var newOptions = []
      branchs.map(item => {
        newOptions.push({ label: item?.nameFantasy, value: item?.id })
      })
      setOptions(newOptions)
    }
  }, [branchs])



  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="p-24" style={{ width: "400px" }}>
        <h4 className="mb-20">Configurações gerais</h4>
        <ValidatorForm onSubmit={handleFormSubmit}>
          <Grid className="mb-16" container spacing={10}>
            <Grid item sm={12}>
              <Select data={options} onChange={(value) => { setBranch(value) }} />
              <label className="mb-4">Configuração de Transporte Mínimo</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="transporte_minimo"
                onChange={(event) => {
                  setTransporte_minimo(event.target.value)
                }}
                type="number"
                name="transporte_minimo"
                value={transporte_minimo}
              />
              <label className="mb-4">Volume Mínimo Para Entrega</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="minimo_entrega"
                onChange={(event) => {
                  setMinimo_entrega(event.target.value)
                }}
                type="number"
                name="minimo_entrega"
                value={minimo_entrega}
              />
              <label className="mb-4">Distância Máxima de Entrega</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="maximo_entrega"
                onChange={(event) => {
                  setMaximo_entrega(event.target.value)
                }}
                type="number"
                name="maximo_entrega"
                value={maximo_entrega}
              />
              <label className="mb-4">Tempo Máximo de Entrega</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="tempo_maximo_entrega"
                onChange={(event) => {
                  setTempo_maximo_entrega(event.target.value)
                }}
                type="number"
                name="tempo_maximo_entrega"
                value={tempo_maximo_entrega}
              />


            </Grid>
          </Grid>
          {
            message != "" && <div style={{ backgroundColor: "rgba(255,0,0,.2)", padding: 5, marginTop: 10 }}><Icon style={{ color: "red", marginRight: 5, marginLeft: 5 }} fontSize="small">close</Icon><span style={{ color: "red", fontWeight: "bold" }}>{message}</span></div>
          }
          <div className="flex flex-space-between flex-middle">
            <Button variant="contained" color="primary" type="submit">
              Salvar
            </Button>
            <Button onClick={() => props.handleClose()}>Cancelar</Button>
          </div>
        </ValidatorForm>
      </div>
    </Dialog >
  );
}

const mapStateToProps = state => ({
  userRedux: state.user,
  branchs: state.branch.branchs,
});

export default connect(mapStateToProps)(MemberEditorDialog);