/* eslint-disable */
import React, { Component, useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Button,
  Icon,
  Grid,
  Card,
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { getAllUser, deleteUser } from "./TableService";
import { Breadcrumb } from "egret";
import shortid from "shortid";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment"
import ptLocale from "date-fns/locale/pt-BR";
import { MyDocument } from "./pdf"
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReactExport from "react-export-excel";
import { connect } from "react-redux";
import IntegrationReactSelect from "./ReactSelect"
import IntegrationReactSelectProduct from "./ReactSelectProduct"
import { listProductTypes, listProducts } from "../../redux/actions/ProductActions"
import { listFckReports } from "../../redux/actions/ReportsActions"
import Swal from 'sweetalert2'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const dataSet1 = [
  {
    inicial: "02/09/2021",
    final: "02/09/2021",
    produto: 'Concreto',
    tipo_produto: "Concreto",
    volume_m3: "300",
    valor_medio: "R$ 100,00",
    valor_total: "R$ 200,00",
  },
  {
    inicial: "02/09/2021",
    final: "02/09/2021",
    produto: 'Concreto',
    tipo_produto: "FCK 10",
    volume_m3: "300",
    valor_medio: "R$ 100,00",
    valor_total: "R$ 200,00",
  },
  {
    inicial: "02/09/2021",
    final: "02/09/2021",
    produto: 'Concreto',
    tipo_produto: "FCK 20",
    volume_m3: "300",
    valor_medio: "R$ 100,00",
    valor_total: "R$ 200,00",
  },

];

const CrudTable = props => {

  const { userRedux, productTypes, products, fck } = props

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [date, setDate] = useState(new Date())
  const [distancia, setDistancia] = useState(0)
  const [volume, setVolume] = useState("")
  const [finalDate, setFinalDate] = useState(new Date())
  const [userList, setUserList] = useState([])
  const [product, setProduct] = useState(null)
  const [optionsProduct, setOptionsProduct] = useState([])
  const [optionsProductType, setOptionsProductType] = useState([])
  const [productType, setProductType] = useState(null)
  const [dataSet, setDataSet] = useState([])
  const [
    , setShouldOpenEditorDialog] = useState(false)
  const [shouldOpenConfirmationDialog, setShouldOpenConfirmationDialog] = useState(false)


  const setPageFunction = page => {
    setPage(page)
  };

  const setRowsPerPageFunction = event => {
    setRowsPerPage(event.target.value)
  };

  const handleChangePage = (event, newPage) => {
    setPageFunction(newPage);
  };

  const handleDialogClose = () => {
    setShouldOpenEditorDialog(false)
    setShouldOpenConfirmationDialog(false)
  };

  const handleDeleteUser = user => {
    setShouldOpenConfirmationDialog(true)
  };

  const handleConfirmationResponse = () => {

  };

  const createObjectDataSet = (reports) => {
    var newObj = []
    reports?.map((item, key) => {
      newObj.push({
        Data_inicio: item?.startDate,
        Data_fim: item?.endDate,
        Produto: item?.product?.type?.name,
        Tipo_Produto: item?.product?.name,
        Total: item?.valueTotal?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        Media: item?.valueMedia?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        Total_m3: item?.volumeTotalM3

      })
      return item;
    })
    console.log("newOjt", newObj)
    setDataSet(newObj)
  }

  const handleDateChange = date => {
    console.log("date", date)
    setDate(date)
  };

  const handleFinalDateChange = finalDate => {
    setFinalDate(finalDate)
  };

  const listarFck = () => {
    if (date == "") {
      Swal.fire({
        icon: 'error',
        title: "Preencha a data inicial!",
        showConfirmButton: false,
        timer: 3000
      })
    } else if (finalDate == "") {
      Swal.fire({
        icon: 'error',
        title: "Preencha a data final!",
        showConfirmButton: false,
        timer: 3000
      })
    } else if (product == null) {
      Swal.fire({
        icon: 'error',
        title: "Preencha o produto!",
        showConfirmButton: false,
        timer: 3000
      })
    } else if (productType == null) {
      Swal.fire({
        icon: 'error',
        title: "Preencha o tipo do produto!",
        showConfirmButton: false,
        timer: 3000
      })
    } else {
      props.dispatch(listFckReports(userRedux?.token, userRedux?.company, productType, product, moment(date).format("DD/MM/YYYY"), moment(finalDate).format("DD/MM/YYYY")))
    }
  }

  useEffect(() => {
    console.log("fck", fck)
    if (fck != null) {
      createObjectDataSet(fck)
    }
  }, [fck])

  useEffect(() => {
    if (productTypes != null) {
      console.log("productTypes", productTypes)
      var newOptions = []
      productTypes.map(item => {
        newOptions.push({ label: item?.name, value: item?.id })
      })
      console.log("newOptions", newOptions)
      setOptionsProduct(newOptions)
    }
  }, [productTypes])

  useEffect(() => {
    if (products != null) {
      console.log("products", products)
      var newOptions = []
      products.map(item => {
        if (item?.name?.indexOf("FCK") != -1 || item?.name?.indexOf("fck") != -1) {
          newOptions.push({ label: item?.name, value: item?.id })
        }
      })
      console.log("newOptions 2", newOptions)
      setOptionsProductType(newOptions)
    }
  }, [products])

  useEffect(() => {
    if (product != null) {
      props.dispatch(listProducts(userRedux?.token, userRedux?.company, product))
    }
  }, [product])

  useEffect(() => {
    props.dispatch(listProductTypes(userRedux?.token, userRedux?.company))

  }, [])

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: "Cálculo de vendas por FCK" }]} />
      </div>
      <Grid container spacing={5}>
        <Grid item lg={3} md={3} sm={12} xs={12} style={{ marginTop: 6 }}>
          <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="mb-16 w-100"
              margin="none"
              id="mui-pickers-date"
              label="Data Início"
              format="dd/MM/yyyy"
              inputVariant="standard"
              type="text"
              autoOk={true}
              value={date}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12} style={{ marginTop: 6 }}>
          <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="mb-16 w-100"
              margin="none"
              id="mui-pickers-date"
              format="dd/MM/yyyy"
              label="Data Fim"
              inputVariant="standard"
              type="text"
              autoOk={true}
              value={finalDate}
              onChange={handleFinalDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item lg={2} md={2} sm={12} xs={12}>
          <IntegrationReactSelectProduct data={optionsProduct} setSelected={(value) => { setProduct(value) }} />
        </Grid>
        <Grid item lg={2} md={2} sm={12} xs={12}>
          <IntegrationReactSelect data={optionsProductType} setSelected={(value) => { setProductType(value) }} />
        </Grid>
        <Grid item lg={2} md={2} sm={12} xs={12}>
          <Button color="primary" variant="contained" type="button" onClick={() => { listarFck() }} style={{ marginTop: 10 }}>
            <Icon>filter_list</Icon>
            <span className="pl-8 capitalize">Filtrar</span>
          </Button>
        </Grid>
      </Grid>
      {
        fck?.length > 0 ?
          <>
            <ExcelFile filename="relatorio-vendas-fck" element={<Button style={{ backgroundColor: "#f78726", color: "white", marginBottom: 10, marginRight: 5 }} aria-label="Add">Exportar Excel</Button>}>
              <ExcelSheet data={dataSet} name="Employees">
                <ExcelColumn label="Data Inicial" value="Data_inicio" />
                <ExcelColumn label="Data Final" value="Data_fim" />
                <ExcelColumn label="Produto" value="Produto" />
                <ExcelColumn label="Tipo Produto" value="Tipo_Produto" />
                <ExcelColumn label="Volume em m3" value="Total_m3" />
                <ExcelColumn label="Valor médio" value="Media" />
                <ExcelColumn label="Valor Total" value="Total" />
              </ExcelSheet>
            </ExcelFile>

            <PDFDownloadLink document={<MyDocument data={dataSet} />} fileName="relatório-vendas-fck.pdf">
              <Button style={{ backgroundColor: "transparent", border: "1.5px solid #f78726", color: "#f78726", marginBottom: 10 }} aria-label="Add">Exportar Pdf</Button>
            </PDFDownloadLink>
            <Card className="w-100 overflow-auto" elevation={6}>
              <h6 style={{ textAlign: "center", marginRight: 10, fontSize: 10, marginTop: 10 }}>Data de emissão: {moment().format("D/M/Y")}</h6>
              <h6 style={{ textAlign: "center", marginRight: 10, fontSize: 10, }}>Usuário: {userRedux?.displayName}</h6>
              <h4 style={{ textAlign: "center", marginRight: 10 }}>Cálculo de vendas por FCK</h4>

              <Table className="crud-table" style={{ whiteSpace: "pre", minWidth: "750px" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Data Inicial</TableCell>
                    <TableCell>Data Final</TableCell>
                    <TableCell>Produto</TableCell>
                    <TableCell>Tipo Produto</TableCell>
                    <TableCell>Volume total em m3</TableCell>
                    <TableCell>Valor médio</TableCell>
                    <TableCell>Valor total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fck
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((item, index) => (
                      <TableRow key={shortid.generate()} style={{ paddingVertical: 5 }}>
                        <TableCell className="px-0" align="left">{item?.startDate}</TableCell>
                        <TableCell className="px-0" align="left">{item?.endDate}</TableCell>
                        <TableCell className="px-0" align="left">{item?.product?.type?.name}</TableCell>
                        <TableCell className="px-0" align="left">{item?.product?.name}</TableCell>
                        <TableCell className="px-0" align="left">{item?.valueTotal}</TableCell>
                        <TableCell className="px-0" align="left">{item?.valueMedia}</TableCell>
                        <TableCell className="px-0" align="left">{item?.volumeTotalM3}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>

              <TablePagination
                className="px-16"
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={fck.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage="Linhas por Página"
                backIconButtonProps={{
                  "aria-label": "Anterior"
                }}
                nextIconButtonProps={{
                  "aria-label": "Próxima"
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={setRowsPerPageFunction}
              />
            </Card>
          </>
          :
          <Card className="w-100 overflow-auto" elevation={6} style={{ padding: 50 }}>
            <h4 align="center">Sem resultados</h4>
            <h6 align="center">(Selecione os parâmetros)</h6>
          </Card>
      }

    </div >
  );
}

const mapStateToProps = state => ({
  userRedux: state.user,
  products: state.product.products,
  fck: state.reports.fck,
  productTypes: state.product.productTypes,
});

export default connect(mapStateToProps)(CrudTable);
