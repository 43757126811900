import history from "history.js";
import jwtAuthService from "../../services/jwtAuthService";
import axios from "axios";


export const CREATE_COMPANIES_LOADING = "CREATE_COMPANIES_LOADING";
export const CREATE_COMPANIES_SUCCESS = "CREATE_COMPANIES_SUCCESS";
export const CREATE_COMPANIES_FAILED = "CREATE_COMPANIES_FAILED";
export const UPDATE_COMPANIES_LOADING = "UPDATE_COMPANIES_LOADING";
export const UPDATE_COMPANIES_SUCCESS = "UPDATE_COMPANIES_SUCCESS";
export const UPDATE_COMPANIES_FAILED = "UPDATE_COMPANIES_FAILED";
export const DELETE_COMPANIES_LOADING = "DELETE_COMPANIES_LOADING";
export const DELETE_COMPANIES_SUCCESS = "DELETE_COMPANIES_SUCCESS";
export const DELETE_COMPANIES_FAILED = "DELETE_COMPANIES_FAILED";
export const LIST_COMPANIES_LOADING = "LIST_COMPANIES_LOADING";
export const LIST_COMPANIES_SUCCESS = "LIST_COMPANIES_SUCCESS";
export const LIST_COMPANIES_FAILED = "LIST_COMPANIES_FAILED";
export const LIST_ORDER_COMPANY_LOADING = "LIST_ORDER_COMPANY_LOADING";
export const LIST_ORDER_COMPANY_SUCCESS = "LIST_ORDER_COMPANY_SUCCESS";
export const LIST_ORDER_COMPANY_FAILED = "LIST_ORDER_COMPANY_FAILED";
export const LIST_ORDER_WAITING_FOR_SELLER_COMPANY_LOADING = "LIST_ORDER_WAITING_FOR_SELLER_COMPANY_LOADING";
export const LIST_ORDER_WAITING_FOR_SELLER_COMPANY_SUCCESS = "LIST_ORDER_WAITING_FOR_SELLER_COMPANY_SUCCESS";
export const LIST_ORDER_WAITING_FOR_SELLER_COMPANY_FAILED = "LIST_ORDER_WAITING_FOR_SELLER_COMPANY_FAILED";
export const LIST_FILIAL_RAIO_LOADING = "LIST_FILIAL_RAIO_LOADING";
export const LIST_FILIAL_RAIO_SUCCESS = "LIST_FILIAL_RAIO_SUCCESS";
export const LIST_FILIAL_RAIO_FAILED = "LIST_FILIAL_RAIO_FAILED";
export const UPDATE_FILIAL_RAIO_LOADING = "UPDATE_FILIAL_RAIO_LOADING";
export const UPDATE_FILIAL_RAIO_SUCCESS = "UPDATE_FILIAL_RAIO_SUCCESS";
export const UPDATE_FILIAL_RAIO_FAILED = "UPDATE_FILIAL_RAIO_FAILED";

export const CLEAR = "CLEAR";

export function listOrderWaitingForSellerCompany(token, company) {
  return dispatch => {
    dispatch({
      type: LIST_ORDER_WAITING_FOR_SELLER_COMPANY_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/order/company/${company}/aguardando_vendedor`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_ORDER_WAITING_FOR_SELLER_COMPANY_SUCCESS,
        payload: res.data
      });
      console.log("res LIST_ORDER_WAITING_FOR_SELLER_COMPANY_SUCCESS", res)
    }).catch(eer => {
      console.log("eer LIST_ORDER_WAITING_FOR_SELLER_COMPANY_FAILED", eer?.response?.data?.message)
      dispatch({
        type: LIST_ORDER_WAITING_FOR_SELLER_COMPANY_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}
export function listOrderCompany(token, company) {
  return dispatch => {
    dispatch({
      type: LIST_ORDER_COMPANY_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/order/company/${company}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_ORDER_COMPANY_SUCCESS,
        payload: res.data
      });
      console.log("res LIST_ORDER_COMPANY_SUCCESS", res)
    }).catch(eer => {
      console.log("eer LIST_ORDER_COMPANY_FAILED", eer?.response?.data?.message)
      dispatch({
        type: LIST_ORDER_COMPANY_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}
export function listCompanys(token) {
  return dispatch => {
    dispatch({
      type: LIST_COMPANIES_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/company`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_COMPANIES_SUCCESS,
        payload: res.data
      });
      console.log("res LIST_COMPANIES_SUCCESS", res)
    }).catch(eer => {
      console.log("eer LIST_COMPANIES_FAILED", eer?.response?.data?.message)
      dispatch({
        type: LIST_COMPANIES_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function createCompany(token, data) {
  return dispatch => {
    dispatch({
      type: CREATE_COMPANIES_LOADING,
    });
    axios({
      method: 'post',
      url: `https://api.stonik.com.br/web/company`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: CREATE_COMPANIES_SUCCESS,
        payload: res.data
      });
      console.log("res CREATE_COMPANIES_SUCCESS", res)
    }).catch(eer => {
      console.log("eer CREATE_COMPANIES_FAILED", eer?.response?.data?.message)
      dispatch({
        type: CREATE_COMPANIES_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function updateCompany(token, data, company) {
  return dispatch => {
    dispatch({
      type: UPDATE_COMPANIES_LOADING,
    });
    axios({
      method: 'put',
      url: `https://api.stonik.com.br/web/company/${company}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: UPDATE_COMPANIES_SUCCESS,
        payload: res.data
      });
      console.log("res UPDATE_COMPANIES_SUCCESS", res)
    }).catch(eer => {
      console.log("eer UPDATE_COMPANIES_FAILED", eer?.response?.data?.message)
      dispatch({
        type: UPDATE_COMPANIES_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function deleteCompany(token, company) {
  return dispatch => {
    dispatch({
      type: DELETE_COMPANIES_LOADING,
    });
    axios({
      method: 'delete',
      url: `https://api.stonik.com.br/web/company/${company}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: DELETE_COMPANIES_SUCCESS,
        payload: res.data
      });
      console.log("res DELETE_COMPANIES_SUCCESS", res)
    }).catch(eer => {
      console.log("eer DELETE_COMPANIES_FAILED", eer?.response?.data?.message)
      dispatch({
        type: DELETE_COMPANIES_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function listRaioFilial(token, company) {
  return dispatch => {
    dispatch({
      type: LIST_FILIAL_RAIO_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/company/${company}/branch/raio`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_FILIAL_RAIO_SUCCESS,
        payload: res.data
      });
      console.log("res LIST_FILIAL_RAIO_SUCCESS", res)
    }).catch(eer => {
      console.log("eer LIST_FILIAL_RAIO_FAILED", eer?.response?.data?.message)
      dispatch({
        type: LIST_FILIAL_RAIO_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function updateRaioFilial(token, company, data, branch) {
  return dispatch => {
    dispatch({
      type: UPDATE_FILIAL_RAIO_LOADING,
    });
    axios({
      method: 'put',
      url: `https://api.stonik.com.br/web/company/${company}/branch/raio/${branch}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: UPDATE_FILIAL_RAIO_SUCCESS,
        payload: res.data
      });
      console.log("res UPDATE_FILIAL_RAIO_SUCCESS", res)
    }).catch(eer => {
      console.log("eer UPDATE_FILIAL_RAIO_FAILED", eer?.response?.data?.message)
      dispatch({
        type: UPDATE_FILIAL_RAIO_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function clear() {
  return dispatch => {
    dispatch({
      type: CLEAR
    });
  };
}
