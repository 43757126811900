import UserTable from "./GeneralReports";

const GeneralReports = [
  {
    path: "/geral",
    exact: true,
    component: UserTable
  }
];

export default GeneralReports;
