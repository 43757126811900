import AppSnackbar from "./snackbar/AppSnackbar";
import AppDialog from "./dialog/AppDialog";
import AppExpansionPanel from "./expansion-panel/AppExpansionPanel";
import AppAutoComplete from "./auto-complete/AppAutoComplete";
import AppSlider from "./slider/AppSlider";
import AppRadio from "./radio/AppRadio";
import AppSwitch from "./switch/AppSwitch";
import AppCheckbox from "./checkbox/AppCheckbox";
import AppMenu from "./menu/AppMenu";
import AppProgress from "./AppProgress";
import AppIcon from "./icons/AppIcon";
import AppButton from "./buttons/AppButton";
import AppForm from "./forms/AppForm";
import AppTable from "./tables/AppTable";


const materialRoutes = [
    {
        path: "/material/table",
        component: AppTable
    },
    {
        path: "/material/form",
        component: AppForm
    },
    {
        path: "/material/buttons",
        component: AppButton
    },
    {
        path: "/material/icons",
        component: AppIcon
    },
    {
        path: "/material/progress",
        component: AppProgress
    },
    {
        path: "/material/menu",
        component: AppMenu
    },
    {
        path: "/material/checkbox",
        component: AppCheckbox
    },
    {
        path: "/material/switch",
        component: AppSwitch
    },
    {
        path: "/material/radio",
        component: AppRadio
    },
    {
        path: "/material/slider",
        component: AppSlider
    },
    {
        path: "/material/autocomplete",
        component: AppAutoComplete
    },
    {
        path: "/material/expansion-panel",
        component: AppExpansionPanel
    },
    {
        path: "/material/dialog",
        component: AppDialog
    },
    {
        path: "/material/snackbar",
        component: AppSnackbar
    },
]

export default materialRoutes;
