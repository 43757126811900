import history from "history.js";
import jwtAuthService from "../../services/jwtAuthService";
import axios from "axios";


export const LIST_DRIVER_LOADING = "LIST_DRIVER_LOADING";
export const LIST_DRIVER_SUCCESS = "LIST_DRIVER_SUCCESS";
export const LIST_DRIVER_FAILED = "LIST_DRIVER_FAILED";
export const UPDATE_DRIVER_LOADING = "UPDATE_DRIVER_LOADING";
export const UPDATE_DRIVER_SUCCESS = "UPDATE_DRIVER_SUCCESS";
export const UPDATE_DRIVER_FAILED = "UPDATE_DRIVER_FAILED";
export const DELETE_DRIVER_LOADING = "DELETE_DRIVER_LOADING";
export const DELETE_DRIVER_SUCCESS = "DELETE_DRIVER_SUCCESS";
export const DELETE_DRIVER_FAILED = "DELETE_DRIVER_FAILED";
export const CREATE_DRIVER_LOADING = "CREATE_DRIVER_LOADING";
export const CREATE_DRIVER_SUCCESS = "CREATE_DRIVER_SUCCESS";
export const CREATE_DRIVER_FAILED = "CREATE_DRIVER_FAILED";

export const CLEAR = "CLEAR";

export function listDriver(token, company) {
  return dispatch => {
    dispatch({
      type: LIST_DRIVER_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/company/${company}/driver`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_DRIVER_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_DRIVER_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function createDriver(token, data, company) {
  return dispatch => {
    dispatch({
      type: CREATE_DRIVER_LOADING,
    });
    axios({
      method: 'post',
      url: `https://api.stonik.com.br/web/company/${company}/driver`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: CREATE_DRIVER_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: CREATE_DRIVER_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}
export function updateDriver(token, data, company) {
  return dispatch => {
    dispatch({
      type: UPDATE_DRIVER_LOADING,
    });
    axios({
      method: 'put',
      url: `https://api.stonik.com.br/web/company/${company}/driver/${data.id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: UPDATE_DRIVER_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: UPDATE_DRIVER_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function deleteDriver(token, id, company) {
  return dispatch => {
    dispatch({
      type: DELETE_DRIVER_LOADING,
    });
    axios({
      method: 'delete',
      url: `https://api.stonik.com.br/web/company/${company}/driver/${id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: DELETE_DRIVER_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: DELETE_DRIVER_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function clear() {
  return dispatch => {
    dispatch({
      type: CLEAR
    });
  };
}
