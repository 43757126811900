import Pricing from "./Pricing";

const otherRoutes = [
  {
    path: "/others/pricing",
    component: Pricing
  }
];

export default otherRoutes;
