import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Icon,
  TablePagination,
  Button,
  Grid,
  Card,
} from "@material-ui/core";
import { Breadcrumb } from "egret";
import shortid from "shortid";
import IntegrationReactSelect from "./ReactSelect";
import { MyDocument } from "../utilities/pdf"
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReactExport from "react-export-excel";
import { connect } from "react-redux";
import Swal from 'sweetalert2'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const CrudTable = (props) => {

  const { userRedux } = props

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [dataSet1, setDataSet1] = useState([
    {
      inicial: "02/09/2021",
      final: "02/09/2021",
      filial: 'Matriz',
      faturado: "R$ 3000,00"
    },
    {
      inicial: "02/09/2021",
      final: "02/09/2021",
      filial: 'Matriz',
      faturado: "R$ 3000,00"
    }, {
      inicial: "02/09/2021",
      final: "02/09/2021",
      filial: 'Matriz',
      faturado: "R$ 3000,00"
    },
  ])
  const [page, setPage] = useState(0)
  const [date, setDate] = useState(new Date())
  const [finalDate, setFinalDate] = useState(new Date())
  const [userList, setUserList] = useState([])
  const [volume, setVolume] = useState("")
  const [distancia, setDistancia] = useState("")
  const [column, setColumn] = useState(null)
  const [columns, setColumns] = useState([])
  const [labels, setLabels] = useState([
    { label: "Volume em produção diária em m3", value: "Volume em produção diária em m3" },
    { label: "Produção diária em R$", value: "Produção diária em R$" },
    { label: "Frota", value: "Frota" },
    { label: "Volume Produzido", value: "Volume Produzido" },
    { label: "Filial", value: "Filial" },
    { label: "Tipo do produto", value: "Tipo do produto" },
  ])
  const [shouldOpenEditorDialog, setShouldOpenEditorDialog] = useState(false)
  const [shouldOpenConfirmationDialog, setShouldOpenConfirmationDialog] = useState(false)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  };

  const handleDialogClose = () => {
    setShouldOpenEditorDialog(false)
    setShouldOpenConfirmationDialog(false)
  };

  const setRowsPerPageFunction = event => {
    setRowsPerPage(event.target.value);
  };

  const handleDeleteUser = user => {
    setShouldOpenConfirmationDialog(true)
  };

  const handleConfirmationResponse = () => {

  };

  useEffect(() => {
    // getAllUser().then(({ data }) => setUserList(data))
  })

  useEffect(() => {
    console.log("column select", column)
  }, [column])

  useEffect(() => {
    console.log("columns selecteds", columns)
  }, [columns])

  useEffect(() => {
    console.log("userList", userList)
  }, [userList])

  const addColumn = () => {
    var column_add = [...columns]
    if (column != null) {
      var index = column_add.indexOf(column.value)
      // var index_values = labels.value.indexOf(column.value)
      labels.map((item, key) => {
        // console.log("index", index)
        if (item.value == column.value) {
          labels.splice(key, 1)
        }
      })

      // labels.filter(item => {

      // })
      console.log("index", index)
      console.log("labels", labels)
      if (index == -1) {
        setLabels(labels)
        column_add.push(column.value)
        setColumns(column_add)

      }
    }
  }

  const saveTemplate = () => {
    Swal.fire({
      icon: 'warning',
      title: "Deseja realmente salvar este relatório?",
      showDenyButton: true,
      confirmButtonText: 'Adicionar',
      denyButtonText: `Não adicionar`,
    }).then((result) => {
      Swal.fire({
        icon: 'success',
        title: "Relatório salvo com sucesso!",
        timer: 3000
      })
    })
  }

  const handleDateChange = date => {
    setDate(date)
  };

  const handleFinalDateChange = finalDate => {
    setFinalDate(finalDate)
  };

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: "Relatório personalizado" }]} />
      </div>
      <Grid container spacing={5}>
        <Grid item lg={8} md={8} sm={12} xs={12} style={{ marginTop: 6 }}>
          <IntegrationReactSelect values={labels} getValue={(value) => {
            if (value != "") {
              setColumn(value)
            }

          }} />
        </Grid>
        <Grid item lg={2} md={2} sm={12} xs={12}>
          <Button color="primary" variant="contained" onClick={() => { addColumn() }} type="submit" style={{ marginTop: 10 }}>
            <Icon>add_circle_outline</Icon>
            <span className="pl-8 capitalize">Adicionar Coluna</span>
          </Button>
        </Grid>
        <Grid item lg={2} md={2} sm={12} xs={12}>
          <Button color="primary" variant="contained" onClick={() => { saveTemplate() }} type="submit" style={{ marginTop: 10 }}>
            <Icon>cloud_done</Icon>
            <span className="pl-8 capitalize">Salvar relatório</span>
          </Button>
        </Grid>
      </Grid>
      {/* {
        userList.length > 0 ?
          <> */}
      <ExcelFile filename="relatorio-faturamento" element={<Button style={{ backgroundColor: "green", color: "white", marginBottom: 10, marginRight: 5, marginTop: 10 }} aria-label="Add">Exportar Excel</Button>}>
        <ExcelSheet data={dataSet1} name="Employees">
          <ExcelColumn label="Data Inicial" value="inicial" />
          <ExcelColumn label="Data Final" value="final" />
          <ExcelColumn label="Filial" value="filial" />
          <ExcelColumn label="Valor Faturado" value="faturado" />
        </ExcelSheet>
      </ExcelFile>

      <PDFDownloadLink document={<MyDocument />} fileName="relatório-faturamento.pdf">
        <Button style={{ backgroundColor: "#d4241c", color: "white", marginBottom: 10, marginTop: 10 }} aria-label="Add">Exportar Pdf</Button>
      </PDFDownloadLink>
      <Card className="w-100 overflow-auto" elevation={6}>
        <h6 style={{ textAlign: "center", marginRight: 10, fontSize: 10, marginTop: 10 }}>Data de emissão: 02/09/2021</h6>
        <h6 style={{ textAlign: "center", marginRight: 10, fontSize: 10, }}>Usuário: Bruno Victor</h6>
        <h4 style={{ textAlign: "center", marginRight: 10 }}>Relatório personalizado</h4>

        <Table className="crud-table" style={{ whiteSpace: "pre", minWidth: "750px" }}>
          <TableHead>
            <TableRow>
              {
                columns.map(item => (
                  <TableCell>{item}</TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={shortid.generate()} style={{ paddingVertical: 5 }}>
              {
                columns.map(item => (

                  <TableCell className="px-0" align="left">{item}</TableCell>
                ))
              }

            </TableRow>
            {/* {userList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user, index) => (
                
              )
              )} */}
          </TableBody>
        </Table>

        <TablePagination
          className="px-16"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={userList.length}
          rowsPerPage={rowsPerPage}
          page={page}
                labelRowsPerPage="Linhas por Página"
                backIconButtonProps={{
            "aria-label": "Anterior"
          }}
          nextIconButtonProps={{
            "aria-label": "Próxima"
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={setRowsPerPageFunction}
        />
      </Card>
      {/* </>
          :
          <Card className="w-100 overflow-auto" elevation={6} style={{ padding: 50 }}>
            <h4 align="center">Sem resultados</h4>
            <h6 align="center">(Selecione os parâmetros)</h6>
          </Card>
      } */}

    </div >
  );
}

const mapStateToProps = state => ({
  userRedux: state.user,
  operators: state.operator.operators,
  loading: state.operator.loading,
  error: state.operator.error,
  success: state.operator.success,
});

export default connect(mapStateToProps)(CrudTable);