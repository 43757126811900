import history from "history.js";
import jwtAuthService from "../../services/jwtAuthService";
import axios from "axios";


export const LIST_GENERAL_OPERATION_REPORTS_LOADING = "LIST_GENERAL_OPERATION_REPORTS_LOADING";
export const LIST_GENERAL_OPERATION_REPORTS_SUCCESS = "LIST_GENERAL_OPERATION_REPORTS_SUCCESS";
export const LIST_GENERAL_OPERATION_REPORTS_FAILED = "LIST_GENERAL_OPERATION_REPORTS_FAILED";
export const LIST_GENERAL_REPORTS_LOADING = "LIST_GENERAL_REPORTS_LOADING";
export const LIST_GENERAL_REPORTS_SUCCESS = "LIST_GENERAL_REPORTS_SUCCESS";
export const LIST_GENERAL_REPORTS_FAILED = "LIST_GENERAL_REPORTS_FAILED";
export const LIST_REVENUES_REPORTS_LOADING = "LIST_REVENUES_REPORTS_LOADING";
export const LIST_REVENUES_REPORTS_SUCCESS = "LIST_REVENUES_REPORTS_SUCCESS";
export const LIST_REVENUES_REPORTS_FAILED = "LIST_REVENUES_REPORTS_FAILED";
export const LIST_DIARY_PRODUCTION_REPORTS_LOADING = "LIST_DIARY_PRODUCTION_REPORTS_LOADING";
export const LIST_DIARY_PRODUCTION_REPORTS_SUCCESS = "LIST_DIARY_PRODUCTION_REPORTS_SUCCESS";
export const LIST_DIARY_PRODUCTION_REPORTS_FAILED = "LIST_DIARY_PRODUCTION_REPORTS_FAILED";
export const LIST_CONCRETE_MIXER_PRODUCTION_REPORTS_LOADING = "LIST_CONCRETE_MIXER_PRODUCTION_REPORTS_LOADING";
export const LIST_CONCRETE_MIXER_PRODUCTION_REPORTS_SUCCESS = "LIST_CONCRETE_MIXER_PRODUCTION_REPORTS_SUCCESS";
export const LIST_CONCRETE_MIXER_PRODUCTION_REPORTS_FAILED = "LIST_CONCRETE_MIXER_PRODUCTION_REPORTS_FAILED";
export const LIST_PRODUCTION_BY_PRODUCT_REPORTS_LOADING = "LIST_PRODUCTION_BY_PRODUCT_REPORTS_LOADING";
export const LIST_PRODUCTION_BY_PRODUCT_REPORTS_SUCCESS = "LIST_PRODUCTION_BY_PRODUCT_REPORTS_SUCCESS";
export const LIST_PRODUCTION_BY_PRODUCT_REPORTS_FAILED = "LIST_PRODUCTION_BY_PRODUCT_REPORTS_FAILED";
export const LIST_DISTANCE_AND_VOLUME_REPORTS_LOADING = "LIST_DISTANCE_AND_VOLUME_REPORTS_LOADING";
export const LIST_DISTANCE_AND_VOLUME_REPORTS_SUCCESS = "LIST_DISTANCE_AND_VOLUME_REPORTS_SUCCESS";
export const LIST_DISTANCE_AND_VOLUME_REPORTS_FAILED = "LIST_DISTANCE_AND_VOLUME_REPORTS_FAILED";
export const LIST_AUDITORIA_REPORTS_LOADING = "LIST_AUDITORIA_REPORTS_LOADING";
export const LIST_AUDITORIA_REPORTS_SUCCESS = "LIST_AUDITORIA_REPORTS_SUCCESS";
export const LIST_AUDITORIA_REPORTS_FAILED = "LIST_AUDITORIA_REPORTS_FAILED";
export const LIST_FCK_REPORTS_LOADING = "LIST_FCK_REPORTS_LOADING";
export const LIST_FCK_REPORTS_SUCCESS = "LIST_FCK_REPORTS_SUCCESS";
export const LIST_FCK_REPORTS_FAILED = "LIST_FCK_REPORTS_FAILED";


export const CLEAR = "CLEAR";

export function listGeneralOperationReports(token, startDate, finalDate, company) {
  return dispatch => {
    dispatch({
      type: LIST_GENERAL_OPERATION_REPORTS_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/report/geral/operation?companyId=${company}&startDateFilter=${startDate}&endDateFilter=${finalDate}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_GENERAL_OPERATION_REPORTS_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_GENERAL_OPERATION_REPORTS_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function listGeneralReports(token, startDate, finalDate, company) {
  return dispatch => {
    dispatch({
      type: LIST_GENERAL_REPORTS_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/report/geral?companyId=${company}&startDateFilter=${startDate}&endDateFilter=${finalDate}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_GENERAL_REPORTS_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_GENERAL_REPORTS_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function listRevenuesReports(token, startDate, finalDate, company, branch = null) {
  return dispatch => {
    dispatch({
      type: LIST_REVENUES_REPORTS_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/report/billing?companyId=${company}${branch != null ? "&companyBranchId=" + branch : ""}&startDateFilter=${startDate}&endDateFilter=${finalDate}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_REVENUES_REPORTS_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_REVENUES_REPORTS_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function listDiaryProductionReports(token, startDate, finalDate, company) {
  return dispatch => {
    dispatch({
      type: LIST_DIARY_PRODUCTION_REPORTS_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/report/production/daily?companyId=${company}&startDateFilter=${startDate}&endDateFilter=${finalDate}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_DIARY_PRODUCTION_REPORTS_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_DIARY_PRODUCTION_REPORTS_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function listConcreteMixerProductionReports(token, startDate, finalDate, vehicleId = null, company) {
  return dispatch => {
    dispatch({
      type: LIST_CONCRETE_MIXER_PRODUCTION_REPORTS_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/report/production/media/betoneira?companyId=${company}${vehicleId == null ? "" : "&vehicleId=" + vehicleId}&startDateFilter=${startDate}&endDateFilter=${finalDate}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_CONCRETE_MIXER_PRODUCTION_REPORTS_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_CONCRETE_MIXER_PRODUCTION_REPORTS_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function listProductionByProductReports(token, startDate, finalDate, company, companyBranchId = null, productId = null) {
  return dispatch => {
    dispatch({
      type: LIST_PRODUCTION_BY_PRODUCT_REPORTS_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/report/production/media/product?companyId=${company}${companyBranchId == null ? "" : "&companyBranchId=" + companyBranchId}${productId == null ? "" : "&productId=" + productId }&startDateFilter=${startDate}&endDateFilter=${finalDate}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_PRODUCTION_BY_PRODUCT_REPORTS_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_PRODUCTION_BY_PRODUCT_REPORTS_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function listDistanceAndVolumeReports(token, startDate, finalDate, distance_min = 100, distance_max = "", volume_m3_min = "", volume_m3_max = 100, company, vehicleId = null) {
  return dispatch => {
    dispatch({
      type: LIST_DISTANCE_AND_VOLUME_REPORTS_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/report/production/distance-volume?companyId=${company}${vehicleId == null ? "" : "&vehicleId=" + vehicleId}&startDateFilter=${startDate}&endDateFilter=${finalDate}&distanceMin=${distance_min}&distanceMax=${distance_max}&volumeM3Min=${volume_m3_min}&volumeM3Max=${volume_m3_max}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_DISTANCE_AND_VOLUME_REPORTS_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_DISTANCE_AND_VOLUME_REPORTS_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function listAuditoriaReports(token, user, company, start, end) {
  return dispatch => {
    dispatch({
      type: LIST_AUDITORIA_REPORTS_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/audit?companyId=${company}&userId=${user}&startDate=${start}&endDate=${end}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_AUDITORIA_REPORTS_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_AUDITORIA_REPORTS_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function listFckReports(token, company, product, productType, start, end) {
  return dispatch => {
    dispatch({
      type: LIST_FCK_REPORTS_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/report/fck?companyId=${company}&productId=${product}&productTypeId=${productType}&startDateFilter=${start}&endDateFilter=${end}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_FCK_REPORTS_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_FCK_REPORTS_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function clear() {
  return dispatch => {
    dispatch({
      type: CLEAR
    });
  };
}
