import React, { useState, useEffect } from "react";
import {
  Dialog,
  Button,
  Grid,
  FormControlLabel,
  Switch,
  Icon,
  LinearProgress,
  CircularProgress
} from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputMask from "react-input-mask";

import { connect } from "react-redux";

import { listFleet, createFleet, updateFleet, clear } from "../../redux/actions/FleetActions"

import Swal from 'sweetalert2'

const MemberEditorDialog = (props) => {

  const {
    open,
    handleClose,
    user,
    loading,
    error,
    success,
    uid,
    fleets
  } = props

  const [frota, setFrota] = useState(uid != null ? uid.fleet : "")
  const [name, setName] = useState(uid != null ? uid.name : "")
  const [plate, setPlate] = useState(uid != null ? uid.plate : "")
  const [description, setDescription] = useState(uid != null ? uid.description : "")
  const [type, setType] = useState(uid != null ? uid.type : "")

  const [titleError, setTitleError] = useState("")

  const handleFormSubmit = () => {
    if (plate == "") {
      setTitleError("Preencha a placa da frota")
    } else if (type == "") {
      setTitleError("Preencha o tipo da frota")
    } else {
      uid != null ?
        props.dispatch(updateFleet(user?.token, {
          id: uid?.id,
          name,
          plate,
          fleet: frota,
          description,
          type,
          company: {
            id: user?.company
          }
        }, user?.company))
        :
        props.dispatch(createFleet(user?.token, {
          name,
          plate,
          fleet: frota,
          description,
          type,
          company: {
            id: user?.company
          }
        }, user?.company))
    }
  };

  useEffect(() => {
    if (success != false) {
      handleClose()
      Swal.fire({
        icon: 'success',
        title: success,
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(() => {
        props.dispatch(clear())
        props.dispatch(listFleet(user?.token, user?.company))
      }, 3500)
    }
  }, [success])

  useEffect(() => {
    if (error != null) {
      handleClose()

      Swal.fire({
        icon: 'error',
        title: error,
        showConfirmButton: false,
        timer: 3000
      })

      setTimeout(() => {
        props.dispatch(clear())
      }, 3500)
    }
  }, [error])

  useEffect(() => {
    console.log("props", props)
  }, [props])

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="p-24" style={{ width: "400px" }}>
        <h4 className="mb-20">Adicionar Frota</h4>
        <ValidatorForm onSubmit={() => {
          handleFormSubmit()
        }}>
          <Grid container spacing={10}>
            <Grid item sm={12}>


              {/* <label className="mb-4">Nome</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="Name"
                onChange={(event) => {
                  setName(event.target.value)
                }}
                type="numeric"
                name="name"
                value={name}
              /> */}
              <label className="mb-4">Placa</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="Placa"
                onChange={(event) => {
                  setPlate(event.target.value)
                }}
                type="numeric"
                name="placa"
                value={plate}
              />
              <label className="mb-4">Frota</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="Frota"
                onChange={(event) => {
                  setFrota(event.target.value)
                }}
                type="numeric"
                name="frota"
                value={frota}
              />
              <label className="mb-4">Descrição</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="Description"
                onChange={(event) => {
                  setDescription(event.target.value)
                }}
                type="numeric"
                name="Description"
                value={description}
              />

              <label className="mb-4">Tipo</label>
              <select disabled={uid != null} onChange={(event) => { setType(event.target.value) }} className="w-100 mb-16" style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                <option value=""></option>
                <option selected={type == "BETONEIRA"} value="Betoneira">Betoneira</option>
                <option selected={type == "BOMBA"} value="Bomba">Bomba</option>
              </select>

            </Grid>
          </Grid>
          {
            titleError != "" && <div style={{ backgroundColor: "rgba(255,0,0,.2)", padding: 5, marginTop: 10 }}><Icon style={{ color: "red", marginRight: 5, marginLeft: 5 }} fontSize="small">close</Icon><span style={{ color: "red", fontWeight: "bold" }}>{titleError}</span></div>
          }

          <div className="flex flex-space-between flex-middle" style={{ marginTop: 10 }}>
            <Button variant="contained" color="primary" type="submit">
              {
                loading == true ?
                  <CircularProgress fontSize="small" />
                  :
                  "Salvar"
              }

            </Button>
            <Button onClick={() => {
              handleClose()
            }}>Cancelar</Button>
          </div>
        </ValidatorForm>
      </div>
    </Dialog >
  );

}

const mapStateToProps = state => ({
  user: state.user,
  fleets: state.fleet.fleets,
  loading: state.fleet.loading,
  error: state.fleet.error,
  success: state.fleet.success,
});

export default connect(mapStateToProps)(MemberEditorDialog);
