import AppMap from "./AppMap";

const mapRoutes = [
  {
    path: "/map",
    component: AppMap
  }
];

export default mapRoutes;
