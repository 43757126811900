import React, { useEffect, useState } from "react";
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Button,
  withStyles,
  Icon,
  CircularProgress
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { withRouter, Link } from "react-router-dom";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { loginWithEmailAndPassword, resetPassword, sendCode, clear } from "../../redux/actions/LoginActions";

import Input from "./../../components/Input"


const styles = theme => ({
  wrapper: {
    position: "relative"
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

const SignIn = props => {

  const { classes, userRedux, history } = props;

  const [cpf, setCpf] = useState('')
  const [password, setPassword] = useState('')
  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState('')
  const [openAttempt, setOpenAttempt] = useState(false)
  const [titleAttempt, setTitleAttempt] = useState('')
  const [show, setShow] = useState(false)
  const [attempts, setAttempts] = useState(1)

  useEffect(() => {
    console.log("attempts", attempts)
    if (props.login.error != null) {
      console.log("caiu aqui", props.login.error)
      if (attempts == 3) {
        setOpenAttempt(true)
        setTitleAttempt("Você tentou logar 3 vezes, sem sucesso! um email foi enviado para você no email cadastrado neste cpf: " + cpf)
        props.resetPassword(cpf);

        setAttempts(1)
      } else {
        setOpen(true)
        setTitle(props.login.error)
        let attemptsSome = attempts + 1
        setAttempts(attemptsSome)
      }

      props.clear()
    }
  }, [props.login.error])

  const goToPainel = () => {
    // window.location.href = "/painel";
    history.push({ pathname: "/painel" });
  }

  

  useEffect(() => {
    return () => {
      setAttempts(1)
    }
  }, [])

  const handleClose = () => {
    setOpen(false)
  }

  const handleCloseAttempt = () => {
    setOpenAttempt(false)
    props.history.push("/session/forgot-password", { showMeCode: true, cpfSignIn: cpf })
  }

  const handleFormSubmit = event => {
    props.loginWithEmailAndPassword({ password, cpf: cpf.replace(/[^0-9]/g, "") });
  };



  return (
    <>

      {
        userRedux?.token == null || userRedux?.token == "" || userRedux?.token == undefined ? 
        <div className="signup flex flex-center w-100 h-100vh">
            <Dialog
              open={openAttempt}
              onClose={handleCloseAttempt}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Erro"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {titleAttempt}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseAttempt} color="primary">
                  Tudo bem
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Erro"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {title}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Tudo bem
                </Button>
              </DialogActions>
            </Dialog>
            <div className="p-8">
              <Card className="signup-card position-relative y-center">
                <Grid container>
                  <Grid item lg={5} md={5} sm={5} xs={12}>
                    <div className="p-32 flex flex-center flex-middle h-100">
                      <img src="/assets/images/stonik-laranja.png" alt="" />
                    </div>
                  </Grid>
                  <Grid item lg={7} md={7} sm={7} xs={12}>
                    <div className="p-36 h-100 bg-light-gray position-relative">
                      <ValidatorForm onSubmit={handleFormSubmit}>
                        <Input label="Cpf" mask="999.999.999-99" value={cpf} onChange={(event) => {
                          setCpf(event.target.value)
                        }} />
                        <div style={{ cursor: "pointer", position: "absolute", marginTop: 30, right: 45 }} onClick={() => { setShow(!show) }}>
                          <Icon style={{ color: "darkgray" }}>{show ? "visibility_off" : "visibility"}</Icon>
                        </div>
                        <Input type={show ? "" : "password"} label="Senha" mask="" value={password} onChange={(event) => {
                          setPassword(event.target.value)
                        }} />
                        <FormControlLabel
                          className="mb-8"
                          name="agreement"
                          control={<Checkbox checked />}
                          label="Eu aceito os termos de uso e política de privacidade"
                        />
                        <div className="flex flex-middle mb-8">
                          <div className={classes.wrapper}>
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={props.login.loading}
                              type="submit"
                            >
                              Login
                            </Button>
                            {props.login.loading && (
                              <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                              />
                            )}
                          </div>
                          {/* <span className="ml-16 mr-8">ou</span>
                      <Button
                        className="capitalize"
                        onClick={() =>
                          this.props.history.push("/session/signup")
                        }
                      >
                        Cadastrar
                      </Button> */}
                        </div>
                        <Button
                          className="text-primary"
                          onClick={() =>
                            props.history.push("/session/forgot-password")
                          }
                        >
                          Esqueci minha senha
                        </Button>
                      </ValidatorForm>
                    </div>
                  </Grid>
                </Grid>
              </Card>
            </div>
          </div>
        : goToPainel()
      }
    </>
  );
}

const mapStateToProps = state => ({
  loginWithEmailAndPassword: PropTypes.func.isRequired,
  login: state.login,
  userRedux: state.user,
});
export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      { loginWithEmailAndPassword, resetPassword, clear }
    )(SignIn)
  )
);
