import {
  SET_USER_DATA,
  REMOVE_USER_DATA,
  USER_LOGGED_OUT,
  REGISTER_USER_LOADING,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILED,
  CHANGE_AVATAR_LOADING,
  CHANGE_AVATAR_SUCCESS,
  CHANGE_AVATAR_FAILED,
  UPDATE_USER_LOADING,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED,
  UPDATE_ALERT_LOADING,
  UPDATE_ALERT_SUCCESS,
  UPDATE_ALERT_FAILED,
  LIST_ALERT_LOADING,
  LIST_ALERT_SUCCESS,
  LIST_ALERT_FAILED,
  UPDATE_PASSWORD_LOADING,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILED,
  CLEAR
} from "../actions/UserActions";
import localStorageService from "../../services/localStorageService";

import jwtAuthService from "../../services/jwtAuthService";

const initialState = {success: false,
  loading: false,
  error: null,
  user: null,
  alerts: []
};

const userReducer = function(state = initialState, action) {
  switch (action.type) {
    case SET_USER_DATA: {
      return {
        ...state,
        ...action.data
      };
    }
    case REMOVE_USER_DATA: {
      return {
        ...state
      };
    }
    case REGISTER_USER_LOADING: {
      console.log("REGISTER_USER_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case REGISTER_USER_SUCCESS: {
      console.log("REGISTER_USER_SUCCESS")
      return {
        ...state,
        success: "Cadastro efetuado com sucesso!",
        loading: false,
        error: null
      };
    }
    case REGISTER_USER_FAILED: {
      console.log("REGISTER_USER_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case CHANGE_AVATAR_LOADING: {
      console.log("CHANGE_AVATAR_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case CHANGE_AVATAR_SUCCESS: {
      console.log("CHANGE_AVATAR_SUCCESS")
      return {
        ...state,
        success: "Foto de perfil alterada com sucesso!",
        loading: false,
        error: null
      };
    }
    case CHANGE_AVATAR_FAILED: {
      console.log("CHANGE_AVATAR_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case LIST_ALERT_LOADING: {
      console.log("LIST_ALERT_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_ALERT_SUCCESS: {
      console.log("LIST_ALERT_SUCCESS", action)
      return {
        ...state,
        alerts: action.payload,
        loading: false,
        error: null
      };
    }
    case LIST_ALERT_FAILED: {
      console.log("LIST_ALERT_FAILED", action.payload)
      return {
        ...state,
        alerts: action.data,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case UPDATE_ALERT_LOADING: {
      console.log("UPDATE_ALERT_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_ALERT_SUCCESS: {
      return {
        ...state,
        success: "Alertas vinculados com sucesso!",
        loading: false,
        error: null
      };
    }
    case UPDATE_ALERT_FAILED: {
      console.log("UPDATE_ALERT_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case UPDATE_USER_LOADING: {
      console.log("UPDATE_USER_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_USER_SUCCESS: {
      var auth_user = localStorageService.getItem("auth_user")
      var newUser = {
        ...auth_user,
        displayName: action.payload.name,
        contact: action.payload.contact,
        email: action.payload.email,
        cpf: action.payload.cpf,
      }

      localStorageService.setItem("auth_user", newUser)

      console.log("UPDATE_USER_SUCCESS", action.payload)

      return {
        ...state,
        displayName: action.payload.name,
        contact: action.payload.contact,
        email: action.payload.email,
        cpf: action.payload.cpf,
        success: "Dados alterados com sucesso!",
        loading: false,
        error: null
      };
    }
    case UPDATE_USER_FAILED: {
      console.log("UPDATE_USER_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case UPDATE_PASSWORD_LOADING: {
      console.log("UPDATE_PASSWORD_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_PASSWORD_SUCCESS: {
      console.log("UPDATE_PASSWORD_SUCCESS", action.payload)

      return {
        ...state,
        success: "Senha alterada com sucesso!",
        loading: false,
        error: null
      };
    }
    case UPDATE_PASSWORD_FAILED: {
      console.log("UPDATE_PASSWORD_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case CLEAR: {
      return {
        ...state,
        error: null,
        success: false,
        loading: false
      };
    }
    case USER_LOGGED_OUT: {
      jwtAuthService.setUser([])
      return {
        modules: [],
        success: false,
        loading: false,
        error: null,
        user: null
      };
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
