import React, { Component, useState, useEffect } from "react";
import {
  Dialog,
  Button,
  Grid,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { getUserById, updateUser, addNewUser } from "./TableService";
import { generateRandomId } from "utils";
import InputMask from "react-input-mask";
import {
  TimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import Select from "./ReactSelect"
import { listBranch } from "../../redux/actions/BranchActions"
import { updateRaioFilial } from "../../redux/actions/CompanyActions"
import { connect } from "react-redux";
const MemberEditorDialog = props => {

  let { open, handleClose, userRedux, uid, branchs } = props;

  const [days, setDays] = useState([])
  const [raio, setRaio] = useState(uid != null ? uid?.raio : "")
  const [options, setOptions] = useState([])
  const [branch, setBranch] = useState(uid != null ? uid?.branch?.id : "")
  const [message, setMessage] = useState("")

  console.log("uid", uid)

  const handleChange = (event, source) => {
  };

  const handleFormSubmit = () => {
    if (raio == "") {
      setMessage("Preencha o raio")
    } else {
      const obj = {
        raio
      }
      props.dispatch(updateRaioFilial(userRedux?.token, userRedux?.company, obj, branch))
      handleClose()
    }
  };

  useEffect(() => {
    props.dispatch(listBranch(userRedux?.token, userRedux?.company))
  }, [])

  useEffect(() => {
    if (branchs != null) {
      console.log("branchs", branchs)
      var newOptions = []
      branchs.map(item => {
        newOptions.push({ label: item?.name, value: item?.id })
      })
      setOptions(newOptions)
    }
  }, [branchs])


  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="p-24" style={{ width: "400px" }}>
        <h4 className="mb-20">Km Do Raio Da Unidade</h4>
        <ValidatorForm onSubmit={handleFormSubmit}>
          <Grid className="mb-16" container spacing={10}>
            <Grid item sm={12}>
              <Select data={options} selected={branch} disabled={uid == null ? false : true} onChange={(value) => { setBranch(value) }} />
              <label className="mb-4">Km do Raio</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="raio"
                onChange={(event) => {setRaio(event.target.value)}}
                type="number"
                name="raio"
                value={raio}
              />

            </Grid>
          </Grid>

          <div className="flex flex-space-between flex-middle">
            <Button variant="contained" color="primary" type="submit">
              Salvar
            </Button>
            <Button onClick={() => props.handleClose()}>Cancelar</Button>
          </div>
        </ValidatorForm>
      </div>
    </Dialog >
  );
}

const mapStateToProps = state => ({
  userRedux: state.user,
  branchs: state.branch.branchs,
});

export default connect(mapStateToProps)(MemberEditorDialog);
