import React, { Component, useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControlLabel,
  TablePagination,
  Button,
  Grid,
  Card,
} from "@material-ui/core";
import { getAllUser, deleteUser } from "./TableService";
import { Breadcrumb } from "egret";
import shortid from "shortid";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptLocale from "date-fns/locale/pt-BR";
import IntegrationReactSelect from "./ReactSelect";
import { MyDocument } from "../utilities/pdf"
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReactExport from "react-export-excel";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from "react-redux";
import Swal from 'sweetalert2'

import { listarAlert, updateAlert, clear } from "../../redux/actions/UserActions"
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const dataSet1 = [
  {
    inicial: "02/09/2021",
    final: "02/09/2021",
    filial: 'Matriz',
    faturado: "R$ 3000,00"
  },
  {
    inicial: "02/09/2021",
    final: "02/09/2021",
    filial: 'Matriz',
    faturado: "R$ 3000,00"
  }, {
    inicial: "02/09/2021",
    final: "02/09/2021",
    filial: 'Matriz',
    faturado: "R$ 3000,00"
  },
];

const CrudTable = props => {
  const { userRedux, alerts, success, error } = props

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [date, setDate] = useState(new Date())
  const [distancia, setDistancia] = useState(0)
  const [uid, setUid] = useState(0)
  const [volume, setVolume] = useState("")
  const [finalDate, setFinalDate] = useState(new Date())
  const [userList, setUserList] = useState([])
  const [checkboxs, setCheckboxs] = useState([])
  const [boxes, setBoxes] = useState([])
  const [shouldOpenEditorDialog, setShouldOpenEditorDialog] = useState(false)
  const [shouldOpenConfirmationDialog, setShouldOpenConfirmationDialog] = useState(false)
  const setPageFunction = page => {
    setPage(page)
  };

  const setRowsPerPageFunction = event => {
    setRowsPerPage(event.target.value)
  };

  const handleChangePage = (event, newPage) => {
    setPageFunction(newPage);
  };

  const handleDialogClose = () => {
    setShouldOpenEditorDialog(false)
    setShouldOpenConfirmationDialog(false)
    updatePageData();
  };

  const handleDeleteUser = user => {
    setShouldOpenConfirmationDialog(true)
  };

  const handleConfirmationResponse = () => {

  };

  useEffect(() => {
    if (success != false) {
      setShouldOpenConfirmationDialog(false)
      Swal.fire({
        icon: 'success',
        title: success,
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(() => {
        props.dispatch(clear())
        window.location.reload()
      }, 3500)
    }
  }, [success])

  useEffect(() => {
    if (error != null) {
      console.log("Error", error)
      setShouldOpenConfirmationDialog(false)

      Swal.fire({
        icon: 'error',
        title: error,
        showConfirmButton: false,
        timer: 3000
      })

      setTimeout(() => {
        props.dispatch(clear())
        window.location.reload()
      }, 3500)
    }
  }, [error])

  useEffect(() => {
    props.dispatch(listarAlert(userRedux?.token))
  }, [])
  useEffect(() => {
    console.log("alerts", alerts)
    if (alerts != null) {
      setBoxes(alerts)
    }
  }, [alerts])

  const updatePageData = () => {
    getAllUser().then(({ data }) => setUserList([...data]));
  };

  const handleDateChange = date => {
    console.log("date", date)
    setDate(date)
  };

  const handleFinalDateChange = finalDate => {
    setFinalDate(finalDate)
  };

  const handleSubmission = () => {
    props.dispatch(updateAlert(userRedux?.token, boxes))
  }

  const handleChange = value => {
    console.log(value)
    const checkboxReply = [...boxes]
    var newBox = checkboxReply?.find(inner => inner?.alert?.value == value)
    console.log("newBox", newBox)
    if (newBox != undefined) {
      var index = checkboxReply?.indexOf(newBox)
      var permission = newBox.permission
      newBox.permission = !permission
      checkboxReply.splice(index, 1, newBox)
    }
    console.log("checkboxReply", checkboxReply)
    setBoxes(checkboxReply)
  }

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: "Selecione os seus alertas" }]} />
      </div>
      <Grid container spacing={5}>
        {
          boxes?.map((item, key) => {
            return <Grid item lg={12} md={12} sm={12} xs={12}>
              <FormControlLabel
                className="ml-0"
                key={1}
                control={
                  <Checkbox
                    name={item?.alert?.name}
                    checked={boxes?.find(inner => (inner?.alert?.value == item?.alert?.value && inner?.permission == true)) != undefined ? true : false}
                    onChange={event => { handleChange(event.target.value) }}
                    value={item?.alert?.value}
                  />
                }
                label={
                  item?.alert?.name
                }
              />
            </Grid>
          })
        }
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Button variant="contained" color="primary" onClick={() => { handleSubmission() }} style={{ marginTop: 10 }}>
            <span className="pl-8 capitalize">Salvar</span>
          </Button>
        </Grid>
      </Grid>
    </div >
  );
}

const mapStateToProps = state => ({
  userRedux: state.user,
  operators: state.operator.operators,
  alerts: state.user.alerts,
  loading: state.user.loading,
  error: state.user.error,
  success: state.user.success,
});

export default connect(mapStateToProps)(CrudTable);
