import React from "react";
import { Redirect } from "react-router-dom";

import dashboardRoutes from "./views/dashboard/DashboardRoutes";
import PainelRoutes from "./views/painel/PainelRoutes";
import utilitiesRoutes from "./views/utilities/UtilitiesRoutes";
import sessionRoutes from "./views/sessions/SessionRoutes";

import materialRoutes from "./views/material-kit/MaterialRoutes";
import chartsRoute from "./views/charts/ChartsRoute";
import dragAndDropRoute from "./views/Drag&Drop/DragAndDropRoute";
import invoiceRoutes from "./views/invoice/InvoioceRoutes";
import calendarRoutes from "./views/calendar/CalendarRoutes";
import crudRoute from "./views/CRUD/CrudRoutes";
import UserExternalRoutes from "./views/AddExternalUser/UserExternalRoutes";
import UserInternRoutes from "./views/AddInternUser/UserInternRoutes";
import NewUserRoutes from "./views/AddNewUser/NewUserRoutes";
import Company from "./views/Company/CompanyRoutes";
import AddClientRoutes from "./views/AddClient/AddClientRoutes";
import AddManagerRoutes from "./views/AddManager/AddManagerRoutes";
import AddTypistRoutes from "./views/AddTypist/AddTypistRoutes";
import AddSalesManRoutes from "./views/AddSalesMan/AddSalesManRoutes";
import AddIndicatorsRoutes from "./views/AddIndicators/AddIndicatorsRoutes";
import AddDriverRoutes from "./views/AddDriver/AddDriverRoutes";
import AddProductTypeRoutes from "./views/AddProductType/AddProductTypeRoutes";
import AddProductRoutes from "./views/AddProduct/AddProductRoutes";
import AddFleetRoutes from "./views/AddFleet/AddFleetRoutes";
import AddCompanyBranchRoutes from "./views/AddCompanyBranch/AddCompanyBranchRoutes";
import AddOperatorRoutes from "./views/AddOperator/AddOperatorRoutes";
import AddBomberRoutes from "./views/AddBomber/AddBomberRoutes";
import AddAuditRoutes from "./views/AddAudit/AddAuditRoutes";
import AddTicketRoutes from "./views/AddTicket/AddTicketRoutes";
import AddContractRoutes from "./views/AddContract/AddContractRoutes";
import AddApplicationTypeRoutes from "./views/AddApplicationType/AddApplicationTypeRoutes";
import AddDiscardRoutes from "./views/AddDiscard/AddDiscardRoutes";
import AddExceptionRoutes from "./views/AddException/AddExceptionRoutes";
import EditUserRoutes from "./views/AlterUser/EditUserRoutes";
import EditPasswordRoutes from "./views/AlterPassword/EditPasswordRoutes";
import MyGeneralConfigsRoutes from "./views/MyGeneralConfigs/MyGeneralConfigsRoutes";
import ClientConfigsRoutes from "./views/ClientConfigs/ClientConfigsRoutes";
import MyMetricMixerConfigsRoutes from "./views/MyMetricMixerConfigs/MyMetricMixerConfigsRoutes";
import MyRaioConfigsRoutes from "./views/MyRaioConfigs/MyRaioConfigsRoutes";
import MyAverageSaleValueConfigsRoutes from "./views/MyAverageSaleValueConfigs/MyAverageSaleValueConfigsRoutes";
import GeneralOperationReportsRoutes from "./views/GeneralOperationReports/GeneralOperationReportsRoutes";
import GeneralReportsRoutes from "./views/GeneralReports/GeneralReportsRoutes";
import RevenuesReportsRoutes from "./views/RevenuesReports/RevenuesReportsRoutes";
import DiaryProductionReportsRoutes from "./views/DiaryProductionReports/DiaryProductionReportsRoutes";
import ConcreteMixerProductionReportsRoutes from "./views/ConcreteMixerProductionReports/ConcreteMixerProductionReportsRoutes";
import ProductionByProductReportsRoutes from "./views/ProductionByProductReports/ProductionByProductReportsRoutes";
import AnalysisOfDistanceAndVolumeReportsRoutes from "./views/AnalysisOfDistanceAndVolumeReports/AnalysisOfDistanceAndVolumeReportsRoutes";
import UnloadingReportsRoutes from "./views/UnloadingReports/UnloadingReportsRoutes";
import CalculationSalesPerFckReportsRoutes from "./views/CalculationSalesPerFckReports/CalculationSalesPerFckReportsRoutes";
import CustomReportsRoutes from "./views/CustomReports/CustomReportsRoutes";
import DiscardReportsRoutes from "./views/DiscardReports/DiscardReportsRoutes";
import DiscardAnalysisReportsRoutes from "./views/DiscardAnalysisReports/DiscardAnalysisReportsRoutes";
import InconsistenciesReportsRoutes from "./views/InconsistenciesReports/InconsistenciesReportsRoutes";
import DiscardRadiusReportsRoutes from "./views/DiscardRadiusReports/DiscardRadiusReportsRoutes";
import RequestTrackingRoutes from "./views/RequestTracking/RequestTrackingRoutes";
import ScheduleManagementRoutes from "./views/ScheduleManagement/ScheduleManagementRoutes";
import inboxRoute from "./views/inbox/InboxRoutes";
import formsRoutes from "./views/forms/FormsRoutes";
import mapRoutes from "./views/map/MapRoutes";
import chatRoutes from "./views/chat-box/ChatRoutes";
import todoRoutes from "./views/todo/TodoRoutes";
import pageLayoutRoutes from "./views/page-layouts/PageLayoutRoutees";
import ListRoute from "./views/list/ListRoute";
import OrdersQueueRoutes from "./views/OrdersQueue/OrdersQueueRoutes";
import WashingControlRoutes from "./views/WashingControl/WashingControlRoutes";
import OcurrencesRoutes from "./views/Ocurrences/OcurrencesRoutes";
import SecondDischardAlertRoutes from "./views/SecondDischardAlert/SecondDischardAlertRoutes";
import ExceededDistanceAlertRoutes from "./views/ExceededDistanceAlert/ExceededDistanceAlertRoutes";
import ExceededDistanceByVolumeAlertRoutes from "./views/ExceededDistanceByVolumeAlert/ExceededDistanceByVolumeAlertRoutes";
import TopDeliveryPerMinuteRoutes from "./views/TopDeliveryPerMinute/TopDeliveryPerMinuteRoutes";
import MyDischardsRoutes from "./views/MyDischards/MyDischardsRoutes";
import MyDiscartsRoutes from "./views/MyDiscarts/MyDiscartsRoutes";
import MyWashesRoutes from "./views/MyWashes/MyWashesRoutes";

/*
  Required if you are going to use Evelynn Landing page
  https://themeforest.net/item/react-landing-material-ui-react-saasproduct-landing-page/23847400
*/
import homeRoutes from "./views/home/HomeRoutes";

import otherRoutes from "./views/others/OtherRoutes";
import scrumBoardRoutes from "./views/scrum-board/ScrumBoardRoutes";
import ecommerceRoutes from "./views/ecommerce/EcommerceRoutes";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/login" />
  }
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />
  }
];

const routes = [
  ...homeRoutes,
  ...sessionRoutes,
  ...dashboardRoutes,
  ...PainelRoutes,
  ...materialRoutes,
  ...utilitiesRoutes,
  ...chartsRoute,
  ...dragAndDropRoute,
  ...calendarRoutes,
  ...invoiceRoutes,
  ...crudRoute,
  ...UserExternalRoutes,
  ...UserInternRoutes,
  ...NewUserRoutes,
  ...Company,
  ...AddClientRoutes,
  ...AddManagerRoutes,
  ...AddTypistRoutes,
  ...AddSalesManRoutes,
  ...AddIndicatorsRoutes,
  ...AddDriverRoutes,
  ...AddProductTypeRoutes,
  ...AddProductRoutes,
  ...AddFleetRoutes,
  ...AddCompanyBranchRoutes,
  ...AddOperatorRoutes,
  ...AddBomberRoutes,
  ...AddAuditRoutes,
  ...AddTicketRoutes,
  ...AddContractRoutes,
  ...AddApplicationTypeRoutes,
  ...AddDiscardRoutes,
  ...AddExceptionRoutes,
  ...EditUserRoutes,
  ...OrdersQueueRoutes,
  ...EditPasswordRoutes,
  ...MyGeneralConfigsRoutes,
  ...ClientConfigsRoutes,
  ...MyMetricMixerConfigsRoutes,
  ...MyRaioConfigsRoutes,
  ...MyAverageSaleValueConfigsRoutes,
  ...GeneralOperationReportsRoutes,
  ...GeneralReportsRoutes,
  ...RevenuesReportsRoutes,
  ...DiaryProductionReportsRoutes,
  ...ConcreteMixerProductionReportsRoutes,
  ...ProductionByProductReportsRoutes,
  ...AnalysisOfDistanceAndVolumeReportsRoutes,
  ...CalculationSalesPerFckReportsRoutes,
  ...UnloadingReportsRoutes,
  ...CustomReportsRoutes,
  ...DiscardReportsRoutes,
  ...DiscardAnalysisReportsRoutes,
  ...InconsistenciesReportsRoutes,
  ...DiscardRadiusReportsRoutes,
  ...RequestTrackingRoutes,
  ...ScheduleManagementRoutes,
  ...WashingControlRoutes,
  ...OcurrencesRoutes,
  ...MyDischardsRoutes,
  ...MyDiscartsRoutes,
  ...MyWashesRoutes,
  ...SecondDischardAlertRoutes,
  ...ExceededDistanceAlertRoutes,
  ...ExceededDistanceByVolumeAlertRoutes,
  ...TopDeliveryPerMinuteRoutes,
  ...inboxRoute,
  ...formsRoutes,
  ...mapRoutes,
  ...chatRoutes,
  ...todoRoutes,
  ...scrumBoardRoutes,
  ...ecommerceRoutes,
  ...pageLayoutRoutes,
  ...otherRoutes,
  ...ListRoute,
  ...redirectRoute,
  ...errorRoute
];

export default routes;
