import React, { Component, useState, useEffect } from "react";
import {
  Dialog,
  Button,
  Grid,
  FormControlLabel,
  Switch,
  CircularProgress,
  Icon
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { getUserById, updateUser, addNewUser } from "./TableService";
import { generateRandomId } from "utils";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import { createClient } from "../../redux/actions/ClientActions"
import { listPermissionsOfClient, updateModuleUser } from "../../redux/actions/ModuleActions"

import Swal from 'sweetalert2'

const MemberEditorDialog = (props) => {

  const { handleClose, open, uid, clients, user, modules, modules_client } = props

  const [pessoa, setPessoa] = useState(uid != null ? uid?.client?.id : "");

  const [message, setMessage] = useState("");
  const [indexSelecteds, setIndexSelecteds] = useState([]);

  const handleFormSubmit = () => {
    handleClose()
    console.log("modules", modules)
    var newArray = []
    //Verifica os indexs selecionados e adiciona ao array como permission true
    indexSelecteds.map(index => {
      var filter = modules_client.filter(item => item?.module?.id == index)
      newArray.push({
        module: { ...filter[0]?.module },
        permission: true
      })
    })
    //Verifica os indexs deselecionados e adiciona ao array como permission false
    modules_client.map(item => {
      console.log("result" + item, indexSelecteds.indexOf(item?.module?.id))
      if (indexSelecteds.indexOf(item?.module?.id) == -1) {
        newArray.push({
          module: { ...item?.module },
          permission: false
        })
      }
    })

    console.log("newArray", newArray)
    props.dispatch(updateModuleUser(user?.token, user?.company, uid?.id, newArray))
    // Swal.fire({
    //   icon: 'success',
    //   title: "Módulos vinculados ao usuário!",
    //   showConfirmButton: false,
    //   timer: 5000
    // })
  }

  const handleChange = (value) => {
    console.log("value", value)
    var newArray = [...indexSelecteds]
    var index = newArray.indexOf(parseInt(value))
    if (index == -1) {
      newArray.push(parseInt(value))
    } else {
      newArray.splice(index, 1)
    }
    setIndexSelecteds(newArray)
    // console.log("newArray", newArray)
  }

  useEffect(() => {
    console.log("Clients", clients)
    console.log("uid", uid)
    if (uid != null) {
      props.dispatch(listPermissionsOfClient(user?.token, user?.company, uid?.id))
    }

  }, [])

  useEffect(() => {
    if (modules_client != null) {
      console.log("modules_client", modules_client)
      var indexs = []
      modules_client.map(item => {
        item?.permission == true && indexs.push(item?.module?.id)
        
      })
      setIndexSelecteds(indexs)
    }
  }, [modules_client])

  useEffect(() => {
    indexSelecteds.length > 0 && console.log("indexSelecteds", indexSelecteds)
  }, [indexSelecteds])

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="p-24" style={{ width: "400px" }}>
        <h4 className="mb-20">Adicionar Configuração</h4>
        <ValidatorForm onSubmit={() => {
          handleFormSubmit()
        }}>
          <Grid className="mb-16" container spacing={10}>
            <Grid item sm={12}>
              <label className="mb-4">Usuário</label>
              {
                uid != null ?
                  <>
                    <InputMask
                      className="w-100 mb-16"
                      style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1, marginBottom: 30 }}
                      label="nome"
                      disabled="disabled"
                      type="text"
                      value={uid?.name}
                    />
                  </>
                  :
                  <>
                    <select onChange={(event) => { setPessoa(event.target.value) }} className="w-100 mb-16" style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                      <option value=""></option>
                      {
                        clients.map(item => {
                          return (
                            <option value={item?.client?.id}>{item?.client?.name} {item?.client?.cpf}</option>
                          )
                        })
                      }
                    </select>
                  </>
              }

              {
                pessoa != "" && (
                  <>
                    {
                      modules_client == null ?
                        (
                          <CircularProgress className="primary" />
                        )
                        :
                        modules_client?.length > 0 && modules_client?.map((item, key) => {
                          return (
                            <Grid item sm={12}>
                              <FormControlLabel
                                className="ml-0"
                                key={key}
                                control={
                                  <Checkbox
                                    name={key}
                                    checked={indexSelecteds.indexOf(item?.module?.id) == -1 ? false : true}
                                    onChange={event => { handleChange(event.target.value) }}
                                    value={item?.module?.id}
                                  />
                                }
                                label={
                                  item?.module?.description
                                }
                              />
                            </Grid>
                          )
                        })
                    }

                  </>
                )
              }


              {
                message != "" && <div style={{ backgroundColor: "rgba(255,0,0,.2)", padding: 5, marginTop: 10 }}><Icon style={{ color: "red", marginRight: 5, marginLeft: 5 }} fontSize="small">close</Icon><span style={{ color: "red", fontWeight: "bold" }}>{message}</span></div>
              }
            </Grid>
          </Grid>

          <div className="flex flex-space-between flex-middle">
            <Button variant="contained" color="primary" type="submit">
              Salvar
            </Button>
            <Button onClick={() => {
              handleClose()
            }}>Cancelar</Button>
          </div>
        </ValidatorForm>
      </div>
    </Dialog>
  );
}

const mapStateToProps = state => ({
  user: state.user,
  modules_client: state.module.modules_client
});

export default connect(mapStateToProps)(MemberEditorDialog);