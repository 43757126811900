/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";
import { RechartCreator } from "egret";
import moment from "moment"

const data = [
  {
    name: "Hoje",
    OnePlus: 4000,
    Mi: 2400,
    amt: 2400
  },
  {
    name: "Ontem",
    OnePlus: 3000,
    Mi: 1398,
    amt: 2210
  },
  {
    name: moment().subtract("2", "day").format("DD/MM/YYYY"),
    OnePlus: 2000,
    Mi: 9800,
    amt: 2290
  },
  {
    name: moment().subtract("3", "day").format("DD/MM/YYYY"),
    OnePlus: 2780,
    Mi: 3908,
    amt: 2000
  },
  {
    name: moment().subtract("4", "day").format("DD/MM/YYYY"),
    OnePlus: 1890,
    Mi: 4800,
    amt: 2181
  },
  {
    name: moment().subtract("5", "day").format("DD/MM/YYYY"),
    OnePlus: 2390,
    Mi: 3800,
    amt: 2500
  },
  {
    name: moment().subtract("6", "day").format("DD/MM/YYYY"),
    OnePlus: 3490,
    Mi: 4300,
    amt: 2100
  },
  {
    name: moment().subtract("7", "day").format("DD/MM/YYYY"),
    OnePlus: 3000,
    Mi: 1398,
    amt: 2210
  }
];

const array = ["a", "b", "c", "d", "e", "f", "g"];

const SimpleLineChart = ({ height, width, data }) => {



  const [newData, setNewData] = useState(null)
  const [keys, setKeys] = useState([])

  useEffect(() => {
    var newArr = [];
    console.log("data", data)
    if (data != null) {
      var dataArr = data

      dataArr?.map((item, keyData) => {

        var products = {}
        var values = []

        // var myobj = {
        //   nome: item?.date

        // }
        // var valor = array[keyData]

        // myobj[valor] = 0
        // newArr.push(myobj)

        products.valor = item.volumeTotalM3

        let obj = {
          nome: item.date,
          ...products
        }

        newArr.push(obj)
        // var myobj = {
        //   nome: item?.date

        // }
        // var valor = array[keyData]

        // myobj[valor] = 0
        // newArr.push(myobj)
      })
      setNewData(newArr.reverse())
    }
  }, [data])

  return (
    <RechartCreator height={height} width={width}>
      <LineChart
        data={newData}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0
        }}
      >
        <CartesianGrid vertical={true} strokeDasharray="3 3" />
        <XAxis dataKey="nome" />
        <YAxis />
        <Tooltip />
        {console.log("new Data", newData)}
        <Line
                type="monotone"
                dataKey={"valor"}
                stroke="#f78726"
                activeDot={{ r: 8 }}
              />
        {/* {
          newData != null && newData.map((item) => {
            var keys = Object.keys(item)
            console.log("keys", keys)
            keys.splice(keys.indexOf("nome"), 1)
            return (
              <Line
                type="monotone"
                dataKey={keys[0]}
                stroke="#8884d8"
                activeDot={{ r: 0 }}
              />
            )
          })
        } */}
      </LineChart>
    </RechartCreator>
  );
};

export default SimpleLineChart;
