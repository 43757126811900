import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Icon,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Modal,
  TableCell,
  Card,
  Box
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Breadcrumb } from "egret";
import MarkerMap from "./MarkerMap";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptLocale from "date-fns/locale/pt-BR";
import ReactExport from "react-export-excel";
import { connect } from "react-redux";
import Swal from 'sweetalert2'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const CrudTable = (props) => {

  const { userRedux } = props

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [dataSet1, setDataSet1] = useState([
    {
      inicial: "02/09/2021",
      final: "02/09/2021",
      filial: 'Matriz',
      faturado: "R$ 3000,00"
    },
    {
      inicial: "02/09/2021",
      final: "02/09/2021",
      filial: 'Matriz',
      faturado: "R$ 3000,00"
    }, {
      inicial: "02/09/2021",
      final: "02/09/2021",
      filial: 'Matriz',
      faturado: "R$ 3000,00"
    },
  ])
  const [page, setPage] = useState(0)
  const [date, setDate] = useState(new Date())
  const [finalDate, setFinalDate] = useState(new Date())
  const [openModal, setOpenModal] = useState(false)
  const [position, setPosition] = useState([])
  const [volume, setVolume] = useState("")
  const [numero_pedido, setNumero_pedido] = useState("")
  const [distancia, setDistancia] = useState("")
  const [shouldOpenEditorDialog, setShouldOpenEditorDialog] = useState(false)
  const [shouldOpenConfirmationDialog, setShouldOpenConfirmationDialog] = useState(false)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  };

  const handleDialogClose = () => {
    setShouldOpenEditorDialog(false)
    setShouldOpenConfirmationDialog(false)
  };

  const setRowsPerPageFunction = event => {
    setRowsPerPage(event.target.value);
  };

  const handleDeleteUser = user => {
    setShouldOpenConfirmationDialog(true)
  };

  const handleConfirmationResponse = () => {

  };

  useEffect(() => {

  })


  const handleDateChange = date => {
    setDate(date)
  };

  const handleFinalDateChange = finalDate => {
    setFinalDate(finalDate)
  };

  const openInformations = () => {
    if (numero_pedido != "") {
      setPosition([{ lat: -15.689556627048344, lng: -47.861426119359 }])
    } else {
      Swal.fire({
        icon: 'error',
        title: "Selecione o número do pedido",
        showConfirmButton: false,
        timer: 3000
      })
    }
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: "Relatório de pedido" }]} />
      </div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ width: "80%", marginLeft: "10%", marginTop: 20 }}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: "60%",
          border: '2px solid #f78726',
          boxShadow: 24,
        }}>
          <MarkerMap coords={position} />

        </Box>
      </Modal>
      <Grid container spacing={5}>
        <Grid item lg={3} md={3} sm={12} xs={12} style={{ marginTop: 6 }}>
          <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="mb-16 w-100"
              margin="none"
              id="mui-pickers-date"
              label="Data Início"
              format="dd/MM/yyyy"
              inputVariant="standard"
              type="text"
              autoOk={true}
              value={date}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12} style={{ marginTop: 6 }}>
          <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="mb-16 w-100"
              margin="none"
              id="mui-pickers-date"
              format="dd/MM/yyyy"
              label="Data Fim"
              inputVariant="standard"
              type="text"
              autoOk={true}
              value={finalDate}
              onChange={handleFinalDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <ValidatorForm>
            <TextValidator
              className="mb-16 w-100"
              label="Número do pedido"
              onChange={(event) => { setNumero_pedido(event.target.value) }}
              type="text"
              inputVariant="standard"
              name="numero_pedido"
              value={numero_pedido}
              style={{ marginTop: 5 }}
              validators={[
                "required"
              ]}
              errorMessages={["this field is required"]}
            />
          </ValidatorForm>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Button color="primary" variant="contained" style={{ marginTop: 10 }} onClick={() => {
            openInformations()
          }}>
            <Icon>filter_list</Icon>
            <span className="pl-8 capitalize">Filtrar</span>
          </Button>
        </Grid>
      </Grid>

      <Card className="w-100 overflow-auto" elevation={6}>
        {
          position.length > 0 ?
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontSize: 12 }}>Data</TableCell>
                  <TableCell style={{ fontSize: 12 }}>Status</TableCell>
                  <TableCell style={{ fontSize: 12 }}>Ação</TableCell>
                  {/* <TableCell style={{ fontSize: 12 }} align="right">Obra</TableCell>
                  <TableCell style={{ fontSize: 12 }} align="right">Motorista</TableCell>
                  <TableCell style={{ fontSize: 12 }} align="right">Conteúdo</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell style={{ fontSize: 10 }}>21/03/2022 10:00</TableCell>
                  <TableCell style={{ fontSize: 10 }}>Status 1</TableCell>
                  <TableCell style={{ fontSize: 10 }}><span style={{ cursor: "pointer", color: "#f78726" }} onClick={() => { setOpenModal(true) }}><Icon fontSize="small">visibility</Icon></span></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontSize: 10 }}>21/03/2022 10:10</TableCell>
                  <TableCell style={{ fontSize: 10 }}>Status 2</TableCell>
                  <TableCell style={{ fontSize: 10 }}><span style={{ cursor: "pointer", color: "#f78726" }} onClick={() => { setOpenModal(true) }}><Icon fontSize="small">visibility</Icon></span></TableCell>

                </TableRow>
                <TableRow>
                  <TableCell style={{ fontSize: 10 }}>21/03/2022 10:11</TableCell>
                  <TableCell style={{ fontSize: 10 }}>Status 3</TableCell>
                  <TableCell style={{ fontSize: 10 }}><span style={{ cursor: "pointer", color: "#f78726" }} onClick={() => { setOpenModal(true) }}><Icon fontSize="small">visibility</Icon></span></TableCell>

                </TableRow>
                <TableRow>
                  <TableCell style={{ fontSize: 10 }}>21/03/2022 10:20</TableCell>
                  <TableCell style={{ fontSize: 10 }}>Status 4</TableCell>
                  <TableCell style={{ fontSize: 10 }}><span style={{ cursor: "pointer", color: "#f78726" }} onClick={() => { setOpenModal(true) }}><Icon fontSize="small">visibility</Icon></span></TableCell>

                </TableRow>
                <TableRow>
                  <TableCell style={{ fontSize: 10 }}>21/03/2022 10:30</TableCell>
                  <TableCell style={{ fontSize: 10 }}>Status 5</TableCell>
                  <TableCell style={{ fontSize: 10 }}><span style={{ cursor: "pointer", color: "#f78726" }} onClick={() => { setOpenModal(true) }}><Icon fontSize="small">visibility</Icon></span></TableCell>

                </TableRow>
                <TableRow>
                  <TableCell style={{ fontSize: 10 }}>21/03/2022 10:35</TableCell>
                  <TableCell style={{ fontSize: 10 }}>Status 6</TableCell>
                  <TableCell style={{ fontSize: 10 }}><span style={{ cursor: "pointer", color: "#f78726" }} onClick={() => { setOpenModal(true) }}><Icon fontSize="small">visibility</Icon></span></TableCell>

                </TableRow>
                <TableRow>
                  <TableCell style={{ fontSize: 10 }}>21/03/2022 10:36</TableCell>
                  <TableCell style={{ fontSize: 10 }}>Status 7</TableCell>
                  <TableCell style={{ fontSize: 10 }}><span style={{ cursor: "pointer", color: "#f78726" }} onClick={() => { setOpenModal(true) }}><Icon fontSize="small">visibility</Icon></span></TableCell>

                </TableRow>
                <TableRow>
                  <TableCell style={{ fontSize: 10 }}>21/03/2022 10:40</TableCell>
                  <TableCell style={{ fontSize: 10 }}>Status 8</TableCell>
                  <TableCell style={{ fontSize: 10 }}><span style={{ cursor: "pointer", color: "#f78726" }} onClick={() => { setOpenModal(true) }}><Icon fontSize="small">visibility</Icon></span></TableCell>

                </TableRow>
                <TableRow>
                  <TableCell style={{ fontSize: 10 }}>21/03/2022 10:45</TableCell>
                  <TableCell style={{ fontSize: 10 }}>Status 9</TableCell>
                  <TableCell style={{ fontSize: 10 }}><span style={{ cursor: "pointer", color: "#f78726" }} onClick={() => { setOpenModal(true) }}><Icon fontSize="small">visibility</Icon></span></TableCell>

                </TableRow>
              </TableBody>
            </Table>
            :
            <h1 style={{ textAlign: "center", margin: "10px" }}>Selecione o pedido</h1>
        }

      </Card>
      {/* </>
          :
          <Card className="w-100 overflow-auto" elevation={6} style={{ padding: 50 }}>
            <h4 align="center">Sem resultados</h4>
            <h6 align="center">(Selecione os parâmetros)</h6>
          </Card>
      } */}

    </div >
  );
}

const mapStateToProps = state => ({
  userRedux: state.user,
  operators: state.operator.operators,
  loading: state.operator.loading,
  error: state.operator.error,
  success: state.operator.success,
});

export default connect(mapStateToProps)(CrudTable);