/* eslint-disable */
import React, { Component, useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  Icon,
  IconButton,
  TableRow,
  TableCell,
  TablePagination,
  Button,
  Grid,
  Card,
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { getAllUser, deleteUser } from "./TableService";
import { Breadcrumb } from "egret";
import shortid from "shortid";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment"
import ptLocale from "date-fns/locale/pt-BR";
import { MyDocument } from "./pdf"
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReactExport from "react-export-excel";
import { connect } from "react-redux";
import IntegrationReactSelect from "./ReactSelect"
import { listDiscard, listDiscardPerRadiusReport } from '../../redux/actions/DiscardActions'
import { listException } from '../../redux/actions/ExceptionActions'
import Swal from 'sweetalert2'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const dataSet1 = [
  {
    data: "01/10/2021",
    hora: "08:00",
    evento: '01/10/2021',
    nome: 'Raio 1',
  },
  {
    data: "01/10/2021",
    hora: "08:00",
    evento: '01/10/2021',
    nome: 'Raio 1',
  },
  {
    data: "01/10/2021",
    hora: "08:00",
    evento: '01/10/2021',
    nome: 'Raio 1',
  },

];

const CrudTable = props => {

  const [radio, setRadio] = useState(null)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [date, setDate] = useState(new Date())
  const [distancia, setDistancia] = useState(0)
  const [uid, setUid] = useState(0)
  const [volume, setVolume] = useState("")
  const [finalDate, setFinalDate] = useState(new Date())
  const [userList, setUserList] = useState([])
  const [
    , setShouldOpenEditorDialog] = useState(false)
  const [shouldOpenConfirmationDialog, setShouldOpenConfirmationDialog] = useState(false)
  const [options, setOptions] = useState([])
  const [dataSet, setDataSet] = useState([])

  const { userRedux, discards, exceptions, discardPerRadius } = props

  const setPageFunction = page => {
    setPage(page)
  };

  const setRowsPerPageFunction = event => {
    setRowsPerPage(event.target.value)
  };

  const handleChangePage = (event, newPage) => {
    setPageFunction(newPage);
  };

  const handleDialogClose = () => {
    setShouldOpenEditorDialog(false)
    setShouldOpenConfirmationDialog(false)
    updatePageData();
  };

  const handleDeleteUser = user => {
    setShouldOpenConfirmationDialog(true)
  };

  const handleConfirmationResponse = () => {

  };

  const createObjectDataSet = (reports) => {
    var newObj = []
    reports?.map((item, key) => {
      newObj.push({
        Data: item?.date,
        Hora: item?.hour,
        Evento: item?.eventName,
        Raio: item?.radio,
      })
      return item;
    })
    console.log("newOjt", newObj)
    setDataSet(newObj)
  }

  useEffect(() => {
    props.dispatch(listDiscard(userRedux?.token, userRedux?.company))
    props.dispatch(listException(userRedux?.token, userRedux?.company, "DISCARD"))
  }, [])

  useEffect(() => {
    console.log("discards", discards)
  }, [discards])

  useEffect(() => {
    if (exceptions != null) {
      var newOptions = []
      exceptions.map(item => {
        // if(item?.type == "EXCEPTION"){
        newOptions.push({ label: item?.name, value: item?.id })
        // }
      })
      setOptions(newOptions)
    }
  }, [exceptions])

  const updatePageData = () => {
    getAllUser().then(({ data }) => setUserList([...data]));
  };

  const handleDateChange = date => {
    console.log("date", date)
    setDate(date)
  };

  const handleFinalDateChange = finalDate => {
    setFinalDate(finalDate)
  };

  const handleSubmit = () => {
    if (date == "") {
      Swal.fire({
        icon: 'error',
        title: 'Preencha a data inicial',
        showConfirmButton: false,
        timer: 3000
      })
    } else if (finalDate == "") {
      Swal.fire({
        icon: 'error',
        title: 'Preencha a data final',
        showConfirmButton: false,
        timer: 3000
      })
    } else if (radio == null || radio == '') {
      Swal.fire({
        icon: 'error',
        title: 'Selecione o raio',
        showConfirmButton: false,
        timer: 3000
      })
    } else {
      props.dispatch(listDiscardPerRadiusReport(userRedux?.token, userRedux?.company, moment(date).format("DD/MM/YYYY"), moment(finalDate).format("DD/MM/YYYY"), radio))
    }
  }

  useEffect(() => {
    if (discardPerRadius?.length > 0) {
      createObjectDataSet(discardPerRadius)
    }
  }, [discardPerRadius])

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: "Relatório de descarte por raio" }]} />
      </div>
      <Grid container spacing={5}>
        <Grid item lg={3} md={3} sm={12} xs={12} style={{ marginTop: 6 }}>
          <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="mb-16 w-100"
              margin="none"
              id="mui-pickers-date"
              label="Data Início"
              format="dd/MM/yyyy"
              inputVariant="standard"
              type="text"
              autoOk={true}
              value={date}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12} style={{ marginTop: 6 }}>
          <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="mb-16 w-100"
              margin="none"
              id="mui-pickers-date"
              format="dd/MM/yyyy"
              label="Data Fim"
              inputVariant="standard"
              type="text"
              autoOk={true}
              value={finalDate}
              onChange={handleFinalDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <IntegrationReactSelect data={options} onChange={value => {
            setRadio(value)
          }} />
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Button color="primary" variant="contained" type="button" onClick={() => {
            handleSubmit()
          }} style={{ marginTop: 10 }}>
            <Icon>filter_list</Icon>
            <span className="pl-8 capitalize">Filtrar</span>
          </Button>
        </Grid>
      </Grid>
      {
        discardPerRadius.length > 0 ?
          <>
            <ExcelFile filename="relatorio-descarte-por-raio" element={<Button style={{ backgroundColor: "#f78726", color: "white", marginBottom: 10, marginRight: 5 }} aria-label="Add">Exportar Excel</Button>}>
              <ExcelSheet data={dataSet} name="Employees">
                <ExcelColumn label="Data" value="Data" />
                <ExcelColumn label="Hora" value="Hora" />
                <ExcelColumn label="Evento" value="Evento" />
                <ExcelColumn label="Nome do raio" value="Raio" />
              </ExcelSheet>
            </ExcelFile>

            <PDFDownloadLink document={<MyDocument data={dataSet} />} fileName="relatório-descarte-por-raio.pdf">
              <Button style={{ backgroundColor: "transparent", border: "1.5px solid #f78726", color: "#f78726", marginBottom: 10 }} aria-label="Add">Exportar Pdf</Button>
            </PDFDownloadLink>
            <Card className="w-100 overflow-auto" elevation={6}>
              <h6 style={{ textAlign: "center", marginRight: 10, fontSize: 10, marginTop: 10 }}>Data de emissão: {moment().format("D/M/Y")}</h6>
              <h6 style={{ textAlign: "center", marginRight: 10, fontSize: 10, }}>Usuário: {userRedux?.displayName}</h6>
              <h4 style={{ textAlign: "center", marginRight: 10 }}>Relatório de descarte por raio</h4>

              <Table className="crud-table" style={{ whiteSpace: "pre", minWidth: "750px" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Data</TableCell>
                    <TableCell>Hora</TableCell>
                    <TableCell>Evento</TableCell>
                    <TableCell>Nome do raio</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {discardPerRadius
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((user, index) => (
                      <TableRow key={shortid.generate()} style={{ paddingVertical: 5 }}>
                        <TableCell className="px-0" align="left">{item?.date}</TableCell>
                        <TableCell className="px-0" align="left">{item?.hour}</TableCell>
                        <TableCell className="px-0" align="left">{item?.eventName}</TableCell>
                        <TableCell className="px-0" align="left">{item?.radio}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>

              <TablePagination
                className="px-16"
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={userList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage="Linhas por Página"
                backIconButtonProps={{
                  "aria-label": "Anterior"
                }}
                nextIconButtonProps={{
                  "aria-label": "Próxima"
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={setRowsPerPageFunction}
              />
            </Card>
          </>
          :
          <Card className="w-100 overflow-auto" elevation={6} style={{ padding: 50 }}>
            <h4 align="center">Sem resultados</h4>
            <h6 align="center">(Selecione os parâmetros)</h6>
          </Card>
      }

    </div >
  );
}

const mapStateToProps = state => ({
  userRedux: state.user,
  discards: state.discard.discards,
  discardPerRadius: state.discard.discardPerRadius,
  exceptions: state.exception.exceptions,

});

export default connect(mapStateToProps)(CrudTable);
