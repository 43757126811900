import history from "history.js";
import jwtAuthService from "../../services/jwtAuthService";
import axios from "axios";

export const UPDATE_STATE_CONFIRM_SELLER_LOADING = "UPDATE_STATE_CONFIRM_SELLER_LOADING";
export const UPDATE_STATE_CONFIRM_SELLER_SUCCESS = "UPDATE_STATE_CONFIRM_SELLER_SUCCESS";
export const UPDATE_STATE_CONFIRM_SELLER_FAILED = "UPDATE_STATE_CONFIRM_SELLER_FAILED";
export const LIST_CONFIRMED_ORDERS_LOADING = "LIST_CONFIRMED_ORDERS_LOADING";
export const LIST_CONFIRMED_ORDERS_SUCCESS = "LIST_CONFIRMED_ORDERS_SUCCESS";
export const LIST_CONFIRMED_ORDERS_FAILED = "LIST_CONFIRMED_ORDERS_FAILED";
export const LIST_LAVAGENS_LOADING = "LIST_LAVAGENS_LOADING";
export const LIST_LAVAGENS_SUCCESS = "LIST_LAVAGENS_SUCCESS";
export const LIST_LAVAGENS_FAILED = "LIST_LAVAGENS_FAILED";
export const LIST_OCORRENCIAS_LOADING = "LIST_OCORRENCIAS_LOADING";
export const LIST_OCORRENCIAS_SUCCESS = "LIST_OCORRENCIAS_SUCCESS";
export const LIST_OCORRENCIAS_FAILED = "LIST_OCORRENCIAS_FAILED";
export const LIST_INCONSISTENCIAS_LOADING = "LIST_INCONSISTENCIAS_LOADING";
export const LIST_INCONSISTENCIAS_SUCCESS = "LIST_INCONSISTENCIAS_SUCCESS";
export const LIST_INCONSISTENCIAS_FAILED = "LIST_INCONSISTENCIAS_FAILED";
export const DELETE_ORDER_LOADING = "DELETE_ORDER_LOADING";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const DELETE_ORDER_FAILED = "DELETE_ORDER_FAILED";
export const UPDATE_ORDER_LOADING = "UPDATE_ORDER_LOADING";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAILED = "UPDATE_ORDER_FAILED";

export const CLEAR = "CLEAR";

export function updateStateConfirmSeller(token, company, order) {
  return dispatch => {
    dispatch({
      type: UPDATE_STATE_CONFIRM_SELLER_LOADING,
    });
    axios({
      method: 'post',
      url: `https://api.stonik.com.br/web/order/company/${company}/${order}/confirm_vendedor`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: UPDATE_STATE_CONFIRM_SELLER_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: UPDATE_STATE_CONFIRM_SELLER_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}
export function listInconsistencias(token, company, start, end, branch = null) {
  return dispatch => {
    dispatch({
      type: LIST_INCONSISTENCIAS_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/report/inconsistency?companyId=${company}${branch == null ? "" : "&companyBranchId=" + branch}&startDateFilter=${start}&endDateFilter=${end}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_INCONSISTENCIAS_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_INCONSISTENCIAS_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}
export function deleteOrder(token, company, order) {
  return dispatch => {
    dispatch({
      type: DELETE_ORDER_LOADING,
    });
    axios({
      method: 'delete',
      url: `https://api.stonik.com.br/web/order/company/${company}/${order}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: DELETE_ORDER_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: DELETE_ORDER_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}
export function listOcorrencias(token, company, start, end, branch = null, driver = null) {
  return dispatch => {
    dispatch({
      type: LIST_OCORRENCIAS_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/report/occurrences?companyId=${company}&startDateFilter=${start}&endDateFilter=${end}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_OCORRENCIAS_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_OCORRENCIAS_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}
export function listLavagens(token, company, start, end, branch = null, driver = null) {
  return dispatch => {
    dispatch({
      type: LIST_LAVAGENS_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/report/washing?companyId=${company}${branch == null ? "" : "&companyBranchId=" + branch}&startDateFilter=${start}&endDateFilter=${end}${driver == null ? "" : "&driverUserId=" + driver}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_LAVAGENS_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_LAVAGENS_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}
export function listConfirmedOrders(token, company) {
  return dispatch => {
    dispatch({
      type: LIST_CONFIRMED_ORDERS_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/order/company/${company}/pedido_confirmado`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_CONFIRMED_ORDERS_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_CONFIRMED_ORDERS_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function updateOrder(token, company, order, data) {
  return dispatch => {
    dispatch({
      type: UPDATE_ORDER_LOADING,
    });
    axios({
      method: 'put',
      url: `https://api.stonik.com.br/web/order/company/${company}/${order}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: UPDATE_ORDER_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: UPDATE_ORDER_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function clear() {
  return dispatch => {
    dispatch({
      type: CLEAR
    });
  };
}
