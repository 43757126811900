import React, { useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from "@material-ui/core";
import { isThisWeek } from 'date-fns';
// Create styles
const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingTop: 10,
        paddingHorizontal: 10
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    header: {
        width: '15%',
        fontSize: 7,
        textAlign: "center",
        fontWeight: "800"
    },
    subtitle: {
        width: '15%',
        textAlign: "center",
        fontSize: 6,
    }
});

// Create Document Component
export const MyDocument = (props) => {
    const { data } = props

    useEffect(() => {
        console.log('data', data)
    }, [data])

    return (
        < Document >
            <Page size="A4" style={styles.page}>
                <View style={styles.tableContainer}>
                    <View style={[styles.row, { marginBottom: 10 }]}>
                        <Text style={styles.header}>Data</Text>
                        <Text style={styles.header}>Placa</Text>
                        <Text style={styles.header}>Primeira nota</Text>
                        <Text style={styles.header}>Horario primeira nota</Text>
                        <Text style={styles.header}>Qtd de notas</Text>
                        <Text style={styles.header}>Qtd de entregas</Text>
                        <Text style={styles.header}>Qtd de inconsistências</Text>
                    </View>
                    {
                        data?.map(item => {
                            console.log("item", item)
                            return (
                                <View style={[styles.row, { marginTop: 8 }]}>
                                    <Text style={styles.subtitle}>{item.date}</Text>
                                    <Text style={styles.subtitle}>{item.plate}</Text>
                                    <Text style={styles.subtitle}>{item.firstInvoice}</Text>
                                    <Text style={styles.subtitle}>{item.hourFirstInvoice}</Text>
                                    <Text style={styles.subtitle}>{item.qntInvoices}</Text>
                                    <Text style={styles.subtitle}>{item.qntDischarges}</Text>
                                    <Text style={styles.subtitle}>{item.qntInconsistency}</Text>
                                </View>)
                        })
                    }
                </View>

            </Page>
        </Document >
    )
};