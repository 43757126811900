import history from "history.js";
import jwtAuthService from "../../services/jwtAuthService";
import axios from "axios";


export const LIST_APPLICATION_LOADING = "LIST_APPLICATION_LOADING";
export const LIST_APPLICATION_SUCCESS = "LIST_APPLICATION_SUCCESS";
export const LIST_APPLICATION_FAILED = "LIST_APPLICATION_FAILED";
export const UPDATE_APPLICATION_LOADING = "UPDATE_APPLICATION_LOADING";
export const UPDATE_APPLICATION_SUCCESS = "UPDATE_APPLICATION_SUCCESS";
export const UPDATE_APPLICATION_FAILED = "UPDATE_APPLICATION_FAILED";
export const DELETE_APPLICATION_LOADING = "DELETE_APPLICATION_LOADING";
export const DELETE_APPLICATION_SUCCESS = "DELETE_APPLICATION_SUCCESS";
export const DELETE_APPLICATION_FAILED = "DELETE_APPLICATION_FAILED";
export const CREATE_APPLICATION_LOADING = "CREATE_APPLICATION_LOADING";
export const CREATE_APPLICATION_SUCCESS = "CREATE_APPLICATION_SUCCESS";
export const CREATE_APPLICATION_FAILED = "CREATE_APPLICATION_FAILED";

export const CLEAR = "CLEAR";

export function listApplication(token) {
  return dispatch => {
    dispatch({
      type: LIST_APPLICATION_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/ticket-application`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_APPLICATION_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_APPLICATION_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function createApplication(token, data) {
  return dispatch => {
    dispatch({
      type: CREATE_APPLICATION_LOADING,
    });
    axios({
      method: 'post',
      url: `https://api.stonik.com.br/web/ticket-application`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: CREATE_APPLICATION_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: CREATE_APPLICATION_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}
export function updateApplication(token, data) {
  return dispatch => {
    dispatch({
      type: UPDATE_APPLICATION_LOADING,
    });
    axios({
      method: 'put',
      url: `https://api.stonik.com.br/web/ticket-application/${data.id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: UPDATE_APPLICATION_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: UPDATE_APPLICATION_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function deleteApplication(token, id) {
  return dispatch => {
    dispatch({
      type: DELETE_APPLICATION_LOADING,
    });
    axios({
      method: 'delete',
      url: `https://api.stonik.com.br/web/ticket-application/${id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: DELETE_APPLICATION_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: DELETE_APPLICATION_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function clear() {
  return dispatch => {
    dispatch({
      type: CLEAR
    });
  };
}
