import React, { useEffect, useState } from "react";
import {
  Dialog,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  Icon
} from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputMask from "react-input-mask";
import {
  TimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import moment from "moment"
import axios from "axios"
import Swal from 'sweetalert2'
import { listState } from '../../redux/actions/StateActions'
import { createCompany, updateCompany } from '../../redux/actions/CompanyActions'

import { connect } from "react-redux";

const MemberEditorDialog = (props) => {

  const { open, handleClose, states, userRedux, uid } = props;

  const [capacity, setCapacity] = useState("")
  const [day, setDays] = useState("")
  const [daysArray, setDaysArray] = useState({
    monday: true,
    mondayHourInit: "08:00",
    mondayHourEnd: "18:00",
    tuesday: true,
    tuesdayHourInit: "08:00",
    tuesdayHourEnd: "18:00",
    wednesday: true,
    wednesdayHourInit: "08:00",
    wednesdayHourEnd: "18:00",
    thursday: true,
    thursdayHourInit: "08:00",
    thursdayHourEnd: "18:00",
    friday: true,
    fridayHourInit: "08:00",
    fridayHourEnd: "18:00",
    saturday: false,
    saturdayHourInit: "00:00",
    saturdayHourEnd: "00:00",
    sunday: false,
    sundayHourInit: "00:00",
    sundayHourEnd: "00:00"
  })

  const [monday, setMonday] = useState(uid != null ? uid?.workDays.monday : true)
  const [mondayHourInit, setMondayHourInit] = useState(uid != null ? `hour Tue Oct 19 2021 ${uid?.workDays.mondayHourInit} GMT-0300` : "hour Tue Oct 19 2021 08:00:00 GMT-0300")
  const [mondayHourEnd, setMondayHourEnd] = useState(uid != null ? `hour Tue Oct 19 2021 ${uid?.workDays.mondayHourEnd} GMT-0300` : "hour Tue Oct 19 2021 18:00:00 GMT-0300")
  const [tuesday, setTuesday] = useState(uid != null ? uid?.workDays.tuesday : true)
  const [tuesdayHourInit, setTuesdayHourInit] = useState(uid != null ? `hour Tue Oct 19 2021 ${uid?.workDays.tuesdayHourInit} GMT-0300` : "hour Tue Oct 19 2021 08:00:00 GMT-0300")
  const [tuesdayHourEnd, setTuesdayHourEnd] = useState(uid != null ? `hour Tue Oct 19 2021 ${uid?.workDays.tuesdayHourEnd} GMT-0300` : "hour Tue Oct 19 2021 18:00:00 GMT-0300")
  const [wednesday, setWednesday] = useState(uid != null ? uid?.workDays.wednesday : true)
  const [wednesdayHourInit, setWednesdayHourInit] = useState(uid != null ? `hour Tue Oct 19 2021 ${uid?.workDays.wednesdayHourInit} GMT-0300` : "hour Tue Oct 19 2021 08:00:00 GMT-0300")
  const [wednesdayHourEnd, setWednesdayHourEnd] = useState(uid != null ? `hour Tue Oct 19 2021 ${uid?.workDays.wednesdayHourEnd} GMT-0300` : "hour Tue Oct 19 2021 18:00:00 GMT-0300")
  const [thursday, setThursday] = useState(uid != null ? uid?.workDays.thursday : true)
  const [thursdayHourInit, setThursdayHourInit] = useState(uid != null ? `hour Tue Oct 19 2021 ${uid?.workDays.thursdayHourInit} GMT-0300` : "hour Tue Oct 19 2021 08:00:00 GMT-0300")
  const [thursdayHourEnd, setThursdayHourEnd] = useState(uid != null ? `hour Tue Oct 19 2021 ${uid?.workDays.thursdayHourEnd} GMT-0300` : "hour Tue Oct 19 2021 18:00:00 GMT-0300")
  const [friday, setFriday] = useState(uid != null ? uid?.workDays.friday : true)
  const [fridayHourInit, setFridayHourInit] = useState(uid != null ? `hour Tue Oct 19 2021 ${uid?.workDays.fridayHourInit} GMT-0300` : "hour Tue Oct 19 2021 08:00:00 GMT-0300")
  const [fridayHourEnd, setFridayHourEnd] = useState(uid != null ? `hour Tue Oct 19 2021 ${uid?.workDays.fridayHourEnd} GMT-0300` : "hour Tue Oct 19 2021 18:00:00 GMT-0300")
  const [saturday, setSaturday] = useState(uid != null ? uid?.workDays.saturday : false)
  const [saturdayHourInit, setSaturdayHourInit] = useState(uid != null ? `hour Tue Oct 19 2021 ${uid?.workDays.saturdayHourInit} GMT-0300` : "hour Tue Oct 19 2021 08:00:00 GMT-0300")
  const [saturdayHourEnd, setSaturdayHourEnd] = useState(uid != null ? `hour Tue Oct 19 2021 ${uid?.workDays.saturdayHourEnd} GMT-0300` : "hour Tue Oct 19 2021 18:00:00 GMT-0300")
  const [sunday, setSunday] = useState(uid != null ? uid?.workDays.sunday : false)
  const [sundayHourInit, setSundayHourInit] = useState(uid != null ? `hour Tue Oct 19 2021 ${uid?.workDays.sundayHourInit} GMT-0300` : "hour Tue Oct 19 2021 08:00:00 GMT-0300")
  const [sundayHourEnd, setSundayHourEnd] = useState(uid != null ? `hour Tue Oct 19 2021 ${uid?.workDays.sundayHourEnd} GMT-0300` : "hour Tue Oct 19 2021 18:00:00 GMT-0300")

  const [comeco, setComeco] = useState("")
  const [fim, setFim] = useState("")
  const [fantasyName, setFantasyName] = useState(uid != null ? uid?.nameFantasy : "")
  const [razaoSocial, setRazaoSocial] = useState(uid != null ? uid?.nameCorporate : "")
  const [pointName, setPointName] = useState(uid != null ? uid?.name : "")
  const [cnpj, setCnpj] = useState(uid != null ? uid?.document : "")
  const [contact, setContact] = useState(uid != null ? uid?.contact1 : "")
  const [contact2, setContact2] = useState(uid != null ? uid?.contact2 : "")
  const [prodDiaria, setProdDiaria] = useState(uid != null ? uid?.productionDailyM3 : "")
  const [rua, setRua] = useState(uid != null ? uid?.address.street : "")
  const [numero, setNumero] = useState(uid != null ? uid?.address.number : "")
  const [complemento, setComplemento] = useState(uid != null ? uid?.address.complement : "")
  const [cep, setCep] = useState(uid != null ? uid?.address.cep : "")
  const [state, setState] = useState("")
  const [selectedEstadoItem, setSelectedEstadoItem] = useState("")
  const [city, setCity] = useState(uid != null ? uid?.address.city.name : "")
  const [cityId, setCityId] = useState(uid != null ? uid?.address.city.id : "")
  const [latitude, setLatitude] = useState(uid != null ? uid?.address.latitude : "")
  const [longitude, setLongitude] = useState(uid != null ? uid?.address.longitude : "")
  const [codIntegrador, setCodIntegrador] = useState(uid != null ? uid?.integrationCode : "")
  const [logo, setLogo] = useState(uid != null ? uid?.logo : "")
  const [site, setSite] = useState(uid != null ? uid?.site : "")
  const [maxWidthImage, setMaxWidthImage] = useState(false)

  const [message, setMessage] = useState("")

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      console.log("reader", reader)
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const changeHandler = (event) => {
    if ((event.target.files[0].size / 1024) > 2000) {
      setMaxWidthImage(true)
      setLogo("")
    } else {
      setMaxWidthImage(false)
      getBase64(event.target.files[0]).then(
        data => {
          setLogo(data.substring(22))
        }
      );
    }
  };

  const buscarCep = (text) => {
    var textReplaced = text.replace("-", "")
    axios({
      method: 'get',
      url: `https://viacep.com.br/ws/${textReplaced}/json/`,
      headers: {
        "Content-Type": "application/json"
      }
    }).then(res => {
      var data = res.data
      setRua(`${data.logradouro} ${data.bairro}`)
      setState(data.uf)
      setCity(data.localidade)
      setCityId(data.ibge)
      var find = states.find(res => res.unit == data.uf)
      setSelectedEstadoItem(find)
      console.log("data", data)
    }).catch(eer => {
      console.log("err", eer)
    })
  }

  const changeDay = (number) => {
    setDays(number)
    // var daysArrayInner = [...day]
    // var exist = daysArrayInner.indexOf(number)
    // if (exist == -1) {
    //   daysArrayInner.push(number)
    //   console.log("days", daysArrayInner)
    //   setDays(daysArrayInner)
    // } else {
    //   daysArrayInner.splice(exist, 1);
    //   console.log("days", daysArrayInner)
    //   setDays(daysArrayInner)
    // }
  }

  const handleFormSubmit = () => {
    if (pointName == "") {
      setMessage("Preencha o nome do ponto")
    } else if (razaoSocial == "") {
      setMessage("Preencha a razão social do ponto")
    } else if (cnpj == "") {
      setMessage("Preencha o cnpj do ponto")
    } else if (contact == "") {
      setMessage("Preencha o contato do ponto")
    } else if (cep == "") {
      setMessage("Preencha o cep do ponto")
    } else if (rua == "") {
      setMessage("Preencha a rua do ponto")
    } else if (numero == "") {
      setMessage("Preencha o número do ponto")
    } else if (latitude == "") {
      setMessage("Preencha a latitude do ponto")
    } else if (longitude == "") {
      setMessage("Preencha a longitude do ponto")
    } else if (state == "") {
      setMessage("Preencha o estado do ponto")
    } else if (cityId == "") {
      setMessage("Preencha o nome da cidade")
    } else if (codIntegrador == "") {
      setMessage("Preencha o código integrador")
    } else {
      handleClose()
      const obj = {
        name: pointName,
        nameCorporate: razaoSocial,
        nameFantasy: fantasyName,
        integrationCode: codIntegrador,
        document: cnpj,
        contact1: contact,
        productionDailyM3: prodDiaria,
        contact2: contact2,
        address: {
          street: rua,
          number: numero,
          complement: complemento,
          neighborhood: "",
          cep,
          latitude,
          longitude,
          city: {
            id: cityId
          }
        },
        workDays: {
          monday,
          mondayHourInit: moment(mondayHourInit).format("HH:mm"),
          mondayHourEnd: moment(mondayHourEnd).format("HH:mm"),
          tuesday,
          tuesdayHourInit: moment(tuesdayHourInit).format("HH:mm"),
          tuesdayHourEnd: moment(tuesdayHourEnd).format("HH:mm"),
          wednesday,
          wednesdayHourInit: moment(wednesdayHourInit).format("HH:mm"),
          wednesdayHourEnd: moment(wednesdayHourEnd).format("HH:mm"),
          thursday,
          thursdayHourInit: moment(thursdayHourInit).format("HH:mm"),
          thursdayHourEnd: moment(thursdayHourEnd).format("HH:mm"),
          friday,
          fridayHourInit: moment(fridayHourInit).format("HH:mm"),
          fridayHourEnd: moment(fridayHourEnd).format("HH:mm"),
          saturday,
          saturdayHourInit: moment(saturdayHourInit).format("HH:mm"),
          saturdayHourEnd: moment(saturdayHourEnd).format("HH:mm"),
          sunday,
          sundayHourInit: moment(sundayHourInit).format("HH:mm"),
          sundayHourEnd: moment(sundayHourEnd).format("HH:mm")
        },
        logo,
        site
      }
      Swal.fire({
        icon: 'warning',
        title: uid == null ? "Deseja realmente adicionar esta empresa?" : "Deseja realmente editar esta empresa?",
        showDenyButton: true,
        confirmButtonColor: "#f78726",
        denyButtonColor: "#262d47",
        confirmButtonText: uid == null ? 'Adicionar' : "Editar",
        denyButtonText: uid == null ? `Não adicionar` : "Não Editar",
      }).then((result) => {
        if (result.isConfirmed) {
          if (uid == null) {
            props.dispatch(createCompany(userRedux?.token, obj))
          } else {
            props.dispatch(updateCompany(userRedux?.token, obj, uid?.id))
          }
        }
      })



    }


  };

  useEffect(() => {
    props.dispatch(listState(userRedux?.token))
    console.log("uid", uid)
  }, [])

  useEffect(() => {
    console.log("states", states)
  }, [states])

  useEffect(() => {
    console.log("cep", cep)
    if (cep.length == 8) {
      buscarCep(cep);
    }
  }, [cep])

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="p-24" style={{ width: "400px" }}>
        <h4 className="mb-20">Cadastrar Empresa</h4>
        <ValidatorForm onSubmit={handleFormSubmit}>
          <Grid className="mb-16" container spacing={10}>
            <Grid item sm={12}>
              <label className="mb-4">Nome do ponto</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="pointName"
                onChange={event => setPointName(event.target.value)}
                type="text"
                name="pointName"
                value={pointName}
              />
              <label className="mb-4">Nome fantasia</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="FantasyName"
                onChange={event => setFantasyName(event.target.value)}
                type="text"
                name="FantasyName"
                value={fantasyName}
              />
              <label className="mb-4">Razão social</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="razaoSocial"
                onChange={event => setRazaoSocial(event.target.value)}
                type="text"
                name="razaoSocial"
                value={razaoSocial}
              />
              <label className="mb-4">Logomarca</label>
              {maxWidthImage && <p className="mb-4 w-100" style={{ color: "red", fontSize: 10 }}>Enviar imagem com menos de 2 MB</p>}
              <input className="w-100 mb-16" onChange={changeHandler} type="file" style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }} />

              <label className="mb-4">Cnpj</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                mask="99.999.999/9999-99"
                label="Cnpj"
                onChange={(event) => {
                  setCnpj(event.target.value)
                }}
                type="text"
                name="cnpj"
                value={cnpj}
              />
              <label className="mb-4">Contato 1</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                mask="(99) 9 9999-9999"
                label="Contact"
                onChange={(event) => {
                  setContact(event.target.value)
                }}
                type="text"
                name="Contact"
                value={contact}
              />
              <label className="mb-4">Contato 2</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                mask="(99) 9 9999-9999"
                label="Contact2"
                onChange={(event) => {
                  setContact2(event.target.value)
                }}
                type="text"
                name="Contact2"
                value={contact2}
              />
              <label className="mb-4">CEP</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="Cep"
                onChange={(event) => {
                  setCep(event.target.value)
                }}
                type="text"
                name="Cep"
                value={cep}
              />
              {
                uid == null && (
                  <>
                    <label className="mb-4">Estado</label>
                    <InputMask
                      className="w-100 mb-16"
                      style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                      label="State"
                      disabled="disabled"
                      onChange={(event) => {
                        setState(event.target.value)
                      }}
                      type="text"
                      name="State"
                      value={state}
                    />
                  </>
                )
              }

              <label className="mb-4">Cidade</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="City"
                disabled="disabled"
                onChange={(event) => {
                  setCity(event.target.value)
                }}
                type="text"
                name="City"
                value={city}
              />
              <label className="mb-4">Rua</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="Rua"
                onChange={(event) => {
                  setRua(event.target.value)
                }}
                type="text"
                name="Rua"
                value={rua}
              />
              <label className="mb-4">Número</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="Numero"
                onChange={(event) => {
                  setNumero(event.target.value)
                }}
                type="text"
                name="Numero"
                value={numero}
              />
              <label className="mb-4">Complemento</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="Complemento"
                onChange={(event) => {
                  setComplemento(event.target.value)
                }}
                type="text"
                name="Complemento"
                value={complemento}
              />
              <label className="mb-4">Latitude</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="latitude"
                onChange={event => setLatitude(event.target.value)}
                type="number"
                name="latitude"
                value={latitude}
              />

              <label className="mb-4">Longitude</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1, marginBottom: 30 }}
                label="longitude"
                onChange={event => setLongitude(event.target.value)}
                type="number"
                name="longitude"
                value={longitude}
              />
              <label className="mb-4">Cod Integrador</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1, marginBottom: 30 }}
                label="CodIntegrador"
                onChange={event => setCodIntegrador(event.target.value)}
                type="number"
                name="CodIntegrador"
                value={codIntegrador}
              />
              <label className="mb-4">Capacidade Máxima de Produção Diária</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1, marginBottom: 30 }}
                label="prodDiaria"
                onChange={event => setProdDiaria(event.target.value)}
                type="number"
                name="prodDiaria"
                value={prodDiaria}
              />
              {/* <label className="mb-4">Capacidade de produção diária (em m3)</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="Capacity"
                onChange={event => setCapacity(event.target.value)}
                type="number"
                name="capacity"
                value={capacity}
              /> */}
              <label className="mb-4">Selecione os dias de trabalho:</label>
              <h6 style={{ marginBottom: 10, fontSize: 10, color: "red" }}>(Clique sobre o dia para parametrizar)</h6>
              <div className="w-100" style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", marginBottom: 10 }}>
                <span onClick={() => { changeDay(1) }} style={{ flex: 1, backgroundColor: day == 1 ? "#f78726" : "transparent", border: "1px solid #f78726", borderRadius: 10, cursor: "pointer", fontWeight: "bold", color: day == 1 ? "white" : "#f78726", padding: 5, width: 25, textAlign: "center" }}>
                  SEG
                </span>
                <span onClick={() => { changeDay(2) }} style={{ flex: 1, backgroundColor: day == 2 ? "#f78726" : "transparent", border: "1px solid #f78726", borderRadius: 10, cursor: "pointer", fontWeight: "bold", color: day == 2 ? "white" : "#f78726", padding: 5, width: 25, textAlign: "center" }}>
                  TER
                </span>
                <span onClick={() => { changeDay(3) }} style={{ flex: 1, backgroundColor: day == 3 ? "#f78726" : "transparent", border: "1px solid #f78726", borderRadius: 10, cursor: "pointer", fontWeight: "bold", color: day == 3 ? "white" : "#f78726", padding: 5, width: 25, textAlign: "center" }}>
                  QUA
                </span>
                <span onClick={() => { changeDay(4) }} style={{ flex: 1, backgroundColor: day == 4 ? "#f78726" : "transparent", border: "1px solid #f78726", borderRadius: 10, cursor: "pointer", fontWeight: "bold", color: day == 4 ? "white" : "#f78726", padding: 5, width: 25, textAlign: "center" }}>
                  QUI
                </span>
                <span onClick={() => { changeDay(5) }} style={{ flex: 1, backgroundColor: day == 5 ? "#f78726" : "transparent", border: "1px solid #f78726", borderRadius: 10, cursor: "pointer", fontWeight: "bold", color: day == 5 ? "white" : "#f78726", padding: 5, width: 25, textAlign: "center" }}>
                  SEX
                </span>
                <span onClick={() => { changeDay(6) }} style={{ flex: 1, backgroundColor: day == 6 ? "#f78726" : "transparent", border: "1px solid #f78726", borderRadius: 10, cursor: "pointer", fontWeight: "bold", color: day == 6 ? "white" : "#f78726", padding: 5, width: 25, textAlign: "center" }}>
                  SAB
                </span>
                <span onClick={() => { changeDay(7) }} style={{ flex: 1, backgroundColor: day == 7 ? "#f78726" : "transparent", border: "1px solid #f78726", borderRadius: 10, cursor: "pointer", fontWeight: "bold", color: day == 7 ? "white" : "#f78726", padding: 5, width: 25, textAlign: "center" }}>
                  DOM
                </span>
              </div>
              {
                day != "" &&
                <div style={{ border: "1.5px solid #f78726", padding: 5, borderRadius: 10 }}>
                  {/* <label style={{marginTop: 10}}>Selecione o horário de atendimento </label>
                  <span style={{ width: "100%", color: "#f78726", fontWeight: "bold" }}>
                    {
                      day == 1 ?
                        "na Segunda"
                        : day == 2 ?
                          "na Terça"
                          : day == 3 ?
                            "na Quarta"
                            : day == 4 ?
                              "na Quinta"
                              : day == 5 ?
                                "na Sexta"
                                : day == 6 ?
                                  "no Sábado"
                                  : day == 7 ? "no Domingo" : null
                    }
                  </span> */}

                  <div className="w-100" style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", marginBottom: 10 }}>
                    <FormControlLabel
                      className="flex-grow-1"
                      name={
                        "Ativar Funcionamento"
                      }
                      onChange={() => {
                        day == 1 ?
                          setMonday(!monday)
                          : day == 2 ?
                            setTuesday(!tuesday)
                            : day == 3 ?
                              setWednesday(!wednesday)
                              : day == 4 ?
                                setThursday(!thursday)
                                : day == 5 ?
                                  setFriday(!friday)
                                  : day == 6 ?
                                    setSaturday(!saturday)
                                    : day == 7 ? setSunday(!sunday) : setSunday(!sunday)
                      }}
                      control={<Checkbox checked={
                        day == 1 ?
                          monday
                          : day == 2 ?
                            tuesday
                            : day == 3 ?
                              wednesday
                              : day == 4 ?
                                thursday
                                : day == 5 ?
                                  friday
                                  : day == 6 ?
                                    saturday
                                    : day == 7 ? sunday : false
                      } />}
                      label={
                        day == 1 ?
                          "Ativar funcionamento na Segunda"
                          : day == 2 ?
                            "Ativar funcionamento na Terça"
                            : day == 3 ?
                              "Ativar funcionamento na Quarta"
                              : day == 4 ?
                                "Ativar funcionamento na Quinta"
                                : day == 5 ?
                                  "Ativar funcionamento na Sexta"
                                  : day == 6 ?
                                    "Ativar funcionamento no Sábado"
                                    : day == 7 ? "Ativar funcionamento no Domingo" : null
                      }
                    />
                    <label className="mb-4 w-100" style={{ fontSize: 10 }}>De:</label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <TimePicker cancelLabel="Cancelar" okLabel="Selecionar horário" clearLabel="Limpar horário" disablePast={true} ampm={false} value={
                        day == 1 ?
                          mondayHourInit
                          : day == 2 ?
                            tuesdayHourInit
                            : day == 3 ?
                              wednesdayHourInit
                              : day == 4 ?
                                thursdayHourInit
                                : day == 5 ?
                                  fridayHourInit
                                  : day == 6 ?
                                    saturdayHourInit
                                    : day == 7 ? sundayHourInit : ""
                      } onChange={hour => {
                        console.log("hour", hour)
                        day == 1 ?
                          setMondayHourInit(hour)
                          : day == 2 ?
                            setTuesdayHourInit(hour)
                            : day == 3 ?
                              setWednesdayHourInit(hour)
                              : day == 4 ?
                                setThursdayHourInit(hour)
                                : day == 5 ?
                                  setFridayHourInit(hour)
                                  : day == 6 ?
                                    setSaturdayHourInit(hour)
                                    : day == 7 ? setSundayHourInit(hour) : setSundayHourInit(hour)
                      }} />
                    </MuiPickersUtilsProvider>


                  </div>
                  <div className="w-100" style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", marginBottom: 15 }}>
                    <label className="mb-4 w-100" style={{ fontSize: 10 }}>Até:</label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <TimePicker cancelLabel="Cancelar" okLabel="Selecionar horário" clearLabel="Limpar horário" disablePast={true} ampm={false} value={
                        day == 1 ?
                          mondayHourEnd
                          : day == 2 ?
                            tuesdayHourEnd
                            : day == 3 ?
                              wednesdayHourEnd
                              : day == 4 ?
                                thursdayHourEnd
                                : day == 5 ?
                                  fridayHourEnd
                                  : day == 6 ?
                                    saturdayHourEnd
                                    : day == 7 ? sundayHourEnd : ""
                      } onChange={hour => {
                        day == 1 ?
                          setMondayHourEnd(hour)
                          : day == 2 ?
                            setTuesdayHourEnd(hour)
                            : day == 3 ?
                              setWednesdayHourEnd(hour)
                              : day == 4 ?
                                setThursdayHourEnd(hour)
                                : day == 5 ?
                                  setFridayHourEnd(hour)
                                  : day == 6 ?
                                    setSaturdayHourEnd(hour)
                                    : day == 7 ? setSundayHourEnd(hour) : setSundayHourEnd(hour)
                      }} />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              }
              <label className="mb-4">Site</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1, marginBottom: 30 }}
                label="Site"
                onChange={event => setSite(event.target.value)}
                type="text"
                name="Site"
                value={site}
              />
              {/* <label className="mb-4 w-100" style={{ fontSize: 12 }}>Dados dos descartes</label>
              <div style={{ height: 1, backgroundColor: "lightgray", width: "100%", marginBottom: 15 }}></div> */}



              {/* <label className="mb-4 w-100" style={{ fontSize: 12 }}>Pontos de exceção</label>
              <div style={{ height: 1, backgroundColor: "lightgray", width: "100%", marginBottom: 15 }}></div>
              <label className="mb-4">Nome do ponto</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="pointName2"
                onChange={this.handleChange}
                type="number"
                name="pointName2"
                value={pointName2}
              />

              <label className="mb-4">Latitude</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="latitude2"
                onChange={this.handleChange}
                type="number"
                name="latitude2"
                value={latitude2}
              />

              <label className="mb-4">Longitude</label>
              <InputMask
                className="w-100 mb-16"
                style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                label="longitude2"
                onChange={this.handleChange}
                type="number"
                name="longitude2"
                value={longitude2}
              /> */}
              {
                message != "" && <div style={{ backgroundColor: "rgba(255,0,0,.2)", padding: 5, marginTop: 10 }}><Icon style={{ color: "red", marginRight: 5, marginLeft: 5 }} fontSize="small">close</Icon><span style={{ color: "red", fontWeight: "bold" }}>{message}</span></div>
              }
            </Grid>
          </Grid>

          <div className="flex flex-space-between flex-middle">
            <Button variant="contained" color="primary" type="submit">
              Salvar
            </Button>
            <Button onClick={() => handleClose()}>Cancelar</Button>
          </div>
        </ValidatorForm>
      </div>
    </Dialog >
  );
}

const mapStateToProps = state => ({
  userRedux: state.user,
  states: state.state.states,
  countys: state.state.countys,
  loading: state.contract.loading,
  error: state.contract.error,
  success: state.contract.success,
});

export default connect(mapStateToProps)(MemberEditorDialog);
