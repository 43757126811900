import UserTable from "./UserTable";

const UserRoute = [
  {
    path: "/configuracoes-usuario",
    exact: true,
    component: UserTable
  }
];

export default UserRoute;
