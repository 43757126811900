import { authRoles } from "../../auth/authRoles";

import UserTable from "./UserTable";

const UserRoute = [
  {
    path: "/usuario-externo",
    exact: true,
    component: UserTable,
  }
];

export default UserRoute;
