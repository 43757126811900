import history from "history.js";
import jwtAuthService from "../../services/jwtAuthService";
import axios from "axios";


export const LIST_MANAGER_LOADING = "LIST_MANAGER_LOADING";
export const LIST_MANAGER_SUCCESS = "LIST_MANAGER_SUCCESS";
export const LIST_MANAGER_FAILED = "LIST_MANAGER_FAILED";
export const UPDATE_MANAGER_LOADING = "UPDATE_MANAGER_LOADING";
export const UPDATE_MANAGER_SUCCESS = "UPDATE_MANAGER_SUCCESS";
export const UPDATE_MANAGER_FAILED = "UPDATE_MANAGER_FAILED";
export const DELETE_MANAGER_LOADING = "DELETE_MANAGER_LOADING";
export const DELETE_MANAGER_SUCCESS = "DELETE_MANAGER_SUCCESS";
export const DELETE_MANAGER_FAILED = "DELETE_MANAGER_FAILED";
export const CREATE_MANAGER_LOADING = "CREATE_MANAGER_LOADING";
export const CREATE_MANAGER_SUCCESS = "CREATE_MANAGER_SUCCESS";
export const CREATE_MANAGER_FAILED = "CREATE_MANAGER_FAILED";
export const LIST_MANAGER_INDICATOR_LOADING = "LIST_MANAGER_INDICATOR_LOADING";
export const LIST_MANAGER_INDICATOR_SUCCESS = "LIST_MANAGER_INDICATOR_SUCCESS";
export const LIST_MANAGER_INDICATOR_FAILED = "LIST_MANAGER_INDICATOR_FAILED";
export const UPDATE_MANAGER_INDICATOR_LOADING = "UPDATE_MANAGER_INDICATOR_LOADING";
export const UPDATE_MANAGER_INDICATOR_SUCCESS = "UPDATE_MANAGER_INDICATOR_SUCCESS";
export const UPDATE_MANAGER_INDICATOR_FAILED = "UPDATE_MANAGER_INDICATOR_FAILED";

export const CLEAR = "CLEAR";

export function listManagerIndicator(token, company, manager) {
  return dispatch => {
    dispatch({
      type: LIST_MANAGER_INDICATOR_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/company/${company}/manager/${manager}/indicator`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_MANAGER_INDICATOR_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_MANAGER_INDICATOR_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}
export function updateManagerIndicator(token, company, manager, data) {
  return dispatch => {
    dispatch({
      type: UPDATE_MANAGER_INDICATOR_LOADING,
    });
    axios({
      method: 'put',
      url: `https://api.stonik.com.br/web/company/${company}/manager/${manager}/indicator`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: UPDATE_MANAGER_INDICATOR_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: UPDATE_MANAGER_INDICATOR_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}
export function listManager(token, company) {
  return dispatch => {
    dispatch({
      type: LIST_MANAGER_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/company/${company}/manager`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_MANAGER_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_MANAGER_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function createManager(token, data, company) {
  return dispatch => {
    dispatch({
      type: CREATE_MANAGER_LOADING,
    });
    axios({
      method: 'post',
      url: `https://api.stonik.com.br/web/company/${company}/manager`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: CREATE_MANAGER_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: CREATE_MANAGER_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}
export function updateManager(token, data, company) {
  return dispatch => {
    dispatch({
      type: UPDATE_MANAGER_LOADING,
    });
    axios({
      method: 'put',
      url: `https://api.stonik.com.br/web/company/${company}/manager/${data.id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: UPDATE_MANAGER_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: UPDATE_MANAGER_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function deleteManager(token, id, company) {
  return dispatch => {
    dispatch({
      type: DELETE_MANAGER_LOADING,
    });
    axios({
      method: 'delete',
      url: `https://api.stonik.com.br/web/company/${company}/manager/${id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: DELETE_MANAGER_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: DELETE_MANAGER_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function clear() {
  return dispatch => {
    dispatch({
      type: CLEAR
    });
  };
}
