/* eslint-disable */
import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Button,
  IconButton,
  Grid,
  Icon,
  Card,
  Fab
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { Breadcrumb } from "egret";
import { PDFDownloadLink } from '@react-pdf/renderer';
import DateFnsUtils from "@date-io/date-fns";
import ptLocale from "date-fns/locale/pt-BR";
import IntegrationReactSelect from "./ReactSelect";
import { MyDocument } from "./pdf"
import ReactExport from "react-export-excel";
import { connect } from "react-redux";
import shortid from "shortid";
import { listOcorrencias } from "../../redux/actions/OrderActions"
import { listBranch } from "../../redux/actions/BranchActions"
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Swal from 'sweetalert2'
import moment from "moment"

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const CrudTable = (props) => {

  const { userRedux, drivers, branchs, lavagens, ocorrencias } = props

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [dataSet1, setDataSet1] = useState([
    {
      inicial: "02/09/2021",
      final: "02/09/2021",
      filial: 'Matriz',
      faturado: "R$ 3000,00"
    },
    {
      inicial: "02/09/2021",
      final: "02/09/2021",
      filial: 'Matriz',
      faturado: "R$ 3000,00"
    }, {
      inicial: "02/09/2021",
      final: "02/09/2021",
      filial: 'Matriz',
      faturado: "R$ 3000,00"
    },
  ])
  const [page, setPage] = useState(0)
  const [date, setDate] = useState(new Date())
  const [finalDate, setFinalDate] = useState(new Date())
  const [userList, setUserList] = useState([])
  const [options, setOptions] = useState([])
  const [optionsDriver, setOptionsDriver] = useState([])
  const [driver, setDriver] = useState(null)
  const [branch, setBranch] = useState(null)
  const [shouldOpenEditorDialog, setShouldOpenEditorDialog] = useState(false)
  const [shouldOpenConfirmationDialog, setShouldOpenConfirmationDialog] = useState(false)
  const [dataSet, setDataSet] = useState([])
  const [open, setOpen] = useState(false)
  const [uid, setUid] = useState(null)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  };

  const handleDialogClose = () => {
    setShouldOpenEditorDialog(false)
    setShouldOpenConfirmationDialog(false)
  };

  const setRowsPerPageFunction = event => {
    setRowsPerPage(event.target.value);
  };

  const handleDeleteUser = user => {
    setShouldOpenConfirmationDialog(true)
  };

  const handleConfirmationResponse = () => {

  };

  const handleSubmit = () => {
    if (date == "") {
      Swal.fire({
        icon: 'error',
        title: "Preencha a data inicial",
        showConfirmButton: false,
        timer: 3000
      })
    } else if (finalDate == "") {
      Swal.fire({
        icon: 'error',
        title: "Preencha a data final",
        showConfirmButton: false,
        timer: 3000
      })
    } else {
      props.dispatch(listOcorrencias(userRedux?.token, userRedux?.company, moment(date).format("DD/MM/YYYY"), moment(finalDate).format("DD/MM/YYYY"), branch))
    }
  }

  const createObjectDataSet = (reports) => {
    var newObj = []
    reports?.map((item, key) => {
      newObj.push({
        Data: item?.date,
        Motorista: item?.driverName,
        Veiculo: item?.vehiclePlate,
        NF: item?.ticketNumber,
        Ponto: item?.locationEvent,
        Status: item?.status,
      })
      return item;
    })
    console.log("newOjt", newObj)
    setDataSet(newObj)
  }

  useEffect(() => {
    props.dispatch(listOcorrencias(userRedux?.token, userRedux?.company, moment(date).format("DD/MM/YYYY"), moment(finalDate).format("DD/MM/YYYY"),))
    props.dispatch(listBranch(userRedux?.token, userRedux?.company))
  }, [])

  const handleDateChange = date => {
    setDate(date)
  };

  useEffect(() => {
    console.log("branchs", branchs)
    if (branchs != null) {
      var newOptions = []
      branchs.map(item => {
        newOptions.push({ label: item?.name, value: item?.id })
      })
      setOptions(newOptions)
    }
  }, [branchs])

  const handleFinalDateChange = finalDate => {
    setFinalDate(finalDate)
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: "Ocorrências" }]} />
      </div>
      <Grid container spacing={5}>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          onClose={() => { setOpen(false) }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Detalhes da ocorrência"}
          </DialogTitle>
          <DialogContent>
            <h3>Descrição</h3>
            <p>{uid?.description}</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {setOpen(false)}} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
        <Grid item lg={2} md={2} sm={12} xs={12} style={{ marginTop: 6 }}>
          <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="mb-16 w-100"
              margin="none"
              id="mui-pickers-date"
              label="Data Início"
              format="dd/MM/yyyy"
              inputVariant="standard"
              type="text"
              autoOk={true}
              value={date}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item lg={2} md={2} sm={12} xs={12} style={{ marginTop: 6 }}>
          <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="mb-16 w-100"
              margin="none"
              id="mui-pickers-date"
              format="dd/MM/yyyy"
              label="Data Fim"
              inputVariant="standard"
              type="text"
              autoOk={true}
              value={finalDate}
              onChange={handleFinalDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <IntegrationReactSelect data={options} onChange={value => setBranch(value)} />
        </Grid>
        <Grid item lg={2} md={2} sm={12} xs={12}>
          <Button color="primary" variant="contained" type="button" onClick={() => { handleSubmit() }} style={{ marginTop: 10 }}>
            <Icon>filter_list</Icon>
            <span className="pl-8 capitalize">Filtrar</span>
          </Button>
        </Grid>
      </Grid>
      {
        ocorrencias?.length > 0 ?
          <>
            <ExcelFile filename="controle-lavagem" element={<Button style={{ backgroundColor: "#f78726", color: "white", marginBottom: 10, marginRight: 5 }} aria-label="Add">Exportar Excel</Button>}>
              <ExcelSheet data={dataSet} name="Employees">
                <ExcelColumn label="Data" value="Data" />
                <ExcelColumn label="Motorista" value="Motorista" />
                <ExcelColumn label="Veiculo" value="Veiculo" />
                <ExcelColumn label="Nota Fiscal" value="NF" />
                <ExcelColumn label="Ponto" value="Ponto" />
                <ExcelColumn label="Status" value="Status" />
              </ExcelSheet>
            </ExcelFile>

            <PDFDownloadLink document={<MyDocument data={dataSet} />} fileName="controle-lavagem.pdf">
              <Button style={{ backgroundColor: "transparent", border: "1.5px solid #f78726", color: "#f78726", marginBottom: 10 }} aria-label="Add">Exportar Pdf</Button>
            </PDFDownloadLink>
            <Card className="w-100 overflow-auto" elevation={6}>
              <h6 style={{ textAlign: "center", marginRight: 10, fontSize: 10, marginTop: 10 }}>Data de emissão: {moment().format("DD/MM/YYYY")}</h6>
              <h6 style={{ textAlign: "center", marginRight: 10, fontSize: 10, }}>Usuário: {userRedux?.displayName}</h6>
              <h4 style={{ textAlign: "center", marginRight: 10 }}>Ocorrências</h4>

              <Table className="crud-table" style={{ whiteSpace: "pre", minWidth: "750px" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Data</TableCell>
                    <TableCell>Tipo</TableCell>
                    <TableCell>Ação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ocorrencias
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((item, index) => (
                      <TableRow key={shortid.generate()} style={{ paddingVertical: 5 }}>
                        <TableCell className="px-0" align="left">{item?.date}</TableCell>
                        <TableCell className="px-0" align="left">{item?.type}</TableCell>
                        <TableCell className="px-0" align="left">
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                              setOpen(true)
                              setUid(item)
                            }}
                          >
                            <Icon>visibility</Icon>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>

              <TablePagination
                className="px-16"
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={ocorrencias.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage="Linhas por Página"
                backIconButtonProps={{
                  "aria-label": "Anterior"
                }}
                nextIconButtonProps={{
                  "aria-label": "Próxima"
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={setRowsPerPageFunction}
              />
            </Card>
          </>
          :
          <Card className="w-100 overflow-auto" elevation={6} style={{ padding: 50 }}>
            <h4 align="center">Sem resultados</h4>
            <h6 align="center">(Selecione os parâmetros)</h6>
          </Card>
      }

    </div >
  );
}

const mapStateToProps = state => ({
  userRedux: state.user,
  operators: state.operator.operators,
  ocorrencias: state.orders.ocorrencias,
  drivers: state.driver.drivers,
  branchs: state.branch.branchs,
  lavagens: state.orders.lavagens,
  loading: state.operator.loading,
  error: state.operator.error,
  success: state.operator.success,
});

export default connect(mapStateToProps)(CrudTable);