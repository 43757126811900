/* eslint-disable */
import jwtAuthService from "../../services/jwtAuthService";
// import FirebaseAuthService from "../../services/firebase/firebaseAuthService";
import { setUserData } from "./UserActions";
import history from "history.js";
import axios from "axios";
import localStorageService from "../../services/localStorageService";
import { navigations } from "../../navigations";
import { authRoles } from "../../auth/authRoles";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const CLEAR = "CLEAR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
export const SEND_CODE_LOADING = "SEND_CODE_LOADING";
export const SEND_CODE_SUCCESS = "SEND_CODE_SUCCESS";
export const SEND_CODE_FAILED = "SEND_CODE_FAILED";

function translateProfile(profile) {
  switch (profile) {
    case "DRIVER":
      return "MOTORISTA"
    case "MANAGER":
      return "GESTOR"
    case "OPERATOR":
      return "OPERADOR"
    case "BOMBER":
      return "BOMBISTA"
    case "SELLER":
      return "VENDEDOR"
    case "WRITER":
      return "DIGITADOR"
    case "CLIENT":
      return "CLIENT"
    case "SUPER_ADM":
      return "SA"
    default:
      return "CLIENT"
  }
}

export function loginWithEmailAndPassword({ cpf, password }) {
  return dispatch => {
    dispatch({
      type: LOGIN_LOADING
    });


    axios({
      method: 'post',
      url: 'https://api.stonik.com.br/auth/login',
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        cpf: cpf,
        password
      }
    }).then(response => {
      console.log("response", response)
      var token = response?.data?.access_token
      jwtAuthService.setSession(response?.data?.access_token)
      axios({
        method: 'get',
        url: 'https://api.stonik.com.br/web/user',
        headers: {
          "Content-Type": "application/json"
        }
      }).then(data => {
        if (data?.data?.type == "CLIENT" || data?.data?.type == "BOMBER") {
          return dispatch({
            type: LOGIN_ERROR,
            payload: { response: { data: { message: "Você não tem acesso a esse sistema!" } } }
          });
        } else {

          var newArray = {}
          newArray.userId = data?.data?.id
          newArray.role = translateProfile(data?.data?.type)
          newArray.displayName = data?.data?.name
          newArray.email = data?.data?.email
          newArray.photoURL = data?.data?.avatar == null ? "/assets/images/face-7.jpg" : data?.data?.avatar
          newArray.age = 99
          newArray.token = token
          newArray.company = data?.data?.company?.id || 1
          newArray.cpf = data?.data?.cpf
          newArray.contact = data?.data?.contact

          axios({
            method: 'get',
            url: `https://api.stonik.com.br/app/appmodule/permission/company/${newArray.company}/user/${newArray.userId}`,
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${newArray.token}`
            }
          }).then(res => {
            const data = res.data
            var newModules = [...navigations]
            newModules.map((item, key) => {
              if (item.type == "ALL") {
                if (item?.name == "Painel") {
                  if ((newArray?.role == "SA" || newArray?.role == "GESTOR")) {
                    item.auth = [authRoles.admin, authRoles.bomber, authRoles.client, authRoles.driver, authRoles.gestor, authRoles.operator, authRoles.sa, authRoles.seller, authRoles.writer]
                  }
                } else {
                  item.auth = [authRoles.admin, authRoles.bomber, authRoles.client, authRoles.driver, authRoles.gestor, authRoles.operator, authRoles.sa, authRoles.seller, authRoles.writer]

                }
                return true;
              } else {
                if (item.hasOwnProperty("children")) {
                  var excluse = []
                  item.children.map((item_children, key_children) => {
                    var achou = false;
                    if (item_children.type == "ALL") {
                      item_children.auth = [authRoles.admin, authRoles.bomber, authRoles.client, authRoles.driver, authRoles.gestor, authRoles.operator, authRoles.sa, authRoles.seller, authRoles.writer]
                      return true;
                    } else {
                      data.map((item_module, key_module) => {
                        if (item_module.module.name == item_children.type && item_module.permission == true) {
                          item_children.auth = [newArray?.role]
                          achou = true
                          return true;
                        }
                      })
                    }
                    if (achou == false) {
                      item_children.auth = ""
                      item_children.name = ""
                      item_children.path = ""
                      item_children.icon = ""

                    }
                  })
                } else {
                  var achou = false;
                  data.map((item_module, key_module) => {
                    if (item_module.module.name == item.type && item_module.permission == true) {
                      item.auth = [newArray?.role]
                      achou = true
                      return;
                    }
                  })
                  if (achou == false) {
                    item.auth = ""
                    item.name = ""
                    item.path = ""
                    item.icon = ""

                  }
                }
              }
            })
            newArray.modules = [...newModules]
            jwtAuthService.setUser(newArray)

            dispatch(setUserData(newArray));

            history.push({
              pathname: "/"
            });

            return dispatch({
              type: LOGIN_SUCCESS
            });

          }).catch(eer => {
            console.log("eer", eer?.response?.data?.message)
            newArray.modules = [...navigations]
            jwtAuthService.setUser(newArray)
            dispatch(setUserData(newArray));

            history.push({
              pathname: "/"
            });

            return dispatch({
              type: LOGIN_SUCCESS
            });
          });
        }

        console.log("newArray", newArray)

      }).catch(err => {
        console.log("err", err)
        return dispatch({
          type: LOGIN_ERROR,
          payload: err
        });
      })
    }).catch(err => {
      console.log("err", err)
      return dispatch({
        type: LOGIN_ERROR,
        payload: err
      });
    })

    // jwtAuthService
    //   .loginWithEmailAndPassword(email, password)
    //   .then(user => {
    //     dispatch(setUserData(user));

    //     history.push({
    //       pathname: "/"
    //     });

    //     return dispatch({
    //       type: LOGIN_SUCCESS
    //     });
    //   })
    //   .catch(error => {
    //     return dispatch({
    //       type: LOGIN_ERROR,
    //       payload: error
    //     });
    //   });
  };
}

export function resetPassword(cpf_email) {

  var cpf_replace = cpf_email.replace(/[^0-9]/g, "")

  return dispatch => {
    dispatch({
      type: RESET_PASSWORD_LOADING
    });
    axios({
      method: 'post',
      url: 'https://api.stonik.com.br/auth/reset_password',
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        cpf_email: cpf_replace
      }
    }).then(res => {
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: RESET_PASSWORD_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };



}

export function sendCode(code, password, confirm_password) {
  return dispatch => {
    dispatch({
      type: SEND_CODE_LOADING
    });
    axios({
      method: 'post',
      url: 'https://api.stonik.com.br/auth/reset_password/confirm',
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        code,
        password,
        confirm_password
      }
    }).then(res => {
      dispatch({
        type: SEND_CODE_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: SEND_CODE_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function clear() {
  return dispatch => {
    dispatch({
      type: CLEAR
    });
  };
}

export function firebaseLoginEmailPassword({ email, password }) {
  return {
    abc: "abc"
  }
  // return dispatch => {
  //   FirebaseAuthService.signInWithEmailAndPassword(email, password)
  //     .then(user => {
  //       if (user) {
  //         dispatch(
  //           setUserData({
  //             userId: "1",
  //             role: "ADMIN",
  //             displayName: "Watson Joyce",
  //             email: "watsonjoyce@gmail.com",
  //             photoURL: "/assets/images/face-7.jpg",
  //             age: 25,
  //             token: "faslkhfh423oiu4h4kj432rkj23h432u49ufjaklj423h4jkhkjh",
  //             ...user
  //           })
  //         );

  //         history.push({
  //           pathname: "/"
  //         });

  //         return dispatch({
  //           type: LOGIN_SUCCESS
  //         });
  //       } else {
  //         return dispatch({
  //           type: LOGIN_ERROR,
  //           payload: "Login Failed"
  //         });
  //       }
  //     })
  //     .catch(error => {
  //       return dispatch({
  //         type: LOGIN_ERROR,
  //         payload: error
  //       });
  //     });
  // };
}
