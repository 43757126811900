import React from "react";

const PrivacyTerm = props => {

  return (
    <div style={{ paddingLeft: 200, paddingRight: 200, backgroundColor: "#f78726" }}>
      <div style={{backgroundColor: "white", paddingTop: 50, paddingBottom: 100, paddingLeft: 30, paddingRight: 30}}>
        <h3 style={{textAlign: "center", marginBottom: 10}} color="primary">Termo de privacidade</h3>
        <p style={{fontSize: 15}}>A Autolog Rastreamento Ltda  compromete-se a manter em ambiente seguro, sob-sigilo todos os dados cadastrais, incluindo e-mail informado, número de IP (Internet Protocol) de acesso e outras informações pessoais e da instituição. Todavia, é de responsabilidade dos usuários manter seu login e senha de acesso ao aplicativo Stonik em sigilo, bem como realizar a conexão com o sistema em um dispositivo seguro, provido de antivírus e firewall.</p>
        <p style={{fontSize: 15}}>No ato do cadastro no sistema o usuário escolhe uma senha, que será utilizada em todos os seus acessos. Em caso de esquecimento, há a possibilidade de recuperar a senha por meio do link “Esqueci minha senha”.</p>
        <p style={{fontSize: 15}}>O usuário recebe uma nova senha de acesso em seu e-mail cadastrado. A senha é pessoal e intransferível. Dessa forma, fica sob a responsabilidade do usuário em comprometer-se a mantê-la em sigilo.</p>
        <p style={{fontSize: 15}}>A Autolog Rastreamento Ltda não fornece dados pessoais do usuário sem seu consentimento, no entanto quando apuradas irregularidades que ferem a legislação vigente, a Autolog atua em cooperação com os órgãos reguladores.</p>
        <p style={{fontSize: 15}}>A Autolog Rastreamento Ltda declara que o aplicativo Stonik atende aos requisitos constantes na Lei Geral de Proteção de dados.</p>
        <p style={{fontSize: 15}}>Ao clicar em <b>cadastre-se</b> o usuário declara por fim, compreender e estar ciente desta política de privacidade.</p>
        <img src="/assets/images/stonik-laranja.png" alt="" style={{width: "15%", marginTop: 60, marginLeft: "43%"}} />
      </div>
    </div>
  )
}

export default PrivacyTerm;
