import {
  LIST_SALESMAN_LOADING,
  LIST_SALESMAN_SUCCESS,
  LIST_SALESMAN_FAILED,
  UPDATE_SALESMAN_LOADING,
  UPDATE_SALESMAN_SUCCESS,
  UPDATE_SALESMAN_FAILED,
  DELETE_SALESMAN_LOADING,
  DELETE_SALESMAN_SUCCESS,
  DELETE_SALESMAN_FAILED,
  CREATE_SALESMAN_LOADING,
  CREATE_SALESMAN_SUCCESS,
  CREATE_SALESMAN_FAILED,
  CLEAR
} from "../actions/SalesmanActions";


const initialState = {success: false,
  loading: false,
  error: null,
  salesmans: []
};

const SalesmanReducer = function(state = initialState, action) {
  switch (action.type) {
    case LIST_SALESMAN_LOADING: {
      console.log("LIST_SALESMAN_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_SALESMAN_SUCCESS: {
      console.log("LIST_SALESMAN_SUCCESS", action?.payload)
      return {
        ...state,
        salesmans: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_SALESMAN_FAILED: {
      console.log("LIST_SALESMAN_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case DELETE_SALESMAN_LOADING: {
      console.log("DELETE_SALESMAN_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_SALESMAN_SUCCESS: {
      console.log("DELETE_SALESMAN_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Vendedor deletado com sucesso!",
        loading: false,
        error: null
      };
    }
    case DELETE_SALESMAN_FAILED: {
      console.log("DELETE_SALESMAN_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case UPDATE_SALESMAN_LOADING: {
      console.log("UPDATE_SALESMAN_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_SALESMAN_SUCCESS: {
      console.log("UPDATE_SALESMAN_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Vendedor atualizado com sucesso!",
        loading: false,
        error: null
      };
    }
    case UPDATE_SALESMAN_FAILED: {
      console.log("UPDATE_SALESMAN_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case CREATE_SALESMAN_LOADING: {
      console.log("CREATE_SALESMAN_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case CREATE_SALESMAN_SUCCESS: {
      console.log("CREATE_SALESMAN_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Vendedor adicionado com sucesso!",
        loading: false,
        error: null
      };
    }
    case CREATE_SALESMAN_FAILED: {
      console.log("CREATE_SALESMAN_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    
    case CLEAR: {
      return {
        ...state,
        error: null,
        success: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default SalesmanReducer;
