import CrudTable from "./MyDiscarts";

const MyDiscarts = [
  {
    path: "/meus-descartes",
    exact: true,
    component: CrudTable
  }
];

export default MyDiscarts;
