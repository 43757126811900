import AppScrumBoard from "./AppScrumBoard";

import Board from "./Board";

const scrumBoardRoutes = [
  {
    path: "/lista_pedidos/:id",
    component: Board
  },
  {
    path: "/lista_pedidos",
    component: AppScrumBoard
  }
];

export default scrumBoardRoutes;
