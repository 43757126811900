import React, { Component, useState, useEffect } from "react";
import {
  Icon,
  IconButton,
  Avatar,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Grid,
  Button,
  Card
} from "@material-ui/core";
import ScrumBoardContainer from "./ScrumBoardContainer";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { addNewCardInList } from "./ScrumBoardService";
import { EgretMenu } from "egret";
import moment from "moment"
import InputMask from "react-input-mask";
import Input from "./../../components/Input"
import { listProductTypes, listProducts } from "../../redux/actions/ProductActions"
import axios from "axios"
import { listState } from '../../redux/actions/StateActions'
import { updateOrder, clear } from '../../redux/actions/OrderActions'
import { listApplication } from '../../redux/actions/ApplicationActions'
import Swal from 'sweetalert2'

import {
  getBoardById,
  addListInBoard,
  getAllMembers,
  getAllLabels,
  addMemberInBoard,
  deleteMemberFromBoard
} from "../../redux/actions/ScrumBoardActions";

const Board = props => {

  const { userRedux, products, productTypes, applications, states, loading, error, success } = props

  const [step, setStep] = useState(1)
  const [order, setOrder] = useState("")
  const [meterage, setMeterage] = useState("")
  const [totalValue, setTotalValue] = useState("")
  const [city, setCity] = useState("")
  const [dateHour, setDateHour] = useState("")
  const [user, setUser] = useState("")
  const [status, setStatus] = useState("")
  const [isHabilited, setIsHabilited] = useState("")
  const [contact, setContact] = useState("")
  const [cep, setCep] = useState("")
  const [endereco, setEndereco] = useState("")
  const [rua, setRua] = useState("")
  const [numero, setNumero] = useState("")
  const [state, setState] = useState("")
  const [selectedEstadoItem, setSelectedEstadoItem] = useState("")
  const [cityId, setCityId] = useState("")
  const [complemento, setComplemento] = useState("")
  const [options, setOptions] = useState([])
  const [optionsState, setOptionsState] = useState([])
  const [requireBomber, setRequireBomber] = useState(false)
  const [product, setProduct] = useState("")
  const [productName, setProductName] = useState("")
  const [productTypeName, setProductTypeName] = useState("")
  const [productTypeId, setProductTypeId] = useState("")
  const [applicationName, setApplicationName] = useState("")
  const [applicationId, setApplicationId] = useState("")
  const [optionsProductType, setOptionsProductType] = useState([])
  const [optionsApplication, setOptionsApplication] = useState([])
  const [dateDelivery, setDateDelivery] = useState("")
  const [dateDeliveryEdit, setDateDeliveryEdit] = useState("")
  const [bairro, setBairro] = useState("")
  const [contract, setContract] = useState(null)
  const [message, setMessage] = useState("")

  const buscarCep = (text) => {

    var textReplaced = text.replace("-", "")

    axios({
      method: 'get',
      url: `https://viacep.com.br/ws/${textReplaced}/json/`,
      headers: {
        "Content-Type": "application/json"
      }
    }).then(res => {
      var data = res.data
      setRua(`${data.logradouro} ${data.bairro}`)
      setState(data.uf)
      setCity(data.localidade)
      setCityId(data.ibge)
      var find = states.find(res => res.unit == data.uf)
      setSelectedEstadoItem(find)
      console.log("data", data)
    }).catch(eer => {
      console.log("err", eer)
    })
  }

  useEffect(() => {
    console.log("props", props.location.search)
    var search = props.location.search.slice(1)
    var split = search.split("&")
    console.log("split", split)
    var values = []
    split.map(item => {
      var splitEquals = item.split("=")
      values.push(splitEquals[1])
    })
    console.log("values", values)
    setOrder(values[0])
    setMeterage(values[1])
    setTotalValue(values[2])
    setCity(decodeURI(values[3]))
    setDateHour(moment(decodeURI(values[4])).add("3", "hours").format("DD/MM/YYYY HH:mm"))
    setUser(values[5].replace(/%20/gi, " "))
    setStatus(values[6].replace(/%20/gi, " "))
    setIsHabilited(values[7] == "false" ? false : true)
    setContact(decodeURI(values[8]))
    setProduct(values[9])
    setRequireBomber(values[10] == "false" ? false : true)
    setCep(values[11]?.replace('-', ''))
    setEndereco(decodeURI(values[12]))
    setRua(decodeURI(values[13]))
    setNumero(values[14])
    setComplemento(decodeURI(values[15]))
    setProductName(decodeURI(values[16]))
    setApplicationName(decodeURI(values[17]))
    setProductTypeName(decodeURI(values[18]))
    setProductTypeId(values[19])
    setApplicationId(values[20])
    setDateDelivery(decodeURI(values[21]))
    setBairro(decodeURI(values[22]))
    setContract(values[23] == "null" ? null : values[23])
    if (values[24]) {
      setStep(values[24])
      console.log("values[24]", values[24])
    }

    console.log("application", values[20])

  }, [props.location])

  useEffect(() => {
    props.dispatch(listProductTypes(userRedux?.token, userRedux?.company))
    props.dispatch(listProducts(userRedux?.token, userRedux?.company))
    props.dispatch(listApplication(userRedux?.token))
    props.dispatch(listState(userRedux?.token))
  }, [])

  useEffect(() => {
    if (products != null) {
      console.log("products", products)
      var newOptions = []
      products.map(item => {
        newOptions.push({ label: item?.name, value: item?.id })
      })
      setOptions(newOptions)
    }
  }, [products])

  useEffect(() => {
    if (productTypes != null) {
      console.log("productTypes", productTypes)
      var newOptions = []
      productTypes.map(item => {
        newOptions.push({ label: item?.name, value: item?.id })
      })
      setOptionsProductType(newOptions)
    }
  }, [productTypes])

  useEffect(() => {
    if (applications != null) {
      console.log("applications", applications)
      var newOptions = []
      applications.map(item => {
        newOptions.push({ label: item?.name, value: item?.id })
      })
      setOptionsApplication(newOptions)
    }
  }, [applications])

  useEffect(() => {
    if (states != null) {
      console.log("states", states)
      var newOptions = []
      states.map(item => {
        newOptions.push({ label: item?.description, value: item?.id })
      })
      setOptionsState(newOptions)
    }
  }, [states])

  useEffect(() => {
    console.log("cep", cep)
    if (cep?.length == 8 && step != 1) {
      buscarCep(cep);
    }
  }, [cep])

  useEffect(() => {
    console.log("success", success)
    if (success != false) {
      Swal.fire({
        icon: 'success',
        title: success,
        text: "Aguardando emissão do ticket",
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(() => {
        props.dispatch(clear())
        goTo("/lista_pedidos")
      }, 3500)
    }
  }, [success])


  const goTo = (link) => {
    return (
      <Link to={link} />
    )
  }

  useEffect(() => {
    console.log("error", error)
    if (error != null) {
      Swal.fire({
        icon: 'error',
        title: error,
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(() => {
        props.dispatch(clear())
      }, 3500)
    }
  }, [error])

  const handleEditOrder = () => {
    if (product == "") {
      setMessage("Selecione o produto!")
    } else if (meterage == "") {
      setMessage("Preencha a metragem!")
    } else if (cep == "") {
      setMessage("Preencha o cep do cliente!")
    } else if (cep?.length < 8 || cep?.length > 8) {
      setMessage("O CEP deve conter somente 8 dígitos!")
    } else if (endereco == "") {
      setMessage("Preencha o endereço do cliente!")
    } else if (totalValue == "") {
      setMessage("Preencha o valor total do produto!")
    } else if (endereco == "") {
      setMessage("Preencha o dia de entrega do produto!")
    } else if (applicationId == "") {
      setMessage("Preencha o tipo de aplicação do produto!")
    } else if (numero == "") {
      setMessage("Preencha o número do endereço do cliente!")
    } else {
      props.dispatch(updateOrder(userRedux?.token, userRedux?.company, order, {
        product: {
          id: product
        },
        application: {
          id: applicationId
        },
        valueTotal: totalValue,
        dateDelivery: dateDeliveryEdit == "" ? dateDelivery : moment(dateDeliveryEdit).format("DD/MM/YYYY HH:mm"),
        meterage: meterage,
        pumpServiceRequired: requireBomber,
        deliveryLocation: {
          street: rua,
          number: numero,
          complement: complemento,
          neighborhood: bairro,
          city: {
            id: cityId
          },
          cep: cep
        },
        contract
      }))
    }
  }

  let { memberList = [] } = props;

  return (
    <div className="scrum-board m-sm-30">
      <div className="flex flex-wrap flex-middle flex-space-between mb-16">
        <div className="flex flex-middle">
          <Link to="/lista_pedidos">
            <IconButton>
              <Icon>arrow_back</Icon>
            </IconButton>
          </Link>
          <h5 className="m-0 ml-8 capitalize">Detalhes do pedido</h5>
        </div>
      </div>
      <Grid container spacing={2} style={{ justifyContent: "center" }}>
        <Card className="p-24 cursor-pointer" elevation={10} style={{ width: "50%" }}>
          {
            step == 1 ? <>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <h6 className="m-0 ml-8 capitalize" align="center">Número do Pedido:</h6>
                <p className="m-0 ml-8 capitalize" align="center">{order}</p>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: "10px" }}>
                <h6 className="m-0 ml-8 capitalize" align="center">Nome do Cliente:</h6>
                <p className="m-0 ml-8 capitalize" align="center">{user}</p>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: "10px" }}>
                <h6 className="m-0 ml-8 capitalize" align="center">Contato:</h6>
                <p className="m-0 ml-8 capitalize" align="center">{contact}</p>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: "10px" }}>
                <h6 className="m-0 ml-8 capitalize" align="center">Cidade:</h6>
                <p className="m-0 ml-8 capitalize" align="center">{city}</p>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: "10px" }}>
                <h6 className="m-0 ml-8 capitalize" align="center">Data e Hora de Criação:</h6>
                <p className="m-0 ml-8 capitalize" align="center">{dateHour}</p>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: "10px" }}>
                <h6 className="m-0 ml-8 capitalize" align="center">Data e Hora de Entrega:</h6>
                <p className="m-0 ml-8 capitalize" align="center">{dateDelivery}</p>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: "10px" }}>
                <h6 className="m-0 ml-8 capitalize" align="center">Habilitado:</h6>
                <p className="m-0 ml-8 capitalize" align="center">{isHabilited == "true" ? "Sim" : "Não"}</p>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: "10px" }}>
                <h6 className="m-0 ml-8 capitalize" align="center">Produto:</h6>
                <p className="m-0 ml-8 capitalize" align="center">{productName}</p>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: "10px" }}>
                <h6 className="m-0 ml-8 capitalize" align="center">Tipo do Produto:</h6>
                <p className="m-0 ml-8 capitalize" align="center">{productTypeName}</p>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: "10px" }}>
                <h6 className="m-0 ml-8 capitalize" align="center">Aplicação:</h6>
                <p className="m-0 ml-8 capitalize" align="center">{applicationName}</p>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: "10px" }}>
                <h6 className="m-0 ml-8 capitalize" align="center">Metragem:</h6>
                <p className="m-0 ml-8 capitalize" align="center">{meterage}</p>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: "10px" }}>
                <h6 className="m-0 ml-8 capitalize" align="center">Bombeado:</h6>
                <p className="m-0 ml-8 capitalize" align="center">{requireBomber == true ? "Sim" : "Não"}</p>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: "10px" }}>
                <h6 className="m-0 ml-8 capitalize" align="center">Valor Total:</h6>
                <p className="m-0 ml-8 capitalize" align="center">{totalValue}</p>
              </Grid>
            </>
              :
              <>
                <label className="mb-4">Produto</label>
                <select className="w-100 mb-16" onChange={event => { setProductTypeId(event.target.value) }} style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                  <option value=""></option>
                  {
                    options.map(item =>
                      <option selected={productTypeId == item?.value} value={item?.value}>{item?.label}</option>
                    )
                  }

                </select>
                <label className="mb-4">Tipo do Produto</label>
                <select className="w-100 mb-16" onChange={event => { setProduct(event.target.value) }} style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                  <option value=""></option>
                  {
                    optionsProductType.map(item =>
                      <option selected={product == item?.value} value={item?.value}>{item?.label}</option>
                    )
                  }

                </select>
                <label className="mb-4">Tipo de Aplicação</label>
                <select className="w-100 mb-16" onChange={event => { setApplicationId(event.target.value) }} style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                  <option value=""></option>

                  {
                    optionsApplication.map(item =>
                      <option selected={applicationId == item?.value} value={item?.value}>{item?.label}</option>
                    )
                  }

                </select>
                <label className="mb-4">Metragem</label>
                <InputMask
                  className="w-100 mb-16"
                  style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                  label="meterage"
                  onChange={
                    (event) => { setMeterage(event.target.value) }}
                  type="text"
                  name="meterage"
                  value={meterage}
                />
                <label className="mb-4">Bombeamento</label>
                <select className="w-100 mb-16" onChange={event => { setRequireBomber(event.target.value) }} style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                  <option selected={requireBomber} value={true}>Sim</option>
                  <option selected={!requireBomber} value={false}>Nao</option>
                </select>
                {
                  dateDelivery != null &&
                  <>
                    <label className="mb-4">Data de Entrega</label>
                    <InputMask
                      className="w-100 mb-16"
                      style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                      label="dateDelivery"
                      onChange={
                        (event) => { setDateDeliveryEdit(event.target.value) }}
                      type="datetime-local"
                      name="dateDelivery"
                      value={dateDeliveryEdit == "" ? dateDelivery?.split(" ")[0]?.split("/")[2] + "-" + dateDelivery?.split(" ")[0]?.split("/")[1] + "-" + dateDelivery?.split(" ")[0]?.split("/")[0] + "T" + dateDelivery?.split(" ")[1] : dateDeliveryEdit}
                    />
                  </>
                }
                <label className="mb-4">Valor Total</label>
                <InputMask
                  className="w-100 mb-16"
                  style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                  label="valorTotal"
                  onChange={
                    (event) => { setTotalValue(event.target.value) }}
                  type="number"
                  name="totalValue"
                  value={totalValue}
                />
                <label className="mb-4">CEP</label>
                <InputMask
                  className="w-100 mb-16"
                  style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                  label="cep"
                  onChange={
                    (event) => { setCep(event.target.value) }}
                  type="numeric"
                  name="cep"
                  value={cep}
                />
                <label className="mb-4">Estado</label>
                <InputMask
                  className="w-100 mb-16"
                  style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                  label="state"
                  disabled="disabled"
                  onChange={(event) => {
                    setState(event.target.value)
                  }}
                  type="text"
                  name="state"
                  value={state}
                />
                <label className="mb-4">Cidade</label>
                <InputMask
                  className="w-100 mb-16"
                  style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                  label="City"
                  disabled="disabled"
                  onChange={(event) => {
                    setCity(event.target.value)
                  }}
                  type="text"
                  name="City"
                  value={city}
                />
                <label className="mb-4">Rua</label>
                <InputMask
                  className="w-100 mb-16"
                  style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                  label="rua"
                  onChange={
                    (event) => { setRua(event.target.value) }}
                  type="text"
                  name="rua"
                  value={rua}
                />
                <label className="mb-4">Bairro</label>
                <InputMask
                  className="w-100 mb-16"
                  style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                  label="bairro"
                  onChange={
                    (event) => { setBairro(event.target.value) }}
                  type="text"
                  name="bairro"
                  value={bairro}
                />
                <label className="mb-4">Número</label>
                <InputMask
                  className="w-100 mb-16"
                  style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                  label="numero"
                  onChange={
                    (event) => { setNumero(event.target.value) }}
                  type="text"
                  name="numero"
                  value={numero}
                />
                <label className="mb-4">Complemento</label>
                <InputMask
                  className="w-100 mb-16"
                  style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}
                  label="complemento"
                  onChange={
                    (event) => { setComplemento(event.target.value) }}
                  type="text"
                  name="complemento"
                  value={complemento}
                />
                {
                  message != "" && <div style={{ backgroundColor: "rgba(255,0,0,.2)", padding: 5, marginTop: 10 }}><Icon style={{ color: "red", marginRight: 5, marginLeft: 5 }} fontSize="small">close</Icon><span style={{ color: "red", fontWeight: "bold" }}>{message}</span></div>
                }
                <Button onClick={() => {
                  handleEditOrder()
                }} style={{ width: "100%", backgroundColor: "#f78726", borderRadius: 10, color: "white", paddingTop: 5 }}>Editar Registro</Button>

              </>
          }

        </Card>
      </Grid>
    </div>
  );

}

const mapStateToProps = state => ({
  getBoardById: PropTypes.func.isRequired,
  addListInBoard: PropTypes.func.isRequired,
  userRedux: state.user,
  applications: state.application.applications,
  products: state.product.productTypes,
  productTypes: state.product.products,
  states: state.state.states,
  loading: state.orders.loading,
  success: state.orders.success,
  error: state.orders.error,
  getAllMembers: PropTypes.func.isRequired,
  getAllLabels: PropTypes.func.isRequired,
  addMemberInBoard: PropTypes.func.isRequired,
  deleteMemberFromBoard: PropTypes.func.isRequired,
  board: state.scrumboard.board,
  memberList: state.scrumboard.memberList,
  labelList: state.scrumboard.labelList
});

export default connect(
  mapStateToProps
)(Board);
