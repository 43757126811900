import {
  LIST_COMPANIES_LOADING,
  LIST_COMPANIES_SUCCESS,
  LIST_COMPANIES_FAILED,
  LIST_ORDER_COMPANY_LOADING,
  LIST_ORDER_COMPANY_SUCCESS,
  LIST_ORDER_COMPANY_FAILED,
  LIST_FILIAL_RAIO_LOADING,
  LIST_FILIAL_RAIO_SUCCESS,
  LIST_FILIAL_RAIO_FAILED,
  UPDATE_FILIAL_RAIO_LOADING,
  UPDATE_FILIAL_RAIO_SUCCESS,
  UPDATE_FILIAL_RAIO_FAILED,
  LIST_ORDER_WAITING_FOR_SELLER_COMPANY_LOADING,
  LIST_ORDER_WAITING_FOR_SELLER_COMPANY_SUCCESS,
  LIST_ORDER_WAITING_FOR_SELLER_COMPANY_FAILED,
  CREATE_COMPANIES_LOADING,
  CREATE_COMPANIES_SUCCESS,
  CREATE_COMPANIES_FAILED,
  UPDATE_COMPANIES_LOADING,
  UPDATE_COMPANIES_SUCCESS,
  UPDATE_COMPANIES_FAILED,
  DELETE_COMPANIES_LOADING,
  DELETE_COMPANIES_SUCCESS,
  DELETE_COMPANIES_FAILED,
  CLEAR
} from "../actions/CompanyActions";


const initialState = {
  success: false,
  loading: false,
  error: null,
  companies: [],
  raioFiliais: [],
  orders_company: []
};

const CompanyReducer = function (state = initialState, action) {
  switch (action.type) {
    case LIST_ORDER_WAITING_FOR_SELLER_COMPANY_LOADING: {
      console.log("LIST_ORDER_WAITING_FOR_SELLER_COMPANY_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_ORDER_WAITING_FOR_SELLER_COMPANY_SUCCESS: {
      console.log("LIST_ORDER_WAITING_FOR_SELLER_COMPANY_SUCCESS")
      return {
        ...state,
        orders_company: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_ORDER_WAITING_FOR_SELLER_COMPANY_FAILED: {
      console.log("LIST_ORDER_WAITING_FOR_SELLER_COMPANY_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case LIST_ORDER_COMPANY_LOADING: {
      console.log("LIST_ORDER_COMPANY_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_ORDER_COMPANY_SUCCESS: {
      console.log("LIST_ORDER_COMPANY_SUCCESS")
      return {
        ...state,
        orders_company: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_ORDER_COMPANY_FAILED: {
      console.log("LIST_ORDER_COMPANY_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case LIST_COMPANIES_LOADING: {
      console.log("LIST_COMPANIES_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_COMPANIES_SUCCESS: {
      console.log("LIST_COMPANIES_SUCCESS")
      return {
        ...state,
        companies: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_COMPANIES_FAILED: {
      console.log("LIST_COMPANIES_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case CREATE_COMPANIES_LOADING: {
      console.log("CREATE_COMPANIES_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case CREATE_COMPANIES_SUCCESS: {
      console.log("CREATE_COMPANIES_SUCCESS")
      return {
        ...state,
        success: "Empresa cadastrada com sucesso!",
        loading: false,
        error: null
      };
    }
    case CREATE_COMPANIES_FAILED: {
      console.log("CREATE_COMPANIES_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case UPDATE_COMPANIES_LOADING: {
      console.log("UPDATE_COMPANIES_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_COMPANIES_SUCCESS: {
      console.log("UPDATE_COMPANIES_SUCCESS")
      return {
        ...state,
        success: "Empresa editada com sucesso!",
        loading: false,
        error: null
      };
    }
    case UPDATE_COMPANIES_FAILED: {
      console.log("UPDATE_COMPANIES_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case DELETE_COMPANIES_LOADING: {
      console.log("DELETE_COMPANIES_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_COMPANIES_SUCCESS: {
      console.log("DELETE_COMPANIES_SUCCESS")
      return {
        ...state,
        success: "Empresa excluida com sucesso!",
        loading: false,
        error: null
      };
    }
    case DELETE_COMPANIES_FAILED: {
      console.log("DELETE_COMPANIES_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }

    case LIST_FILIAL_RAIO_LOADING: {
      console.log("LIST_FILIAL_RAIO_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_FILIAL_RAIO_SUCCESS: {
      console.log("LIST_FILIAL_RAIO_SUCCESS")
      return {
        ...state,
        raioFiliais: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_FILIAL_RAIO_FAILED: {
      console.log("LIST_FILIAL_RAIO_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }

    case UPDATE_FILIAL_RAIO_LOADING: {
      console.log("UPDATE_FILIAL_RAIO_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_FILIAL_RAIO_SUCCESS: {
      console.log("UPDATE_FILIAL_RAIO_SUCCESS")
      return {
        ...state,
        success: "Raio da Empresa editada com sucesso!",
        loading: false,
        error: null
      };
    }
    case UPDATE_FILIAL_RAIO_FAILED: {
      console.log("UPDATE_FILIAL_RAIO_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }

    case CLEAR: {
      return {
        ...state,
        error: null,
        success: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default CompanyReducer;
