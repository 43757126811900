import {
  LIST_OPERATOR_LOADING,
  LIST_OPERATOR_SUCCESS,
  LIST_OPERATOR_FAILED,
  UPDATE_OPERATOR_LOADING,
  UPDATE_OPERATOR_SUCCESS,
  UPDATE_OPERATOR_FAILED,
  DELETE_OPERATOR_LOADING,
  DELETE_OPERATOR_SUCCESS,
  DELETE_OPERATOR_FAILED,
  CREATE_OPERATOR_LOADING,
  CREATE_OPERATOR_SUCCESS,
  CREATE_OPERATOR_FAILED,
  CLEAR
} from "../actions/OperatorActions";


const initialState = {success: false,
  loading: false,
  error: null,
  operators: []
};

const OperatorReducer = function(state = initialState, action) {
  switch (action.type) {
    case LIST_OPERATOR_LOADING: {
      console.log("LIST_OPERATOR_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_OPERATOR_SUCCESS: {
      console.log("LIST_OPERATOR_SUCCESS", action?.payload)
      return {
        ...state,
        operators: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_OPERATOR_FAILED: {
      console.log("LIST_OPERATOR_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case DELETE_OPERATOR_LOADING: {
      console.log("DELETE_OPERATOR_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_OPERATOR_SUCCESS: {
      console.log("DELETE_OPERATOR_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Operador deletado com sucesso!",
        loading: false,
        error: null
      };
    }
    case DELETE_OPERATOR_FAILED: {
      console.log("DELETE_OPERATOR_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case UPDATE_OPERATOR_LOADING: {
      console.log("UPDATE_OPERATOR_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_OPERATOR_SUCCESS: {
      console.log("UPDATE_OPERATOR_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Operador atualizado com sucesso!",
        loading: false,
        error: null
      };
    }
    case UPDATE_OPERATOR_FAILED: {
      console.log("UPDATE_OPERATOR_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case CREATE_OPERATOR_LOADING: {
      console.log("CREATE_OPERATOR_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case CREATE_OPERATOR_SUCCESS: {
      console.log("CREATE_OPERATOR_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Operador adicionado com sucesso!",
        loading: false,
        error: null
      };
    }
    case CREATE_OPERATOR_FAILED: {
      console.log("CREATE_OPERATOR_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    
    case CLEAR: {
      return {
        ...state,
        error: null,
        success: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default OperatorReducer;
