import React, { useEffect, useState } from "react";
import {
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Icon,
  TablePagination,
  Button,
  Card
} from "@material-ui/core";
import MemberEditorDialog from "./MemberEditorDialog";
import MemberVisualizationDialog from "./MemberVisualizationDialog";
import { Breadcrumb, ConfirmationDialog } from "egret";
import shortid from "shortid";

import { clear, listTicket, deleteTicket } from "../../redux/actions/TicketActions"
import { listContracts } from '../../redux/actions/ContractActions'
import { listProductTypes } from '../../redux/actions/ProductActions'
import { listState } from '../../redux/actions/StateActions'
import { listDriver } from '../../redux/actions/DriverActions'
import { listBomber } from '../../redux/actions/BomberActions'
import { listFleet } from '../../redux/actions/FleetActions'
import { listApplication } from '../../redux/actions/ApplicationActions'

import { connect } from "react-redux";

import Swal from 'sweetalert2'

const CrudTable = (props) => {

  const {
    userRedux,
    tickets,
    contracts,
    bombers,
    productTypes,
    drivers,
    states,
    fleets,
    applications,
    loading,
    success,
    error
  } = props

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [uid, setUid] = useState(null)
  const [historic, setHistoric] = useState(null)
  const [ticketList, setTicketList] = useState([])
  const [contractsList, setContractList] = useState([])
  const [bomberList, setBomberList] = useState([])
  const [fleetsList, setFleetsList] = useState([])
  const [applicationsList, setApplicationsList] = useState([])
  const [driverList, setDriverList] = useState([])
  const [productTypeList, setProductTypeList] = useState([])
  const [stateList, setStateList] = useState([])
  const [user, setUser] = useState("")
  const [shouldOpenEditorDialog, setShouldOpenEditorDialog] = useState(false)
  const [shouldOpenVisualizationDialog, setShouldOpenVisualizationDialog] = useState(false)
  const [shouldOpenConfirmationDialog, setShouldOpenConfirmationDialog] = useState(false)

  const setPageFunction = page => {
    setPage(page);
  };

  const setRowsPerPageFunction = event => {
    setRowsPerPage(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPageFunction(newPage);
  };

  const handleDialogCloseFunction = () => {
    setShouldOpenEditorDialog(false)
    setShouldOpenConfirmationDialog(false)
  };

  const handleDialogCloseVisualizationFunction = () => {
    setShouldOpenVisualizationDialog(false)
  };

  const handleDeleteUser = user => {
    setShouldOpenConfirmationDialog(true)
    setUid(user.user.id)
  };

  const handleConfirmationResponse = () => {
    props.dispatch(deleteTicket(userRedux?.token, uid, userRedux?.company))
  };

  useEffect(() => {
    props.dispatch(listTicket(userRedux?.token, userRedux?.company))
    props.dispatch(listProductTypes(userRedux?.token, userRedux?.company))
    props.dispatch(listState(userRedux?.token))
    props.dispatch(listDriver(userRedux?.token, userRedux?.company))
    props.dispatch(listBomber(userRedux?.token, userRedux?.company))
    props.dispatch(listFleet(userRedux?.token, userRedux?.company))
    props.dispatch(listApplication(userRedux?.token))
    props.dispatch(listContracts(userRedux?.token, userRedux?.id, userRedux?.company))
  }, [])

  useEffect(() => {
    console.log("tickets", tickets)
    tickets != null && setTicketList(tickets)
  }, [tickets])

  useEffect(() => {
    console.log("contracts", contracts)
    contracts != null && setContractList(contracts)
  }, [contracts])
  
  useEffect(() => {
    console.log("productTypes", productTypes)
    productTypes != null && setProductTypeList(productTypes)
  }, [productTypes])

  useEffect(() => {
    console.log("states", states)
    states != null && setStateList(states)
  }, [states])

  useEffect(() => {
    console.log("drivers", drivers)
    drivers != null && setDriverList(drivers)
  }, [drivers])

  useEffect(() => {
    console.log("bombers", bombers)
    bombers != null && setBomberList(bombers)
  }, [bombers])

  useEffect(() => {
    console.log("fleets", fleets)
    fleets != null && setFleetsList(fleets)
  }, [fleets])

  useEffect(() => {
    console.log("applications", applications)
    applications != null && setApplicationsList(applications)
  }, [applications])

  useEffect(() => {
    if (success != false) {
      setShouldOpenConfirmationDialog(false)
      Swal.fire({
        icon: 'success',
        title: success,
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(() => {
        props.dispatch(clear())
        window.location.reload()
      }, 3500)
    }
  }, [success])

  useEffect(() => {
    if (error != null) {
      console.log("Error", error)
      setShouldOpenConfirmationDialog(false)

      Swal.fire({
        icon: 'error',
        title: error,
        showConfirmButton: false,
        timer: 3000
      })

      setTimeout(() => {
        props.dispatch(clear())
        setShouldOpenEditorDialog(true)
        // window.location.reload()
      }, 3500)
    }
  }, [error])

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: "Adicionar ticket" }]} />
      </div>

      <Button
        className="mb-16"
        variant="contained"
        color="primary"
        onClick={() => {
          setUid(null)
          setShouldOpenEditorDialog(true)
        }}
      >
        <Icon>add-circle</Icon>
        Adicionar registro
      </Button>
      <Card className="w-100 overflow-auto" elevation={6}>
        <Table className="crud-table" style={{ whiteSpace: "pre", minWidth: "750px" }}>
          <TableHead>
            <TableRow>
              <TableCell>Pedido</TableCell>
              <TableCell>Nº nota</TableCell>
              <TableCell>Valor total</TableCell>
              <TableCell align="center">Ação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ticketList
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => (
                <TableRow key={shortid.generate()}>
                  <TableCell className="px-0" align="left">
                    {item.order.order}
                  </TableCell>
                  <TableCell className="px-0" align="left">
                    {item.ticketNumber}
                  </TableCell>
                  <TableCell className="px-0" align="left">
                    {item.valueTotalTicket}
                  </TableCell>
                  <TableCell className="px-0 border-none" align="center">
                    <IconButton
                      onClick={() => {
                        setUid(item)
                        setShouldOpenVisualizationDialog(true)
                      }}
                    >
                      <Icon color="primary">remove_red_eye</Icon>
                    </IconButton>
                    {/* <IconButton onClick={() => handleDeleteUser(item)}>
                      <Icon color="error">remove_red_eye</Icon>
                    </IconButton> */}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        <TablePagination
          className="px-16"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={ticketList?.length}
          rowsPerPage={rowsPerPage}
          page={page}
                labelRowsPerPage="Linhas por Página"
                backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={setRowsPerPageFunction}
        />

        {shouldOpenEditorDialog && (
          <MemberEditorDialog
            handleClose={handleDialogCloseFunction}
            open={shouldOpenEditorDialog}
            contracts={contractsList}
            productType={productTypeList}
            states={stateList}
            drivers={driverList}
            bombers={bomberList}
            fleets={fleetsList}
            applications={applicationsList}
            historicHandle={(value) => {setHistoric(value)}}
            historic={historic}
          />
        )}
        {shouldOpenVisualizationDialog && (
          <MemberVisualizationDialog
            handleClose={handleDialogCloseVisualizationFunction}
            open={shouldOpenVisualizationDialog}
            uid={uid}
          />
        )}
        {shouldOpenConfirmationDialog && (
          <ConfirmationDialog
            open={shouldOpenConfirmationDialog}
            onConfirmDialogClose={handleDialogCloseFunction}
            onYesClick={handleConfirmationResponse}
            text="Tem certeza que deseja excluir este registro?"
            title="Excluir registro"
            confirmButtonText="Sim"
            declineButtonText="Não"
          />
        )}
      </Card>
    </div>
  );

}

const mapStateToProps = state => ({
  userRedux: state.user,
  tickets: state.ticket.tickets,
  contracts: state.contract.contracts,
  bombers: state.bomber.bombers,
  drivers: state.driver.drivers,
  productTypes: state.product.productTypes,
  states: state.state.states,
  fleets: state.fleet.fleets,
  applications: state.application.applications,
  loading: state.ticket.loading,
  error: state.ticket.error,
  success: state.ticket.success,
});

export default connect(mapStateToProps)(CrudTable);
