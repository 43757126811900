import EditUser from "./EditUser";

const formsRoutes = [
  {
    path: "/alterar-senha",
    component: EditUser
  },
];

export default formsRoutes;
