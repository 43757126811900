import { authRoles } from "./auth/authRoles";

export const navigations = [
  {
    name: "Painel",
    path: "/painel",
    icon: "dashboard",
    type: "ALL",
    auth: [authRoles.sa, authRoles.driver]
  },
  {
    name: "Meu Prefil",
    path: "/perfil",
    type: "ALL",
    icon: "person",
    auth: [authRoles.sa]
  },
  {
    name: "Editar Meus Dados",
    path: "/editar-usuario",
    type: "ALL",
    icon: "edit",
    auth: [authRoles.sa]
  },
  {
    name: "Alterar Senha",
    path: "/alterar-senha",
    type: "ALL",
    icon: "vpn_key",
    auth: [authRoles.sa]
  },
  {
    name: "Configurações",
    icon: "settings_applications",
    children: [
      {
        name: "Minhas Configurações",
        path: "/configuracoes-usuario",
        icon: "person",
        type: "CONFIGURATION_PERSONAL",
        auth: [authRoles.sa]
      },
      // {
      //   name: "Gerais",
      //   path: "/configuracoes-gerais",
      //   icon: "settings",
      //   type: "CONFIGURATION_SYSTEM",
      //   auth: [authRoles.sa]
      // },
      {
        name: "Gestão De Indicadores",
        path: "/indicadores",
        icon: "trending_up",
        type: "CONFIGURATION_INDICATOR_MANAGEMENT",
        auth: [authRoles.sa]
      },
      // {
      //   name: "Valor Médio Em M3",
      //   path: "/valor-medio-metro-cubico-venda",
      //   icon: "local_shipping",
      //   type: "CONFIGURATION_CUBIC_METER_SALE",
      //   auth: [authRoles.sa]
      // },
      {
        name: "Produção Por Betoneira",
        path: "/producao-esperada-por-betoneira",
        icon: "local_shipping",
        type: "CONFIGURATION_PRODUCTION_BY_CONCRETE_MIXER",
        auth: [authRoles.sa]
      },
      {
        name: "Raio",
        path: "/configuracoes-raio",
        icon: "wifi_tethering",
        type: "CONFIGURATION_RADIUS_OF_THE_UNIT",
        auth: [authRoles.sa]
      },
    ]
  },
  {
    name: "Cadastros",
    icon: "add",
    children: [
      {
        name: "Usuário Externo",
        path: "/usuario-externo",
        icon: "person",
        type: "CRUD_EXTERNAL_USER",
        auth: [authRoles.sa]
      },
      {
        name: "Empresas Concreteiras",
        path: "/empresas-concreteiras",
        icon: "house",
        type: "CRUD_COMPANY",
        auth: [authRoles.sa]
      },
      {
        name: "Cliente",
        path: "/cliente",
        icon: "people",
        type: "CRUD_CLIENT",
        auth: [authRoles.sa]
      },
      {
        name: "Vendedor",
        path: "/vendedor",
        icon: "shopping_basket",
        type: "CRUD_SELLER",
        auth: [authRoles.sa]
      },
      {
        name: "Motorista",
        path: "/motorista",
        icon: "time_to_leave",
        type: "CRUD_DRIVER",
        auth: [authRoles.sa]
      },
      {
        name: "Produtos",
        path: "/produtos",
        icon: "note_add",
        type: "CRUD_PRODUCT_TYPE",
        auth: [authRoles.sa]
      },
      {
        name: "Tipo De Produto",
        path: "/tipo-produto",
        icon: "person",
        type: "CRUD_PRODUCT",
        auth: [authRoles.sa]
      },
      {
        name: "Frota",
        path: "/frota",
        icon: "local_shipping",
        type: "CRUD_VEHICLE",
        auth: [authRoles.sa]
      },
      {
        name: "Exceção",
        path: "/ponto-excecao",
        icon: "format_list_bulleted",
        type: "CRUD_LOCATION",
        auth: [authRoles.sa]
      },
      // {
      //   name: "Descarte",
      //   path: "/ponto-descarte",
      //   icon: "format_list_bulleted",
      //   type: "CRUD_LOCATION",
      //   auth: [authRoles.sa]
      // },
      {
        name: "Empresa/Filial",
        path: "/empresa-filial",
        icon: "house",
        type: "CRUD_COMPANY_BRANCH",
        auth: [authRoles.sa]
      },
      {
        name: "Operador",
        path: "/operador",
        icon: "build",
        type: "CRUD_OPERATOR",
        auth: [authRoles.sa]
      },
      {
        name: "Bombista",
        path: "/bombista",
        icon: "ev_station",
        type: "CRUD_BOMBER",
        auth: [authRoles.sa]
      },
      {
        name: "Contrato",
        path: "/contrato",
        icon: "assignment",
        type: "CRUD_CONTRACT",
        auth: [authRoles.sa]
      },
      {
        name: "Tipo De Aplicação",
        path: "/tipo-aplicacao",
        icon: "queue",
        type: "CRUD_APPLICATION_TYPE",
        auth: [authRoles.sa]
      },
      {
        name: "Gestor",
        path: "/gestor",
        icon: "record_voice_over",
        type: "CRUD_MANAGER",
        auth: [authRoles.sa]
      },
      {
        name: "Digitador",
        path: "/digitador",
        icon: "keyboard",
        type: "CRUD_WRITER",
        auth: [authRoles.sa]
      },
    ]
  },
  {
    name: "Relatórios",
    icon: "format_list_bulleted",
    children: [
      {
        name: "Operação Geral",
        path: "/operacao-geral",
        icon: "format_list_bulleted",
        type: "REPORT_OPERATION_GENERAL",
        auth: [authRoles.sa]
      },
      {
        name: "Relatório Geral",
        path: "/geral",
        icon: "format_list_bulleted",
        type: "REPORT_GENERAL",
        auth: [authRoles.sa]
      },
      {
        name: "Faturamento",
        path: "/faturamento",
        icon: "format_list_bulleted",
        type: "REPORT_BILLING",
        auth: [authRoles.sa]
      },
      {
        name: "Produção Diária",
        path: "/producao-diaria",
        icon: "format_list_bulleted",
        type: "REPORT_PRODUCTION_DAILY",
        auth: [authRoles.sa]
      },
      {
        name: "Produção Média Betoneira",
        path: "/producao-media-betoneira",
        icon: "format_list_bulleted",
        type: "REPORT_PRODUCTION_BETONEIRA_AVERAGE",
        auth: [authRoles.sa]
      },
      {
        name: "Produção Por Produto",
        path: "/producao-por-produto",
        icon: "format_list_bulleted",
        type: "REPORT_PRODUCTION_PRODUCT",
        auth: [authRoles.sa]
      },
      {
        name: "Distância e Volume",
        path: "/analise-de-distancia-e-volume",
        icon: "format_list_bulleted",
        type: "REPORT_DISTANCE_AND_VOLUME_ANALYSIS",
        auth: [authRoles.sa]
      },
      {
        name: "Cálculo De Vendas Por FCK",
        path: "/calculo-vendas-por-fck",
        icon: "format_list_bulleted",
        type: "REPORT_SALES_FCK",
        auth: [authRoles.sa]
      },
      // {
      //   name: "Relatório personalizado",
      //   path: "/relatorio-personalizado",
      //   icon: "format_list_bulleted",
      //   auth: [authRoles.gestor]
      // },
      {
        name: "Relatório Descarregamento",
        path: "/descarregamento",
        icon: "format_list_bulleted",
        type: "REPORT_DISCHARGE",
        auth: [authRoles.sa]
      },
      {
        name: "Relatório Descarte",
        path: "/relatorio-descarte",
        icon: "format_list_bulleted",
        type: "REPORT_DISCARD",
        auth: [authRoles.sa]
      },
      {
        name: "Análise De Descarte",
        path: "/relatorio-analise-descarte",
        icon: "format_list_bulleted",
        type: "REPORT_DISCARD_ANALYSIS",
        auth: [authRoles.sa]
      },
      {
        name: "Relatório de Inconsistências",
        path: "/inconsistencias",
        icon: "format_list_bulleted",
        type: "REPORT_INCONSISTENCY",
        auth: [authRoles.sa]
      },
      {
        name: "Descarte Por Raio",
        path: "/descarte-por-raio",
        icon: "format_list_bulleted",
        type: "REPORT_DISCARD_RADIUS",
        auth: [authRoles.sa]
      },
    ]
  },
  {
    name: "Pedidos",
    icon: "format_list_bulleted",
    children: [
      {
        name: "Fila De Pedidos",
        icon: "group_add",
        path: "/lista_pedidos",
        type: "ORDER_QUEUE",
        auth: [authRoles.sa]
      },
      {
        name: "Gestão De Agendamento",
        icon: "today",
        path: "/gestao-de-agendamento",
        type: "MANAGEMENT_SCHEDULE",
        auth: [authRoles.sa]
      },
      // {
      //   name: "Rastreio De Pedido",
      //   icon: "explore",
      //   path: "/rastreio-pedido",
      //   type: "QUERY_TRACKING_ORDER",
      //   auth: [authRoles.sa]
      // },
      {
        name: "Ticket",
        path: "/ticket",
        icon: "receipt",
        type: "CRUD_TICKET",
        auth: [authRoles.sa]
      },
    ]
  },
  // {
  //   name: "Gestão da operação",
  //   icon: "format_list_bulleted",
  //   path: "/gestao-operacao",
  //   auth: authRoles.gestor
  // },
  {
    name: "Controle De Lavagem",
    icon: "photo_filter",
    path: "/controle-lavagem",
    type: "WASHING_CONTROL",
    auth: [authRoles.sa]
  },
  {
    name: "Ocorrências",
    icon: "report_problem",
    path: "/ocorrencias",
    type: "OCCURRENCES",
    auth: [authRoles.sa]
  },
  {
    name: "Meus Alertas",
    icon: "notifications_active",
    path: "/meus-alertas",
    type: "MY_ALERTS",
    auth: [authRoles.sa]
  },
  // {
  //   name: "Minhas Descargas",
  //   icon: "rv_hookup",
  //   path: "/minhas-descargas",
  //   type: "VIEW_MY_DISCHARGE",
  //   auth: []
  // },
  // {
  //   name: "Meus Descartes",
  //   icon: "delete_sweep",
  //   path: "/meus-descartes",
  //   type: "VIEW_MY_DISCARD",
  //   auth: []
  // },
  // {
  //   name: "Minhas Lavagens",
  //   icon: "photo_filter",
  //   path: "/minhas-lavagens",
  //   type: "VIEW_MY_WASHING",
  //   auth: []
  // },
  {
    name: "Controle De Auditoria",
    icon: "receipt",
    path: "/auditoria",
    type: "CONTROL_AUDIT_SYSTEM",
    auth: [authRoles.sa]
  },
  // {
  //   name: "CRUD Table",
  //   icon: "format_list_bulleted",
  //   path: "/crud-table",
  //   badge: { value: "68", color: "primary" }
  // },
  // {
  //   name: "Egret List",
  //   icon: "list",
  //   children: [
  //     {
  //       name: "Infinite Scroll",
  //       path: "/infinite-scroll",
  //       iconText: "I"
  //     },
  //     {
  //       name: "List",
  //       path: "/egret-list",
  //       iconText: "L"
  //     }

  //   ]
  // },
  // {
  //   name: "Invoice",
  //   icon: "receipt",
  //   path: "/invoice/list"
  // },
  // {
  //   name: "Scrum Board",
  //   icon: "group_work",
  //   path: "/scrum-board"
  // },
  // {
  //   name: "Calendar",
  //   icon: "date_range",
  //   path: "/calendar",
  //   badge: { value: "5", color: "secondary" }
  // },
  // {
  //   name: "Chat",
  //   icon: "chat",
  //   path: "/chat",
  //   badge: { value: "3", color: "primary" }
  // },
  // {
  //   name: "Inbox",
  //   icon: "inbox",
  //   path: "/inbox",
  //   badge: { value: "6", color: "primary" }
  // },
  // {
  //   name: "Map",
  //   icon: "add_location",
  //   path: "/map"
  // },
  // {
  //   name: "Todo",
  //   icon: "center_focus_strong",
  //   path: "/todo/list"
  // },
  // {
  //   name: "Forms",
  //   icon: "description",
  //   children: [
  //     {
  //       name: "Basic",
  //       path: "/forms/basic",
  //       iconText: "B"
  //     },
  //     {
  //       name: "Editor",
  //       path: "/forms/editor",
  //       iconText: "E"
  //     },
  //     {
  //       name: "Upload",
  //       path: "/forms/upload",
  //       iconText: "U"
  //     },
  //     {
  //       name: "Wizard",
  //       path: "/forms/wizard",
  //       iconText: "W"
  //     }
  //   ]
  // },
  // {
  //   name: "Page Layouts",
  //   icon: "view_carousel",
  //   children: [
  //     {
  //       name: "Left Sidebar Card",
  //       path: "/page-layouts/Left-sidebar-card",
  //       iconText: "L"
  //     },
  //     {
  //       name: "User Profile",
  //       path: "/perfil",
  //       iconText: "U"
  //     }
  //   ]
  // },

  // {
  //   name: "UI Kits",
  //   icon: "favorite",
  //   badge: { value: "50+", color: "secondary" },
  //   children: [
  //     {
  //       name: "Auto Complete",
  //       path: "/material/autocomplete",
  //       iconText: "A"
  //     },
  //     {
  //       name: "Buttons",
  //       path: "/material/buttons",
  //       iconText: "B"
  //     },
  //     {
  //       name: "Checkbox",
  //       path: "/material/checkbox",
  //       iconText: "C"
  //     },
  //     {
  //       name: "Dialog",
  //       path: "/material/dialog",
  //       iconText: "D"
  //     },
  //     {
  //       name: "Expansion Panel",
  //       path: "/material/expansion-panel",
  //       iconText: "E"
  //     },
  //     {
  //       name: "Form",
  //       path: "/material/form",
  //       iconText: "F"
  //     },
  //     {
  //       name: "Icons",
  //       path: "/material/icons",
  //       iconText: "I"
  //     },
  //     {
  //       name: "Menu",
  //       path: "/material/menu",
  //       iconText: "M"
  //     },
  //     {
  //       name: "Progress",
  //       path: "/material/progress",
  //       iconText: "P"
  //     },
  //     {
  //       name: "Radio",
  //       path: "/material/radio",
  //       iconText: "R"
  //     },
  //     {
  //       name: "Switch",
  //       path: "/material/switch",
  //       iconText: "S"
  //     },
  //     {
  //       name: "Slider",
  //       path: "/material/slider",
  //       iconText: "S"
  //     },
  //     {
  //       name: "Snackbar",
  //       path: "/material/snackbar",
  //       iconText: "S"
  //     },
  //     {
  //       name: "Table",
  //       path: "/material/table",
  //       iconText: "T"
  //     }
  //   ]
  // },
  // {
  //   name: "Charts",
  //   icon: "trending_up",
  //   children: [
  //     {
  //       name: "Echarts",
  //       path: "/charts/echarts",
  //       iconText: "E"
  //     },
  //     {
  //       name: "Recharts",
  //       path: "/charts/recharts",
  //       iconText: "R"
  //     },
  //     {
  //       name: "React Vis",
  //       path: "/charts/react-vis",
  //       iconText: "R"
  //     },
  //     {
  //       name: "Victory Chart",
  //       path: "/charts/victory-charts",
  //       iconText: "V"
  //     }
  //   ]
  // },
  // {
  //   name: "Drag and Drop",
  //   icon: "control_camera",
  //   path: "/others/drag-and-drop"
  // },
  // {
  //   name: "Others",
  //   icon: "blur_on",
  //   children: [
  //     {
  //       name: "Pricing",
  //       path: "/others/pricing",
  //       iconText: "P"
  //     }
  //   ]
  // },
  // {
  //   name: "Multilevel",
  //   icon: "trending_up",
  //   children: [
  //     {
  //       name: "Level 1",
  //       iconText: "E",
  //       children: [
  //         {
  //           name: "Item 1",
  //           path: "/chart"
  //         },
  //         {
  //           name: "Item 2",
  //           path: "/chart"
  //         },
  //         {
  //           name: "Item 3",
  //           path: "/chart"
  //         },
  //         {
  //           name: "Item 4",
  //           path: "/chart"
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   name: "Utilities",
  //   icon: "format_list_bulleted",
  //   children: [
  //     {
  //       name: "Color",
  //       path: "/utilities/color",
  //       iconText: "C"
  //     },
  //     {
  //       name: "Spacing",
  //       path: "/utilities/spacing",
  //       iconText: "S"
  //     },
  //     {
  //       name: "Typography",
  //       path: "/utilities/typography",
  //       iconText: "T"
  //     },
  //     {
  //       name: "Display",
  //       path: "/utilities/display",
  //       iconText: "D"
  //     }
  //   ]
  // },
  // {
  //   name: "Sessions",
  //   icon: "trending_up",
  //   children: [
  //     {
  //       name: "Sign in",
  //       iconText: "SI",
  //       path: "/session/signin"
  //     },
  //     {
  //       name: "Sign up",
  //       iconText: "SU",
  //       path: "/session/signup"
  //     },
  //     {
  //       name: "Error",
  //       iconText: "404",
  //       path: "/session/404"
  //     }
  //   ]
  // }
];

