import React, { useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from "@material-ui/core";
import { isThisWeek } from 'date-fns';
// Create styles
const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingVertical: 10,
        paddingHorizontal: 10
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    header: {
        fontSize: 7,
        width: "4.15%",
        textAlign: "left",
        fontWeight: "800"
    },
    subtitle: {
        width: '4.15%',
        textAlign: "left",
        fontSize: 6,
    }
});

// Create Document Component
export const MyDocument = (props) => {
    const { data } = props

    useEffect(() => {
        console.log('data', data)
    }, [data])

    return (
        < Document>
            <Page size="A4" style={styles.page} orientation="landscape">
                <View style={styles.tableContainer}>
                    <View style={[styles.row, { marginBottom: 10 }]}>
                        <Text style={styles.header}>Nome</Text>
                        <Text style={styles.header}>Tp. pessoa</Text>
                        <Text style={styles.header}>CPF/CNPJ</Text>
                        <Text style={styles.header}>Produto</Text>
                        <Text style={styles.header}>Dt agendamento</Text>
                        <Text style={styles.header}>Dt entrega</Text>
                        <Text style={styles.header}>Nº pedido</Text>
                        <Text style={styles.header}>Nº contrato</Text>
                        <Text style={styles.header}>Nº frota</Text>
                        <Text style={styles.header}>Nº nota</Text>
                        <Text style={styles.header}>Tipo Concreto</Text>
                        <Text style={styles.header}>Vl. unitario</Text>
                        <Text style={styles.header}>Volume em M3</Text>
                        <Text style={styles.header}>Endereço</Text>
                        <Text style={styles.header}>Estado</Text>
                        <Text style={styles.header}>Cidade</Text>
                        <Text style={styles.header}>Aplicação</Text>
                        <Text style={styles.header}>Motorista</Text>
                        <Text style={styles.header}>Bombeamento</Text>
                        <Text style={styles.header}>Bombista</Text>
                        <Text style={styles.header}>Vr Bomb.</Text>
                        <Text style={styles.header}>Prazo pag.</Text>
                        <Text style={styles.header}>Unidade</Text>
                        <Text style={styles.header}>Vl. Total</Text>
                    </View>
                    {
                        data?.map(item => {
                            console.log("item", item)
                            return (
                                <View style={[styles.row]}>
                                    <Text style={styles.subtitle}>{item?.Nome}</Text>
                                    <Text style={styles.subtitle}>{item?.Tp_Pessoa}</Text>
                                    <Text style={styles.subtitle}>{item?.Cpf_cnpj}</Text>
                                    <Text style={styles.subtitle}>{item?.Produto}</Text>
                                    <Text style={styles.subtitle}>{item?.Data_agendamento}</Text>
                                    <Text style={styles.subtitle}>{item?.Data_entrega}</Text>
                                    <Text style={styles.subtitle}>{item?.N_pedido}</Text>
                                    <Text style={styles.subtitle}>{item?.N_contrato}</Text>
                                    <Text style={styles.subtitle}>{item?.Numero_frota}</Text>
                                    <Text style={styles.subtitle}>{item?.Numero_nota}</Text>
                                    <Text style={styles.subtitle}>{item?.tipo_concreto}</Text>
                                    <Text style={styles.subtitle}>{item?.valor_unitario}</Text>
                                    <Text style={styles.subtitle}>{item?.volume_m3}</Text>
                                    <Text style={styles.subtitle}>{item?.endereco}</Text>
                                    <Text style={styles.subtitle}>{item?.estado}</Text>
                                    <Text style={styles.subtitle}>{item?.cidade}</Text>
                                    <Text style={styles.subtitle}>{item?.aplicacao}</Text>
                                    <Text style={styles.subtitle}>{item?.motorista}</Text>
                                    <Text style={styles.subtitle}>{item?.bombeamento}</Text>
                                    <Text style={styles.subtitle}>{item?.bombista}</Text>
                                    <Text style={styles.subtitle}>{item?.valor_bombeamento}</Text>
                                    <Text style={styles.subtitle}>{item?.prazo_pagamento}</Text>
                                    <Text style={styles.subtitle}>{item?.unidade}</Text>
                                    <Text style={styles.subtitle}>{item?.vl_total}</Text>
                                </View>)
                        })
                    }
                </View>

            </Page>
        </Document >
    )
};