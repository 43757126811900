import UserTable from "./SecondDischardAlert";

const SecondDischardAlert = [
  {
    path: "/meus-alertas",
    exact: true,
    component: UserTable
  }
];

export default SecondDischardAlert;
