import AppEchart from "./echarts/AppEchart";
import AppRechart from "./recharts/AppRechart";
import AppVictoryChart from "./victory-charts/AppVictoryChart";
import AppReactVis from "./react-vis/AppReactVis";

const chartsRoute = [
  {
    path: "/charts/echarts",
    component: AppEchart
  },
  {
    path: "/charts/recharts",
    component: AppRechart
  },
  {
    path: "/charts/victory-charts",
    component: AppVictoryChart
  },
  {
    path: "/charts/react-vis",
    component: AppReactVis
  }
];

export default chartsRoute;
