import { authRoles } from "../../auth/authRoles";

import Shop from "./Shop";
import Cart from "./Cart";
import Checkout from "./Checkout";

const ecommerceRoutes = [
  {
    path: "/ecommerce/shop",
    component: Shop,
    auth: authRoles.admin
  },
  {
    path: "/ecommerce/cart",
    component: Cart,
    auth: authRoles.admin
  },
  {
    path: "/ecommerce/checkout",
    component: Checkout,
    auth: authRoles.admin
  },
];

export default ecommerceRoutes;
