import UserTable from "./Ocurrences";

const Ocurrences = [
  {
    path: "/ocorrencias",
    exact: true,
    component: UserTable
  }
];

export default Ocurrences;
