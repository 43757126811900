/* eslint-disable */
import React, { Component, useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  Icon,
  IconButton,
  TableRow,
  TableCell,
  TablePagination,
  Button,
  Grid,
  Card,
  Collapse,
  Box,
  Typography,
  CircularProgress
} from "@material-ui/core";
import { getAllUser, deleteUser } from "./TableService";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Breadcrumb, ConfirmationDialog } from "egret";
import shortid from "shortid";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptLocale from "date-fns/locale/pt-BR";
import { MyDocument } from "./pdf"
import { format } from "date-fns"
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReactExport from "react-export-excel";
import { connect } from "react-redux";
import Swal from 'sweetalert2'
import IntegrationReactSelect from "./ReactSelect";
import IntegrationReactSelectProduct from "./ReactSelectProduct"
import moment from "moment"
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import MarkerMap from "./MarkerMap";

import { listBranch } from "../../redux/actions/BranchActions"
import { listDischargeReport } from "../../redux/actions/DischargeActions"
import { listProductTypes, listProducts } from "../../redux/actions/ProductActions"

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const CrudTable = (props) => {

  const { userRedux, reports, productTypes, products, branchs } = props

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [date, setDate] = useState(new Date())
  const [finalData, setFinalData] = useState(new Date())
  const [volume, setVolume] = useState("")
  const [distancia, setDistancia] = useState("")
  const [uid, setUid] = useState([])
  const [dataList, setDataList] = useState([])
  const [branchSelect, setBranchSelect] = useState(null)
  const [productTypeSelect, setProductTypeSelect] = useState(null)
  const [dataSet, setDataSet] = useState([])
  const [shouldOpenEditorDialog, setShouldOpenEditorDialog] = useState(false)
  const [shouldOpenConfirmationDialog, setShouldOpenConfirmationDialog] = useState(false)
  const [options, setOptions] = useState([])
  const [branchOptions, setBranchOptions] = useState([])
  const [selected, setSelected] = useState(null)
  const [open, setOpen] = useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogLats, setOpenDialogLats] = useState(null);

  const setPageFunction = page => {
    setPage(page)
  };

  const setRowsPerPageFunction = event => {
    setRowsPerPage(event.target.value)
  };

  const handleChangePage = (event, newPage) => {
    setPageFunction(newPage)
  };

  const handleDialogClose = () => {
    setShouldOpenEditorDialog(false)
    setShouldOpenConfirmationDialog(false)
    updatePageData()
  };

  const handleDeleteUser = user => {
    setShouldOpenConfirmationDialog(true)
  };

  const handleConfirmationResponse = () => {

  };

  const createObjectDataSet = (reports) => {
    var newObj = []
    reports?.map(item => {
      newObj.push({
        date: item?.date,
        plate: item?.vehicle?.plate,
        firstInvoice: item?.firstInvoice,
        hourFirstInvoice: item?.hourFirstInvoice,
        qntInvoices: item?.qntInvoices,
        qntDischarges: item?.qntDischarges,
        qntInconsistency: item?.qntInconsistency,
      })
      return item;
    })
    console.log("newOjt", newObj)
    setDataSet(newObj)
  }

  const filterData = () => {
    if (date == null) {
      Swal.fire({
        icon: 'error',
        title: "Adicione a data",
        showConfirmButton: false,
        timer: 3000
      })
    }
    else {
      props.dispatch(listDischargeReport(userRedux?.token, userRedux?.company, format(date, "dd/MM/yyyy"), branchSelect, productTypeSelect))
    }
  }

  const handleClickOpen = (coords) => {
    setOpenDialog(true)
    setOpenDialogLats(coords)
  }

  const handleClose = () => {
    setOpenDialog(false)
    setOpenDialogLats(null)
  }

  useEffect(() => {
    updatePageData()
    console.log("date", date)
    props.dispatch(listProducts(userRedux?.token, userRedux?.company))
    props.dispatch(listBranch(userRedux?.token, userRedux?.company))

  }, [])

  useEffect(() => {
    console.log("reports", reports)
    setDataList(reports)
    createObjectDataSet(reports)
  }, [reports])

  useEffect(() => {
    if (products != null) {
      console.log("products", products)
      var newOptions = []
      products.map(item => {
        newOptions.push({ label: item?.name, value: item?.id })
      })
      setOptions(newOptions)
    }
  }, [products])

  useEffect(() => {
    if (branchs != null) {
      console.log("branchs", branchs)
      var newOptions = []
      branchs.map(item => {
        newOptions.push({ label: item?.nameFantasy, value: item?.id })
      })
      setBranchOptions(newOptions)
    }
  }, [branchs])

  const updatePageData = () => {
  };

  const handleDateChange = date => {
    console.log("date", date)
    setDate(date)
  };

  const handleFinalDateChange = finalDate => {
    setFinalData(finalDate)
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: "Relatório de Descarregamento" }]} />
      </div>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        onClose={() => { handleClose() }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Localização do descarregamento selecionado"}
        </DialogTitle>
        <DialogContent>
          <MarkerMap coords={openDialogLats} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={5}>
        <Grid item lg={3} md={3} sm={12} xs={12} style={{ marginTop: 6 }}>
          <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="mb-16 w-100"
              margin="none"
              id="mui-pickers-date"
              label="Data Início"
              format="dd/MM/yyyy"
              inputVariant="standard"
              type="text"
              autoOk={true}
              value={date}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <IntegrationReactSelect data={branchOptions} onChange={(value) => { setBranchSelect(value) }} />
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <IntegrationReactSelectProduct data={options} onChange={(value) => { setProductTypeSelect(value) }} />
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Button color="primary" variant="contained" type="button" onClick={() => { filterData() }} style={{ marginTop: 10 }}>
            <Icon>filter_list</Icon>
            <span className="pl-8 capitalize">Filtrar</span>
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <ExcelFile filename="relatório-descarregamento" element={<Button style={{ backgroundColor: "#f78726", color: "white", marginBottom: 10, marginRight: 5 }} aria-label="Add">Exportar Excel</Button>}>
            <ExcelSheet data={dataSet} name="Descarregamento">
              <ExcelColumn label="Data" value="date" />
              <ExcelColumn label="Placa" value="plate" />
              <ExcelColumn label="Nota" value="firstInvoice" />
              <ExcelColumn label="Horário" value="hourFirstInvoice" />
              <ExcelColumn label="Quantidade de notas" value="qntInvoices" />
              <ExcelColumn label="Quantidade de entregas" value="qntDischarges" />
              <ExcelColumn label="Quantidade de inconsistências" value="qntInconsistency" />
            </ExcelSheet>
          </ExcelFile>
          <PDFDownloadLink document={<MyDocument data={dataSet} />} fileName="relatório-descarregamento.pdf">
            <Button style={{ backgroundColor: "transparent", border: "1.5px solid #f78726", color: "#f78726", marginBottom: 10 }} aria-label="Add">Exportar Pdf</Button>
          </PDFDownloadLink>
        </Grid>
      </Grid>


      <Card className="w-100 overflow-auto" elevation={6}>
        <h6 style={{ textAlign: "center", marginRight: 10, fontSize: 10, marginTop: 10 }}>Data de emissão: {moment().format("D/MM/Y")}</h6>
        <h6 style={{ textAlign: "center", marginRight: 10, fontSize: 10, }}>Usuário: {userRedux?.displayName}</h6>
        <h4 style={{ textAlign: "center", marginRight: 10 }}>Relatório De Descarregamento</h4>
        {
          dataList.length == 0 ?
            <h5 style={{ textAlign: "center", marginTop: 20 }}>Sem dados para esta filtragem.</h5>
            :
            <Table className="crud-table" style={{ whiteSpace: "pre", minWidth: "750px" }}>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Placa</TableCell>
                  <TableCell>Nota</TableCell>
                  <TableCell>Horário</TableCell>
                  <TableCell>Qtd. de notas</TableCell>
                  <TableCell>Qtd. de entregas</TableCell>
                  <TableCell>Qtd. de inconsistências</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <>
                      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={1} style={{ paddingVertical: 5 }}>
                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(index == open ? null : index)}
                          >
                            <Icon>{open == index ? "expand_less" : "expand_more"}</Icon>
                          </IconButton>
                        </TableCell>
                        <TableCell className="px-0" align="left">{item?.date}</TableCell>
                        <TableCell className="px-0" align="left">{item?.vehicle?.plate}</TableCell>
                        <TableCell className="px-0" align="left">{item?.firstInvoice}</TableCell>
                        <TableCell className="px-0" align="left">{item?.hourFirstInvoice}</TableCell>
                        <TableCell className="px-0" align="left">{item?.qntInvoices}</TableCell>
                        <TableCell className="px-0" align="left">{item?.qntDischarges}</TableCell>
                        <TableCell className="px-0" align="left">{item?.qntInconsistency}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                          <Collapse in={open == index} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                              <Grid container spacing={5}>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                  <Typography variant="h6" gutterBottom align="center" component="div" style={{ fontSize: 14, backgroundColor: "#cad1d5" }}>
                                    Notas
                                  </Typography>
                                  <Table size="small">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell style={{ fontSize: 12 }}>Nota</TableCell>
                                        <TableCell style={{ fontSize: 12 }}>Data/hora</TableCell>
                                        <TableCell style={{ fontSize: 12 }} align="right">Obra</TableCell>
                                        <TableCell style={{ fontSize: 12 }} align="right">Motorista</TableCell>
                                        <TableCell style={{ fontSize: 12 }} align="right">Conteúdo</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {
                                        item?.tickets?.map((item_nota, key_nota) => {

                                          return (
                                            <TableRow key={key_nota}>
                                              <TableCell style={{ fontSize: 10 }}>
                                                {item_nota?.ticketNumber}
                                              </TableCell>
                                              <TableCell style={{ fontSize: 10 }}>{item_nota?.createdTicket}</TableCell>
                                              <TableCell style={{ fontSize: 10 }} align="right">{item_nota?.order?.deliveryLocation?.street}</TableCell>
                                              <TableCell style={{ fontSize: 10 }} align="right">{item_nota?.driver?.name}</TableCell>
                                              <TableCell style={{ fontSize: 10 }} align="right">{item_nota?.order?.product?.name}</TableCell>
                                            </TableRow>
                                          )

                                        })
                                      }

                                    </TableBody>
                                  </Table>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                  <Typography variant="h6" gutterBottom align="center" component="div" style={{ fontSize: 14, backgroundColor: "#cad1d5" }}>
                                    Descargas
                                  </Typography>
                                  <Table size="small">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell style={{ fontSize: 12 }}>Data evento</TableCell>
                                        <TableCell style={{ fontSize: 12 }} align="right">Endereço</TableCell>
                                        <TableCell style={{ fontSize: 12 }} align="right">Ação</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {
                                        item?.discharges?.map((item_descarga, key_descarga) => {

                                          return (
                                            <TableRow key={key_descarga}>
                                              <TableCell style={{ fontSize: 10 }}>{moment(item_descarga?.dateTimeAction).format("D/M/Y")}</TableCell>
                                              <TableCell style={{ fontSize: 10 }} align="right">{item_descarga?.address}</TableCell>
                                              <TableCell style={{ fontSize: 10 }} align="right">
                                                <IconButton
                                                  onClick={() => handleClickOpen({ lat: item_descarga?.latitude, lng: item_descarga?.longitude })}
                                                >
                                                  <Icon style={{ fontSize: 15 }} color="primary">public</Icon>
                                                </IconButton>
                                              </TableCell>
                                            </TableRow>
                                          )

                                        })
                                      }
                                    </TableBody>
                                  </Table>
                                </Grid>
                              </Grid>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
        }


        <TablePagination
          className="px-16"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={dataList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Linhas por Página"
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={setRowsPerPageFunction}
        />
        {shouldOpenConfirmationDialog && (
          <ConfirmationDialog
            open={shouldOpenConfirmationDialog}
            onConfirmDialogClose={handleDialogClose}
            onYesClick={handleConfirmationResponse}
            text="Tem certeza que deseja excluir este registro?"
            title="Excluir registro"
            confirmButtonText="Sim"
            declineButtonText="Não"
          />
        )}
      </Card>
    </div>
  );
}

const mapStateToProps = state => ({
  userRedux: state.user,
  reports: state.discharge.discharges,
  productTypes: state.product.productTypes,
  products: state.product.products,
  branchs: state.branch.branchs,
  loading: state.reports.loading,
  error: state.reports.error,
  success: state.reports.success,
});

export default connect(mapStateToProps)(CrudTable);