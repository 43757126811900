import React, { useState, useEffect } from "react";
import {
  Dialog,
  Button,
  Grid,
  Icon
} from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";

import { createDriver, updateDriver } from "../../redux/actions/DriverActions"
import { listUnboundClient, addUserToClient, clearClientsUnbound } from "../../redux/actions/ClientActions"

import Input from "./../../components/Input"

import Swal from 'sweetalert2'

const MemberEditorDialog = (props) => {

  const { handleClose, open, user, uid, clients, clients_unbounds } = props

  const [nome, setNome] = useState(uid != null ? uid.user.name : "");
  const [cpf, setCpf] = useState(uid != null ? uid.user.cpf : "");
  const [email, setEmail] = useState(uid != null ? uid.user.email : "");
  const [password, setPassword] = useState("");
  const [contact, setContact] = useState(uid != null ? uid.user.contact : "");
  const [client, setClient] = useState(uid != null ? uid.user.client : "");
  const [showInformations, setShowInformations] = useState(false);

  const [message, setMessage] = useState("");

  function TestaCPF(strCPF) {
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF == "00000000000") return false;

    for (var i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (var i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }

  const verificarCpf = () => {
    console.log("cpf.length", cpf.length)
    if (cpf == "" || cpf.length != 14) {
      setMessage("Preencha o cpf do usuário corretamente!")
      setShowInformations(false)
    } else if (client == "") {
      setMessage("Selecione o cliente que deseja associar neste cpf!")
      setShowInformations(false)
    } else {
      props.dispatch(listUnboundClient(user?.token, user?.company, client, cpf.replace(/[^0-9]/g, "")))
    }

  }

  const vincularUsuario = () => {
    if (cpf == "" || cpf.length != 14) {
      setMessage("Preencha o cpf do usuário corretamente!")
    } else if (client == "") {
      setMessage("Selecione o cliente que deseja associar neste cpf!")
    } else {
      handleClose()
      props.dispatch(addUserToClient(user?.token, {userId: clients_unbounds[0]?.id} , user?.company, client))
    }

  }

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  useEffect(() => {
    console.log("uid", uid)
    return () => {
      props.dispatch(clearClientsUnbound())
    }
  }, [])

  useEffect(() => {
    console.log("clients_unbounds", clients_unbounds)
    if (clients_unbounds != null) {
      if (clients_unbounds.length == 0) {
        setMessage("CPF já vinculado ao cliente ou inexistente!")
        setShowInformations(false)
      } else {
        setMessage("")
        setNome(clients_unbounds[0]?.name)
        setEmail(clients_unbounds[0]?.email)
        setContact(clients_unbounds[0]?.contact)
        setShowInformations(true)
      }
    }
  }, [clients_unbounds])

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="p-24" style={{ width: "400px" }}>
        <h4 className="mb-20">Adicionar Usuário Externo</h4>
        <ValidatorForm>
          <Grid className="mb-16" container spacing={10}>
            <Grid item sm={12}>
              {
                uid == null ?
                  <>
                    <label className="mb-4">CPF</label>
                    <Input mask="999.999.999-99" value={cpf} onChange={(event) => {
                      setCpf(event.target.value)
                    }} />
                    <label className="mb-4">Cliente</label>
                    <select className="w-100 mb-16" onChange={(event) => { setClient(event.target.value) }} style={{ padding: 5, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 1 }}>
                      <option value=""></option>
                      {
                        clients.map(item => {
                          return (
                            <option value={item?.id}>{item?.name}</option>
                          )
                        })
                      }


                    </select>
                    <Button variant="outlined" color="primary" className="w-100 mb-16" onClick={() => { verificarCpf() }}>
                      <Icon>search</Icon> Verificar CPF
                    </Button>
                    {/* {
                      showInformations && <>
                        
                      </>
                    } */}
                    {
                      showInformations && <>
                        <h5 className="w-100 text-center mb-16 mt-16" color="primary"><Icon style={{fontSize: 15}}>person</Icon> Usuário selecionado</h5>
                        <h6 className="w-100 text-center primary" color="primary">Nome</h6>
                        <p className="w-100 text-center">{nome}</p>
                        <h6 className="w-100 text-center" color="primary">Email</h6>
                        <p className="w-100 text-center">{email}</p>
                        <h6 className="w-100 text-center" color="primary">Contato</h6>
                        <p className="w-100 text-center">{contact}</p>
                        <Button variant="contained" color="primary" className="w-100 mt-16" onClick={() => { vincularUsuario() }}>
                          <Icon>save</Icon> Vincular usuário
                        </Button>
                      </>
                    }
                  </>
                  :
                  <>
                    <label className="mb-4">CPF</label>
                    <Input mask="999.999.999-99" value={cpf} onChange={(event) => {
                      setCpf(event.target.value)
                    }} />
                    <label className="mb-4">Nome</label>
                    <Input value={nome} style={{ marginTop: -50 }} onChange={(event) => {
                      setNome(event.target.value)
                    }} />
                    <label className="mb-4">Email</label>
                    <Input value={email} style={{ marginTop: -50 }} onChange={(event) => {
                      setEmail(event.target.value)
                    }} />

                    <label className="mb-4">Contato</label>
                    <Input mask="(99) 9 9999-9999" value={contact} onChange={(event) => {
                      setContact(event.target.value)
                    }} />
                  </>
              }


              {
                message != "" && <div style={{ backgroundColor: "rgba(255,0,0,.2)", padding: 5, marginTop: 10 }}><Icon style={{ color: "red", marginRight: 5, marginLeft: 5 }} fontSize="small">close</Icon><span style={{ color: "red", fontWeight: "bold" }}>{message}</span></div>
              }
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>
    </Dialog>
  );
}

const mapStateToProps = state => ({
  user: state.user,
  clients_unbounds: state.client.clients_unbounds,
  loading: state.driver.loading,
  error: state.driver.error,
  success: state.driver.success,
});

export default connect(mapStateToProps)(MemberEditorDialog);