import AppList from "./AppList";
import InfiniteList from "./InfiniteList";

const ListRoute = [
  {
    path: "/egret-list",
    exact: true,
    component: AppList
  },
  {
    path: "/infinite-scroll",
    exact: true,
    component: InfiniteList
  }
];

export default ListRoute;
