import AppTodo from "./AppTodo";

const todoRoutes = [
  {
    path: "/todo/list",
    component: AppTodo
  }
];

export default todoRoutes;
