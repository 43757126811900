import history from "history.js";
import jwtAuthService from "../../services/jwtAuthService";
import axios from "axios";


export const REGISTER_PRODUCT_TYPE_LOADING = "REGISTER_PRODUCT_TYPE_LOADING";
export const REGISTER_PRODUCT_TYPE_SUCCESS = "REGISTER_PRODUCT_TYPE_SUCCESS";
export const REGISTER_PRODUCT_TYPE_FAILED = "REGISTER_PRODUCT_TYPE_FAILED";
export const GET_PRODUCT_TYPE_LOADING = "GET_PRODUCT_TYPE_LOADING";
export const GET_PRODUCT_TYPE_SUCCESS = "GET_PRODUCT_TYPE_SUCCESS";
export const GET_PRODUCT_TYPE_FAILED = "GET_PRODUCT_TYPE_FAILED";
export const DELETE_PRODUCT_TYPE_LOADING = "DELETE_PRODUCT_TYPE_LOADING";
export const DELETE_PRODUCT_TYPE_SUCCESS = "DELETE_PRODUCT_TYPE_SUCCESS";
export const DELETE_PRODUCT_TYPE_FAILED = "DELETE_PRODUCT_TYPE_FAILED";
export const UPDATE_PRODUCT_TYPE_LOADING = "UPDATE_PRODUCT_TYPE_LOADING";
export const UPDATE_PRODUCT_TYPE_SUCCESS = "UPDATE_PRODUCT_TYPE_SUCCESS";
export const UPDATE_PRODUCT_TYPE_FAILED = "UPDATE_PRODUCT_TYPE_FAILED";
export const GET_PRODUCT_LOADING = "GET_PRODUCT_LOADING";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILED = "GET_PRODUCT_FAILED";
export const GET_SPECIFIC_PRODUCT_LOADING = "GET_SPECIFIC_PRODUCT_LOADING";
export const GET_SPECIFIC_PRODUCT_SUCCESS = "GET_SPECIFIC_PRODUCT_SUCCESS";
export const GET_SPECIFIC_PRODUCT_FAILED = "GET_SPECIFIC_PRODUCT_FAILED";
export const DELETE_PRODUCT_LOADING = "DELETE_PRODUCT_LOADING";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILED = "DELETE_PRODUCT_FAILED";
export const CREATE_PRODUCT_LOADING = "CREATE_PRODUCT_LOADING";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAILED = "CREATE_PRODUCT_FAILED";
export const UPDATE_PRODUCT_LOADING = "UPDATE_PRODUCT_LOADING";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILED = "UPDATE_PRODUCT_FAILED";

export const CREATE_GOAL_M3_PRODUCT_LOADING = "CREATE_GOAL_M3_PRODUCT_LOADING";
export const CREATE_GOAL_M3_PRODUCT_SUCCESS = "CREATE_GOAL_M3_PRODUCT_SUCCESS";
export const CREATE_GOAL_M3_PRODUCT_FAILED = "CREATE_GOAL_M3_PRODUCT_FAILED";

export const UPDATE_GOAL_M3_PRODUCT_LOADING = "UPDATE_GOAL_M3_PRODUCT_LOADING";
export const UPDATE_GOAL_M3_PRODUCT_SUCCESS = "UPDATE_GOAL_M3_PRODUCT_SUCCESS";
export const UPDATE_GOAL_M3_PRODUCT_FAILED = "UPDATE_GOAL_M3_PRODUCT_FAILED";

export const LIST_GOAL_M3_PRODUCT_LOADING = "LIST_GOAL_M3_PRODUCT_LOADING";
export const LIST_GOAL_M3_PRODUCT_SUCCESS = "LIST_GOAL_M3_PRODUCT_SUCCESS";
export const LIST_GOAL_M3_PRODUCT_FAILED = "LIST_GOAL_M3_PRODUCT_FAILED";

export const DELETE_GOAL_M3_PRODUCT_LOADING = "DELETE_GOAL_M3_PRODUCT_LOADING";
export const DELETE_GOAL_M3_PRODUCT_SUCCESS = "DELETE_GOAL_M3_PRODUCT_SUCCESS";
export const DELETE_GOAL_M3_PRODUCT_FAILED = "DELETE_GOAL_M3_PRODUCT_FAILED";

export const CLEAR = "CLEAR";

export function listProductTypes(token, company) {
  return dispatch => {
    dispatch({
      type: GET_PRODUCT_TYPE_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/company/${company}/product/types`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: GET_PRODUCT_TYPE_SUCCESS,
        payload: res.data
      });
      console.log("res product type", res)
    }).catch(eer => {
      console.log("eer product type", eer?.response?.data?.message)
      dispatch({
        type: GET_PRODUCT_TYPE_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}
export function registerProductType(token, data, company) {
  return dispatch => {
    dispatch({
      type: REGISTER_PRODUCT_TYPE_LOADING,
    });
    axios({
      method: 'post',
      url: `https://api.stonik.com.br/web/company/${company}/product/types`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: REGISTER_PRODUCT_TYPE_SUCCESS,
        payload: res.data
      });
      console.log("res ADD product type", res)
    }).catch(eer => {
      console.log("eer ADD product type", eer?.response?.data?.message)
      dispatch({
        type: REGISTER_PRODUCT_TYPE_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function updateProductType(token, data, company) {
  return dispatch => {
    dispatch({
      type: UPDATE_PRODUCT_TYPE_LOADING,
    });
    axios({
      method: 'put',
      url: `https://api.stonik.com.br/web/company/${company}/product/types/${data.id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: UPDATE_PRODUCT_TYPE_SUCCESS,
        payload: res.data
      });
      console.log("res product", res)
    }).catch(eer => {
      console.log("eer product", eer?.response?.data?.message)
      dispatch({
        type: UPDATE_PRODUCT_TYPE_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function deleteProductType(token, typeId, company) {
  return dispatch => {
    dispatch({
      type: DELETE_PRODUCT_TYPE_LOADING,
    });
    axios({
      method: 'delete',
      url: `https://api.stonik.com.br/web/company/${company}/product/types/${typeId}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: DELETE_PRODUCT_TYPE_SUCCESS,
        payload: res.data
      });
      console.log("res product", res)
    }).catch(eer => {
      console.log("eer product", eer?.response?.data?.message)
      dispatch({
        type: DELETE_PRODUCT_TYPE_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function listSpecificProduct(token, company, id) {
  return dispatch => {
    dispatch({
      type: GET_SPECIFIC_PRODUCT_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/company/${company}/product/${id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: GET_SPECIFIC_PRODUCT_SUCCESS,
        payload: res.data
      });
      console.log("res product", res)
    }).catch(eer => {
      console.log("eer product", eer?.response?.data?.message)
      dispatch({
        type: GET_SPECIFIC_PRODUCT_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}
export function listProducts(token, company, typeId = null) {
  return dispatch => {
    dispatch({
      type: GET_PRODUCT_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/company/${company}/product?typeId=${typeId != null ? typeId : ""}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: GET_PRODUCT_SUCCESS,
        payload: res.data
      });
      console.log("res product", res)
    }).catch(eer => {
      console.log("eer product", eer?.response?.data?.message)
      dispatch({
        type: GET_PRODUCT_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function deleteProduct(token, company, productId) {
  return dispatch => {
    dispatch({
      type: DELETE_PRODUCT_LOADING,
    });
    axios({
      method: 'delete',
      url: `https://api.stonik.com.br/web/company/${company}/product/${productId}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: DELETE_PRODUCT_SUCCESS,
        payload: res.data
      });
      console.log("res product", res)
    }).catch(eer => {
      console.log("eer product", eer?.response?.data?.message)
      dispatch({
        type: DELETE_PRODUCT_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function createProduct(token, company, data) {
  return dispatch => {
    dispatch({
      type: CREATE_PRODUCT_LOADING,
    });
    axios({
      method: 'post',
      url: `https://api.stonik.com.br/web/company/${company}/product`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: CREATE_PRODUCT_SUCCESS,
        payload: res.data
      });
      console.log("res product", res)
    }).catch(eer => {
      console.log("eer product", eer?.response?.data?.message)
      dispatch({
        type: CREATE_PRODUCT_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function updateProduct(token, company, data) {
  return dispatch => {
    dispatch({
      type: UPDATE_PRODUCT_LOADING,
    });
    axios({
      method: 'put',
      url: `https://api.stonik.com.br/web/company/${company}/product/${data.id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: UPDATE_PRODUCT_SUCCESS,
        payload: res.data
      });
      console.log("res product", res)
    }).catch(eer => {
      console.log("eer product", eer?.response?.data?.message)
      dispatch({
        type: UPDATE_PRODUCT_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function listGoalm3Product(token, company) {
  return dispatch => {
    dispatch({
      type: LIST_GOAL_M3_PRODUCT_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/company/${company}/goal/product`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_GOAL_M3_PRODUCT_SUCCESS,
        payload: res.data
      });
      console.log("res product goal m3", res)
    }).catch(eer => {
      console.log("eer product goal m3", eer?.response?.data?.message)
      dispatch({
        type: LIST_GOAL_M3_PRODUCT_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}
export function createGoalm3Product(token, data, company) {
  return dispatch => {
    dispatch({
      type: CREATE_GOAL_M3_PRODUCT_LOADING,
    });
    axios({
      method: 'post',
      url: `https://api.stonik.com.br/web/company/${company}/goal/product`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: CREATE_GOAL_M3_PRODUCT_SUCCESS,
        payload: res.data
      });
      console.log("res ADD create goal m3", res)
    }).catch(eer => {
      console.log("eer ADD create goal m3", eer?.response?.data?.message)
      dispatch({
        type: CREATE_GOAL_M3_PRODUCT_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function updateGoalm3Product(token, data, company) {
  return dispatch => {
    dispatch({
      type: UPDATE_GOAL_M3_PRODUCT_LOADING,
    });
    axios({
      method: 'put',
      url: `https://api.stonik.com.br/web/company/${company}/goal/product/${data?.product?.id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: UPDATE_GOAL_M3_PRODUCT_SUCCESS,
        payload: res.data
      });
      console.log("res update m3 goal", res)
    }).catch(eer => {
      console.log("eer update m3 goal", eer?.response?.data?.message)
      dispatch({
        type: UPDATE_GOAL_M3_PRODUCT_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function deleteGoalm3Product(token, company, id) {
  return dispatch => {
    dispatch({
      type: DELETE_GOAL_M3_PRODUCT_LOADING,
    });
    axios({
      method: 'delete',
      url: `https://api.stonik.com.br/web/company/${company}/goal/product/${id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: DELETE_GOAL_M3_PRODUCT_SUCCESS,
        payload: res.data
      });
      console.log("res delete goal m3", res)
    }).catch(eer => {
      console.log("eer delete goal m3", eer?.response?.data?.message)
      dispatch({
        type: DELETE_GOAL_M3_PRODUCT_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function clear() {
  return dispatch => {
    dispatch({
      type: CLEAR
    });
  };
}
