import MyConfigs from "./MyConfigs";

const formsRoutes = [
  {
    path: "/producao-esperada-por-betoneira",
    component: MyConfigs
  },
];

export default formsRoutes;
