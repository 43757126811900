import {
  LIST_CLIENT_LOADING,
  LIST_CLIENT_SUCCESS,
  LIST_CLIENT_FAILED,
  LIST_CLIENT_UNBOUND_LOADING,
  LIST_CLIENT_UNBOUND_SUCCESS,
  LIST_CLIENT_UNBOUND_FAILED,
  LIST_CLIENT_LINKED_LOADING,
  LIST_CLIENT_LINKED_SUCCESS,
  LIST_CLIENT_LINKED_FAILED,
  LIST_NOT_CLIENT_LOADING,
  LIST_NOT_CLIENT_SUCCESS,
  LIST_NOT_CLIENT_FAILED,
  CREATE_CLIENT_LOADING,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_FAILED,
  ADD_USER_TO_CLIENT_LOADING,
  ADD_USER_TO_CLIENT_SUCCESS,
  ADD_USER_TO_CLIENT_FAILED,
  UPDATE_CLIENT_LOADING,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILED,
  DELETE_CLIENT_LOADING,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILED,
  CLEAR,
  CLEAR_UNBOUND
} from "../actions/ClientActions";


const initialState = {success: false,
  loading: false,
  error: null,
  clients: [],
  not_clients: [],
  clients_unbounds: null,
  clients_linkeds: null
};

const userReducer = function(state = initialState, action) {
  switch (action.type) {
    case LIST_CLIENT_LINKED_LOADING: {
      console.log("LIST_CLIENT_LINKED_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_CLIENT_LINKED_SUCCESS: {
      console.log("LIST_CLIENT_LINKED_SUCCESS", action?.payload)
      return {
        ...state,
        clients_linkeds: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_CLIENT_LINKED_FAILED: {
      console.log("LIST_CLIENT_LINKED_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case LIST_CLIENT_UNBOUND_LOADING: {
      console.log("LIST_CLIENT_UNBOUND_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_CLIENT_UNBOUND_SUCCESS: {
      console.log("LIST_CLIENT_UNBOUND_SUCCESS", action?.payload)
      return {
        ...state,
        clients_unbounds: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_CLIENT_UNBOUND_FAILED: {
      console.log("LIST_CLIENT_UNBOUND_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case LIST_CLIENT_LOADING: {
      console.log("LIST_CLIENT_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_CLIENT_SUCCESS: {
      console.log("LIST_CLIENT_SUCCESS", action?.payload)
      return {
        ...state,
        clients: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_CLIENT_FAILED: {
      console.log("LIST_CLIENT_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case LIST_NOT_CLIENT_LOADING: {
      console.log("LIST_NOT_CLIENT_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_NOT_CLIENT_SUCCESS: {
      console.log("LIST_NOT_CLIENT_SUCCESS", action?.payload)
      return {
        ...state,
        not_clients: action?.payload,
        loading: false,
        error: null
      };
    }
    case LIST_NOT_CLIENT_FAILED: {
      console.log("LIST_NOT_CLIENT_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case ADD_USER_TO_CLIENT_LOADING: {
      console.log("ADD_USER_TO_CLIENT_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_USER_TO_CLIENT_SUCCESS: {
      console.log("ADD_USER_TO_CLIENT_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Cliente vinculado com sucesso!",
        loading: false,
        error: null
      };
    }
    case ADD_USER_TO_CLIENT_FAILED: {
      console.log("ADD_USER_TO_CLIENT_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case CREATE_CLIENT_LOADING: {
      console.log("CREATE_CLIENT_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case CREATE_CLIENT_SUCCESS: {
      console.log("CREATE_CLIENT_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Cliente adicionado com sucesso!",
        loading: false,
        error: null
      };
    }
    case CREATE_CLIENT_FAILED: {
      console.log("CREATE_CLIENT_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case UPDATE_CLIENT_LOADING: {
      console.log("UPDATE_CLIENT_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_CLIENT_SUCCESS: {
      console.log("UPDATE_CLIENT_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Cliente atualizado com sucesso!",
        loading: false,
        error: null
      };
    }
    case UPDATE_CLIENT_FAILED: {
      console.log("UPDATE_CLIENT_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    case DELETE_CLIENT_LOADING: {
      console.log("DELETE_CLIENT_LOADING")
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_CLIENT_SUCCESS: {
      console.log("DELETE_CLIENT_SUCCESS", action?.payload)
      return {
        ...state,
        success: "Cliente deletado com sucesso!",
        loading: false,
        error: null
      };
    }
    case DELETE_CLIENT_FAILED: {
      console.log("DELETE_CLIENT_FAILED", action.payload)
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    }
    
    case CLEAR_UNBOUND: {
      return {
        ...state,
        clients_unbounds: null
      };
    }
    case CLEAR: {
      return {
        ...state,
        error: null,
        success: false,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default userReducer;
