import React, { Component, useEffect, useState } from "react";
import {
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Icon,
  TablePagination,
  Button,
  Card
} from "@material-ui/core";
import { getAllUser, deleteUser } from "./TableService";
import MemberEditorDialog from "./MemberEditorDialog";
import { Breadcrumb, ConfirmationDialog } from "egret";
import shortid from "shortid";
import { connect } from "react-redux";
import { listGoalm3Product, deleteGoalm3Product, clear } from "../../redux/actions/ProductActions"
import Swal from 'sweetalert2'

const CrudTable = props => {
  const { 
    userRedux, 
    salesmans, 
    loading,
    success, 
    error,
    goalsProducts,
    successProduct,
    errorProduct
   } = props

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [uid, setUid] = useState(null)
  const [salesmanList, setSalesmanList] = useState([])
  const [userList, setUserList] = useState([])
  const [shouldOpenEditorDialog, setShouldOpenEditorDialog] = useState(false)
  const [shouldOpenConfirmationDialog, setShouldOpenConfirmationDialog] = useState(false)

  const setPageFunction = page => {
    setPage(page);
  };

  const setRowsPerPageFunction = event => {
    setRowsPerPage(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPageFunction(newPage);
  };

  const handleDialogCloseFunction = () => {
    setShouldOpenEditorDialog(false)
    setShouldOpenConfirmationDialog(false)
  };

  const handleDeleteUser = item => {
    setShouldOpenConfirmationDialog(true)
    setUid(item)
  };

  const handleConfirmationResponse = () => {
    props.dispatch(deleteGoalm3Product(userRedux?.token, userRedux?.company, uid))
  }

  useEffect(() => {
    props.dispatch(listGoalm3Product(userRedux?.token, userRedux?.company))
  }, [])

  useEffect(() => {
    if (successProduct != false) {
      setShouldOpenConfirmationDialog(false)
      Swal.fire({
        icon: 'success',
        title: successProduct,
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(() => {
        props.dispatch(clear())
        window.location.reload()
      }, 3500)
    }
  }, [successProduct])

  useEffect(() => {
    if (errorProduct != null) {
      console.log("Error", errorProduct)
      setShouldOpenConfirmationDialog(false)

      Swal.fire({
        icon: 'error',
        title: errorProduct,
        showConfirmButton: false,
        timer: 3000
      })

      setTimeout(() => {
        props.dispatch(clear())
        setShouldOpenEditorDialog(true)
        // window.location.reload()
      }, 3500)
    }
  }, [errorProduct])

  useEffect(() => {
    console.log("goalsProducts", goalsProducts)
  }, [goalsProducts])

  
    return (
      <div className="m-sm-30">
        <div className="mb-sm-30">
          <Breadcrumb routeSegments={[{ name: "Valor médio de metro cúbico para venda" }]} />
        </div>

        <Button
          className="mb-16"
          variant="contained"
          color="primary"
          onClick={() => setShouldOpenEditorDialog(true)}
        >
          <Icon>add-circle</Icon>
          Adicionar registro
        </Button>
        <Card className="w-100 overflow-auto" elevation={6}>
          <Table className="crud-table" style={{ whiteSpace: "pre", minWidth: "750px" }}>
            <TableHead>
              <TableRow>
                <TableCell>Tipo do Produto</TableCell>
                <TableCell>Valor em M3</TableCell>
                <TableCell align="center">Ação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {goalsProducts
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow key={shortid.generate()}>
                    <TableCell className="px-0" align="left">
                      {item?.product?.name}
                    </TableCell>
                    <TableCell className="px-0" align="left">
                      {item?.volumeM3}
                    </TableCell>
                    <TableCell className="px-0 border-none" align="center">
                      <IconButton
                        onClick={() => {
                          setUid(item)
                          setShouldOpenEditorDialog(true)
                        }}
                      >
                        <Icon color="primary">edit</Icon>
                      </IconButton>
                      <IconButton onClick={() => handleDeleteUser(item?.product?.id)}>
                        <Icon color="error">delete</Icon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>

          <TablePagination
            className="px-16"
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={userList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Linhas por Página"
            backIconButtonProps={{
              "aria-label": "Previous Page"
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page"
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={setRowsPerPage}
          />

          {shouldOpenEditorDialog && (
            <MemberEditorDialog
              handleClose={handleDialogCloseFunction}
              open={shouldOpenEditorDialog}
              uid={uid}
            />
          )}
          {shouldOpenConfirmationDialog && (
            <ConfirmationDialog
              open={shouldOpenConfirmationDialog}
              onConfirmDialogClose={handleDialogCloseFunction}
              onYesClick={handleConfirmationResponse}
              text="Tem certeza que deseja excluir este registro?"
              title="Excluir registro"
              confirmButtonText="Sim"
              declineButtonText="Não"
            />
          )}
        </Card>
      </div>
    );
}

const mapStateToProps = state => ({
  userRedux: state.user,
  goalsProducts: state.product.goalsProducts,
  successProduct: state.product.successProduct,
  errorProduct: state.product.errorProduct,
});

export default connect(mapStateToProps)(CrudTable);
