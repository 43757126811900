import UserTable from "./UserTable";

const UserRoute = [
  {
    path: "/ponto-excecao",
    exact: true,
    component: UserTable
  }
];

export default UserRoute;
