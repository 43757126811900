import history from "history.js";
import jwtAuthService from "../../services/jwtAuthService";
import axios from "axios";


export const LIST_DISCARD_ANALISYS_REPORT_LOADING = "LIST_DISCARD_ANALISYS_REPORT_LOADING";
export const LIST_DISCARD_ANALISYS_REPORT_SUCCESS = "LIST_DISCARD_ANALISYS_REPORT_SUCCESS";
export const LIST_DISCARD_ANALISYS_REPORT_FAILED = "LIST_DISCARD_ANALISYS_REPORT_FAILED";
export const LIST_DISCARD_RADIUS_REPORT_LOADING = "LIST_DISCARD_RADIUS_REPORT_LOADING";
export const LIST_DISCARD_RADIUS_REPORT_SUCCESS = "LIST_DISCARD_RADIUS_REPORT_SUCCESS";
export const LIST_DISCARD_RADIUS_REPORT_FAILED = "LIST_DISCARD_RADIUS_REPORT_FAILED";
export const LIST_DISCARD_REPORT_LOADING = "LIST_DISCARD_REPORT_LOADING";
export const LIST_DISCARD_REPORT_SUCCESS = "LIST_DISCARD_REPORT_SUCCESS";
export const LIST_DISCARD_REPORT_FAILED = "LIST_DISCARD_REPORT_FAILED";
export const LIST_DISCARD_LOADING = "LIST_DISCARD_LOADING";
export const LIST_DISCARD_SUCCESS = "LIST_DISCARD_SUCCESS";
export const LIST_DISCARD_FAILED = "LIST_DISCARD_FAILED";
export const UPDATE_DISCARD_LOADING = "UPDATE_DISCARD_LOADING";
export const UPDATE_DISCARD_SUCCESS = "UPDATE_DISCARD_SUCCESS";
export const UPDATE_DISCARD_FAILED = "UPDATE_DISCARD_FAILED";
export const DELETE_DISCARD_LOADING = "DELETE_DISCARD_LOADING";
export const DELETE_DISCARD_SUCCESS = "DELETE_DISCARD_SUCCESS";
export const DELETE_DISCARD_FAILED = "DELETE_DISCARD_FAILED";
export const CREATE_DISCARD_LOADING = "CREATE_DISCARD_LOADING";
export const CREATE_DISCARD_SUCCESS = "CREATE_DISCARD_SUCCESS";
export const CREATE_DISCARD_FAILED = "CREATE_DISCARD_FAILED";

export const CLEAR = "CLEAR";

export function listDiscardPerRadiusReport(token, company, start, end, locationId = null) {
  return dispatch => {
    dispatch({
      type: LIST_DISCARD_RADIUS_REPORT_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/report/discard/raio?companyId=${company}${locationId != null ? "&locationId=" + locationId : ""}&startDateFilter=${start}&endDateFilter=${end}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_DISCARD_RADIUS_REPORT_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_DISCARD_RADIUS_REPORT_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function listAnalisysDiscardReport(token, company, start, end, branch = null) {
  return dispatch => {
    dispatch({
      type: LIST_DISCARD_ANALISYS_REPORT_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/report/discard/analyze?companyId=${company}${branch != null ? '&companyBranchId='  + branch : ''}&startDateFilter=${start}&endDateFilter=${end}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_DISCARD_ANALISYS_REPORT_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_DISCARD_ANALISYS_REPORT_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function listDiscardReport(token, company, start, end, branch = null) {
  return dispatch => {
    dispatch({
      type: LIST_DISCARD_REPORT_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/report/discard?companyId=${company}&startDateFilter=${start}&endDateFilter=${end}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_DISCARD_REPORT_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_DISCARD_REPORT_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function listDiscard(token, company) {
  return dispatch => {
    dispatch({
      type: LIST_DISCARD_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/company/${company}/location/discard`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_DISCARD_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_DISCARD_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function createDiscard(token, data, company) {
  return dispatch => {
    dispatch({
      type: CREATE_DISCARD_LOADING,
    });
    axios({
      method: 'post',
      url: `https://api.stonik.com.br/web/company/${company}/location/discard`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: CREATE_DISCARD_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: CREATE_DISCARD_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}
export function updateDiscard(token, data, company) {
  return dispatch => {
    dispatch({
      type: UPDATE_DISCARD_LOADING,
    });
    axios({
      method: 'put',
      url: `https://api.stonik.com.br/web/company/${company}/location/discard/${data.id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: UPDATE_DISCARD_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: UPDATE_DISCARD_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function deleteDiscard(token, id, company) {
  return dispatch => {
    dispatch({
      type: DELETE_DISCARD_LOADING,
    });
    axios({
      method: 'delete',
      url: `https://api.stonik.com.br/web/company/${company}/location/discard/${id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: DELETE_DISCARD_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: DELETE_DISCARD_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function clear() {
  return dispatch => {
    dispatch({
      type: CLEAR
    });
  };
}
