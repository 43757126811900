import history from "history.js";
import jwtAuthService from "../../services/jwtAuthService";
import axios from "axios";


export const LIST_FLEET_LOADING = "LIST_FLEET_LOADING";
export const LIST_FLEET_SUCCESS = "LIST_FLEET_SUCCESS";
export const LIST_FLEET_FAILED = "LIST_FLEET_FAILED";
export const UPDATE_FLEET_LOADING = "UPDATE_FLEET_LOADING";
export const UPDATE_FLEET_SUCCESS = "UPDATE_FLEET_SUCCESS";
export const UPDATE_FLEET_FAILED = "UPDATE_FLEET_FAILED";
export const DELETE_FLEET_LOADING = "DELETE_FLEET_LOADING";
export const DELETE_FLEET_SUCCESS = "DELETE_FLEET_SUCCESS";
export const DELETE_FLEET_FAILED = "DELETE_FLEET_FAILED";
export const CREATE_FLEET_LOADING = "CREATE_FLEET_LOADING";
export const CREATE_FLEET_SUCCESS = "CREATE_FLEET_SUCCESS";
export const CREATE_FLEET_FAILED = "CREATE_FLEET_FAILED";

export const CREATE_GOAL_M3_VEHICLE_LOADING = "CREATE_GOAL_M3_VEHICLE_LOADING";
export const CREATE_GOAL_M3_VEHICLE_SUCCESS = "CREATE_GOAL_M3_VEHICLE_SUCCESS";
export const CREATE_GOAL_M3_VEHICLE_FAILED = "CREATE_GOAL_M3_VEHICLE_FAILED";

export const UPDATE_GOAL_M3_VEHICLE_LOADING = "UPDATE_GOAL_M3_VEHICLE_LOADING";
export const UPDATE_GOAL_M3_VEHICLE_SUCCESS = "UPDATE_GOAL_M3_VEHICLE_SUCCESS";
export const UPDATE_GOAL_M3_VEHICLE_FAILED = "UPDATE_GOAL_M3_VEHICLE_FAILED";

export const LIST_GOAL_M3_VEHICLE_LOADING = "LIST_GOAL_M3_VEHICLE_LOADING";
export const LIST_GOAL_M3_VEHICLE_SUCCESS = "LIST_GOAL_M3_VEHICLE_SUCCESS";
export const LIST_GOAL_M3_VEHICLE_FAILED = "LIST_GOAL_M3_VEHICLE_FAILED";

export const DELETE_GOAL_M3_VEHICLE_LOADING = "DELETE_GOAL_M3_VEHICLE_LOADING";
export const DELETE_GOAL_M3_VEHICLE_SUCCESS = "DELETE_GOAL_M3_VEHICLE_SUCCESS";
export const DELETE_GOAL_M3_VEHICLE_FAILED = "DELETE_GOAL_M3_VEHICLE_FAILED";

export const CLEAR = "CLEAR";

export function listFleet(token, company) {
  return dispatch => {
    dispatch({
      type: LIST_FLEET_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/company/${company}/vehicle/`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_FLEET_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_FLEET_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function createFleet(token, data, company) {
  return dispatch => {
    dispatch({
      type: CREATE_FLEET_LOADING,
    });
    axios({
      method: 'post',
      url: `https://api.stonik.com.br/web/company/${company}/vehicle/`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: CREATE_FLEET_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: CREATE_FLEET_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}
export function updateFleet(token, data, company) {
  return dispatch => {
    dispatch({
      type: UPDATE_FLEET_LOADING,
    });
    axios({
      method: 'put',
      url: `https://api.stonik.com.br/web/company/${company}/vehicle/${data.id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: UPDATE_FLEET_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: UPDATE_FLEET_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function deleteFleet(token, id, company) {
  return dispatch => {
    dispatch({
      type: DELETE_FLEET_LOADING,
    });
    axios({
      method: 'delete',
      url: `https://api.stonik.com.br/web/company/${company}/vehicle/${id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: DELETE_FLEET_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: DELETE_FLEET_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function listGoalm3Vehicle(token, company) {
  return dispatch => {
    dispatch({
      type: LIST_GOAL_M3_VEHICLE_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/company/${company}/goal/betoneira`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_GOAL_M3_VEHICLE_SUCCESS,
        payload: res.data
      });
      console.log("res VEHICLE goal m3", res)
    }).catch(eer => {
      console.log("eer VEHICLE goal m3", eer?.response?.data?.message)
      dispatch({
        type: LIST_GOAL_M3_VEHICLE_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}
export function createGoalm3Vehicle(token, data, company) {
  return dispatch => {
    dispatch({
      type: CREATE_GOAL_M3_VEHICLE_LOADING,
    });
    axios({
      method: 'post',
      url: `https://api.stonik.com.br/web/company/${company}/goal/betoneira`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: CREATE_GOAL_M3_VEHICLE_SUCCESS,
        payload: res.data
      });
      console.log("res ADD create goal m3", res)
    }).catch(eer => {
      console.log("eer ADD create goal m3", eer?.response?.data?.message)
      dispatch({
        type: CREATE_GOAL_M3_VEHICLE_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function updateGoalm3Vehicle(token, data, company) {
  return dispatch => {
    dispatch({
      type: UPDATE_GOAL_M3_VEHICLE_LOADING,
    });
    axios({
      method: 'put',
      url: `https://api.stonik.com.br/web/company/${company}/goal/betoneira/${data?.branch?.id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: UPDATE_GOAL_M3_VEHICLE_SUCCESS,
        payload: res.data
      });
      console.log("res update m3 goal", res)
    }).catch(eer => {
      console.log("eer update m3 goal", eer?.response?.data?.message)
      dispatch({
        type: UPDATE_GOAL_M3_VEHICLE_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function deleteGoalm3Vehicle(token, company, branch) {
  return dispatch => {
    dispatch({
      type: DELETE_GOAL_M3_VEHICLE_LOADING,
    });
    axios({
      method: 'delete',
      url: `https://api.stonik.com.br/web/company/${company}/goal/betoneira/${branch}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: DELETE_GOAL_M3_VEHICLE_SUCCESS,
        payload: res.data
      });
      console.log("res delete goal m3", res)
    }).catch(eer => {
      console.log("eer delete goal m3", eer?.response?.data?.message)
      dispatch({
        type: DELETE_GOAL_M3_VEHICLE_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function clear() {
  return dispatch => {
    dispatch({
      type: CLEAR
    });
  };
}
