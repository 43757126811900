import AppScrumBoard from "./AppScrumBoard";

import Board from "./Board";

const scrumBoardRoutes = [
  {
    path: "/scrum-board/:id",
    component: Board
  },
  {
    path: "/scrum-board",
    component: AppScrumBoard
  }
];

export default scrumBoardRoutes;
