import history from "history.js";
import jwtAuthService from "../../services/jwtAuthService";
import axios from "axios";


export const LIST_SALESMAN_LOADING = "LIST_SALESMAN_LOADING";
export const LIST_SALESMAN_SUCCESS = "LIST_SALESMAN_SUCCESS";
export const LIST_SALESMAN_FAILED = "LIST_SALESMAN_FAILED";
export const UPDATE_SALESMAN_LOADING = "UPDATE_SALESMAN_LOADING";
export const UPDATE_SALESMAN_SUCCESS = "UPDATE_SALESMAN_SUCCESS";
export const UPDATE_SALESMAN_FAILED = "UPDATE_SALESMAN_FAILED";
export const DELETE_SALESMAN_LOADING = "DELETE_SALESMAN_LOADING";
export const DELETE_SALESMAN_SUCCESS = "DELETE_SALESMAN_SUCCESS";
export const DELETE_SALESMAN_FAILED = "DELETE_SALESMAN_FAILED";
export const CREATE_SALESMAN_LOADING = "CREATE_SALESMAN_LOADING";
export const CREATE_SALESMAN_SUCCESS = "CREATE_SALESMAN_SUCCESS";
export const CREATE_SALESMAN_FAILED = "CREATE_SALESMAN_FAILED";

export const CLEAR = "CLEAR";

export function listSalesman(token, company) {
  return dispatch => {
    dispatch({
      type: LIST_SALESMAN_LOADING,
    });
    axios({
      method: 'get',
      url: `https://api.stonik.com.br/web/company/${company}/seller`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: LIST_SALESMAN_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: LIST_SALESMAN_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function createSalesman(token, data, company) {
  return dispatch => {
    dispatch({
      type: CREATE_SALESMAN_LOADING,
    });
    axios({
      method: 'post',
      url: `https://api.stonik.com.br/web/company/${company}/seller`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: CREATE_SALESMAN_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: CREATE_SALESMAN_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}
export function updateSalesman(token, data, company) {
  return dispatch => {
    dispatch({
      type: UPDATE_SALESMAN_LOADING,
    });
    axios({
      method: 'put',
      url: `https://api.stonik.com.br/web/company/${company}/seller/${data.id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data
    }).then(res => {
      dispatch({
        type: UPDATE_SALESMAN_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: UPDATE_SALESMAN_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function deleteSalesman(token, id, company) {
  return dispatch => {
    dispatch({
      type: DELETE_SALESMAN_LOADING,
    });
    axios({
      method: 'delete',
      url: `https://api.stonik.com.br/web/company/${company}/seller/${id}`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    }).then(res => {
      dispatch({
        type: DELETE_SALESMAN_SUCCESS,
        payload: res.data
      });
      console.log("res", res)
    }).catch(eer => {
      console.log("eer", eer?.response?.data?.message)
      dispatch({
        type: DELETE_SALESMAN_FAILED,
        payload: eer?.response?.data?.message
      });
    });
  };
}

export function clear() {
  return dispatch => {
    dispatch({
      type: CLEAR
    });
  };
}
