import UserTable from "./UserTable";

const UserRoute = [
  {
    path: "/produtos",
    exact: true,
    component: UserTable
  }
];

export default UserRoute;
