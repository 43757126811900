import { authRoles } from "../../auth/authRoles";

import Analytics from "./Painel";

const dashboardRoutes = [
  {
    path: "/painel",
    component: Analytics,
  },
];

export default dashboardRoutes;
